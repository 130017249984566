<div class="mb-5">

  <div [formGroup]="professionistiSearch" class="flex flex-column justify-content-center py-5">
    <p class="grey mb-1">Nome, Cognome, Codice Fiscale</p>

    <p-autoComplete
      appendTo="body"
      formControlName="professionistaSelezionato"
      field="anagrafica"
      [suggestions]="professionistiFiltered"
      (completeMethod)="filterProfessionisti($event)"
      [minLength]="3"
      (input)="inputValueAutocomplete($event)"
    >
      <ng-template let-professionista pTemplate="item">
        <div class="flex align-items-center gap-2">
            <div>{{ professionista.nome }}, {{ professionista.cognome }}, {{ professionista.codiceFiscale }}</div>
        </div>
      </ng-template>

    </p-autoComplete>

  </div>

</div>

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem"></i>
  }@else {
    <p-button label="Iscrivi Professionista" (click)="iscrizioneProfessionista()"></p-button>
  }
</div>
