<div class="flex flex-column flex-wrap backPanel border-round-3xl px-5 mt-5 py-5 align-items-center sm:align-items-start">

  <div>
    <span class="p-float-label px-3 mb-6">
      <p-dropdown [(ngModel)]="nomeListaAttuale" [options]="nomiListe" optionLabel="descrizione" (onChange)="popolaListaAttuale(nomeListaAttuale?.nome!)"></p-dropdown>
    </span>
  </div>

  <p-card [header]="nomeListaAttuale?.descrizione" [style]="{ width: 'fit-content' }" class="mb-5">

    <div class="pt-3">
      @for (item of listaCorrente; track $index) {
        <div class="flex align-items-center mb-1">
          <p-inputSwitch [(ngModel)]="item.attivo" (ngModelChange)="attivoChange()" class="mr-3"></p-inputSwitch>
          <p class="font-semibold text-xl m-0 pb-1">{{ item[getDato(nomeListaAttuale?.nome!)] }}</p>
          <p-button icon="pi pi-pencil" (click)="modificaOption(item, nomeListaAttuale?.nome!)" [rounded]="true" [text]="true" class="pencil ml-2 mb-1" size="small"></p-button>
        </div>
      }
    </div>


    <div class="flex justify-content-center pt-5 pb-3" [formGroup]="nuovaOpzioneForm">

      <input formControlName="opzione" class="borderCustomRight fixedWidth" type="text" placeholder="Aggiungi Opzione" pInputText/>
      <p-button class="borderCustomLeft" severity="success" icon="pi pi-plus" (click)="inviaOpzione(nomeListaAttuale?.nome!)"></p-button>

    </div>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-center">
        <p-button label="Salva Modifiche" icon="pi pi-save" [disabled]="bottoneDisabilitato" (click)="invioModifiche(nomeListaAttuale?.nome!)"></p-button>
      </div>
    </ng-template>

  </p-card>

  <p-toast position="bottom-center"></p-toast>

</div>
