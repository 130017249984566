import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { IscrittoQuoteDto, Pagamento, PagamentoMezzo, Quota } from '../model/iscritti-data';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  private _http = inject(HttpClient);
  private quote_api: string = `${environment.baseUrl}/quote`;

  getQuoteIscritto(id: string | number): Observable<Quota[]>{
    return this._http.get<Quota[]>(`${this.quote_api}/iscritto/${id}`);
  }

  getIscrittoQuoteList(id: string): Observable<IscrittoQuoteDto>{
    return this._http.get<IscrittoQuoteDto>(`${this.quote_api}/iscritto_quote_list/${id}`);
  }

  inserirePagamento(id: number, data: any): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.quote_api}/inserisci-pagamento/${id}`, data);
  }

  getPagamentiOptions(): Observable<PagamentoMezzo[]>{
    return this._http.get<PagamentoMezzo[]>(`${this.quote_api}/pagamenti-options`);
  }

  gestisciCartellaEsattoriale(id: number, cartellaEsattorialeValue: boolean): Observable<Boolean>{
    const params = new HttpParams().set('cartellaEsattorialeValue', cartellaEsattorialeValue.toString());
    return this._http.put<Boolean>(`${this.quote_api}/update-cartella-esattoriale/${id}`, {}, { params: params });
  }

  getSpeseAmministrativeCorrenti(): Observable<number>{
    return this._http.get<number>(`${this.quote_api}/spese-amministrative-correnti`);
  }

  applicaSpeseAmministrativeCorrenti(): Observable<Boolean>{
    return this._http.put<Boolean>(`${this.quote_api}/applica-spese-amministrative-correnti`, {});
  }

}
