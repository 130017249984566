<div>
  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0 gap-3">

        <!-- ricerca iscritti -->
        <div class="sm:mr-3">
          <span class="p-input-icon-left p-input-icon-right p-float-label">
            <i class="pi pi-search"></i>
            <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="stringaFiltro" />
            <label for="cercaIscritto">Cerca Utente</label>
            <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
          </span>
        </div>

        <div class="mb-3 sm:mb-0">
          <p-dropdown formControlName="sezione" [showClear]="true" [options]="sezioniDropdown" optionLabel="nome" placeholder="Sezione"></p-dropdown>
        </div>

        <div class="mb-3 sm:mb-0">
          <p-dropdown formControlName="statoIscrizione" [showClear]="true" [options]="statiIscrizioneDropdown" optionLabel="nome" placeholder="Stato Iscrizione"></p-dropdown>
        </div>

      </div>

    </div>


    <div class="mb-5">

        <!-- PAGINATOR della tabella -->
        <div class="flex justify-content-start">
          <p-paginator
            [rows]="pageSize"
            [first]="currentPage"
            [rowsPerPageOptions]="[25, 100, 250, 500]"
            (onPageChange)="pageChange($event)"
            [totalRecords]="totaleDati"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} di {totalRecords}"
            [showPageLinks]="false"
          >
          </p-paginator>
        </div>

      </div>


  </div>
</div>

<div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaIscrizioni"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th [pSortableColumn]="headerName">
                {{ headerName }}
                <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscrizione>
          <!-- [routerLink]="generaRoutingLink(iscritto)" -->
          <tr style="cursor: pointer;" [routerLink]="'/domande_iscrizione/storico/dettaglio_pratica/' + iscrizione.id">
            @for (rowName of rowsTabella; track $index) {
              <td 
                [ngClass]="checkColumn(rowName) ? 'stato' : null"
                [ngStyle]="checkColumn(rowName) ? { 'color': statoColori.get(iscrizione.statoIscrizione) } : null">
                {{ iscrizione[rowName] }}
              </td>
            }
          </tr>
      </ng-template>
    </p-table>

  </div>

</div>
