import { Component, inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { TipoDelibera, Delibera, DeliberaListDto, DeliberaFiltri } from '../../model/delibere-data';
import { DeliberaService } from '../../services/delibera.service';
import { DialogNuovaDeliberaComponent } from '../dialog-nuova-delibera/dialog-nuova-delibera.component';
import { RoleService } from '../../services/role.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';

@Component({
  selector: 'app-tabella-delibere-finalizza',
  templateUrl: './tabella-delibere-finalizza.component.html',
  styleUrl: './tabella-delibere-finalizza.component.scss'
})
export class TabellaDelibereFinalizzaComponent {

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private dialog_service = inject(DialogService);
  private delibera_service = inject(DeliberaService);
  private message_service = inject(MessageService);
  private role_service = inject(RoleService);
  private custom_filters_service = inject(CustomFiltersService);

  //dialog dati
  ref: DynamicDialogRef | undefined;

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    numeroDelibera: new FormControl<number|null>(null),
    tipoDelibera: new FormControl<TipoDelibera | null>(null),
    chiusa: new FormControl<boolean>(false)
  })

  //options delibere
  deliberaOptions: TipoDelibera[] = [];

  //dati della tabella
  headersTabella: string[] = ['Data Consiglio', 'Data Chiusura']
  rowsTabella: string[] = ['dataDelibera', 'dataChiusura']
  listaDelibere: Delibera[] = [];

  dettaglioDeliberaRoute = '/delibere/dettaglio_delibera/finalizza/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler();

    this.filtri.valueChanges.subscribe(() => {
      this.resetPagination();
      this.loadData();
    })

  }

  //gestione dei dati iniziali
  private initialDataHandler(){

    const delibere: DeliberaListDto = this.route.snapshot.data['delibere'];
    const statiIscritto: TipoDelibera[] = this.route.snapshot.data['tipiDelibera'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaDelibere = delibere.deliberaList;
    this.totaleDati = delibere.conteggio;
    this.deliberaOptions = statiIscritto;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.delibera_service.listaDelibere(data).subscribe({
      next: (result: DeliberaListDto) => {
        this.listaDelibere = result.deliberaList;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'dataDelibera';
    this.order = 'DESC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(delibera: any): string{
    return this.dettaglioDeliberaRoute + `${delibera.tipo.codice}/${delibera.id}`;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  //gestisce ordinamento tabella
  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'dataDelibera';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('numeroDelibera')?.setValue(null)
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      numeroDelibera: null,
      tipoDelibera: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];
    const sd: TipoDelibera[] = this.deliberaOptions!;

    const str: number = sessionData.numeroDelibera;
    const tipo: TipoDelibera = sd.find(item => item.codice === sessionData.tipoDelibera)!;

    this.filtri.patchValue({
      numeroDelibera: str,
      tipoDelibera: tipo
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    this.custom_filters_service.sessionStorageUpdate(this.filtri.value, this.componente, tabData);

  }

  ripristinaIscritto(event: any){
    event.stopPropagation();
  }

  generaAnnoFromStirnga(dataStringa: string): string{
    return new Date(dataStringa).getFullYear().toString();
  }

  // crea nuova delibera
  nuovaDelibera(){

    this.ref = this.dialog_service.open(DialogNuovaDeliberaComponent, {
      header: 'Nuova Delibera'
    })

    this.ref.onClose.subscribe({
      next: result => {
        if(result){
          if(result.esito){
            this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Delibera creata con successo.` });
          }

          if(!result.esito){ //numero di delibera già presente nell'anno inserito (409)
            const stringa_risultato: string = result.errore.status === 409 ? result.errore.error : 'Operazione interrotta.';
            this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: stringa_risultato });
          }
        }
      },
      complete: () => {
        this.clearFilters();
        this.loadData();
      },
      error: (errore) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    });

  }

  transformData(value: string): string {
    if (!value) return value;

    const [year, month, day] = value.split('-');
    return `${day}-${month}-${year}`;
  }

}
