import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FiltersListCancellatiDto, FiltersListContabDto, FiltersListIscrittiDto, FiltersListProvvisoriDto, FiltriAvanzatiDropdownDto, Iscritto, IscrittoDropdownData, IscrittoQuoteDto, IscrittoReadonlyData, ListaCancellatiDto, ListaIscrittiDto, ListaProvvisoriDto, PagedResultDto, Quota, StatoIscritto, Telefono } from '../model/iscritti-data';
import { IscrittiService } from './iscritti.service';
import { StpService } from './stp.service';
import { QuoteService } from './quote.service';
import { DashboardCardsDto, DashboardChartsDto, DashboardTitoliDto, DataDashboard, IscrittoYearsDto } from '../model/dashboard-data';
import { Stp, StpDropdownData, FiltersListStpDto, ListaStpDto, IscrittoStp } from '../model/stp-data';
import { DeliberaService } from './delibera.service';
import { Delibera, DeliberaFiltri, DeliberaListDto, IscrittiDeliberaChiusaDto, TipoDelibera } from '../model/delibere-data';
import { SharedFiltersService } from './shared-filters.service';
import { Allegati, DatiIscrizione, FiltriInviateDto, FiltriStoricoDto, InviateListDto, StoricoListDto } from '../model/domande-data';
import { DomandeService } from './domande.service';

//operazioni dei dati della dashboard
export const DashboardResolver: ResolveFn<DataDashboard> = (route) => {
  return inject(IscrittiService).getDashboardData();
}

export const DashboardCardsResolver: ResolveFn<DashboardCardsDto> = (route) => {
  return inject(IscrittiService).getDashboardCards();
}

export const DashboardYearsResolver: ResolveFn<IscrittoYearsDto[]> = (route) => {
  return inject(IscrittiService).getDashboardYears();
}

export const DashboardChartsResolver: ResolveFn<DashboardChartsDto> = (route) => {
  return inject(IscrittiService).getDashboardCharts();
}

export const DashboardTitoliResolver: ResolveFn<DashboardTitoliDto[]> = (route) => {
  return inject(IscrittiService).getDashboardTitoli();
}

//operazioni su iscritti
export const IscrittiResolver: ResolveFn<ListaIscrittiDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];
  const routeFilters = route.paramMap.get('routeFilters')!;

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const fromDashboard = ['trasferimento', 'sospeso'].includes(routeFilters);

  const data: FiltersListIscrittiDto = {
    stringaFiltro: fromDashboard ? '' : sessionData.stringaFiltro,
    stato: sessionData.stato,
    sezione: fromDashboard ? '' :  sessionData.sezione,
    tipoLista: componente,
    orderColumn: fromDashboard ? 'codiceFiscale' :  sessionData.orderColumn,
    order: fromDashboard ? 'ASC' :  sessionData.order,
    page: fromDashboard ? 0 :  sessionData.page,
    pageSize: fromDashboard ? 25 :  sessionData.pageSize,

    residenzaProvincia: fromDashboard ? '' :  sessionData.residenzaProvincia,
    residenzaComune: fromDashboard ? '' :  sessionData.residenzaComune,

    domicilioProvincia: fromDashboard ? '' :  sessionData.domicilioProvincia,
    domicilioComune: fromDashboard ? '' :  sessionData.domicilioComune,

    annoNascita: fromDashboard ? null :  sessionData.annoNascita,
    annoNascitaAl: fromDashboard ? null :  sessionData.annoNascitaAl,

    annoIscrizione: fromDashboard ? null :  sessionData.annoIscrizione,
    annoIscrizioneAl: fromDashboard ? null :  sessionData.annoIscrizioneAl,

    titoli: fromDashboard ? '' : sessionData.titoli
  };

  //gestione del routeFilters
  if(routeFilters === 'trasferimento') data.stato = 'T';
  if(routeFilters === 'sospeso') data.stato = 'S';

  return inject(IscrittiService).getIscritti(data);
}

export const IscrittiContabResolver: ResolveFn<PagedResultDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];
  const routeFilters = route.paramMap.get('routeFilters')!;

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: FiltersListContabDto = {
    stringaFiltro: sessionData.stringaFiltro,
    stato: sessionData.stato,
    statoIscritto: sessionData.statoIscritto,
    annoDal: sessionData.annoDal,
    annoAl: sessionData.annoAl,
    numeroAnniDa: sessionData.numeroAnniDa,
    numeroAnniAl: sessionData.numeroAnniAl,
    tipoLista: componente,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize,
    stpId: ''
  };

  //gestione del routeFilters
  if(routeFilters === 'moroso') data.stato = 'moroso';

  return inject(IscrittiService).getIscrittiContab(data);
}

export const IscrittiContabCancellatiResolver: ResolveFn<PagedResultDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: FiltersListContabDto = {
    stringaFiltro: sessionData.stringaFiltro,
    stato: sessionData.stato,
    statoIscritto: sessionData.statoIscritto,
    annoDal: sessionData.annoDal,
    annoAl: sessionData.annoAl,
    numeroAnniDa: sessionData.numeroAnniDa,
    numeroAnniAl: sessionData.numeroAnniAl,
    tipoLista: componente,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize,
    stpId: ''
  };

  return inject(IscrittiService).getIscrittiContabCancellati(data);
}

export const ProvvisoriResolver: ResolveFn<ListaProvvisoriDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: FiltersListProvvisoriDto = {
    stringaFiltro: sessionData.stringaFiltro,
    tipoLista: componente,
    sezione: sessionData.sezione,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  };

  return inject(IscrittiService).getProvvisori(data);
}

export const ProvvisoriSelezionabiliResolver: ResolveFn<ListaProvvisoriDto> = (route) => {

  const componente = route.data['componente'];

  const data: FiltersListProvvisoriDto = {
    stringaFiltro: '',
    tipoLista: componente,
    sezione: '',
    orderColumn: 'codiceFiscale',
    order: 'ASC',
    page: 0,
    pageSize: 25
  };

  //gestione provvisori selezionabili nelle delibere
  if(['provvisori_a', 'psi_a'].includes(componente)) data.sezione = 'A';
  if(componente === 'provvisori_b') data.sezione = 'B';

  return inject(IscrittiService).getProvvisori(data);
}

export const ProvvisoriDeliberaResolver: ResolveFn<ListaProvvisoriDto> = (route) => {

  const componente = route.data['componente'];

  const data: FiltersListProvvisoriDto = {
    stringaFiltro: '',
    tipoLista: componente,
    sezione: '',
    orderColumn: 'codiceFiscale',
    order: 'ASC',
    page: 0,
    pageSize: 25
  };

  //gestione provvisori selezionabili nelle delibere
  if(['provvisori_a', 'psi_a'].includes(componente)) data.sezione = 'A';
  if(componente === 'provvisori_b') data.sezione = 'B';

  return inject(IscrittiService).getProvvisoriDelibera(data, Number(route.paramMap.get('id')));
}

export const CancellatiResolver: ResolveFn<ListaCancellatiDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: FiltersListCancellatiDto = {
    stringaFiltro: sessionData.stringaFiltro,
    stato: sessionData.stato,
    tipoLista: componente,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  };

  return inject(IscrittiService).getCancellati(data);
}

export const StpListResolver: ResolveFn<ListaStpDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: FiltersListStpDto = {
    stringaFiltro: sessionData.stringaFiltro,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  };

  return inject(StpService).getAllStp(data);
}

export const StpResolver: ResolveFn<Stp> = (route) => {
  return inject(StpService).getStp(route.paramMap.get('id')!);
}

export const StpIscrittiResolver: ResolveFn<IscrittoStp[]> = (route) => {
  return inject(StpService).getIscrittiStp(route.paramMap.get('id')!);
}

export const StpFiltersResolver: ResolveFn<FiltersListIscrittiDto> = () => {

  const shared_filters = inject(SharedFiltersService);

  const sessionData = shared_filters.handleFilterByComponent('stpFilters');

  const data: FiltersListIscrittiDto = {
    stringaFiltro: sessionData.stringaFiltro,
    stato: sessionData.stato,
    sezione: '',
    tipoLista: 'stpFilters',
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize,

    residenzaProvincia: '',
    residenzaComune: '',

    domicilioProvincia: '',
    domicilioComune: '',

    annoNascita: null,
    annoNascitaAl: null,
    annoIscrizione: null,
    annoIscrizioneAl: null,
    titoli: ''
  };

  return data;
}

export const StatiIscrittoAttiviResolver: ResolveFn<StatoIscritto[]> = (route) => {
  return inject(IscrittiService).getStatiIscrittoAttivi();
}

export const StatiIscrittoNonAttiviResolver: ResolveFn<StatoIscritto[]> = (route) => {
  return inject(IscrittiService).getStatiIscrittoNonAttivi();
}

export const IscrittoResolver: ResolveFn<Iscritto> = (route) => {
  return inject(IscrittiService).getIscritto(route.paramMap.get('id')!);
}

export const IscrittoQuoteListResolver: ResolveFn<IscrittoQuoteDto> = (route) =>{
  return inject(QuoteService).getIscrittoQuoteList(route.paramMap.get('id')!);
}

export const IscrittoReadonlyDataResolver: ResolveFn<IscrittoReadonlyData> = (route) =>{
  return inject(IscrittiService).getIscrittoReadonlyData(route.paramMap.get('id')!);
}

export const IscrittoReadonlyDataTelefoniResolver: ResolveFn<Telefono[]> = (route) =>{
  return inject(IscrittiService).getIscrittoReadonlyDataTelefoni(route.paramMap.get('id')!);
}

//-------------------------------------

export const QuoteResolver: ResolveFn<Quota[]> = (route) =>{
  return inject(QuoteService).getQuoteIscritto(route.paramMap.get('id')!);
}

export const IscrittoDropdwonDataResolver: ResolveFn<IscrittoDropdownData> = (route) => {
  return inject(IscrittiService).getIscrittoDropdwonData();
}

export const DropdwonDataResolver: ResolveFn<IscrittoDropdownData> = (route) => {
  return inject(IscrittiService).getDropdwonData();
}

export const DropdownFiltriAvanzati: ResolveFn<FiltriAvanzatiDropdownDto> = (route) => {
  return inject(IscrittiService).getFiltriAvanzatiDropdown();
}

export const StpDropdownDataResolver: ResolveFn<StpDropdownData> = (route) => {
  return inject(StpService).getDropdwonData();
}

export const DeliberaCorrenteResolver: ResolveFn<Delibera | null> = (route) => {

  const tipo = route.data['tipoDelibera'];
  let tipoCodice = '';

  if(tipo === 'iscrizione_a') tipoCodice = 'A';
  if(tipo === 'iscrizione_b') tipoCodice = 'B';

  return inject(DeliberaService).deliberaCorrente(tipoCodice);
}

export const DelibereElenecoResolver: ResolveFn<DeliberaListDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];

  const sessionData = shared_filters.handleFilterByComponent(componente);

  const data: DeliberaFiltri = {
    numeroDelibera: sessionData.numeroDelibera,
    tipoDelibera: sessionData.tipiDelibera,
    chiusa: sessionData.chiusa,
    finalizzata: sessionData.finalizzata,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  }

  return inject(DeliberaService).listaDelibere(data);
}

export const DeliberaResolver: ResolveFn<Delibera> = (route) => {
  return inject(DeliberaService).getDeliberaById(Number(route.paramMap.get('id')));
}

export const TipiDeliberaResolver: ResolveFn<TipoDelibera[]> = (route) => {
  return inject(DeliberaService).getTipiDelibera();
}

export const IscrittiDeliberaChiusa: ResolveFn<IscrittiDeliberaChiusaDto> = (route) => {

  const data: FiltersListIscrittiDto = {
    stringaFiltro: '',
    stato: '',
    sezione: '',
    tipoLista: '',
    orderColumn: '',
    order: '',
    page: 0,
    pageSize: 25,

    residenzaProvincia: '',
    residenzaComune: '',

    domicilioProvincia: '',
    domicilioComune: '',

    annoNascita: null,
    annoNascitaAl: null,
    annoIscrizione: null,
    annoIscrizioneAl: null,
    titoli: ''
  }

  return inject(DeliberaService).getIscrittiByDeliberaId(Number(route.paramMap.get('id')), data);
}

export const UltimoNumeroIscrizioneResolver: ResolveFn<Number> = (route) => {
  return inject(IscrittiService).getUltimoNumeroIscrizione();
}

export const SpeseAmministrativeCorrentiResolver: ResolveFn<Number> = (route) => {
  return inject(QuoteService).getSpeseAmministrativeCorrenti();
}

export const InviateListResolver: ResolveFn<InviateListDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];
  const sessionData = shared_filters.handleFilterByComponent(componente);

  const filtri: FiltriInviateDto = {
    stringaFiltro: sessionData.stringaFiltro,
    sezioneAlbo: sessionData.sezioneAlbo,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  }

  return inject(DomandeService).getDomandeInviate(filtri);
}

export const LavorateListResolver: ResolveFn<StoricoListDto> = (route) => {

  const shared_filters = inject(SharedFiltersService);

  const componente = route.data['componente'];
  const sessionData = shared_filters.handleFilterByComponent(componente);

  const filtri: FiltriStoricoDto = {
    stringaFiltro: sessionData.stringaFiltro,
    sezioneAlbo: sessionData.sezioneAlbo,
    statoIscrizione: sessionData.statoIscrizione,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  }

  return inject(DomandeService).getStoricoDomande(filtri);
}

export const CaricaBozzaResolver: ResolveFn<DatiIscrizione> = (route) => {
  return inject(DomandeService).getBozza(Number(route.paramMap.get('id')!));
}

export const CaricaAllegatiResolver: ResolveFn<Allegati[]> = (route) => {
  return inject(DomandeService).getFileAllegati(Number(route.paramMap.get('id')!));
}
