import { Component, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { StatoIscritto } from '../../model/iscritti-data';
import { FiltersListStpDto, ListaStpDto, StpDettaglioListDto } from '../../model/stp-data';
import { StpService } from '../../services/stp.service';
import { RoleService } from '../../services/role.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';

@Component({
  selector: 'app-tabella-stp',
  templateUrl: './tabella-stp.component.html',
  styleUrl: './tabella-stp.component.scss'
})
export class TabellaStpComponent {

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private stp_service = inject(StpService);
  private message_service = inject(MessageService);
  public role_service = inject(RoleService);
  private custom_filters_service = inject(CustomFiltersService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>('')
  })

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Ragione Sociale', 'Costituzione Società', 'Numero Iscrizione'];
  rowsTabella: string[] = ['codiceFiscale', 'denominazione', 'costituzioneSocieta', 'codiceStp'];
  listaStp: StpDettaglioListDto[] = [];

  dettaglioStpRoute = '/gestione_albo/stp/dettaglio_stp/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler(); //gestisce i dati iniziali

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const stpList: ListaStpDto = this.route.snapshot.data['listaStp'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaStp = stpList.lista;
    this.totaleDati = stpList.conteggio;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.stp_service.getAllStp(data).subscribe({
      next: (result: ListaStpDto) => {
        this.listaStp = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceStp';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(stp: any): string{
    return this.dettaglioStpRoute + stp.id;
  }

  //assegna colore stato
  coloreStato(statoIscritto: StatoIscritto): string{
    let colore = '';
    const id = statoIscritto.codiceCnop;

    if(id === 'A') colore = '#16A34A';
    if(id === 'S') colore = '#CDC103';
    if(id === 'T') colore = '#00BFFE';
    if(id === 'C') colore = '#FBA465';
    if(id === 'R') colore = '#EF5233';
    if(id === 'P') colore = '#BF08FF';

    return colore;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceStp';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: ''
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const str: string = sessionData.inserimento;

    this.filtri.patchValue({
      inserimento: str
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    this.custom_filters_service.sessionStorageUpdate(this.filtri.value, this.componente, tabData);

  }

  transformData(value: string): string {
    if (!value) return value;

    const [year, month, day] = value.split('-');
    return `${day}-${month}-${year}`;
  }

}
