<div class="mb-5">
  <span class="block mb-5">
    <span class="pi pi-exclamation-triangle"></span>
    Confermi invio email a tutti gli iscritti presenti nella delibera?
  </span>
  <div [formGroup]="protocolloForm" class="flex flex-column justify-content-center py-5">
    <div class="flex align-items-center gap-3 mb-3">
      <label for="protocollo" class="font-semibold w-10rem">
          Numero protocollo
      </label>
      <input pInputText id="protocollo" class="flex-auto" autocomplete="off" formControlName="numeroProtocollo" />
    </div>
    <div class="flex align-items-center gap-3 mb-5">

      <label class="pl-1" for="dataProtocollo" class="font-semibold w-10rem">Data</label>
      <p-calendar appendTo="body" formControlName="dataProtocollo" dateFormat="dd/mm/yy" inputId="dataProtocollo" [iconDisplay]="'input'" [showIcon]="true" class="flex-auto"></p-calendar>
    </div>

  </div>
</div>

<div class="flex justify-content-end">
  <!-- <p-button
      label="Cancel"
      [text]="true"
      severity="secondary"
      (onClick)="closeDialog()" /> -->
  <p-button
      class="denyButton"
      label="Invia"
      [disabled]="protocolloForm.invalid"
      (onClick)="confirmDialog()" icon="pi pi-send"
    />
</div>
