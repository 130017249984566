<!-- resoconto -->
<div class="mb-5">
  <p-fieldset legend="Resoconto" class="prova">
    <div class="flex flex-column sm:flex-row justify-content-between pr-2 gap-3 sm:gap-0">
      <p class="text-xl"><span class="font-bold mr-3">Quote Insolute:</span>{{ quoteInsolute }}</p>
      <p class="text-xl"><span class="font-bold mr-3">Importo da Saldare:</span>{{ importoDaSaldare.toFixed(2) }} €</p>
      <p class="text-xl"><span class="font-bold mr-3">Importo a Credito:</span>{{ importoACredito.toFixed(2) }} €</p>
    </div>

  </p-fieldset>
</div>



<div>

  <p-table
    [value]="listaQuote"
    [rowHover]="true"
    [tableStyle]="{ 'max-width': '90rem' }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Anno Rif.</th>
        <th class="text-center">Data Saldo</th>
        <th class="text-right">Quota</th>
        <th class="text-right">Spese Amministrative</th>
        <th class="text-right">Da Saldare</th>
        <th class="text-center">Mezzo a Saldo</th>
        <th class="text-center">Numero IUV</th>
        <th class="text-center">Cartella Esattoriale</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-quota>
      <tr (click)="op.toggle($event)" [pSelectableRow]="true">
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-center">{{ quota.annoRiferimento }}</td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-center">{{ transformData(quota.saldoData) }}</td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-right">{{ quota.valoreQuota.toFixed(2) }} €</td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-right">{{ (quota.speseAmministrative === null || quota.speseAmministrative === 0) ? 0 : quota.speseAmministrative.toFixed(2) }} €</td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota), 'font-bold': (checkMoroso(quota) !== null && quota.importoDaVersare > 0)}" class="text-right">
          {{ (quota.importoDaVersare === null || quota.importoDaVersare === 0) ? 0 : quota.importoDaVersare.toFixed(2) }} €
        </td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-center">
          {{ (!checkMoroso(quota) && quota.importoDaVersare <= 0) ? mezzoSaldo(quota.pagamentiList) : '' }}
        </td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-center">
          <div class="flex align-items-center justify-content-center gap-2">
            <p>{{ quota.numeroIuv }}</p>
            @if (
                  quota.numeroIuv != null &&
                  quota.importoDaVersare > 0 &&
                  ['contabile', 'contabile-stp'].includes(listaData.componente)
                ) {
              <p-button
                id="iuvCheck"
                icon="pi pi-sync"
                size="small"
                (click)="checkIuv($event, quota.numeroIuv)"
                [disabled]="!role_service.isContabilitaRole()"
              ></p-button>
            }
          </div>
        </td>
        <td [ngClass]="{'tdMoroso': checkMoroso(quota)}" class="text-center">
          <p-checkbox
            [(ngModel)]="quota.cartellaEsattoriale"
            [binary]="true"
            (click)="gestisciCartellaEsattoriale($event, quota.id, quota.cartellaEsattoriale)"
            [disabled]="listaData.componente === 'readonly' || !role_service.isContabilitaRole()" />
        </td>

        <p-overlayPanel #op>
          @if (quota.pagamentiList.length === 0) {
            <p>Ancora nessun pagamento</p>
          }@else {
            <p-table [value]="quota.pagamentiList" [tableStyle]="{ 'max-width': '70rem' }">
              <ng-template pTemplate="header">
                <tr>
                  <th class="customTableT">Data</th>
                  <th class="customTableT">Mezzo</th>
                  <th class="customTableT">Importo</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-pagamento>
                <tr>
                  <td class="customTableT">{{ transformData(pagamento.data) }}</td>
                  <td class="customTableT">{{ pagamento.pagamentoMezzo.tipoPagamento }}</td>
                  <td class="customTableT">{{ pagamento.importo.toFixed(2) }} €</td>
                </tr>
              </ng-template>
            </p-table>
          }
          @if (['contabile', 'contabile-stp'].includes(listaData.componente)) {
            <div class="mt-4 text-center">
              <p-button
                icon="pi pi-plus"
                size="small"
                (click)="inserisciPagamento(quota.id)"
                [disabled]="!role_service.isContabilitaRole()"
              ></p-button>
            </div>
          }

        </p-overlayPanel>
      </tr>
    </ng-template>
  </p-table>

</div>
