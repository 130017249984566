import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditLog, Comune, FiltersListCancellatiDto, FiltersListContabDto, FiltersListIscrittiDto, FiltersListProvvisoriDto, FiltriAvanzatiDropdownDto, Iscritto, IscrittoDropdownData, IscrittoReadonlyData, IscrittoSelezioneStampeDto, ListaCancellatiDto, ListaFiltri, ListaIscrittiDto, ListaProvvisoriDto, ModificaStatoIscrittoDto, Ordine, PagedResultDto, Prefisso, StatoIscritto, Telefono } from '../model/iscritti-data';
import { environment } from '../environment/environment';
import { DashboardCardsDto, DashboardChartsDto, DashboardRegioniDto, DashboardTitoliDto, DataDashboard, IscrittoYearsDto } from '../model/dashboard-data';
import { IscrittoSelezionabile, ProfessionistaDto } from '../model/stp-data';
import { IscrittoItem, RicercaIscritto } from '../model/cnop-data';
import { SearchFilters } from '../model/filters-data';

@Injectable({
  providedIn: 'root'
})
export class IscrittiService {

  private _http = inject(HttpClient);
  private iscritti_api: string = `${environment.baseUrl}/iscritti`;
  private ricerche_api: string = `${environment.baseUrl}/ricerche/iscritti`;
  private image_url: string = `${environment.baseUrl}/images`;
  private audit_api: string = `${environment.baseUrl}/audit`;

  //dashboard
  getDashboardData(): Observable<DataDashboard>{
    return this._http.get<DataDashboard>(this.iscritti_api + `/dati-dashboard`);
  }

  getDashboardCards(): Observable<DashboardCardsDto>{
    return this._http.get<DashboardCardsDto>(this.iscritti_api + `/dashboard_cards`);
  }

  getDashboardYears(): Observable<IscrittoYearsDto[]>{
    return this._http.get<IscrittoYearsDto[]>(this.iscritti_api + `/dashboard_years`);
  }

  getDashboardCharts(): Observable<DashboardChartsDto>{
    return this._http.get<DashboardChartsDto>(this.iscritti_api + `/dashboard_charts`);
  }

  getDashboardTitoli(): Observable<DashboardTitoliDto[]>{
    return this._http.get<DashboardTitoliDto[]>(this.iscritti_api + `/dashboard_titoli`);
  }

  getDashboardRegioni(): Observable<DashboardRegioniDto>{
    return this._http.get<DashboardRegioniDto>(this.iscritti_api + `/dashboard_regioni`);
  }

  //iscritti
  getIscritti(data: SearchFilters): Observable<ListaIscrittiDto>{
    return this._http.post<ListaIscrittiDto>(this.iscritti_api, data);
  }

  getProvvisori(data: SearchFilters): Observable<ListaProvvisoriDto>{
    return this._http.post<ListaProvvisoriDto>(this.iscritti_api + '/provvisori', data);
  }

  getProvvisoriDelibera(data: SearchFilters, idDelibera: number): Observable<ListaProvvisoriDto>{
    return this._http.post<ListaProvvisoriDto>(this.iscritti_api + `/provvisori_delibera/${idDelibera}`, data);
  }

  getCancellati(data: SearchFilters): Observable<ListaCancellatiDto>{
    return this._http.post<ListaCancellatiDto>(this.iscritti_api + '/cancellati', data);
  }

  getStatiIscrittoAttivi(): Observable<StatoIscritto[]>{
    return this._http.get<StatoIscritto[]>(this.iscritti_api + `/stati_iscritto/attivi`);
  }

  getStatiIscrittoNonAttivi(): Observable<StatoIscritto[]>{
    return this._http.get<StatoIscritto[]>(this.iscritti_api + `/stati_iscritto/non_attivi`);
  }

  getIscrittiContab(data: SearchFilters): Observable<PagedResultDto>{
    return this._http.post<PagedResultDto>(this.ricerche_api + '/contabilita', data);
  }

  getIscrittiContabCancellati(data: SearchFilters): Observable<PagedResultDto>{
    return this._http.post<PagedResultDto>(this.ricerche_api + '/contabilita-cancellati', data);
  }

  getIscrittiCnop(data: RicercaIscritto): Observable<IscrittoItem[]>{
    return this._http.post<IscrittoItem[]>(this.ricerche_api + '/cnop', data);
  }

  getIscrittiStampa(stringaRicerca: string): Observable<IscrittoSelezioneStampeDto[]>{
    return this._http.get<IscrittoSelezioneStampeDto[]>(`${this.iscritti_api}/get-iscritti-stampe/${stringaRicerca}`);
  }

  // da controllare

  getIscritto(id: string | number): Observable<Iscritto>{
    return this._http.get<Iscritto>(this.iscritti_api + `/iscritto/${id}`);
  }

  inserisciIscritto(data: any): Observable<number>{
    return this._http.post<number>(this.iscritti_api + '/nuovo-iscritto', data);
  }

  modificaIscritto(id: number, data: any): Observable<any>{
    return this._http.put<any>(this.iscritti_api + `/modifica-iscritto/${id}`, data);
  }

  confermaIscrizione(data: any): Observable<Boolean>{
    return this._http.put<Boolean>(this.iscritti_api + `/conferma-iscrizione`, data);
  }

  creaTelefonoSecondario(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(this.iscritti_api + '/aggiungi-telefono', data);
  }

  cancellaTelefonoSecondario(id: number): Observable<Boolean>{
    return this._http.delete<Boolean>(this.iscritti_api + `/cancella-telefono/${id}`);
  }

  getListaSelezionabili(id: number): Observable<IscrittoSelezionabile[]>{
    return this._http.get<IscrittoSelezionabile[]>(this.iscritti_api + `/lista-selezionabili/${id}`);
  }

  ripristinaIscritto(id: number): Observable<Boolean>{
    return this._http.get<Boolean>(this.iscritti_api + `/ripristina-iscritto/${id}`);
  }

  getIscrittoDropdwonData(): Observable<IscrittoDropdownData>{
    return this._http.get<IscrittoDropdownData>(this.iscritti_api + '/dropdown-iscritto-data');
  }

  getDropdwonData(): Observable<IscrittoDropdownData>{
    return this._http.get<IscrittoDropdownData>(this.iscritti_api + '/dropdown-data');
  }

  getPrefissi(): Observable<Prefisso[]>{
    return this._http.get<Prefisso[]>(this.iscritti_api + '/prefissi');
  }

  getImmagineIscritto(nomeImmagine: string): Observable<Blob>{
    return this._http.get(this.image_url + `/fototessera/${nomeImmagine}`, {responseType: 'blob'});
  }

  checkCodiceFiscale(cf: string): Observable<boolean>{
    return this._http.get<boolean>(this.iscritti_api + `/check_codice_fiscale/${cf}`);
  }

  getFiltriAvanzatiDropdown(): Observable<FiltriAvanzatiDropdownDto>{
    return this._http.get<FiltriAvanzatiDropdownDto>(this.iscritti_api + `/dropdown_filtri_avanzati`);
  }

  getUltimoNumeroIscrizione(): Observable<Number>{
    return this._http.get<Number>(this.iscritti_api + `/ultimo_numero_iscrizione`);
  }

  getIscrittoReadonlyData(id: string): Observable<IscrittoReadonlyData>{
    return this._http.get<IscrittoReadonlyData>(this.iscritti_api + `/iscritto_readonly_data/${id}`);
  }

  getIscrittoReadonlyDataTelefoni(id: string): Observable<Telefono[]>{
    return this._http.get<Telefono[]>(this.iscritti_api + `/numeri_secondari_readonly/${id}`);
  }

  modificaStatoIscritto(id: number, data: ModificaStatoIscrittoDto): Observable<boolean>{
    return this._http.post<boolean>(this.iscritti_api + `/modifica_stato_iscritto/${id}`, data);
  }

  getModificheIscritto(id: string | number): Observable<AuditLog[]>{
    return this._http.get<AuditLog[]>(this.audit_api + `/iscritto/${id}`);
  }

  getOrdini(): Observable<Ordine[]>{
    return this._http.get<Ordine[]>(this.iscritti_api + `/get-ordini`);
  }

  caricaFototessera(fileName: number, file: File): Observable<string> {
    const data = new FormData();
    data.append('file', file);
    return this._http.post<string>(`${this.image_url}/upload/fototessera/${fileName}`, data, { responseType: 'text' as 'json' });
  }


}
