import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Articolo26, IscrittoDropdownData, PagamentoMezzo, Abilitazione, Titolo, Universita, CTU } from '../../model/iscritti-data';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfigurazioneService } from '../../services/configurazione.service';
import { MessageService } from 'primeng/api';
import { IscrittiService } from '../../services/iscritti.service';
import { DialogDinamicoComponent } from '../dialog-dinamico/dialog-dinamico.component';
import { ConfigurazioneDialogComponent } from '../configurazione-dialog/configurazione-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

interface DropValues{
  nome: string;
  descrizione: string;
};

@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrl: './configurazione.component.scss'
})
export class ConfigurazioneComponent implements OnInit{

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private configurazione_service = inject(ConfigurazioneService);
  private message_service = inject(MessageService);
  private dialog_service = inject(DialogService);

  ref: DynamicDialogRef | undefined;

  bottoneDisabilitato = true;
  visible = false;

  //lista attuale
  nomeListaAttuale: DropValues | undefined;
  listaCorrente: any[] = [];
  verifica: boolean[] = [];

  //liste dropdown
  nomiListe: DropValues[] = [
    { nome: 'ctu', descrizione: 'CTU' },
    { nome: 'mezzoPagamento', descrizione: 'Mezzo Pagamento' },
    { nome: 'articolo26', descrizione: 'Motivo Sospensione' },
    { nome: 'abilitazione', descrizione: 'Abilitazione' },
    { nome: 'titolo', descrizione: 'Titolo' },
    { nome: 'universita', descrizione: 'Univeristà' },
  ];

  //liste dati
  articolo26List: Articolo26[] = [];
  pagamentoMezzoList: PagamentoMezzo[] = [];
  CTUList: CTU[] = [];
  abilitazioneList: Abilitazione[] = [];
  titoloList: Titolo[] = [];
  universitaList: Universita[] = [];

  //input
  nuovaOpzioneForm = new FormGroup({
    opzione: new FormControl<string|null>(null)
  });

  ngOnInit(): void {
    this.popolaListe(this.route.snapshot.data['dropdown']);
    this.popolaListaAttuale('ctu');
  }

  //popola le liste
  private popolaListe(dropdown: IscrittoDropdownData){

    this.articolo26List.splice(0, this.articolo26List.length);
    this.pagamentoMezzoList.splice(0, this.pagamentoMezzoList.length);
    this.CTUList.splice(0, this.CTUList.length);
    this.abilitazioneList.splice(0, this.abilitazioneList.length);
    this.titoloList.splice(0, this.titoloList.length);
    this.universitaList.splice(0, this.universitaList.length);

    this.articolo26List.push(...dropdown.articolo26List);
    this.pagamentoMezzoList.push(...dropdown.pagamentoMezzoList);
    this.CTUList.push(...dropdown.CTUList);
    this.abilitazioneList.push(...dropdown.abilitazioneList);
    this.titoloList.push(...dropdown.titoloList);
    this.universitaList.push(...dropdown.universitaList);

  }

  //popola lista attuale
  popolaListaAttuale(nomeLista: string){

    if(nomeLista === 'articolo26') this.listaCorrente = this.articolo26List;
    if(nomeLista === 'mezzoPagamento') this.listaCorrente = this.pagamentoMezzoList;
    if(nomeLista === 'ctu') this.listaCorrente = this.CTUList;
    if(nomeLista === 'abilitazione') this.listaCorrente = this.abilitazioneList;
    if(nomeLista === 'titolo') this.listaCorrente = this.titoloList;
    if(nomeLista === 'universita') this.listaCorrente = this.universitaList;

    for(const listaNome of this.nomiListe){
      if(listaNome.nome === nomeLista){
        this.nomeListaAttuale = listaNome;
        break;
      }
    }

    this.checkerAttuale();

  }

  //gestione checker
  checkerAttuale(){
    this.verifica.splice(0, this.verifica.length);
    this.listaCorrente.forEach(elemento => {
      this.verifica.push(elemento.attivo);
    })
  }

  //gestisce i dati delle drop
  getDato(tipo: string){
    let s = '';

    if(tipo === 'articolo26') s = 'descrizione';
    if(tipo === 'mezzoPagamento') s = 'tipoPagamento';
    if(tipo === 'ctu' || tipo === 'abilitazione' ||
      tipo === 'titolo' || tipo === 'universita') s = 'nome';

    return s;
  }

  //gestisce l'invio di nuove opzioni
  inviaOpzione(tipo: string){
    const data = {
      newOption: this.nuovaOpzioneForm.value.opzione,
      tipo: tipo
    }

    this.configurazione_service.invioNewOption(data).subscribe({
      next: result => {
        this.nuovaOpzioneForm.get('opzione')?.reset();
      },
      complete: () => {
        this.iscritti_service.getDropdwonData().subscribe(result => {
          setTimeout(() => {
            this.popolaListe(result);
          }, 1000);
        });
        this.message_service.add({ severity: 'success', summary: `Inserimento completata`, detail: `Nuova voce salvata con successo` })
      },
      error: err => {
        this.message_service.add({ severity: 'error', summary: 'Inserimento Annullata', detail: 'Qualcosa è andato storto.' })
      }
    })
  }

  //gestisce l'invio delle modifiche alla lista
  invioModifiche(tipo: string){

    let valori: any[] = [];

    this.listaCorrente.forEach(elemento => {
      valori.push({ id: elemento.id, valore: elemento.attivo})
    })

    const data = {
      listaValori: valori,
      tipo: tipo
    }

    this.configurazione_service.invioModificheList(data).subscribe({
      next: result => {
        this.bottoneDisabilitato = true;
      },
      complete: () => {
        this.iscritti_service.getDropdwonData().subscribe(result => {
          setTimeout(() => {
            this.popolaListe(result);
          }, 1000);
        });
        this.message_service.add({ severity: 'success', summary: `Inserimento completato`, detail: `Nuova voce salvata con successo` })
      },
      error: err => {
        this.message_service.add({ severity: 'error', summary: 'Inserimento Annullato', detail: 'Qualcosa è andato storto.' })
      }
    })

  }

  //sulla modifica degli slider
  attivoChange(){
    let flag = true;

    for(let i = 0; i < this.listaCorrente.length; i++){
      if(this.listaCorrente[i].attivo !== this.verifica[i]){
        flag = false;
        break;
      }
    }

    this.bottoneDisabilitato = flag;
  }

  //modifica singola option
  modificaOption(item: any, tipo: string){
    this.ref = this.dialog_service.open(ConfigurazioneDialogComponent, {
      data: {
        optionId: item.id,
        valore: item[this.getDato(tipo)],
        tipo: tipo
      },
      header: 'Modifica Opzione Selezionata'
    });

    this.ref.onClose.subscribe({
      next: result => {

        if(result != undefined){

          const data = {
          optionId: result.optionId,
          valore: result.valore,
          tipo: result.tipo
          }

          this.configurazione_service.invioModificheOption(data).subscribe({
            complete: () => {
              this.iscritti_service.getDropdwonData().subscribe(result => {
                setTimeout(() => {
                  this.popolaListe(result);
                }, 1000);
              });
              this.message_service.add({ severity: 'success', summary: `Inserimento completato`, detail: `Modifica avvenuta con successo` })
            },
            error: err => {
              this.message_service.add({ severity: 'error', summary: 'Inserimento Annullato', detail: 'Qualcosa è andato storto.' })
            }
          })

        }

      }
    });
  }

}
