import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { CancellazionePerTrasferimentoDto, RatificaArt3Dto, RatificaCancellazioneDto, RatificaNullaOstaTrasferimentoDto } from '../model/oprp-data';

@Injectable({
  providedIn: 'root'
})
export class PrintModuliService {

  private _http = inject(HttpClient);
  private print_api: string = `${environment.printUrl}/jr`;

  stampaModuloRatificaArt3(id: number, data: RatificaArt3Dto): Observable<any>{
    return this._http.post(this.print_api + `/oprp-modulo-ratifica-art-3/${id}`, data, { responseType: 'blob' });
  }

  stampaModuloRatificaCancellazione(id: number, data: RatificaCancellazioneDto): Observable<any>{
    return this._http.post(this.print_api + `/oprp-modulo-ratifica-cancellazione/${id}`, data, { responseType: 'blob' });
  }

  stampaModuloRatificaNullaOstaTrasferimento(id: number, data: RatificaNullaOstaTrasferimentoDto): Observable<any>{
    return this._http.post(this.print_api + `/oprp-modulo-ratifica-nulla-osta-trasferimento/${id}`, data, { responseType: 'blob' });
  }

  stampaCancellazionePerTrasferimento(id: number, data: CancellazionePerTrasferimentoDto): Observable<any>{
    return this._http.post(this.print_api + `/cancellazione-per-trasferimento/${id}`, data, { responseType: 'blob' });
  }

}
