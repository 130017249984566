import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Comune, FiltersListIscrittiDto, FiltriAvanzatiDropdownDto, GruppoValore, IscrittoDettaglioListDto, ListaData, ListaIscrittiDto, Provincia, StatoIscritto, Titolo } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environment/environment';
import { Table } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { XlsService } from '../../services/xls.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-tabella-iscritti',
  templateUrl: './tabella-iscritti.component.html',
  styleUrl: './tabella-iscritti.component.scss'
})
export class TabellaIscrittiComponent implements OnInit{

  @Input('data')
  listaIscrittiStp: ListaData = {
    lista: [],
    componenteTipo: '',
    conteggio: 0,
    id: 0
  }

  @ViewChild('dt') dt!: Table;

  xlsSpinner: boolean = false;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;
  showAdvancedFilters: boolean = false;

  changeInProgress: boolean = false;

  numeroFiltriAvanzati: number = 0;

  annoNascitaSelettore: any[] = [
    { label: 'singolo', value: 'singolo' },
    { label: 'intervallo', value: 'intervallo' }
  ];

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private xls_service = inject(XlsService);
  public role_service = inject(RoleService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    stringaFiltro: new FormControl<string|null>(''),
    stato: new FormControl<StatoIscritto|null>(null),
    sezione: new FormControl<GruppoValore|null>(null),

    residenzaProvincia: new FormControl<Provincia|null>(null),
    residenzaComune: new FormControl<Comune|null>(null),

    domicilioProvincia: new FormControl<Provincia|null>(null),
    domicilioComune: new FormControl<Comune|null>(null),

    annoNascita: new FormControl<number|null>(null),
    annoNascitaAl: new FormControl<number|null>(null),

    annoIscrizione: new FormControl<number|null>(null),
    annoIscrizioneAl: new FormControl<number|null>(null),

    titoli: new FormControl<Titolo|null>(null)

  })

  // filtro per gli stati dell'iscritto
  statiDropdown: StatoIscritto[] | undefined;
  provinceDropdown: Provincia[] | undefined;
  comuneDropdown: Comune[] | undefined;
  titoliDropdown: Titolo[] | undefined;
  //anniDropdown: GruppoValore[] | undefined = [];

  sezioniDropdown: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A' },
    { nome: 'Sezione B', valore: 'B' }
  ];

  // titoliDropdown: GruppoValore[] = [
  //   { nome: 'Psicologo/a', valore: 'psicologi' },
  //   { nome: 'Psicoterapeuta', valore: 'psicoterapeuti' },
  //   { nome: 'Dottori in Scienze Tecniche Psicologiche', valore: 'dottori' }
  // ];

  filteredComuni: Comune[] = [];

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'N° Iscrizione', 'Stato', 'Pec']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'numeroIscrizione', 'statoIscritto', 'pec']
  listaIscritti: IscrittoDettaglioListDto[] = [];

  dettaglioIscrittoRoute = '/gestione-albo/gestione-iscritti/dettaglio-iscritto/';

  orderColumnName: string = '';
  order: string = '';

  //dati del paginator
  pageSize: number = 25;
  pageSelected: number = 0;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler(); //gestisce i dati iniziali

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const iscritti: ListaIscrittiDto = this.route.snapshot.data['iscritti'];
    const statiIscritto: StatoIscritto[] = this.route.snapshot.data['statiIscritto'];

    const componente: string = this.route.snapshot.data['componente'];

    const filtriAvanzatiDropdown: FiltriAvanzatiDropdownDto = this.route.snapshot.data['dropdown'];

    //this.popolaYearDrop(filtriAvanzatiDropdown.minorAnno);

    this.provinceDropdown = filtriAvanzatiDropdown.province;
    this.comuneDropdown = filtriAvanzatiDropdown.comuni;
    this.titoliDropdown = filtriAvanzatiDropdown.titoli;

    this.componente = componente;

    const stpFlag = this.listaIscrittiStp.lista.length > 0;

    this.listaIscritti = (stpFlag) ? this.listaIscrittiStp.lista : iscritti.lista;
    this.totaleDati = (stpFlag) ? this.listaIscrittiStp.conteggio : iscritti.conteggio;
    this.statiDropdown = statiIscritto;
    this.tipoLista = (stpFlag) ? this.listaIscrittiStp.componenteTipo : componente;

    if(!stpFlag) this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    this.sessionStorageUpdate();

    const data: FiltersListIscrittiDto = {
      stringaFiltro: values.stringaFiltro!,
      stato: values.stato?.codiceCnop ? values.stato?.codiceCnop : '',
      sezione: values.sezione?.valore ? values.sezione?.valore : '',
      tipoLista: this.tipoLista,
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.pageSelected,
      pageSize: this.pageSize,

      residenzaProvincia: values.residenzaProvincia ? values.residenzaProvincia.codice : '',
      residenzaComune: values.residenzaComune ? values.residenzaComune.codice_istat : '',

      domicilioProvincia: values.domicilioProvincia ? values.domicilioProvincia.codice : '',
      domicilioComune: values.domicilioComune ? values.domicilioComune.codice_istat : '',

      annoNascita: values.annoNascita ? values.annoNascita : null,
      annoNascitaAl: values.annoNascitaAl ? values.annoNascitaAl : null,

      annoIscrizione: values.annoIscrizione ? values.annoIscrizione : null,
      annoIscrizioneAl: values.annoIscrizioneAl ? values.annoIscrizioneAl : null,

      titoli: values.titoli ? values.titoli.id.toString() : ''

    }

    this.iscritti_service.getIscritti(data).subscribe({
      next: (result: ListaIscrittiDto) => {
        this.listaIscritti = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })

    this.numeroFiltriAvanzati = this.conteggioAvanzati();

  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //assegna colore stato
  coloreStato(statoIscritto: StatoIscritto): string{
    let colore = '';
    const id = statoIscritto.codiceCnop;

    if(id === 'A') colore = '#16A34A';
    if(id === 'S') colore = '#CDC103';
    if(id === 'T') colore = '#00BFFE';
    if(id === 'C') colore = '#FBA465';
    if(id === 'R') colore = '#EF5233';
    if(id === 'P') colore = '#BF08FF';

    return colore;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.pageSelected = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('stringaFiltro')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      stringaFiltro: '',
      stato: null,
      sezione: null,

      residenzaProvincia: null,
      residenzaComune: null,

      domicilioProvincia: null,
      domicilioComune: null,

      annoNascita: null,
      annoNascitaAl: null,

      annoIscrizione: null,
      annoIscrizioneAl: null,

      titoli: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //aggiorna il session storage
  sessionStorageUpdate(){

    const values = this.filtri.value;
    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[this.componente].stringaFiltro = values.stringaFiltro;
    sessionData[this.componente].stato = values.stato?.codiceCnop ? values.stato?.codiceCnop : '';
    sessionData[this.componente].sezione = values.sezione?.valore ? values.sezione?.valore : '';
    sessionData[this.componente].orderColumn = this.orderColumnName;
    sessionData[this.componente].order = this.order;
    sessionData[this.componente].page = this.pageSelected;
    sessionData[this.componente].pageSize = this.pageSize;
    sessionData[this.componente].currentPage = this.currentPage;

    const rp = values.residenzaProvincia;
    sessionData[this.componente].residenzaProvincia = rp ? rp?.codice : '';

    const rc = values.residenzaComune;
    sessionData[this.componente].residenzaComune = rc?.codice_istat ? rc?.codice_istat : '';

    const dp = values.domicilioProvincia;
    sessionData[this.componente].domicilioProvincia = dp ? dp.codice : '';

    const dc = values.domicilioComune;
    sessionData[this.componente].domicilioComune = dc?.codice_istat ? dc?.codice_istat : '';

    sessionData[this.componente].annoNascita = values.annoNascita ? values.annoNascita : null;
    sessionData[this.componente].annoNascitaAl = values.annoNascitaAl ? values.annoNascitaAl : null;

    sessionData[this.componente].annoIscrizione = values.annoIscrizione ? values.annoIscrizione : null;
    sessionData[this.componente].annoIscrizioneAl = values.annoIscrizioneAl ? values.annoIscrizioneAl : null;

    sessionData[this.componente].titoli = values.titoli ? values.titoli.id.toString() : '';

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));

  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const statiIscritti: StatoIscritto[] = this.statiDropdown!;
    const sezioni: GruppoValore[] = this.sezioniDropdown;
    const province: Provincia[] = this.provinceDropdown!;
    const comuni: Comune[] = this.comuneDropdown!;
    const titoli: Titolo[] = this.titoliDropdown!;
    //const anni: GruppoValore[] = this.anniDropdown!;

    const str: string = sessionData.stringaFiltro;
    const sez: string = sessionData.sezione;

    const r_prov: string = sessionData.residenzaProvincia;
    const r_com: string = sessionData.residenzaComune;
    const d_prov: string = sessionData.domicilioProvincia;
    const d_com: string = sessionData.domicilioComune;
    const tit: number = sessionData.titoli.id;

    const routeFilter = this.route.snapshot.params['routeFilters'];
    const checkRoute = routeFilter === 'iscritti';

    const statoUpdate: StatoIscritto = cercaStato();
    const sezioneUpdate: GruppoValore | null = cercaSezione();
    const r_provinciaUpdate: Provincia | null = cercaProvincia(r_prov);
    const r_comuneUpdate: Comune | null = cercaComune(r_com);
    const d_provinciaUpdate: Provincia | null = cercaProvincia(d_prov);
    const d_comuneUpdate: Comune | null = cercaComune(d_com);
    const titoloUpdate: Titolo | null = cercaTitolo(tit);

    this.filtri.patchValue({
      stringaFiltro: checkRoute ? str : '',
      stato: statoUpdate,
      sezione: sezioneUpdate,

      residenzaProvincia: checkRoute ? r_provinciaUpdate : null,
      residenzaComune: checkRoute ? r_comuneUpdate : null,

      domicilioProvincia: checkRoute ? d_provinciaUpdate : null,
      domicilioComune: checkRoute ? d_comuneUpdate : null,

      annoNascita: checkRoute ? sessionData.annoNascita : null,
      annoNascitaAl: checkRoute ? sessionData.annoNascitaAl : null,

      annoIscrizione: checkRoute ? sessionData.annoIscrizione : null,
      annoIscrizioneAl: checkRoute ? sessionData.annoIscrizioneAl : null,

      titoli: checkRoute ? titoloUpdate : null
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.pageSelected = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    this.sessionStorageUpdate();

    this.numeroFiltriAvanzati = this.conteggioAvanzati();

    //-------------------------------------------------------

    function cercaStato(): StatoIscritto{
      if(routeFilter === 'iscritti'){
        return statiIscritti.find(si => si.codiceCnop === sessionData.stato)!
      }else{
        return statiIscritti.find(si => si.nome.toLowerCase() === routeFilter)!
      }
    }

    function cercaSezione(): GruppoValore | null{
      if(routeFilter === 'iscritti'){
        return sezioni.find(s => s.valore === sessionData.sezione)!
      }else{
        return null;
      }
    }

    function cercaProvincia(prov: string): Provincia | null{
      return prov ? province.find(pr => pr.codice === prov)! : null;
    }

    function cercaComune(com: string): Comune | null{
      return com ? comuni.find(cm => cm.codice_istat === com)! : null;
    }

    function cercaTitolo(titolo: number): Titolo | null{
      if(routeFilter === 'iscritti'){
        return titolo ? titoli.find(t => t.id === titolo)! : null
      }else{
        return null;
      }
    }

    // function cercaAnno(anno: number): GruppoValore | null{
    //   return anno ?  anni.find(a => a.valore === anno.toString())! : null;
    // }

  }

  //mostra/nascondi filtri avanzati
  showFilters(){
    this.showAdvancedFilters = !this.showAdvancedFilters;
  }

  //popola il dropdown degli anni
  // popolaYearDrop(annoMinore: number){

  //   const currentYear = new Date().getFullYear();

  //   for(let i = currentYear; i >= annoMinore; i--){
  //     this.anniDropdown?.push({nome: i.toString(), valore: i.toString()});
  //   }
  // }

  //conta i filtri avanzati presenti
  conteggioAvanzati(): number{

    const values = this.filtri.value;
    let conteggio = 0;

    if(values.residenzaProvincia) conteggio++;
    if(values.residenzaComune) conteggio++;
    if(values.domicilioProvincia) conteggio++;
    if(values.domicilioComune) conteggio++;
    if(values.annoNascita) conteggio++;
    if(values.annoNascitaAl) conteggio++;
    if(values.annoIscrizione) conteggio++;
    if(values.annoIscrizioneAl) conteggio++;
    if(values.titoli) conteggio++;

    return conteggio;
  }

  //autocompletamento per i p-autocomplete
  filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filteredComuni = this.comuneDropdown!.filter(comune =>
        queryParts.every(part =>
            comune.nome.toLowerCase().includes(part)
        )
      );
    }

    // if(tipo === 'province'){
    //   this.filteredProvince = this.province.filter(provincia =>
    //     queryParts.every(part =>
    //         provincia.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'nazioni'){
    //   this.filteredNazione = this.nazioni.filter(nazione =>
    //     queryParts.every(part =>
    //         nazione.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'prefisso'){
    //   this.filteredPrefisso = this.prefissoData.filter(prefisso =>
    //     queryParts.every(part =>
    //         prefisso.nazione.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'titolo'){
    //   this.filteredTitolo = this.titoloData.filter(titolo =>
    //     queryParts.every(part =>
    //         titolo.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'universita'){
    //   this.filteredUniversita = this.universitaData.filter(univ =>
    //     queryParts.every(part =>
    //         univ.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'ctu'){
    //   this.filteredCTU = this.CTUData.filter(ctu =>
    //     queryParts.every(part =>
    //         ctu.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

    // if(tipo === 'ordini'){
    //   this.filteredOrdini = this.oridiniData.filter(ordine =>
    //     queryParts.every(part =>
    //         ordine.nome.toLowerCase().includes(part)
    //     )
    //   );
    // }

  }

  printIscrittiXLS(){

    this.xlsSpinner = true;

    const values = this.filtri.value;

    const data: FiltersListIscrittiDto = {
      stringaFiltro: values.stringaFiltro!,
      stato: values.stato?.codiceCnop ? values.stato?.codiceCnop : '',
      sezione: values.sezione?.valore ? values.sezione?.valore : '',
      tipoLista: this.tipoLista,
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.pageSelected,
      pageSize: this.pageSize,

      residenzaProvincia: values.residenzaProvincia ? values.residenzaProvincia.codice : '',
      residenzaComune: values.residenzaComune ? values.residenzaComune.codice_istat : '',

      domicilioProvincia: values.domicilioProvincia ? values.domicilioProvincia.codice : '',
      domicilioComune: values.domicilioComune ? values.domicilioComune.codice_istat : '',

      annoNascita: values.annoNascita ? values.annoNascita : null,
      annoNascitaAl: values.annoNascitaAl ? values.annoNascitaAl : null,

      annoIscrizione: values.annoIscrizione ? values.annoIscrizione : null,
      annoIscrizioneAl: values.annoIscrizioneAl ? values.annoIscrizioneAl : null,

      titoli: values.titoli ? values.titoli.id.toString() : ''

    }

    this.xls_service.stampaIscrittiXls(data).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'iscritti_lombardia.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      this.xlsSpinner = false;
    }, error => {
      this.xlsSpinner = false;
      this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      console.error('Error downloading the file', error);
    });

  }

}
