<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

        <!-- ricerca iscritti o altri elementi -->
        <div class="sm:mr-3">
          <span class="p-input-icon-left p-input-icon-right p-float-label">
            <i class="pi pi-search"></i>
            <input type="text" id="cercaNumeroDelibera" class="shadow-4" pInputText pKeyFilter="num" formControlName="numeroDelibera" />
            <label for="cercaNumeroDelibera">Cerca Numero Riepilogo</label>
            <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
          </span>
        </div>

      </div>

    </div>

    <!-- filtri iscritti -->
    <div class="flex align-items-center justify-content-center sm:justify-content-start mb-5 flex-wrap gap-3">

      <div class="flex align-items-center">

        <div class="sm:mb-0">
          <p-dropdown [options]="deliberaOptions" formControlName="tipoDelibera" optionLabel="nome" placeholder="tipo" [showClear]="true"></p-dropdown>
        </div>

      </div>

      <p-button
        class=""
        size="small"
        [outlined]="true"
        icon="pi pi-filter-slash"
        (click)="clearFilters()"
        pTooltip="Pulisci Filtri"
        tooltipPosition="right"
      />

    </div>

  </div>



  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaDelibere"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>

            <th pSortableColumn="numeroDelibera">
                N° Bozza Riepilogo
                <p-sortIcon field="numeroDelibera"></p-sortIcon>
            </th>

            <th pSortableColumn="tipo">
              Tipo
              <p-sortIcon field="tipo"></p-sortIcon>
            </th>

            @for(headerName of headersTabella; track $index){
              <th [pSortableColumn]="rowsTabella[$index]">
                {{ headerName }}
                <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
              </th>
            }

            <th>Compilata</th>

          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-delibera>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(delibera)">

            <td>{{ delibera.numeroDelibera +' / '+ generaAnnoFromStirnga(delibera.dataDelibera) }}</td>

            <td>{{ delibera.tipo.nome }}</td>

            @for (rowName of rowsTabella; track $index) {
              <td>{{ transformData(delibera[rowName]) }}</td>
            }

            <td>
              @if (delibera.stampa) {
                <i class="pi pi-check ml-3" style="color: green; font-size: 1.2em;"></i>
              }
            </td>

          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
