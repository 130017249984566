import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomAutocompleteService {

  filterList(queryParts: string[], list: any[]){
    return list.filter(element =>
      queryParts.every(part =>
        element.nome.toLowerCase().includes(part)
      )
    );
  }

}
