import { Component } from '@angular/core';

@Component({
  selector: 'app-genera-iuv',
  templateUrl: './genera-iuv.component.html',
  styleUrl: './genera-iuv.component.scss'
})
export class GeneraIuvComponent {

}
