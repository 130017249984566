import { Component, OnInit, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeliberaService } from '../../services/delibera.service';
import { TipoDelibera } from '../../model/delibere-data';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-nuova-delibera',
  templateUrl: './dialog-nuova-delibera.component.html',
  styleUrl: './dialog-nuova-delibera.component.scss'
})
export class DialogNuovaDeliberaComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private delibera_service = inject(DeliberaService);

  //spinner del bottone di invio
  isLoadingSpinner = false;

  //dati liste
  tipiDelibera: TipoDelibera[] = [];

  //dati della delibera
  deliberaForm = new FormGroup({
    numeroDelibera: new FormControl<number|null>(null, Validators.required),
    tipo: new FormControl<TipoDelibera|null>(null, Validators.required),
    dataDelibera: new FormControl<Date|null>(null, Validators.required)
  });

  ngOnInit(): void {
    this.delibera_service.getTipiDelibera().subscribe(result => this.tipiDelibera = result);
  }

  generaDelibera(){
    const values = this.deliberaForm.value;

    const day = String(values.dataDelibera?.getDate()).padStart(2, '0');
    const month = String(values.dataDelibera?.getMonth()).padStart(2, '0');
    const year = String(values.dataDelibera?.getFullYear());

    const dataDelibera = `${day}-${month}-${year}`;

    this.delibera_service.generaDelibera(values.numeroDelibera!, values.tipo?.codice!, dataDelibera).subscribe({
      next: (result) => {
        this.dialog_ref.close(
          {
            delibera: result,
            esito: true
          }
        );
      },
      error: (e) => {
        this.dialog_ref.close({errore: e, esito: false});
      }
    })
  }

  annulla(){
    this.dialog_ref.close()
  }

}
