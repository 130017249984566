<div class="w-screen h-screen {{ horizontalLayoutRegions.includes(returnRegione()) ? 'flex flex-column' : '' }} m-0 {{ regione !== 'opl' ? '' : 'main-layout' }}">
  @if (regione === 'opl') {
    <div class="sidebar hidden xl:block">
      <app-sidebar></app-sidebar>
    </div>
  }

    <div class="flex align-items-center justify-content-between {{ horizontalLayoutRegions.includes(returnRegione()) ? 'pt-3' : 'pt-6' }}  pb-4 px-3 mb-3 {{ regione !== 'opl' ? '' : 'header' }}">
      <div class="flex align-items-center ml-3">
        <div class="xl:hidden mr-3">
          <p-button (click)="sidebarVisible = true" icon="pi pi-bars" size="small"></p-button>
        </div>

        @if (horizontalLayoutRegions.includes(returnRegione())) {
          <div class="ml-3 mr-6 mt-3 mb-5 hidden xl:block">
            <p-image src="{{ checkImageRegione() }}" width="200px"></p-image>
          </div>
        }

        @if (checkBackFlagTitolo()) {
          <i class="pi pi-arrow-circle-left mr-3" style="font-size: 2em; color: #2563EB;" (click)="goBack()"></i>
        }
        <!-- [routerLink]="tornaIndietro()" -->

        <p class="m-0 text-lg sm:text-3xl font-semibold">{{ titleRouting }}</p>

        @if (isRoutingLoadingSpinner) {
          <p-progressSpinner class="ml-3" styleClass="w-4rem h-4rem" strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s"/>
        }

      </div>
      <div class="mr-3">
        <p-menu #menu [model]="items" [popup]="true" />
        <p-button class="hidden lg:block" (click)="menu.toggle($event)" icon="pi pi-user" [label]="userDisplayName" severity="secondary" [outlined]="true"></p-button>
        <p-button class="lg:hidden" (click)="menu.toggle($event)" icon="pi pi-user"  severity="secondary" [outlined]="true"></p-button>
      </div>
    </div>
    @if (horizontalLayoutRegions.includes(returnRegione())) {
      <div class="mx-5 mb-3">
        <app-menu-bar></app-menu-bar>
      </div>
    }
    <div class="px-5 main-content">
      <router-outlet></router-outlet>
    </div>
  </div>



<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="sidebarVisible" class="backGray">
    <app-sidebar></app-sidebar>
  </p-sidebar>
</div>
