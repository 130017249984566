import { Component, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { PagedResultDto, GruppoValore, IscrittoContabileDto, FiltersListContabDto, StatoIscritto } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { XlsService } from '../../services/xls.service';
import { RoleService } from '../../services/role.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';

@Component({
  selector: 'app-tabella-contabilita',
  templateUrl: './tabella-contabilita.component.html',
  styleUrl: './tabella-contabilita.component.scss'
})
export class TabellaContabilitaComponent {

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;
  showAdvancedFilters: boolean = false;
  numeroFiltriAvanzati: number = 0;
  xlsSpinner:boolean = false;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private xls_service = inject(XlsService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>(''),
    situazione: new FormControl<GruppoValore | null>(null),
    stato: new FormControl<StatoIscritto | null>(null),
    annoMorositaDal: new FormControl<number|null>(null),
    annoMorositaAl: new FormControl<number|null>(null),
    numeroAnniMorositaDa: new FormControl<number|null>(null),
    numeroAnniMorositaAl: new FormControl<number|null>(null)
  })

  // filtro per gli stati dell'iscritto
  situazioniDropdown: GruppoValore[] = [
    { nome: 'REGOLARE', valore:'regolare' },
    { nome: 'IN ATTESA', valore:'inattesa' },
    { nome: 'MOROSO', valore:'moroso' }
  ]

  statiIscrittoDropdown: StatoIscritto[] = [
    { id: 1, nome: 'ATTIVO', codiceCnop:'A' },
    { id: 2, nome: 'SOSPESO', codiceCnop:'S' },
    { id: 3, nome: 'TRASFERIMENTO', codiceCnop:'T' }
  ]

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Stato Iscritto', 'Situazione Contabile']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'stato', 'situazione']
  listaIscritti: IscrittoContabileDto[] = [];

  dettaglioIscrittoRoute = '/contabilita_iscritti/dettaglio_iscritto/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;

  vociStampa = [
    {
      label: 'Completo',
      command: () => { this.printContabXLS("completa") }
    },
    {
      label: 'Solo Partite Aperte',
      command: () => { this.printContabXLS("solo_aperte") }
    }
  ];


  ngOnInit(): void {

    this.initialDataHandler();

    this.filtri.valueChanges.subscribe(() => {
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const iscritti: PagedResultDto = this.route.snapshot.data['contabile'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaIscritti = iscritti.items;
    this.totaleDati = iscritti.count;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.iscritti_service.getIscrittiContab(data).subscribe({
      next: (result: PagedResultDto) => {
        this.listaIscritti = result.items;
        this.totaleDati = result.count;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })

    this.numeroFiltriAvanzati = this.conteggioAvanzati();

  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //assegna colore stato
  coloreStato(situazione: string): string{
    let colore = '';

    if(situazione === 'regolare') colore = '#2EAB5B';
    if(situazione === 'moroso') colore = '#FF0000';
    if(situazione === 'inattesa') colore = '#E5C130';

    return colore;
  }

  //assegna colore stato
  coloreStatoIscritto(stato: string): string{
    let colore = '';
    const id = stato;

    if(id === 'ATTIVO') colore = '#16A34A';
    if(id === 'SOSPESO') colore = '#CDC103';
    if(id === 'TRASFERIMENTO') colore = '#00BFFE';

    return colore;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: '',
      situazione: null,
      stato: null,
      annoMorositaDal: null,
      annoMorositaAl: null,
      numeroAnniMorositaDa: null,
      numeroAnniMorositaAl: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const situazioni: GruppoValore[] = this.situazioniDropdown;
    const statiIscritto: StatoIscritto[] = this.statiIscrittoDropdown;
    const str: string = sessionData.inserimento;

    const routeFilter = this.route.snapshot.params['routeFilters'];

    const situazioneUpdate: GruppoValore = cercaSituazione();
    const statoIscrittoUpdate: StatoIscritto = cercaStatoIscritto();

    this.filtri.patchValue({
      inserimento: str,
      situazione: situazioneUpdate,
      stato: statoIscrittoUpdate,
      annoMorositaDal: sessionData.annoMorositaDal,
      annoMorositaAl: sessionData.annoMorositaAl,
      numeroAnniMorositaDa: sessionData.numeroAnniMorositaDa,
      numeroAnniMorositaAl: sessionData.numeroAnniMorositaAl
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);


    function estraiStato(routeFilter: string) {
      let stringaStato = '';
      if(routeFilter === 'moroso') stringaStato = 'moroso';
      return stringaStato;
    }

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    this.custom_filters_service.sessionStorageUpdate(this.filtri.value, this.componente, tabData);

    this.numeroFiltriAvanzati = this.conteggioAvanzati();


    //-------------------------------------------------------

    function cercaSituazione(): GruppoValore{
      if(routeFilter === 'iscritti'){
        return situazioni.find(s => s.valore === sessionData.situazione)!
      }else{
        return situazioni.find(s => s.valore === routeFilter)!
      }
    }

    function cercaStatoIscritto(): StatoIscritto{
      return statiIscritto.find(si => si.codiceCnop === sessionData.stato)!
    }
  }

  //mostra/nascondi filtri avanzati
  showFilters(){
    this.showAdvancedFilters = !this.showAdvancedFilters;
  }

  //conta i filtri avanzati presenti
  conteggioAvanzati(): number{

    const values = this.filtri.value;
    let conteggio = 0;

    if(values.annoMorositaDal) conteggio++;
    if(values.annoMorositaAl) conteggio++;
    if(values.numeroAnniMorositaDa) conteggio++;
    if(values.numeroAnniMorositaAl) conteggio++;

    return conteggio;
  }

  printContabXLS(vista: string){

    this.xlsSpinner = true;

    const values = this.filtri.value;

    const data: FiltersListContabDto = {
      stringaFiltro: values.inserimento!,
      stato: values.situazione?.valore ? values.situazione?.valore : '',
      statoIscritto: values.stato?.nome ? values.stato?.nome : '',
      tipoLista: this.tipoLista,
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,

      annoDal: values.annoMorositaDal ? values.annoMorositaDal : null,
      annoAl: values.annoMorositaAl ? values.annoMorositaAl : null,

      numeroAnniDa: values.numeroAnniMorositaDa ? values.numeroAnniMorositaDa : null,
      numeroAnniAl: values.numeroAnniMorositaAl ? values.numeroAnniMorositaAl : null,
      stpId: ''
    }

    if(vista == "completa"){
      this.xls_service.stampaContabXls(data).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contabilità.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        this.xlsSpinner = false;
      }, error => {
        this.xlsSpinner = false;
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
        console.error('Error downloading the file', error);
      });
    }

    if(vista == "solo_aperte"){
      this.xls_service.stampaContabAperteXls(data).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contabilità_solo_aperte.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        this.xlsSpinner = false;
      }, error => {
        this.xlsSpinner = false;
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
        console.error('Error downloading the file', error);
      });
    }



  }

}
