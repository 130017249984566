import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardCardsDto, DashboardChartsDto, DashboardRegioniDto, DashboardTitoliDto, DataDashboard, ElementoCard, IscrittoYearsDto } from '../../model/dashboard-data';
import { environment } from '../../environment/environment';
import { IscrittiService } from '../../services/iscritti.service';
import { KeycloakService } from 'keycloak-angular';
import { RoleService } from '../../services/role.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //skeleton_loaders
  cardsLoading: boolean = true;
  yearsLoading: boolean = true;
  chartsLoading: boolean = true;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private role_service = inject(RoleService);


  //contenuto delle cards
  cards: ElementoCard[] = [
    {
      id: 1,
      icon: 'pi pi-users',
      nome: 'Provvisori',
      stile: 'color: white; font-size: 1.3em; background-color: #FEA702',
      routeString: this.role_service.isAlboOrConsultazione() ? '/gestione_albo/provvisori' : '',
      value: 0
    },
    {
      id: 2,
      icon: 'pi pi-money-bill',
      nome: 'Morosità',
      stile: 'color: white; font-size: 1.3em; background-color: #CD2028',
      routeString: this.role_service.isContabilitaOrisConsultazione() ? 'contabilita-iscritti/moroso' : '',
      value: 0
    },
    {
      id: 3,
      icon: 'pi pi-arrow-right-arrow-left',
      nome: 'Trasferimento',
      stile: 'color: white; font-size: 1.3em; background-color: #53C7A8',
      routeString: this.role_service.isAlboOrConsultazione() ? 'gestione_albo/gestione_iscritti/trasferimento' : '',
      value: 0
    },
    {
      id: 4,
      icon: 'pi pi-clock',
      nome: 'Sospesi',
      stile: 'color: white; font-size: 1.3em; background-color: #EBA79A',
      routeString: this.role_service.isAlboOrConsultazione() ? 'gestione_albo/gestione_iscritti/sospeso' : '',
      value: 0
    }
  ];

  //elementi per la tabella
  annoPiccolo: number | undefined;
  provvisori: number | undefined;
  riepiloghi: IscrittoYearsDto[] = [];
  skeletonArray: Number[] = [1, 2, 3, 4, 5, 6];

  //dati dei grafici
  dataSesso: any;
  optionsSesso: any;

  dataEta: any;
  optionsEta: any;

  dataTitoli: any;
  optionsTitoli: any;

  dataRegioni: any;
  optionsRegioni: any;

  ngOnInit(): void {
    this.loadDashboard();
  }

  //dati della dashboard
  loadDashboard(){

    // const cardsData = this.route.snapshot.data['cards'];
    // const yearsData = this.route.snapshot.data['years'];
    // const chartsData = this.route.snapshot.data['charts'];

    this.iscritti_service.getDashboardCards().subscribe({
      next: (cardsResult: DashboardCardsDto) => {
        if(cardsResult){
          this.cards.forEach(card => {
            if(card.id === 1) card.value = cardsResult.provvisori;
            if(card.id === 2) card.value = cardsResult.morosi;
            if(card.id === 3) card.value = cardsResult.trasferimenti;
            if(card.id === 4) card.value = cardsResult.sospesi;
          });
        }
      },
      complete: () => {
        this.cardsLoading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.iscritti_service.getDashboardYears().subscribe({
      next: (yearsResult: IscrittoYearsDto[]) => {
        if(yearsResult){
          this.riepiloghi.push(...yearsResult);
          this.annoPiccolo = this.estraiAnnoMinore(this.riepiloghi);
        }
      },
      complete: () => {
        this.yearsLoading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.iscritti_service.getDashboardCharts().subscribe({
      next: (chartsResult: DashboardChartsDto) => {
        if(chartsResult){
          this.sessoGrafico(chartsResult);
          this.etaGrafico(chartsResult);
        }
      },
      complete: () => {
        this.chartsLoading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.getTitoli();
    this.getRegioni();

  }

  //costruzione grafico uomini/donne
  private sessoGrafico(result: DashboardChartsDto){

    this.dataSesso = {
      labels: ['Donne', 'Uomini'],
      datasets: [
        {
          data: [result.donne, result.uomini],
          backgroundColor: ["#F9A8D4", "#A5B4FC"],
          hoverBackgroundColor: ["#ffcee8", "#c9d3ff"],
          borderRadius: '5'
        }
      ]
    };

    //opzioni del grafico
    this.optionsSesso = {
      responsive: true,
      maintainAspectRatio: true,
      borderWidth: '0',
      border: '0',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 25,
            font: {
              size: 20
            }
          }
        }
      }
    };
  }

  //costruzione grafico dati anagrafici
  private etaGrafico(result: DashboardChartsDto){

    this.dataEta = {
      labels: ['Under 30', 'Over 50', '30 - 40'],
      datasets: [
        {
          data: [result.underTrenta, result.overCinquanta, result.traTrentaCinquanta],
          backgroundColor: ["#aae0bd", "#f2c05c" , "#f7ef56"],
          hoverBackgroundColor: ["#c5e5d0", "#f9d793", "#fff6bf"],
          borderRadius: '5'
        }
      ]
    };

    //opzioni del grafico
    this.optionsEta = {
      responsive: true,
      maintainAspectRatio: true,
      borderWidth: '0',
      border: '0',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 25,
            font: {
              size: 17
            }
          }
        }
      }
    };
  }

  //costruzione grafico dati anagrafici
  private titoliGrafico(result: DashboardTitoliDto[]){

    this.dataTitoli = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#59ADF6",
            "#9D94FF",
            "#2f4ce0",
            "#C780E8"
          ],
          hoverBackgroundColor: [
            "#94caf7",
            "#c2bdfc",
            "#798be5",
            "#d0a5e5"
          ],
          borderRadius: '5'
        }
      ]
    };

    for(const titolo of result){
      this.dataTitoli.labels.push(titolo.nome);
      this.dataTitoli.datasets[0].data.push(titolo.conteggio);
    }

    //opzioni del grafico
    this.optionsTitoli = {
      responsive: true,
      maintainAspectRatio: true,
      borderWidth: '0',
      border: '0',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 25,
            font: {
              size: 17
            }
          }
        }
      }
    };
  }

  //costruzione grafico dati anagrafici
  private regioniGrafico(result: DashboardRegioniDto){

    this.dataRegioni = {
      labels: ['Lombardia', 'Fuori Rergione', 'Estero'],
      datasets: [
        {
          data: [result.lombardia, result.fuoriRegione, result.estero],
          backgroundColor: ["#4C936F", "#BC5839","#9AAAD1" ],
          hoverBackgroundColor: ["#77BE9C", "#d38974","#B8C3E7" ],
          borderRadius: '5'
        }
      ]
    };

    //opzioni del grafico
    this.optionsRegioni = {
      responsive: true,
      maintainAspectRatio: true,
      borderWidth: '0',
      border: '0',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 25,
            font: {
              size: 17
            }
          }
        }
      }
    };
  }

  //estrai anno minore per gestione grafica della tabella
  private estraiAnnoMinore(riepiloghi: IscrittoYearsDto[]): number{
    let anni: number[] = [];
    let anno: number = 0;

    riepiloghi.forEach( riepilogo => anni.push(riepilogo.anno) );

    anno = Math.min(...anni);

    return anno;
  }

  checkRegione(){

    let classeRegione = '';

    if(this.regione === 'opl') classeRegione = 'backgroundOPL';
    if(this.regione === 'opp') classeRegione = 'backgroundOPP';
    if(this.regione === 'demo') classeRegione = 'backgroundDemo';

    return classeRegione;
  }

  checkRegioneLabel(){

    let label = '';

    if(this.regione === 'opl') label = 'Lombardia';
    if(this.regione === 'opp') label = 'Puglia';
    if(this.regione === 'demo') label = 'Demo';

    return label;
  }

  // caricamenti ----------------

  private getTitoli(){
    this.iscritti_service.getDashboardTitoli().subscribe({
      next: (result: DashboardTitoliDto[]) => {
        this.titoliGrafico(result);
      },
      error: err => {
        console.log(err);
      }
    })
  }

  private getRegioni(){
    this.iscritti_service.getDashboardRegioni().subscribe({
      next: (result: DashboardRegioniDto) => {
        this.regioniGrafico(result);
      },
      error: err => {
        console.log(err);
      }
    })
  }

}
