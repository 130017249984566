<div class="px-1 pb-8 pt-1">

  <div class="text-center mb-8">
    <p>Inserire i dati per completare l'iscrizione di <span class="font-bold">{{ iscritto?.cognome }} {{ iscritto?.nome }}</span></p>
  </div>

  <div [formGroup]="iscrizioneDati" class="flex flex-column">

    <div class="flex justify-content-center">

      <div>
        <span class="p-float-label px-3">
          <input class="px-0" pInputText pKeyFilter="int" id="numeroIscrizione" formControlName="numeroIscrizione"/>
          <label class="pl-1" for="numeroIscrizione">N° Iscrizione*</label>
        </span>
      </div>

      <div class="dataPickerCustom">
        <span class="p-float-label px-3">
          <p-calendar appendTo="body" formControlName="dataIscrizione" inputId="dataIscrizione" [minDate]="dataProtocollo!" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
          <label class="pl-1" for="dataIscrizione">Data Iscrizione*</label>
        </span>
      </div>

      <div>
        <span class="p-float-label px-3">
          <p-dropdown appendTo="body" [options]="sezioni" formControlName="sezioneAlbo" placeholder="scegli albo" optionLabel="nome" inputId="sezioneAlbo"></p-dropdown>
          <label class="pl-1" for="sezioneAlbo">Sezione*</label>
        </span>
      </div>

    </div>

  </div>



</div>

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem"></i>
  }@else {
    <p-button label="Invia Iscrizione" (click)="inviaDati()" [disabled]="iscrizioneDati.invalid"></p-button>
  }
</div>
