import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MenuItem, PrimeNGConfig } from 'primeng/api';

import { environment } from './environment/environment';
import { DeliberaService } from './services/delibera.service';
import { SharedService } from './services/shared.service';
import { Location } from '@angular/common';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  isRoutingLoadingSpinner: boolean = false;

  horizontalLayoutRegions: string[] = ['demo', 'opp'];
  verticalLayoutRegions: string[] = ['opl'];

  title = 'webapp';
  backFlagTitolo = '';
  backFlagComponent = '';

  private routerSubscription: Subscription | undefined;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private location = inject(Location);
  private shared_service = inject(SharedService);

  items: MenuItem[] | undefined;

  titleRouting: string = '';
  sidebarVisible: boolean = false;

  isLoggedIn = false;
  userProfile: KeycloakProfile | null = null;
  userDisplayName?: string;

  constructor(private primengConfig: PrimeNGConfig, private readonly keycloak: KeycloakService){}

  async ngOnInit() {

    console.debug("app init, checking login");
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    console.debug("is logged in "+this.isLoggedIn);

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      let s = this.userProfile.firstName?this.userProfile.firstName+' ':'';
      s+=this.userProfile.lastName??this.userProfile.username;
      this.userDisplayName = s;

      // if (this.keycloak.isUserInRole("admin")) {
      //   this.menuItems.push();
      // }

    }

    this.primengConfig.ripple = true;

    this.router.events.subscribe(e => {
      if(e instanceof ActivationEnd){
        this.titleRouting = this.gestisciTitolo(e);
      }
    })

    this.items = [
      {
          items: [
              {
                  label: 'Profilo',
                  icon: 'pi pi-user-edit',
                  command: () => {
                    this.openProfile();
                  }
              },
              {
                  label: 'Logout',
                  icon: 'pi pi-power-off',
                  command: () => {
                    this.logout();
                  }
              }
          ]
      }
    ];

    this.shared_service.currentMessage.subscribe(message => {
      setTimeout(() => { this.titleRouting = message }, 1300)
    })

    if(this.titleRouting === '') this.titleRouting = 'Dashboard';

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Close the sidebar on route change
        this.sidebarVisible = false;
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
    .subscribe((event) => {

      if (event instanceof NavigationStart) {
        this.isRoutingLoadingSpinner = true;
      }

      if (event instanceof NavigationEnd) {
        this.isRoutingLoadingSpinner = false;
      }

    })

  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }


  //gestisce il titolo della pagina corrente del routing
  private gestisciTitolo(event: any): string{
    const data = event.snapshot.data;

    if(event.snapshot.data['componente'] != undefined)
      this.backFlagComponent = event.snapshot.data['componente'];


    let titolo = data['title'];
    this.backFlagTitolo = titolo;


    if(titolo === 'Dettaglio Iscritto'){
      titolo = `${data['iscritto'].nome} ${data['iscritto'].cognome}`;
      if(data['iscritto'].numeroIscrizione) titolo += ` n.${data['iscritto'].numeroIscrizione}`;
      if(data['iscritto'].titolo) titolo += ` ${data['iscritto'].titolo.nome}`;
    }

    if(titolo === 'Dettaglio Contabilità'){
      titolo += ` - ${data['iscritto'].nome} ${data['iscritto'].cognome}`;
      if(data['iscritto'].numeroIscrizione) titolo += ` n.${data['iscritto'].numeroIscrizione}`;
    }

    if(titolo === 'Stp Dettaglio'){
      titolo += ` - ${data['stp'].denominazione}`
    }

    if(['Riepilogo Sezione A', 'Riepilogo Sezione B', 'Riepilogo Psicoterapeuti Sezione A', 'Riepilogo Trasferiementi'].includes(titolo)){
      titolo = 'Bozza ' + titolo;
      titolo += ` - N° ${data['delibera'].numeroDelibera}`;
    }

    if(titolo === 'Riepilogo Chiuso'){
      titolo += ` - N° ${data['delibera'].numeroDelibera}`;
    }

    if(['Dettaglio Domanda'].includes(titolo)){
      titolo += ` - N° ${data['domanda'].domandaProtocollo}`;
    }

    if(['Dettaglio Pratica'].includes(titolo)){
      titolo += ` - N° ${data['storico_pratica'].domandaProtocollo}`;
    }

    return titolo;
  }

  //non serve non essendoci home senza autenticazione
  login() {
    this.keycloak.login();
  }

  logout() {
    this.keycloak.logout();
  }

  openProfile() {
    this.keycloak.getKeycloakInstance().accountManagement();
  }

  checkBackFlagTitolo(){
    return [
      'Riepilogo Sezione A', 'Riepilogo Sezione B', 'Riepilogo Chiuso', 'Dettaglio Iscritto',
      'Nuovo Iscritto', 'Stp Dettaglio', 'Nuova Stp', 'Dettaglio Contabilità',
      'Riepilogo Psicoterapeuti Sezione A', 'Riepilogo Trasferiementi', 'Nuovo Provvisorio'
    ].includes(this.backFlagTitolo) ? true : false
  }

  goBack(){
    this.location.back();
  }

  returnRegione(){
    return this.regione;
  }

  checkImageRegione(){
    let image = '';

    if(this.regione === 'opp') image = 'assets/images/logo_new_mag2022.png';
    if(this.regione === 'demo') image = 'assets/images/Logo_Synergica.png';

    return image;
  }

}
