<div [formGroup]="statoIscrittoForm" class="pt-6 pb-2">

  <div class="flex justify-content-center">

    <div>
      <span class="p-float-label px-3 mb-6">
        <p-dropdown appendTo="body" [options]="stati" formControlName="statoIscritto" placeholder="scegli uno stato" optionLabel="nome" inputId="statoIscritto"></p-dropdown>
        <label class="pl-1" for="statoIscritto">Stato</label>
      </span>
    </div>

    @if (gestoreStato()) {
      <div class="dataPickerCustom">
        <span class="p-float-label px-3 mb-6">
          <p-calendar appendTo="body" dateFormat="dd/mm/yy" formControlName="dataStato"  inputId="dataStato" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
          <label class="pl-1" for="dataStato">{{ placeholderData() }}</label>
        </span>
      </div>
    }

  </div>

  @if (statoIscrittoForm.get('statoIscritto')?.value!.codiceCnop === 'T'){
    <div class="flex justify-content-center">
      <span class="p-float-label px-3 mb-6">
        <p-dropdown appendTo="body" [options]="ordiniData" formControlName="trasferimentoA" placeholder="scegli un ordine" optionLabel="nome" inputId="trasferimentoA"></p-dropdown>
        <label class="pl-1" for="trasferimentoA">Trasferimento A</label>
      </span>
    </div>
  }

</div>

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem; color: #3a8dde"></i>
  }@else {
    <p-button label="Modifica" [disabled]="statoIscrittoForm.pristine || statoIscrittoForm.invalid" (click)="inviaDati()"/>
  }
</div>
