import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-configurazione-dialog',
  templateUrl: './configurazione-dialog.component.html',
  styleUrl: './configurazione-dialog.component.scss'
})
export class ConfigurazioneDialogComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private dialog_service = inject(DialogService);


  //dati option
  originalString: string | undefined;
  optionId: number | undefined;
  isEqualFromStart: boolean = true;


  optionForm = new FormGroup({
    valore: new FormControl<string|null>(null, Validators.required)
  })


  ngOnInit(): void {
    this.originalString = this.dialog_config.data.valore;
    this.optionForm.patchValue({ valore: this.dialog_config.data.valore })

    this.optionForm.get('valore')?.valueChanges.subscribe(value => {
      this.isEqualFromStart = (value === this.originalString) ? true : false;
    })
  }

  //invio dati
  salvaOption(){
    this.dialog_ref.close({
      optionId: this.dialog_config.data.optionId,
      valore: this.optionForm.value.valore,
      tipo: this.dialog_config.data.tipo
    })
  }

}
