import { Component, inject, OnInit } from '@angular/core';
import { IscrittoDelibera, RatificaArt3Dto, RatificaCancellazioneDto, CancellazionePerTrasferimentoDto, RatificaNullaOstaTrasferimentoDto, ValueObject } from '../../model/oprp-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Comune, IscrittoSelezioneStampeDto, Ordine } from '../../model/iscritti-data';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { PrintModuliService } from '../../services/print-moduli.service';
import { IscrittiService } from '../../services/iscritti.service';
import { MembroConsiglio, PresenzaConsiglioDto } from '../../model/domande-data';
import { ConsiglioService } from '../../services/consiglio.service';

@Component({
  selector: 'app-stampe-e-delibere',
  templateUrl: './stampe-e-delibere.component.html',
  styleUrl: './stampe-e-delibere.component.scss'
})
export class StampeEDelibereComponent implements OnInit{

  private iscritto_service = inject(IscrittiService);
  private print_moduli_service = inject(PrintModuliService);
  private consiglio_service = inject(ConsiglioService);

  ordini: Ordine[] = [];
  ordineSelezionato: Ordine | null | undefined;

  listaIscritti: IscrittoSelezioneStampeDto[] = [];
  iscrittiFiltered: IscrittoSelezioneStampeDto[] = [];

  oggettoOptions: ValueObject[] = [
    {
      nome: 'Cancellazione per Trasferimento dagli elenchi dell\'Albo degli Psicologi',
      value: 'CT'
    },
    {
      nome: 'Ratifica Art.3',
      value: 'RA3'
    },
    {
      nome: 'Ratifica Cancellazione',
      value: 'RC'
    },
    {
      nome: 'Ratifica Nulla-Osta Trasferimento',
      value: 'RNT'
    }
  ];

  consiglio: PresenzaConsiglioDto[] = [];

  deliberaForm = new FormGroup({
    oggetto: new FormControl<ValueObject|null>(null),
    numeroDelibera: new FormControl<number|null>(null, Validators.required),
    iscritto: new FormControl<IscrittoSelezioneStampeDto|null>(null, Validators.required),
    dataDelibera: new FormControl<Date|null>(null, Validators.required)
  })

  ngOnInit(): void {
    this.initialHandler();

    this.deliberaForm.get('oggetto')?.valueChanges.subscribe(result => {
      if(result?.value === 'CT'){
        this.consiglio_service.getMembriConsiglio().subscribe((resultLista: MembroConsiglio[]) => {
          this.consiglio = this.generaListaPresenze(resultLista);
        })
      }
    })
  }

  initialHandler(){
    this.iscritto_service.getOrdini().subscribe({
      next: result => {
        this.ordini = result;
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  checkNumeroDelibera(){
    const oggetti = ['CT', 'RA3', 'RC', 'RNT'];
    return oggetti.includes(this.deliberaForm.get('oggetto')?.value?.value!);
  }

  checkCT(){
    return this.deliberaForm.get('oggetto')?.value?.value! === 'CT';
  }

  checkRNT(){
    return this.deliberaForm.get('oggetto')?.value?.value! === 'RNT';
  }

  // autocompletamento per i p-autocomplete
  /*filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filteredComuni = this.comuni.filter(comune =>
        queryParts.every(part =>
            comune.nome.toLowerCase().includes(part)
        )
      );
    }

  }*/

  // gestione della stampa delle delibere
  generaDelibera(){

    const values = this.deliberaForm.value;

    if(values.oggetto?.value === 'RA3')
      this.moduloRatificaArt3(values);

    if(values.oggetto?.value === 'RC')
      this.moduloCancellazione(values);

    if(values.oggetto?.value === 'RNT')
      this.moduloNullaOstaTrasferimento(values);

    if(values.oggetto?.value === 'CT')
      this.moduloCancellazionePerTrasferimento(values);

  }

  // metodi private -----------------------------------------------------------

  private buildDate(data_in: Date){
    const day = String(data_in.getDate()).padStart(2, '0');
    const month = String(data_in.getMonth() + 1).padStart(2, '0');
    const year = String(data_in.getFullYear());

    const data = `${year}-${month}-${day}`;

    return data;
  }

  // stampa modulo ratifica articolo 3
  private moduloRatificaArt3(values: any){
    const data: RatificaArt3Dto = {
      numeroDelibera: values.numeroDelibera!,
      dataDelibera: this.buildDate(values.dataDelibera!)
    }
    this.print_moduli_service.stampaModuloRatificaArt3(values.iscritto.id, data).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `oprp_modulo_ratifica_art_3.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  // stampa modulo ratifica cancellazione
  private moduloCancellazione(values: any){
    const data: RatificaCancellazioneDto = {
      numeroDelibera: values.numeroDelibera!,
      dataDelibera: this.buildDate(values.dataDelibera!)
    }
    this.print_moduli_service.stampaModuloRatificaCancellazione(values.iscritto.id, data).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `oprp_modulo_cancellazione.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  // stampa modulo ratifica nulla osta trasferimento
  private moduloNullaOstaTrasferimento(values: any){
    const data: RatificaNullaOstaTrasferimentoDto = {
      numeroDelibera: values.numeroDelibera!,
      dataDelibera: this.buildDate(values.dataDelibera!),
      ordine: this.ordineSelezionato?.nome!
    };console.log(data);
    this.print_moduli_service.stampaModuloRatificaNullaOstaTrasferimento(values.iscritto.id, data).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `oprp_modulo_nulla_osta_trasferimento.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  // stampa modulo ratifica nulla osta trasferimento
  private moduloCancellazionePerTrasferimento(values: any){

    const presenze = [];

    for(const c of this.consiglio){
      if(c.presente)
        presenze.push(c.id)
    }

    const data: CancellazionePerTrasferimentoDto = {
      numeroDelibera: values.numeroDelibera!,
      dataDelibera: this.buildDate(values.dataDelibera!),
      consiglio: presenze
    };

    this.print_moduli_service.stampaCancellazionePerTrasferimento(values.iscritto.id, data).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `oprp_modulo_delibera.pdf.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  private generaListaPresenze(lista: MembroConsiglio[]): PresenzaConsiglioDto[]{
    const listaPresenze: PresenzaConsiglioDto[] = [];
    for(const membro of lista)
      listaPresenze.push({...membro, presente: true})
    return listaPresenze;
  }

  inputValueAutocomplete(event: any){
    const val = event.target.value;
    this.gestioneListaSelezionabili(val);
  }

  gestioneListaSelezionabili(stringaRicerca: string){
    this.iscritto_service.getIscrittiStampa(stringaRicerca).subscribe((result: IscrittoSelezioneStampeDto[]) => {
      this.listaIscritti = result;
    });
  }

  iscrittiStampeSearch(event: AutoCompleteCompleteEvent){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    this.iscrittiFiltered = this.listaIscritti.filter(iscritto =>
      queryParts.every(part =>
        iscritto.infoEstese.toLowerCase().includes(part)
      )
    )
  }

}
