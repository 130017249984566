import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment/environment';
import { NotificaIcrizioneDto } from '../model/iscritti-data';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private _http = inject(HttpClient);

  private print_api: string = `${environment.printUrl}/jr`;
  private email_api: string = `${environment.printUrl}/email`;

  stampaIscrizione(delibera: number, sezione: string, psi: boolean): Observable<any>{ // a / b / p
    return this._http.get(this.print_api + `/delibera-${psi ? "psi-" : ""}iscrizione-${sezione}/${delibera}`, { responseType: 'blob' });
  }

  stampaSchedaPersonale(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/scheda-personale/${idIscritto}`, { responseType: 'blob' });
  }

  stampaCertificatoIscrizione(idIscritto: number): Observable<any>{
    const endpoint = `/certificato-iscrizione/${idIscritto}`;
    return this._http.get(this.print_api + endpoint , { responseType: 'blob' });
  }

  stampaCertificatoIscrizioneEstero(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/certificato-iscrizione-estero/${idIscritto}`, { responseType: 'blob' });
  }

  stampaResocontoContabile(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/resoconto-contabile/${idIscritto}`, { responseType: 'blob' });
  }

  stampaCertificatoIscrizioneStp(idStp: number): Observable<any>{
    return this._http.get(this.print_api + `/certificato-iscrizione_stp/${idStp}`, { responseType: 'blob' });
  }

  notificaEmail(idDelibera: number, data: NotificaIcrizioneDto): Observable<boolean>{
    return this._http.post<boolean>(this.email_api + `/invia-notifica-iscrizione/${idDelibera}`, data);
  }

  stampaTesserino(idIscritto: number): Observable<Blob> {
    return this._http.get(this.print_api + `/tesserino/${idIscritto}`, { responseType: 'blob' });
  }
}
