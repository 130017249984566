import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment/environment';
import { SynchProcessStatus } from '../model/cnop-data';

@Injectable({
  providedIn: 'root'
})
export class CnopService {
  private cnop_api: string = `${environment.baseUrl}/cnop`;
  constructor(private http: HttpClient) { }

  getSyncStatus(): Observable<SynchProcessStatus>{
    return this.http.get<SynchProcessStatus>(`${this.cnop_api}`);
  }

  startSyncFull(): Observable<SynchProcessStatus>{
    return this.http.put<SynchProcessStatus>(`${this.cnop_api}/start-all`, {});
  }

  startSyncSingle(iscrittoId: string): Observable<SynchProcessStatus>{
    return this.http.put<SynchProcessStatus>(`${this.cnop_api}/start/${iscrittoId}`, {});
  }
}
