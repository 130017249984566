<div class=" h-full sm:pt-5 pt-8">

  <p-tabView>

    <!-- pagamenti -->
    <p-tabPanel header="Pagamenti">
      <div class="backPanel border-round-3xl">
        <div class="px-5 pt-3 pb-5">

          <!-- tebella pagamenti -->
          <app-tabella-pagamenti [data]="listaData"></app-tabella-pagamenti>

        </div>
      </div>
    </p-tabPanel>

    <!-- anagrafica -->
    <p-tabPanel header="Anagrafica Società">
      <div class="flex flex-column backPanel border-round-3xl px-5 pt-7 pb-2">

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="denominazione" [value]="stp?.denominazione" readonly/>
              <label class="pl-1" for="denominazione">Ragione Sociale</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="codiceFiscale" [value]="stp?.codiceFiscale" readonly/>
              <label class="pl-1" for="codiceFiscale">Codice Fiscale</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="codiceStp" [value]="stp?.codiceStp" readonly/>
              <label class="pl-1" for="codiceStp">Numero Iscrizionie Albo</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="sezioneSocieta" [value]="stp?.sezioneSocieta" readonly/>
              <label class="pl-1" for="sezioneSocieta">Sezione</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="costituzioneSocieta" [value]="stp?.costituzioneSocieta | date : 'dd-MM-yyyy'" readonly/>
              <label class="pl-1" for="costituzioneSocieta">Data Costituzione</label>
            </span>
          </div>

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="iscrizioneData" [value]="stp?.iscrizioneData | date : 'dd-MM-yyyy'" readonly/>
              <label class="pl-1" for="iscrizioneData">Data Iscrizione</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="indirizzoSocieta" [value]="stp?.indirizzoSocieta" readonly/>
              <label class="pl-1" for="indirizzoSocieta">Indirizzo Società</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="comuneSocieta" [value]="stp?.comuneSocieta?.nome" readonly/>
              <label class="pl-1" for="comuneSocieta">Comune</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="provinciaSocieta" [value]="stp?.provinciaSocieta?.nome" readonly/>
              <label class="pl-1" for="provinciaSocieta">Provincia</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="capSocieta" [value]="stp?.capSocieta" readonly/>
              <label class="pl-1" for="capSocieta">CAP</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domandaProtocollo" [value]="stp?.domandaProtocollo" readonly/>
              <label class="pl-1" for="domandaProtocollo">Protocollo Domanda</label>
            </span>
          </div>

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domandaData" [value]="stp?.domandaData | date : 'dd-MM-yyyy'" readonly/>
              <label class="pl-1" for="domandaData">Data Domanda</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rea" [value]="stp?.rea" readonly/>
              <label class="pl-1" for="rea">Rea</label>
            </span>
          </div>

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="reaData" [value]="stp?.reaData | date : 'dd-MM-yyyy'" readonly/>
              <label class="pl-1" for="reaData">Data Rea</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="emailPeo" [value]="stp?.emailPeo" readonly/>
              <label class="pl-1" for="emailPeo">Email Peo</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="emailPec" [value]="stp?.emailPec" readonly/>
              <label class="pl-1" for="emailPec">Email Pec</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="numeroTelefono" [value]="stp?.numeroTelefono" readonly/>
              <label class="pl-1" for="numeroTelefono">Numero Telefono</label>
            </span>
          </div>

        </div>

        <div class="flex align-items-center pl-3 mb-3">
          <p-checkbox class="mr-1" [binary]="true" inputId="stpMultidisciplinare" [(ngModel)]="flagMultidisciplinare" readonly></p-checkbox>
          <label class="checkboxLabelCustom text-xl pl-3" for="stpMultidisciplinare">STP Multidisciplinare</label>
        </div>

        <div class="flex align-items-center pl-3 mb-3">
          <p-checkbox class="mr-1" [binary]="true" inputId="stpUnipersonale" [(ngModel)]="flagUnipersonale" readonly></p-checkbox>
          <label class="checkboxLabelCustom text-xl pl-3" for="stpUnipersonale">STP Unipersonale</label>
        </div>

      </div>
    </p-tabPanel>

    <!-- albo -->
    <!-- <p-tabPanel header="Rappresentante Legale">
      <div class="flex flex-column backPanel border-round-3xl px-5 pt-7 pb-2">

        <div class="pt-3">
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" style="width: 100%;" pInputText id="tipoSocio" [value]="stp?.tipoSocio?.nome" readonly/>
            <label class="pl-1" for="tipoSocio">Tipo Socio*</label>
          </span>
        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteOrdine" [value]="stp?.rappresentanteOrdine" readonly/>
              <label class="pl-1" for="rappresentanteOrdine">Ordine</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteNumeroOrdine" [value]="stp?.rappresentanteNumeroOrdine" readonly/>
              <label class="pl-1" for="rappresentanteNumeroOrdine">Numero Ordine</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteNome" [value]="stp?.rappresentanteNome" readonly/>
              <label class="pl-1" for="rappresentanteNome">Nome*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteCognome" [value]="stp?.rappresentanteCognome" readonly/>
              <label class="pl-1" for="rappresentanteCognome">Cognome*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteCodiceFiscale" [value]="stp?.rappresentanteCodiceFiscale" readonly/>
              <label class="pl-1" for="rappresentanteCodiceFiscale">Codice Fiscale*</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteLuogoNascita" [value]="stp?.rappresentanteLuogoNascita" readonly/>
              <label class="pl-1" for="rappresentanteLuogoNascita">Luogo Nascita*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteProvinciaNascita" [value]="stp?.rappresentanteProvinciaNascita?.nome" readonly/>
              <label class="pl-1" for="rappresentanteProvinciaNascita">Provincia Nascita*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteNazionalita" [value]="stp?.rappresentanteNazionalita?.nome" readonly/>
              <label class="pl-1" for="rappresentanteNazionalita">Cittadinanza*</label>
            </span>
          </div>

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteDataNascita" [value]="stp?.rappresentanteDataNascita | date : 'dd-MM-yyyy'" readonly/>
              <label class="pl-1" for="rappresentanteDataNascita">Data Nascita*</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteIndirizzo" [value]="stp?.rappresentanteIndirizzo" readonly/>
              <label class="pl-1" for="rappresentanteIndirizzo">Indirizzo*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteCitta" [value]="stp?.rappresentanteCitta" readonly/>
              <label class="pl-1" for="rappresentanteCitta">Città*</label>
            </span>
          </div>


          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteProvincia" [value]="stp?.rappresentanteProvincia?.nome" readonly/>
              <label class="pl-1" for="rappresentanteProvincia">Provincia*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="rappresentanteCap" [value]="stp?.rappresentanteCap" readonly/>
              <label class="pl-1" for="rappresentanteCap">CAP*</label>
            </span>
          </div>

        </div>

      </div>
    </p-tabPanel> -->

  </p-tabView>

  <!-- @if (regione === 'opl') {
    <div class="absolute top-0 right-0 mr-6 mt-8 pt-5">
      <p-button icon="pi pi-print" [disabled]="role_service.isConsultazioneRole()"></p-button>
    </div>
  } -->


  <p-toast position="bottom-center"></p-toast>
</div>
