import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CivilianextService } from '../../services/civllianext.service';
import { ImportProcessStatus } from '../../model/civilianext-data';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, interval } from 'rxjs';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-import-iscritti',
  templateUrl: './import-iscritti.component.html',
  styleUrl: './import-iscritti.component.scss'
})
export class ImportIscrittiComponent implements OnInit, OnDestroy {
  private message_service = inject(MessageService);
  public role_service = inject(RoleService);
  status?: ImportProcessStatus;
  secondsCounter: Observable<number>;
  constructor (private service: CivilianextService) {
    this.secondsCounter = interval(10000);
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }

  private subscribe?: Subscription ;
  ngOnInit(): void {
    this.loadStatus();

    this.subscribe = this.secondsCounter.subscribe({
      next: (value:number) => {
        this.loadStatus();
      }
    });
  }

  loadStatus() {
    this.service.getImportStatus().subscribe({
      next: (status: ImportProcessStatus)=> {
        this.status=status;
      }
    });
  }

  startSynch() {
    this.service.startImport().subscribe({
      next: (status: ImportProcessStatus)=> {
        if (status.started) {
          this.message_service.add({ severity: 'success', summary: 'Operazione completata', detail: 'Importazione avviata' })
        }
      },
      complete: ()=> {
        this.loadStatus();
      }
    });
  }
}
