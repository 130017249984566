export const environment = {
  production: true,
  baseUrl: 'https://opp.synergica.tech/albo-api',
  printUrl: 'https://opp.synergica.tech/print-api',
  keycloakUrl: 'https://auth3.synergica.tech',
  civilianextUrl: '', //'https://opl.synergica.tech/civilianext-api',
  moduliUrl: 'https://opp.synergica.tech/moduli-api',
  domandeUrl: 'https://opp.synergica.tech/domande-api',
  regione: 'opp',
  realm: 'opp',
  clientId: 'opp-web'
};
