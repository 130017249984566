import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UnsavedDialogComponent } from '../components/unsaved-dialog/unsaved-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedService {

  private dialog_service = inject(DialogService);

  ref: DynamicDialogRef | undefined;

  //deactivate handler
  unsavedChangesChecker(formRecived: FormGroup): Promise<void>{

    return new Promise<void>((resolve, reject) => {

      if (formRecived.dirty) {

        const unsavedMod: string[] = [];

        Object.keys(formRecived.controls).forEach(formGroupName => {
          const formGroup = formRecived.get(formGroupName) as FormGroup;
          Object.keys(formGroup.controls).forEach(controlName => {
            const control = formGroup.get(controlName);

            if (control && control.dirty) {
              unsavedMod.push(this.formatNomi(controlName) + ` (${this.formatNomi(formGroupName)})`);
            }

          });
        });


        this.ref = this.dialog_service.open(UnsavedDialogComponent, {
          data: {
            unsavedMod: unsavedMod
          },
          header: 'Modifiche non salvate'.toUpperCase()
        });

        this.ref.onClose.subscribe(result => {
          if (result) {
            resolve();
          } else {
            reject();
          }
        });

      } else {
        resolve();
      }

    });

  }

  //formatta nomi per proprieta
  private formatNomi(str: string): string{

    if(str === 'articolo82'){
      return 'Articolo 8.2';
    }

    let formattedName = str.charAt(0).toUpperCase();

    for (let i = 1; i < str.length; i++) {
      const currentChar = str.charAt(i);

      if (currentChar === currentChar.toUpperCase() && i !== 0) {
        if (!isNaN(parseInt(str.charAt(i - 1))) && !isNaN(parseInt(currentChar))) {
          formattedName += currentChar;
        } else {
          formattedName += ' ' + currentChar;
        }
      } else {
        formattedName += currentChar;
      }
    }

    return formattedName.trim();
  }

}
