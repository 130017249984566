import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment/environment';
import { BaseResponse, ImportProcessStatus } from '../model/civilianext-data';

@Injectable({
  providedIn: 'root'
})
export class CivilianextService {
  private cvImportapi: string = `${environment.civilianextUrl}/importer`;
  private cvPagamentiapi: string = `${environment.civilianextUrl}/pagamenti`;
  constructor(private http: HttpClient) { }

  getImportStatus(): Observable<ImportProcessStatus>{
    return this.http.get<ImportProcessStatus>(`${this.cvImportapi}`);
  }

  startImport(): Observable<ImportProcessStatus>{
    return this.http.put<ImportProcessStatus>(`${this.cvImportapi}/start`, {});
  }

  // getImportIUVStatus(): Observable<ImportProcessStatus>{
  //   return this.http.get<ImportProcessStatus>(`${this.cvPagamentiapi}`);
  // }

  startImportIUV(): Observable<ImportProcessStatus>{
    return this.http.put<ImportProcessStatus>(`${this.cvPagamentiapi}/start-import`, {});
  }

  verificaIUV(stp: boolean, iuv: string): Observable<BaseResponse>{
    const path_seg=stp?'-stp':'';
    return this.http.put<BaseResponse>(`${this.cvPagamentiapi}/verifica-iuv${path_seg}/${iuv}`, {});
  }
}
