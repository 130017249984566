import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { QuotaStp, StpQuoteDto } from '../model/stp-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteStpService {

  private _http = inject(HttpClient);
  private quote_api: string = `${environment.baseUrl}/quote_stp`;


  getQuoteStp(id: string | number): Observable<QuotaStp[]>{
    return this._http.get<QuotaStp[]>(`${this.quote_api}/stp/${id}`);
  }

  getStpQuoteList(id: string): Observable<StpQuoteDto>{
    return this._http.get<StpQuoteDto>(`${this.quote_api}/stp_quote_list/${id}`);
  }

  inserirePagamento(id: number, data: any): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.quote_api}/inserisci-pagamento-stp/${id}`, data);
  }

  gestisciCartellaEsattoriale(id: number, cartellaEsattorialeValue: boolean): Observable<Boolean>{
    const params = new HttpParams().set('cartellaEsattorialeValue', cartellaEsattorialeValue.toString());
    return this._http.put<Boolean>(`${this.quote_api}/update-cartella-esattoriale-stp/${id}`, {}, { params: params });
  }

}
