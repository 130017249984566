import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // gestione titolo provvisori in approvazione delibera
  private messageSource = new Subject<string>();
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  // check del campo autocomplete
  checkValueAutocomplete(formControlString: string, form: any){

    const control = form.get(formControlString);
    const controlValue = control!.value;

    if(control && (!controlValue || typeof controlValue !== 'object')){
      control!.setErrors({ invalid: true });
    }else{
      control!.setErrors(null);
    }

  }

}
