import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment/environment';
import { Allegati, DatiIscrizione, FiltriInviateDto, FiltriStoricoDto, InviateListDto, ResponseIdDto, RifiutoPraticaDto, StoricoListDto } from '../model/domande-data';

@Injectable({
  providedIn: 'root'
})
export class DomandeService {

  private _http = inject(HttpClient);
  private domandeAlbo_api = `${environment.domandeUrl}/iscrizione-albo`;
  private domande_api = `${environment.domandeUrl}/iscrizione`;
  private allegati_api = `${environment.domandeUrl}/files`;

  registraProvvisorioDaDomanda(id: number): Observable<ResponseIdDto>{
    return this._http.post<ResponseIdDto>(this.domandeAlbo_api + `/registra-provvisorio/${id}`, {});
  }

  approvaDomandeIscrizione(id: number): Observable<string>{
    return this._http.post<string>(this.domandeAlbo_api + `/approva-domande-iscrizione/${id}`, {}, { responseType: 'text' as 'json' });
  }
  getDomandeInviate(data: FiltriInviateDto): Observable<InviateListDto>{
    return this._http.post<InviateListDto>(this.domande_api + `/domande-inviate`, data);
  }

  getStoricoDomande(data: FiltriStoricoDto): Observable<StoricoListDto>{
    return this._http.post<StoricoListDto>(this.domande_api + `/storico-pratiche`, data);
  }

  getBozza(id: number): Observable<DatiIscrizione>{
    return this._http.get<DatiIscrizione>(this.domande_api + `/carica-pratica/${id}`);
  }

  LavoraDomanda(id: number): Observable<string>{
    return this._http.put<string>(this.domande_api + `/domanda-in-lavorazione/${id}`, {}, { responseType: 'text' as 'json' });
  }

  rifiutaPratica(id: number, data: RifiutoPraticaDto): Observable<string>{
    return this._http.put<string>(`${this.domande_api}/rifiuta-pratica/${id}`, data, { responseType: 'text' as 'json' });
  }

  getFileAllegati(id: number): Observable<Allegati[]>{
    return this._http.get<Allegati[]>(`${this.allegati_api}/download/${id}`);
  }

  getFileAllegato(id: number, tipoFile: string): Observable<Blob>{
    return this._http.get<Blob>(`${this.allegati_api}/download/${id}/${tipoFile}`, { responseType: 'blob' as 'json' });
  }

  caricaFototessera(id: number, fileName: string): Observable<string> {
    return this._http.post<string>(`${this.allegati_api}/upload/fototessera/${fileName}`, id, { responseType: 'text' as 'json' });
  }

}
