export const environment = {
  production: true,
  baseUrl: 'https://opp.synergica.tech/albo-api',
  printUrl: 'https://opp.synergica.tech/print-api',
  keycloakUrl: 'https://auth3.synergica.tech',
  civilianextUrl: '', //'https://opl.synergica.tech/civilianext-api',
  moduliUrl: 'https://opp.synergica.tech/moduli-api',
  domandeUrl: 'https://opp.synergica.tech/domande-api',
  regione: 'opp',
  realm: 'opp',
  clientId: 'opp-web',
  //dashboard
  cards: [
    {id: 1, type: 'provvisori', value: 0},
    {id: 2, type: 'morosità', value: 0},
    {id: 3, type: 'trasferimento', value: 0},
    {id: 4, type: 'sospesi', value: 0}
  ],
  labelRegionale: 'Piemonte'
};
