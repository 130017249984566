import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificaIcrizioneDto } from '../../model/iscritti-data';
import {formatDate} from '@angular/common';
@Component({
  selector: 'app-dialog-invia-email',
  templateUrl: './dialog-invia-email.component.html',
  styleUrl: './dialog-invia-email.component.scss'
})
export class DialogInviaEmailComponent implements OnInit {
  //private dialog_config = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);


  protocolloForm = new FormGroup({
    numeroProtocollo: new FormControl<string|null>(null, Validators.required),
    dataProtocollo: new FormControl<Date|null>(null, Validators.required)
  })

  ngOnInit(): void {
    //this.stpId = this.dialog_config.data.stpId;
  }


  confirmDialog() {
    if (this.protocolloForm.valid) {
      let value: NotificaIcrizioneDto = {
        numeroProtocollo: this.protocolloForm.value.numeroProtocollo!!,
        dataProtocollo: formatDate(this.protocolloForm.value.dataProtocollo!!, "yyy-MM-dd", "en")
      };
      this.ref.close(value);
    }
  }

  closeDialog() {
    this.ref.close(undefined);
  }

}
