<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div  class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- ricerca iscritti o altri elementi -->
      <div class="mb-5 sm:mb-0 sm:mr-3">
        <span class="p-input-icon-left p-input-icon-right p-float-label">
          <i class="pi pi-search"></i>
          <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="inserimento" />
          <label for="cercaIscritto">Cerca Iscritto</label>
          <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
        </span>
      </div>

      <div class="flex bottoniStyle">
        <p-button
          type="button"
          routerLink="/gestione_albo/nuovo_provvisorio"
          icon="pi pi-plus"
          iconPos="right"
          [severity]="checkRegione()"
          pTooltip="Inserisci Provvisorio"
          tooltipPosition="bottom"
          [disabled]="!role_service.isAlboRole()"
        ></p-button>
      </div>

    </div>

    <!-- avanzati -->
    <div class="flex align-items-center justify-content-center sm:justify-content-start mb-5 flex-wrap gap-3">
      <div>
        <p-dropdown formControlName="sezione" [showClear]="true" [options]="sezioniOptions" optionLabel="nome" placeholder="Sezione"></p-dropdown>
      </div>

      <p-button
        class="clearFiltri"
        size="small"
        [outlined]="true"
        icon="pi pi-filter-slash"
        (click)="clearFilters()"
        pTooltip="Pulisci Filtri"
        tooltipPosition="right"
      />
    </div>

  </div>

  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaProvissori"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="headerName === 'Stato' ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscritto>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(iscritto)">

            @for (rowName of rowsTabella; track $index) {
              <td>{{ iscritto[rowName] }}</td>
            }

          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
