import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { Stp, StpDropdownData, FiltersListStpDto, ListaStpDto, IscrittoStp, TipoSocio, StpContabileListaDto, StpContabileFilters, RuoloSocio } from '../model/stp-data';
import { IscrittoItem } from '../model/cnop-data';
import { SearchFilters } from '../model/filters-data';

@Injectable({
  providedIn: 'root'
})
export class StpService {

  private _http = inject(HttpClient);
  private stp_api: string = `${environment.baseUrl}/stp`;

  getAllStp(data: SearchFilters): Observable<ListaStpDto>{
    return this._http.post<ListaStpDto>(this.stp_api, data);
  }

  getStp(id: string | number): Observable<Stp>{
    return this._http.get<Stp>(`${this.stp_api}/${id}`);
  }

  inserisciStp(data: any): Observable<number>{
    return this._http.post<number>(this.stp_api + '/nuova-stp', data);
  }

  modificaStp(id: number, data: any): Observable<Boolean>{
    return this._http.put<Boolean>(this.stp_api + `/modifica-stp/${id}`, data);
  }

  inserisciProfessionista(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.stp_api}/inserisci-professionista`, data);
  }

  rimuoviSocio(id: string | number): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.stp_api}/rimuovi-socio/${id}`, {});
  }

  getDropdwonData(): Observable<StpDropdownData>{
    return this._http.get<StpDropdownData>(this.stp_api + '/dropdown-stp-data');
  }

  getIscrittiStp(id: string | number): Observable<IscrittoStp[]>{
    return this._http.get<IscrittoStp[]>(`${this.stp_api}/stp-iscritti/${id}`);
  }

  getIscrittoStp(id: string | number): Observable<IscrittoStp>{
    return this._http.get<IscrittoStp>(`${this.stp_api}/stp-iscritto/${id}`);
  }

  updateIscrittoStp(id: string | number, data: IscrittoItem): Observable<IscrittoStp>{
    return this._http.post<IscrittoStp>(`${this.stp_api}/update-professionista/${id}`, data);
  }

  getTipoSocioList(): Observable<TipoSocio[]>{
    return this._http.get<TipoSocio[]>(`${this.stp_api}/tipo-socio-list`);
  }

  getRuoloSocioList(): Observable<RuoloSocio[]>{
    return this._http.get<RuoloSocio[]>(`${this.stp_api}/ruolo-socio-list`);
  }

  getStpListaContabilita(data: SearchFilters): Observable<StpContabileListaDto>{
    return this._http.post<StpContabileListaDto>(`${this.stp_api}/stp-lista-contabilita`, data);
  }

}
