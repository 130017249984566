import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { MembroConsiglio } from '../model/domande-data';

@Injectable({
  providedIn: 'root'
})
export class ConsiglioService {

  private _http = inject(HttpClient);
  private consiglio_api: string = `${environment.baseUrl}/consiglio`;


  getMembriConsiglio(): Observable<MembroConsiglio[]>{
    return this._http.get<MembroConsiglio[]>(`${this.consiglio_api}/get-membri-consiglio`);
  }

}
