import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { StatoIscritto, ListaCancellatiDto, CancellatiDettaglioListDto, FiltersListCancellatiDto, Iscritto } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogDinamicoComponent } from '../dialog-dinamico/dialog-dinamico.component';
import { RoleService } from '../../services/role.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';
import { CustomFiltersService } from '../../services/custom-filters.service';

@Component({
  selector: 'app-tabella-cancellati',
  templateUrl: './tabella-cancellati.component.html',
  styleUrl: './tabella-cancellati.component.scss'
})
export class TabellaCancellatiComponent implements OnInit{

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //dialog ref
  ref: DynamicDialogRef | undefined;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private dialog_service = inject(DialogService);
  private message_service = inject(MessageService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);


  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>(''),
    stato: new FormControl<StatoIscritto | null>(null)
  })

  // filtro per gli stati dell'iscritto
  statiDropdown: StatoIscritto[] | undefined;

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'Stato', 'Pec']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'statoIscritto', 'pec']
  listaCancellati: CancellatiDettaglioListDto[] = [];

  dettaglioIscrittoRoute = '/gestione_albo/gestione_iscritti/dettaglio_iscritto/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler();

    this.filtri.valueChanges.subscribe(() => {
      this.resetPagination();
      this.loadData();
    })

  }

  //gestione dei dati iniziali
  private initialDataHandler(){

    const iscritti: ListaCancellatiDto = this.route.snapshot.data['cancellati'];
    const statiIscritto: StatoIscritto[] = this.route.snapshot.data['statiIscritto'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaCancellati = iscritti.lista;
    this.totaleDati = iscritti.conteggio;
    this.statiDropdown = statiIscritto;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.iscritti_service.getCancellati(data).subscribe({
      next: (result: ListaCancellatiDto) => {
        this.listaCancellati = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //assegna colore stato
  coloreStato(statoIscritto: StatoIscritto): string{
    let colore = '';
    const id = statoIscritto.codiceCnop;

    if(id === 'A') colore = '#16A34A';
    if(id === 'S') colore = '#CDC103';
    if(id === 'T') colore = '#00BFFE';
    if(id === 'C') colore = '#FBA465';
    if(id === 'R') colore = '#EF5233';
    if(id === 'P') colore = '#BF08FF';

    return colore;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: '',
      stato: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //aggiorna il session storage
  sessionStorageUpdate(){

    const values = this.filtri.value;
    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[this.componente].inserimento = values.inserimento;
    sessionData[this.componente].stato = values.stato?.codiceCnop ? values.stato?.codiceCnop : '';
    sessionData[this.componente].orderColumn = this.orderColumnName;
    sessionData[this.componente].order = this.order;
    sessionData[this.componente].page = this.page;
    sessionData[this.componente].pageSize = this.pageSize;
    sessionData[this.componente].currentPage = this.currentPage;

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));

  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];
    const sd: StatoIscritto[] = this.statiDropdown!;

    const str: string = sessionData.inserimento;
    const stato: StatoIscritto = sd.find(item => item.codiceCnop === sessionData.stato)!;

    this.filtri.patchValue({
      inserimento: str,
      stato: stato
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    this.custom_filters_service.sessionStorageUpdate(this.filtri.value, this.componente, tabData);

  }

  ripristinaIscritto(item: Iscritto, event: any){
    event.stopPropagation();

    this.ref = this.dialog_service.open(DialogDinamicoComponent, {
      data: {
        tipo: 'ripristinaIscritto',
        elemento: item
      },
      header: 'Ripristina Iscritto'
    });

    this.ref.onClose.subscribe({
      next: result => {
        if(result){
          console.log(result.elemento)
          this.iscritti_service.ripristinaIscritto(result.elemento).subscribe({
            complete: () =>{
              this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Professionista rimosso.` });
              setTimeout(() => {
                this.loadData();
              }, 1000)
            },
            error: (error) => {
              this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
            }
          })
        }
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

}
