import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Stp } from '../../model/stp-data';
import { environment } from '../../environment/environment';
import { RoleService } from '../../services/role.service';
import { ListaQuote } from '../../model/iscritti-data';

@Component({
  selector: 'app-dettaglio-contabile-stp',
  templateUrl: './dettaglio-contabile-stp.component.html',
  styleUrl: './dettaglio-contabile-stp.component.scss'
})
export class DettaglioContabileStpComponent implements OnInit{

  regione = environment.regione;

  private route = inject(ActivatedRoute);
  public role_service = inject(RoleService);

  stp: Stp |undefined;

  // lfags readonly
  flagMultidisciplinare: boolean = false;
  flagUnipersonale: boolean = false;

  listaData: ListaQuote = {
    lista: [],
    id: null,
    componente: 'contabile-stp'
  }

  ngOnInit(): void {
    this.initialHandler();console.log(this.stp);

  }

  initialHandler(){
    const stpFetcehd = this.route.snapshot.data['stp'];
    this.stp = stpFetcehd;
    this.loadPagamenti(stpFetcehd)
  }

  private loadPagamenti(stp: Stp){
    this.listaData.id = this.stp?.id!,
    this.listaData.lista = this.stp?.quoteList!;

    this.flagMultidisciplinare = stp.stpMultidisciplinare;
    this.flagUnipersonale = stp.stpUnipersonale;
  }

}
