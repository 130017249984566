import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurazioneService {

  private _http = inject(HttpClient);
  private configurazione_api: string = `${environment.baseUrl}/configurazione`;

  invioNewOption(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(this.configurazione_api + '/new-option', data);
  }

  invioModificheList(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(this.configurazione_api + '/modify-list', data);
  }

  invioModificheOption(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(this.configurazione_api + '/modify-option', data);
  }

}
