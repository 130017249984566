import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ImportProcessStatus } from '../../model/civilianext-data';
import { CivilianextService } from '../../services/civllianext.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-import-pagamenti',
  templateUrl: './import-pagamenti.component.html',
  styleUrl: './import-pagamenti.component.scss'
})
export class ImportPagamentiComponent implements OnInit, OnDestroy{

  private civilian = inject(CivilianextService);
  public role_service = inject(RoleService);

  status?: ImportProcessStatus;

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

  loadStatus() {
    /*this.service.getImportStatus().subscribe({
      next: (status: ImportProcessStatus)=> {
        this.status=status;
      }
    });*/
  }

  startSynch() {
    this.civilian.startImportIUV().subscribe({
      next: (status: ImportProcessStatus)=> {
        if (status.started) {
          //this.message_service.add({ severity: 'success', summary: 'Operazione completata', detail: 'Importazione avviata' })
        }
      },
      complete: ()=> {
        this.loadStatus();
      }
    });
  }

}
