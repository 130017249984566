<div class="w-screen h-screen m-0 main-layout">

    <div class="sidebar hidden xl:block">
      <app-sidebar></app-sidebar>
    </div>


    <div class="flex align-items-center justify-content-between pt-6 pb-4 px-3 mb-3 header">
      <div class="flex align-items-center ml-3">
        <div class="xl:hidden mr-3">
          <p-button (click)="sidebarVisible = true" icon="pi pi-bars" size="small"></p-button>
        </div>

        @if (checkBackFlagTitolo()) {
          <i
            class="pi pi-arrow-circle-left mr-3 backIcon"
            style="font-size: 2em; cursor: pointer;"
            [routerLink]="backBuilder"
          ></i>
        }

        <p class="m-0 text-lg sm:text-3xl font-semibold">{{ titleRouting }}</p>

        @if (isRoutingLoadingSpinner) {
          <p-progressSpinner class="ml-3" styleClass="w-4rem h-4rem" strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s"/>
        }

      </div>
      <div class="mr-3">
        <p-menu #menu [model]="items" [popup]="true" />
        <p-button class="hidden lg:block buttonUser" (click)="menu.toggle($event)" icon="pi pi-user" [label]="userDisplayName" severity="secondary" [outlined]="true"></p-button>
        <p-button class="lg:hidden buttonUser" (click)="menu.toggle($event)" icon="pi pi-user"  severity="secondary" [outlined]="true"></p-button>
      </div>
    </div>

    <div class="px-5 main-content">
      <router-outlet></router-outlet>
    </div>
  </div>



<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="sidebarVisible" class="backGray">
    <ng-template pTemplate="header">
      <p-image src="assets/images/{{regione}}.png" width="200px"></p-image>
    </ng-template>
    <app-sidebar></app-sidebar>
  </p-sidebar>
</div>
