<div class="mb-5">
  @if (tipo === 'aggiungiTelefono'){
    <div [formGroup]="telefonoForm" class="pt-3 m-3">
      <div class="flex">
        <div>
          <span class="p-float-label px-3 mb-3 prefissi">
            <p-autoComplete
              appendTo="body"
              formControlName="prefisso"
              [suggestions]="filteredPrefisso"
              (completeMethod)="filterAutoComplete($event, 'prefisso')"
              field="codice"
              placeholder="Es: 'italia'"
              [minLength]="2"
            >

              <ng-template let-prefisso pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ prefisso.bandiera }} {{ prefisso.nazione }} ({{ prefisso.codice }})</div>
                </div>
              </ng-template>

            </p-autoComplete>
            <label class="pl-1" for="prefisso" [ngClass]="{'custom-tab-panel': telefonoForm.get('prefisso')?.invalid}">Prefisso*</label>
          </span>
        </div>
        <span class="p-float-label px-3 mb-6">
          <input class="px-0" pInputText id="numero" formControlName="numero" pKeyFilter="num"/>
          <label class="pl-1" for="numero" [ngClass]="{'custom-tab-panel': telefonoForm.get('numero')?.invalid}">Telefono*</label>
        </span>
      </div>
      <div>
        <span class="p-float-label px-3 mb-6">
          <input class="px-0" pInputText id="tipo" formControlName="tipo"/>
          <label class="pl-1" for="tipo" [ngClass]="{'custom-tab-panel': telefonoForm.get('tipo')?.invalid}">Tipo</label>
        </span>
      </div>
      <div>
        <span class="p-float-label px-3 mb-6">
          <input class="px-0" pInputText id="descrizione" formControlName="descrizione"/>
          <label class="pl-1" for="descrizione" [ngClass]="{'custom-tab-panel': telefonoForm.get('descrizione')?.invalid}">Descrizione</label>
        </span>
      </div>
    </div>
  }

  @if (tipo === 'eliminazioneTelefono') {
    <div>
      <p class="mb-3">Confermi di voler eliminare il numero</p>
      <p class="text-xl text-center font-bold">{{ telefonoEliminazione?.numero }}</p>
    </div>
  }

  @if (tipo === 'rimuoviProfessionista') {
    <div>
      <p class="mb-3">Confermi di voler rimuovere da <span class="font-semibold">{{ nomeStp }}</span> </p>
      <p class="text-xl text-center font-bold">{{ nomeProfessionista }}</p>
    </div>
  }

  @if (tipo === 'inserisciPagamento') {
    <div [formGroup]="pagamentoForm" class="flex pt-4 pb-3">

      <div class="dataPickerCustom">
        <span class="p-float-label px-3">
          <p-calendar appendTo="body" formControlName="data" inputId="data" [iconDisplay]="'input'" [showIcon]="true" class="overflow-visible"></p-calendar>
          <label class="pl-1" for="data">Data Pagamento</label>
        </span>
      </div>

      <div>
        <span class="p-float-label px-3 mb-6">
          <p-dropdown appendTo="body" formControlName="pagamentoMezzo" [options]="pagamentiOptions" [editable]="true" optionLabel="tipoPagamento"></p-dropdown>
          <label class="pl-1" for="pagamentoMezzo">Mezzo Pagamento*</label>
        </span>
      </div>

      <div>
        <span class="p-float-label px-3">
          <p-inputNumber class="px-0" mode="currency" currency="EUR" id="importo" formControlName="importo" minValue="-999999.99"></p-inputNumber>
          <label class="pl-1" for="importo">Importo*</label>
        </span>
      </div>

    </div>
  }

  @if (tipo === 'modificaStato') {
    <div [formGroup]="statoForm" class="flex justify-content-center pt-4 pb-2">

      <div>
        <span class="p-float-label px-3">
          <p-dropdown appendTo="body" [options]="stati" formControlName="statoIscritto" placeholder="scegli uno stato" optionLabel="nome" inputId="statoIscritto"></p-dropdown>
          <label class="pl-1" for="statoIscritto">Stato</label>
        </span>
      </div>

      @if (gestoreStato()) {
        <div class="dataPickerCustom">
          <span class="p-float-label px-3 mb-6">
            <p-calendar appendTo="body" dateFormat="dd/mm/yy" formControlName="dataForm"  inputId="dataForm" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
            <label class="pl-1" for="dataForm">{{ gestoreStatoStringa() }}</label>
          </span>
        </div>
      }

    </div>
  }

  @if (tipo === 'ripristinaIscritto') {
    <div>
      <p class="mb-3">Confermi di voler ripristinare da <span class="font-semibold">{{ nomeIscritto }}</span> ?</p>
    </div>
  }

</div>

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem"></i>
  }@else {
    <p-button
      [label]="labelBottone"
      [ngClass]="classeBottone"
      [disabled]="
        tipo === 'aggiungiTelefono' && (telefonoForm.pristine || telefonoForm.invalid) ||
        tipo === 'inserisciPagamento' && (pagamentoForm.pristine || pagamentoForm.invalid) ||
        tipo === 'modificaStato' && (statoForm.pristine || statoForm.invalid)
      "
      (click)="gestisciTipoDialog()"
    >
    </p-button>
  }
</div>
