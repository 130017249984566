import { Component, Input, OnInit, inject } from '@angular/core';
import { ListaQuote, Pagamento, Quota } from '../../model/iscritti-data';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { DialogDinamicoComponent } from '../dialog-dinamico/dialog-dinamico.component';
import { QuoteService } from '../../services/quote.service';
import { IscrittiService } from '../../services/iscritti.service';
import { ActivatedRoute } from '@angular/router';
import { CivilianextService } from '../../services/civllianext.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-tabella-pagamenti',
  templateUrl: './tabella-pagamenti.component.html',
  styleUrl: './tabella-pagamenti.component.scss'
})
export class TabellaPagamentiComponent implements OnInit{

  @Input('data')
  listaData: ListaQuote = {
    lista: [],
    iscritto: null,
    componente: ''
  }

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private dialog_service = inject(DialogService);
  private quote_service = inject(QuoteService);
  private message_service = inject(MessageService);
  private civilian = inject(CivilianextService);
  public role_service = inject(RoleService);

  //dialog ref
  ref: DynamicDialogRef | undefined;

  //dati iscritto
  iscrittoCorrente: number | undefined;
  listaPagamenti: Pagamento[] = [];

  //lista delle quote
  listaQuote: Quota[] = [];

  //resoconto
  quoteInsolute = 0;
  importoDaSaldare = 0;
  importoACredito = 0;

  ngOnInit(): void {
    this.iscrittoCorrente = this.listaData.iscritto!;
    this.estrazioneGestionePagamenti(this.listaData.lista);
  }

  //trova il mezzo di pagamento a saldo di una quota
  mezzoSaldo(pagamenti: Pagamento[]): string{

    if(pagamenti.length > 0){
      const pagamentoWithRecentDate = pagamenti.reduce((acc, curr) => {

        if (curr.pagamentoMezzo.tipoPagamento.toLowerCase() === 'storno') {
          return acc;
        }

        const currentDate = new Date(acc.data);
        const newDate = new Date(curr.data);

        if (newDate > currentDate)return curr;
        else return acc;
      })
      return pagamentoWithRecentDate.pagamentoMezzo.tipoPagamento;
    }

    return '';
  }

  //inserisci pagamento manualmente
  inserisciPagamento(id: number){

    this.ref = this.dialog_service.open(DialogDinamicoComponent, {
      data: {
        tipo: 'inserisciPagamento',
        id: id
      },
      header: 'Inserisci Pagamento'
    });

    this.ref.onClose.subscribe({
      next: result => {

        if(result){

          const data: Pagamento = {
            id: undefined,
            data: result.elemento.data,
            pagamentoMezzo: result.elemento.pagamentoMezzo,
            importo: result.elemento.importo
          };

          this.quote_service.inserirePagamento(id, data).subscribe({
            complete: () =>{
              this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Pagamento inserito.` });
              this.quote_service.getIscrittoQuoteList(this.iscrittoCorrente!.toString()).subscribe((result) => {
                this.iscrittoCorrente = result.id;
                this.estrazioneGestionePagamenti(result.quoteList);
              })
            },
            error: (error) => {
              this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
            }
          })

        }

      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })

  }

  //estrae i pagamenti dalle quote dell'iscritto
  private estrazioneGestionePagamenti(quote: Quota[]){

    this.quoteInsolute = 0;
    this.importoDaSaldare = 0;
    this.importoACredito = 0;

    this.listaQuote = quote.sort((a, b) => {
      const yearA = parseInt(a.annoRiferimento);
      const yearB = parseInt(b.annoRiferimento);
      return yearB - yearA;
    });

    quote.forEach((quota: Quota) => {
      if(quota.importoDaVersare > 0) {
        this.quoteInsolute++;
        this.importoDaSaldare += quota.importoDaVersare;
      }

      if(quota.importoDaVersare < 0) {
        this.importoACredito += quota.importoDaVersare * -1;
      }

      quota.pagamentiList.forEach((pagamento: Pagamento) => {
        pagamento.anno = quota.annoRiferimento;
        this.listaPagamenti.push(pagamento);
      });
    });

  }

  transformData(value: string): string {
    if (!value) return value;

    const [year, month, day] = value.split('-');
    return `${day}-${month}-${year}`;
  }

  gestisciCartellaEsattoriale(event: any, idQuota: number, cartellaEsattorialeValue: boolean){
    event.stopPropagation();
    this.quote_service.gestisciCartellaEsattoriale(idQuota, cartellaEsattorialeValue).subscribe({
      next: result => {},
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    });
  }

  checkIuv(event: any, numeroIuv: string){
    event.stopPropagation();
    this.civilian.verificaIUV(numeroIuv).subscribe({
      next: result => {
        if(result.esito)
          this.message_service.add({ severity: 'success', summary: 'Esito dell\'operazione', detail: `${result.esito}` });
        else
          this.message_service.add({ severity: 'error', summary: '', detail: 'Nessun Pagamento importato' });
      },
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

}

