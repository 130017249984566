<div [formGroup]="deliberaForm" class="flex flex-column">

  <div class="text-center mb-5 mx-5">
    <p class="text-lg">Il nuovo riepilogo avrà il seguente numero</p>
    <div>
      <span class="p-float-label px-3 mb-6">
        <input class="px-0" pInputText pKeyFilter="int" id="numeroDelibera" formControlName="numeroDelibera"/>
      </span>
    </div>
  </div>

  <div  class="text-center mb-8">
    <p class="mr-3 text-lg">Seleziona <b>tipo</b> e <b>data</b> del riepilogo</p>
  </div>
  <div class="flex justify-content-center">
    <div>
      <span class="p-float-label px-3 mb-6">
        <p-dropdown [options]="tipiDelibera" formControlName="tipo" optionLabel="nome" inputId="tipo" appendTo="body"></p-dropdown>
        <label class="pl-1" for="tipo">Tipo*</label>
      </span>
    </div>

    <div class="dataPickerCustom">
      <span class="p-float-label px-3">
        <p-calendar appendTo="body" formControlName="dataDelibera" dateFormat="dd/mm/yy" inputId="dataDelibera" [iconDisplay]="'input'" [showIcon]="true" class="overflow-visible"></p-calendar>
        <label class="pl-1" for="dataDelibera">Data*</label>
      </span>
    </div>
  </div>
</div>

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem; color: #3a8dde"></i>
  }@else {
    <p-button label="Genera Riepilogo" (click)="generaDelibera()" [disabled]="this.deliberaForm.pristine || this.deliberaForm.invalid"></p-button>
  }
  <p-button label="Annulla" (click)="annulla()" severity="secondary" class="ml-3"></p-button>
</div>
