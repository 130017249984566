import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Table } from 'primeng/table';
import { GruppoValore } from '../../model/iscritti-data';
import { FiltriStoricoDto, StoricoListDto, StoricoTabDto } from '../../model/domande-data';
import { DomandeService } from '../../services/domande.service';


@Component({
  selector: 'app-tabella-storico-domande',
  templateUrl: './tabella-storico-domande.component.html',
  styleUrl: './tabella-storico-domande.component.scss'
})
export class TabellaStoricoDomandeComponent implements OnInit{

  private route = inject(ActivatedRoute);
  private domande_service = inject(DomandeService);


  @ViewChild('dt') dt!: Table;
  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  componente: string = '';
  statoColori = new Map<string, string>([
    ['In Lavorazione', '#E67E22'],
    ['Rifiutata', '#C0392B'],
    ['Approvata', '#3498DB']
  ]);

  sezioniDropdown: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A' },
    { nome: 'Sezione B', valore: 'B' }
  ];

  statiIscrizioneDropdown: GruppoValore[] = [
    { nome: 'In Lavorazione', valore: 'L' },
    { nome: 'Rifiutata', valore: 'R' },
    { nome: 'Approvata', valore: 'A' }
  ];

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    stringaFiltro: new FormControl<string|null>(''),
    sezione: new FormControl<GruppoValore|null>(null),
    statoIscrizione: new FormControl<GruppoValore|null>(null)
  })

  orderColumnName: string = '';
  order: string = '';

  //dati del paginator
  pageSize: number = 25;
  pageSelected: number = 0;
  currentPage: number = 0;

  totaleDati: number = 0;

  listaIscrizioni: StoricoTabDto[] = [];

  //dati della tabella
  headersTabella: string[] = ['N° Domanda', 'Data Domanda', 'Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'Stato']
  rowsTabella: string[] = ['domandaProtocollo', 'domandaData', 'codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'statoIscrizione']

  ngOnInit(): void {
    this.initialDataHandler();

    this.filtri.valueChanges.subscribe(() => {
      this.resetPagination();
      this.sessionStorageUpdate();
      this.loadData();
    })
  }

  initialDataHandler(){

    const data: StoricoListDto = this.route.snapshot.data['finalizzateList'];

    this.componente = this.route.snapshot.data['componente'];

    this.listaIscrizioni = data.listaStorico;
    this.totaleDati = data.conteggio;
    
    this.revertDatesLoaded();

  }

  loadData(){

    const values = this.filtri.value;

    const data: FiltriStoricoDto = {
      stringaFiltro: values.stringaFiltro!,
      sezioneAlbo: values.sezione?.valore! ? values.sezione?.valore : '',
      statoIscrizione: values.statoIscrizione?.valore! ? values.statoIscrizione?.valore : '',
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.pageSelected,
      pageSize: this.pageSize
    }

    this.domande_service.getStoricoDomande(data).subscribe({
      next: result => {
        this.listaIscrizioni = result.listaStorico;
        this.totaleDati = result.conteggio;
        this.revertDatesLoaded();
      },
      error: err => {
        console.log(err);
      }
    });

  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.pageSelected = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'domandaProtocollo';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  private revertDateFormat(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('stringaFiltro')?.setValue('')
  }

  resetPagination(){
    this.orderColumnName = 'domandaProtocollo';
    this.order = 'ASC';
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;
  }

  //aggiorna il session storage
  sessionStorageUpdate(){

    const values = this.filtri.value;
    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[this.componente].stringaFiltro = values.stringaFiltro;
    sessionData[this.componente].sezioneAlbo = values.sezione?.valore ? values.sezione?.valore : '';

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));

  }

  revertDatesLoaded(){
    this.listaIscrizioni.forEach(i => i.domandaData = this.revertDateFormat(i.domandaData));
  }

  checkColumn(column: string): boolean{
    return column === 'statoIscrizione';
  }

}
