import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FiltersListCancellatiDto, FiltersListContabDto, FiltersListIscrittiDto, FiltersListProvvisoriDto, FiltriAvanzatiDropdownDto, Iscritto, IscrittoDropdownData, IscrittoQuoteDto, IscrittoReadonlyData, ListaCancellatiDto, ListaIscrittiDto, ListaProvvisoriDto, PagedResultDto, Quota, StatoIscritto, Telefono } from '../model/iscritti-data';
import { IscrittiService } from './iscritti.service';
import { StpService } from './stp.service';
import { QuoteService } from './quote.service';
import { DashboardCardsDto, DashboardChartsDto, DashboardTitoliDto, DataDashboard, IscrittoYearsDto } from '../model/dashboard-data';
import { Stp, StpDropdownData, FiltersListStpDto, ListaStpDto, IscrittoStp, TipoSocio, QuotaStp, StpQuoteDto, StpContabileListaDto, StpContabileFilters, RuoloSocio } from '../model/stp-data';
import { DeliberaService } from './delibera.service';
import { Delibera, DeliberaFiltri, DeliberaListDto, IscrittiDeliberaChiusaDto, TipoDelibera } from '../model/delibere-data';
import { CustomFiltersService } from './custom-filters.service';
import { of } from 'rxjs';
import { DomandeService } from './domande.service';
import { Allegati, DatiIscrizione, FiltriInviateDto, FiltriStoricoDto, InviateListDto, InviateTabDto, StoricoListDto } from '../model/domande-data';
import { QuoteStpService } from './quote-stp.service';
import { SearchFilters } from '../model/filters-data';

//operazioni dei dati della dashboard
export const DashboardResolver: ResolveFn<DataDashboard> = (route) => {
  return inject(IscrittiService).getDashboardData();
}

export const DashboardCardsResolver: ResolveFn<DashboardCardsDto> = (route) => {
  return inject(IscrittiService).getDashboardCards();
}

export const DashboardYearsResolver: ResolveFn<IscrittoYearsDto[]> = (route) => {
  return inject(IscrittiService).getDashboardYears();
}

export const DashboardChartsResolver: ResolveFn<DashboardChartsDto> = (route) => {
  return inject(IscrittiService).getDashboardCharts();
}

export const DashboardTitoliResolver: ResolveFn<DashboardTitoliDto[]> = (route) => {
  return inject(IscrittiService).getDashboardTitoli();
}

//operazioni su iscritti
export const IscrittiResolver: ResolveFn<ListaIscrittiDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const routeFilters = route.paramMap.get('routeFilters')!;
  const filterData = custom_filters.handleFilterByComponent(componente);

  const fromDashboard = ['trasferimento', 'sospeso'].includes(routeFilters);

  if(fromDashboard){
    filterData.inserimento = '';
    filterData.orderColumn = 'codiceFiscale';
    filterData.order = 'ASC';
    filterData.page = 0;
    filterData.pageSize = 25;
    filterData.residenzaProvincia = '';
    filterData.residenzaComune = '';
    filterData.domicilioProvincia = '';
    filterData.domicilioComune = '';
    filterData.annoNascita = null;
    filterData.annoNascitaAl = null;
    filterData.annoIscrizione = null;
    filterData.annoIscrizioneAl = null;
    filterData.titoli = '';
  }

  if(routeFilters === 'trasferimento') filterData.stato = 'T';
  if(routeFilters === 'sospeso') filterData.stato = 'S';

  return inject(IscrittiService).getIscritti(filterData);
}

export const IscrittiContabResolver: ResolveFn<PagedResultDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const routeFilters = route.paramMap.get('routeFilters')!;
  const filterData = custom_filters.handleFilterByComponent(componente);

  //gestione del routeFilters
  if(routeFilters === 'moroso') filterData.situazione = 'moroso';

  return inject(IscrittiService).getIscrittiContab(filterData);
}

export const IscrittiContabCancellatiResolver: ResolveFn<PagedResultDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);
  return inject(IscrittiService).getIscrittiContabCancellati(filterData);
}

export const ProvvisoriResolver: ResolveFn<ListaProvvisoriDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);
  return inject(IscrittiService).getProvvisori(filterData);
}

export const ProvvisoriSelezionabiliResolver: ResolveFn<ListaProvvisoriDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);
  return inject(IscrittiService).getProvvisori(filterData);
}

export const ProvvisoriDeliberaResolver: ResolveFn<ListaProvvisoriDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);

  return inject(IscrittiService).getProvvisoriDelibera(filterData, Number(route.paramMap.get('id')));
}

export const CancellatiResolver: ResolveFn<ListaCancellatiDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);
  return inject(IscrittiService).getCancellati(filterData);
}

export const StpListResolver: ResolveFn<ListaStpDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);
  return inject(StpService).getAllStp(filterData);
}

export const StpResolver: ResolveFn<Stp> = (route) => {
  return inject(StpService).getStp(route.paramMap.get('id')!);
}

export const StpIscrittiResolver: ResolveFn<IscrittoStp[]> = (route) => {
  return inject(StpService).getIscrittiStp(route.paramMap.get('id')!);
}

export const StpIscrittoResolver: ResolveFn<IscrittoStp> = (route) => {
  return inject(StpService).getIscrittoStp(route.paramMap.get('id')!);
}


export const StpFiltersResolver: ResolveFn<FiltersListIscrittiDto> = () => {
  const shared_filters = inject(CustomFiltersService);
  const sessionData = shared_filters.handleFilterByComponent('stpFilters');

  const data: FiltersListIscrittiDto = {
    inserimento: sessionData.inserimento,
    stato: sessionData.stato,
    sezione: '',
    tipoLista: 'stpFilters',
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize,

    residenzaProvincia: '',
    residenzaComune: '',

    domicilioProvincia: '',
    domicilioComune: '',

    annoNascita: null,
    annoNascitaAl: null,
    annoIscrizione: null,
    annoIscrizioneAl: null,
    titoli: ''
  };

  return data;
}

export const StpContabListResolver: ResolveFn<StpContabileListaDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);

  return inject(StpService).getStpListaContabilita(filterData);
}

export const StatiIscrittoAttiviResolver: ResolveFn<StatoIscritto[]> = (route) => {
  return inject(IscrittiService).getStatiIscrittoAttivi();
}

export const StatiIscrittoNonAttiviResolver: ResolveFn<StatoIscritto[]> = (route) => {
  return inject(IscrittiService).getStatiIscrittoNonAttivi();
}

export const IscrittoResolver: ResolveFn<Iscritto> = (route) => {
  return inject(IscrittiService).getIscritto(route.paramMap.get('id')!);
}

export const IscrittoQuoteListResolver: ResolveFn<IscrittoQuoteDto> = (route) =>{
  return inject(QuoteService).getIscrittoQuoteList(route.paramMap.get('id')!);
}

export const IscrittoReadonlyDataResolver: ResolveFn<IscrittoReadonlyData> = (route) =>{
  return inject(IscrittiService).getIscrittoReadonlyData(route.paramMap.get('id')!);
}

export const IscrittoReadonlyDataTelefoniResolver: ResolveFn<Telefono[]> = (route) =>{
  return inject(IscrittiService).getIscrittoReadonlyDataTelefoni(route.paramMap.get('id')!);
}

//-------------------------------------

export const QuoteResolver: ResolveFn<Quota[]> = (route) =>{
  return inject(QuoteService).getQuoteIscritto(route.paramMap.get('id')!);
}

export const IscrittoDropdwonDataResolver: ResolveFn<IscrittoDropdownData> = (route) => {
  return inject(IscrittiService).getIscrittoDropdwonData();
}

export const DropdwonDataResolver: ResolveFn<IscrittoDropdownData> = (route) => {
  return inject(IscrittiService).getDropdwonData();
}

export const DropdownFiltriAvanzati: ResolveFn<FiltriAvanzatiDropdownDto> = (route) => {
  return inject(IscrittiService).getFiltriAvanzatiDropdown();
}

export const StpDropdownDataResolver: ResolveFn<StpDropdownData> = (route) => {
  return inject(StpService).getDropdwonData();
}

export const tipoSocioListResolver: ResolveFn<TipoSocio[]> = (route) => {
  return inject(StpService).getTipoSocioList();
}

export const ruoloSocioListResolver: ResolveFn<RuoloSocio[]> = (route) => {
  return inject(StpService).getRuoloSocioList();
}

export const StpQuoteResolver: ResolveFn<QuotaStp[]> = (route) =>{
  return inject(QuoteStpService).getQuoteStp(route.paramMap.get('id')!);
}

export const DeliberaCorrenteResolver: ResolveFn<Delibera | null> = (route) => {

  const tipo = route.data['tipoDelibera'];
  let tipoCodice = '';

  if(tipo === 'iscrizione_a') tipoCodice = 'A';
  if(tipo === 'iscrizione_b') tipoCodice = 'B';

  return inject(DeliberaService).deliberaCorrente(tipoCodice);
}

export const DelibereElenecoResolver: ResolveFn<DeliberaListDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);

  return inject(DeliberaService).listaDelibere(filterData);
}

export const DeliberaResolver: ResolveFn<Delibera> = (route) => {
  return inject(DeliberaService).getDeliberaById(Number(route.paramMap.get('id')));
}

export const TipiDeliberaResolver: ResolveFn<TipoDelibera[]> = (route) => {
  return inject(DeliberaService).getTipiDelibera();
}

export const IscrittiDeliberaChiusa: ResolveFn<IscrittiDeliberaChiusaDto> = (route) => {
  const custom_filters = inject(CustomFiltersService);
  const componente = route.data['componente'];
  const filterData = custom_filters.handleFilterByComponent(componente);

  return inject(DeliberaService).getIscrittiByDeliberaId(Number(route.paramMap.get('id')), filterData);
}

export const UltimoNumeroIscrizioneResolver: ResolveFn<Number> = (route) => {
  return inject(IscrittiService).getUltimoNumeroIscrizione();
}

export const SpeseAmministrativeCorrentiResolver: ResolveFn<Number> = (route) => {
  return inject(QuoteService).getSpeseAmministrativeCorrenti();
}

export const InviateListResolver: ResolveFn<InviateListDto> = (route) => {

  const shared_filters = inject(CustomFiltersService);

  const componente = route.data['componente'];
  const sessionData = shared_filters.handleFilterByComponent(componente);

  const filtri: FiltriInviateDto = {
    stringaFiltro: sessionData.inserimento,
    sezioneAlbo: sessionData.sezione,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  }

  return inject(DomandeService).getDomandeInviate(filtri);
}

export const LavorateListResolver: ResolveFn<StoricoListDto> = (route) => {

  const shared_filters = inject(CustomFiltersService);

  const componente = route.data['componente'];
  const sessionData = shared_filters.handleFilterByComponent(componente);

  const filtri: FiltriStoricoDto = {
    stringaFiltro: sessionData.inserimento,
    sezioneAlbo: sessionData.sezione,
    statoIscrizione: sessionData.statoIscrizione,
    orderColumn: sessionData.orderColumn,
    order: sessionData.order,
    page: sessionData.page,
    pageSize: sessionData.pageSize
  }

  return inject(DomandeService).getStoricoDomande(filtri);
}

export const CaricaBozzaResolver: ResolveFn<DatiIscrizione> = (route) => {
  return inject(DomandeService).getBozza(Number(route.paramMap.get('id')!));
}

export const CaricaAllegatiResolver: ResolveFn<Allegati[]> = (route) => {
  return inject(DomandeService).getFileAllegati(Number(route.paramMap.get('id')!));
}
