import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedFiltersService {

  baseFilters: {[key: string]: {}} = {
    'iscritti': {
      stringaFiltro: '',
      stato: '',
      sezione: '',
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0,

      residenzaProvincia: '',
      residenzaComune: '',

      domicilioProvincia: '',
      domicilioComune: '',

      annoNascita: null,
      annoNascitaAl: null,

      annoIscrizione: null,
      annoIscrizioneAl: null,

      titoli: ''
    },
    'provvisori': {
      stringaFiltro: '',
      sezione: '',
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'cancellati': {
      stringaFiltro: '',
      stato: '',
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'stpList': {
      stringaFiltro: '',
      orderColumn: 'codiceStp',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'stpFilters': {
      stringaFiltro: '',
      stato: '',
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'delibere': {
      numeroDelibera: null,
      tipoDelibera: null,
      chiusa: false,
      finalizzata: false,
      orderColumn: 'dataDelibera',
      order: 'DESC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'delibereFinalizzate': {
      numeroDelibera: null,
      tipoDelibera: null,
      chiusa: false,
      finalizzata: true,
      orderColumn: 'dataDelibera',
      order: 'DESC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'delibereChiuse': {
      numeroDelibera: null,
      tipoDelibera: null,
      chiusa: true,
      finalizzata: true,
      orderColumn: 'dataDelibera',
      order: 'DESC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'contabile': {
      stringaFiltro: '',
      stato: '',
      statoIscritto: '',
      annoDal: null,
      annoAl: null,
      numeroAnniDa: null,
      numeroAnniAl: null,
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'contabile-cancellati': {
      stringaFiltro: '',
      stato: '',
      statoIscritto: '',
      annoDal: null,
      annoAl: null,
      numeroAnniDa: null,
      numeroAnniAl: null,
      orderColumn: 'codiceFiscale',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'gestione_domande_iscrizione': {
      stringaFiltro: '',
      sezioneAlbo: '',
      orderColumn: 'domandaProtocollo',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    },
    'storico_domande_iscrizione': {
      stringaFiltro: '',
      sezioneAlbo: '',
      statoIscrizione: '',
      orderColumn: 'domandaProtocollo',
      order: 'ASC',
      page: 0,
      pageSize: 25,
      currentPage: 0
    }
  }

  //verifica genericamente se data esiste e lo rende stringa
  getData(){
    const data = sessionStorage.getItem('data');
    return data ? JSON.parse(data) : null;
  }

  //gestisce i filtri del componente
  handleFilterByComponent(componenete: string){

    const data = this.getData();

    if(data && data[componenete]){
      return data[componenete];
    }

    if(data && !data[componenete]){
      data[componenete] = this.baseFilters[componenete];
      sessionStorage.setItem('data', JSON.stringify(data));
      return this.baseFilters[componenete];
    }

    if(!data){
      let newData: {[key: string]: {}} = {};
      newData[componenete] = this.baseFilters[componenete];
      sessionStorage.setItem('data', JSON.stringify(newData));
      return this.baseFilters[componenete];
    }

  }

}
