<div class="px-3 h-full mt-8 lg:mt-5">

  @if (regione !== 'opl') {
    <div class="flex align-items-center">

      @if (!nuovoIscritto && infoOriginali?.statoIscritto?.codiceCnop !== 'P') {
        @if(syncSpinner){
          <div class="pr-4 pt-1">
            <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
          </div>
        }@else {
          <div>
            <p-button icon="pi pi-upload" (click)="startSynch(infoOriginali?.id!)" class="mr-3" [disabled]="disableBottoneSync()" [severity]="severityButtons"></p-button>
          </div>

        }
      }


      @if(isLoadingSpinner){
        <div class="pl-2 pr-4 pt-1">
          <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
        </div>
      }@else {
        <div>
          <p-button icon="pi pi-save" [disabled]="disableBottoneSalva()" (click)="inviaDatiIscritto()" class="mr-3" [severity]="severityButtons"></p-button>
        </div>
      }

      @if (!nuovoIscritto) {
        <div>
          <p-menu #menu [model]="vociStampa" [popup]="true" appendTo="body"></p-menu>
          <!-- label="Stampa" -->
          <button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-print" [severity]="severityButtons"></button>
        </div>
      }

    </div>
  }

  <form [formGroup]="iscrittoForm">
    <p-tabView (onChange)="onChange($event)" #tv>

      <!-- anagrafica -->
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica')?.invalid}">Anagrafica</span>
        </ng-template>
        <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2" formGroupName="anagrafica">

          <!-- prima riga -->
          <div class="flex flex-wrap">

            <p-fieldset legend="INFORMAZIONI" class="mb-6">

              <div class="flex flex-wrap pt-3">

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <input class="px-0" pInputText id="codiceFiscale" maxlength="16" pKeyFilter="alphanum" formControlName="codiceFiscale"/>
                    <label class="pl-1" for="codiceFiscale" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.codiceFiscale')?.invalid}">Codice Fiscale*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <input class="px-0" pInputText id="alias" formControlName="alias"/>
                    <label class="pl-1" for="alias">Alias</label>
                  </span>
                </div>

              </div>

              <div class="flex flex-wrap pt-3">

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <input class="px-0" pInputText id="nome" formControlName="nome"/>
                    <label class="pl-1" for="nome" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.nome')?.invalid}">Nome*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <input class="px-0" pInputText id="cognome" formControlName="cognome"/>
                    <label class="pl-1" for="cognome" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.cognome')?.invalid}">Cognome*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <p-dropdown [options]="sessi" formControlName="sesso" placeholder="scegli un sesso" optionLabel="nome" inputId="sesso"></p-dropdown>
                    <label class="pl-1" for="sesso" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.sesso')?.invalid}">Sesso*</label>
                  </span>
                </div>

                <div class="dataPickerCustom">
                  <span class="p-float-label px-3 mb-6">
                    <p-calendar formControlName="dataNascita" dateFormat="dd/mm/yy" inputId="dataNascita" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                    <label class="pl-1" for="dataNascita" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.dataNascita')?.invalid}">Data di Nascita*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <input class="px-0" pInputText id="luogoNascita" formControlName="luogoNascita"/>
                    <label class="pl-1" for="luogoNascita" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.luogoNascita')?.invalid}">Luogo di Nascita*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <p-autoComplete appendTo="body" formControlName="provinciaNascita" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.provinciaNascita', iscrittoForm)">

                      <ng-template let-provincia pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                        </div>
                      </ng-template>

                    </p-autoComplete>
                    <label class="pl-1" for="provinciaNascita" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.provinciaNascita')?.invalid}">Provincia di nascita*</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-6">
                    <p-autoComplete appendTo="body" formControlName="cittadinanza" [suggestions]="filteredNazione" (completeMethod)="filterAutoComplete($event, 'nazioni')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.cittadinanza', iscrittoForm)">

                      <ng-template let-nazione pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div>{{ nazione.nome }}</div>
                        </div>
                      </ng-template>

                    </p-autoComplete>
                    <label class="pl-1" for="cittadinanza" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.cittadinanza')?.invalid}">Cittadinanza*</label>
                  </span>
                </div>

              </div>


              <div class="flex flex-wrap mb-3 gap-4">

                <div>

                  <div class="dataPickerCustom">
                    <span class="p-float-label px-3 mb-3">
                      <p-calendar formControlName="scadenzaPermessoSoggiorno" dateFormat="dd/mm/yy" inputId="scadenzaPermessoSoggiorno" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                      <label class="pl-1" for="scadenzaPermessoSoggiorno" [ngClass]="{'label-error': flagScadenzaSoggiorno}">Scadenza P.S.</label>
                    </span>
                  </div>

                  <div class="flex align-items-center pl-3 mb-3">
                    <p-checkbox class="mr-1" formControlName="permessoSoggiorno" [binary]="true" inputId="permessoSoggiorno"></p-checkbox>
                    <label class="checkboxLabelCustom" for="permessoSoggiorno" [ngClass]="{'label-error': flagSoggiorno}">Permesso di Soggiorno</label>
                  </div>

                </div>

                <div class="flex align-items-center pl-3">
                  <p-checkbox class="mr-1" formControlName="permessoSoggiornoIllimitato" [binary]="true" inputId="permessoSoggiornoIllimitato"></p-checkbox>
                  <label class="checkboxLabelCustom" for="permessoSoggiornoIllimitato" [ngClass]="{'label-error': flagSoggiornoIllimitato}">Permesso di Soggiorno Illimitato</label>
                </div>

              </div>

            </p-fieldset>

          </div>

          <!-- prima riga -->
          <p-fieldset legend="RESIDENZA" class="mb-6">
            <div class="flex flex-wrap pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="residenzaIndirizzo" formControlName="residenzaIndirizzo"/>
                  <label class="pl-1" for="residenzaIndirizzo">Indirizzo</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="residenzaCap" maxlength="10" formControlName="residenzaCap"/>
                  <label class="pl-1" for="residenzaCap">CAP</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete appendTo="body" formControlName="residenzaComune" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.residenzaComune', iscrittoForm)">

                    <ng-template let-comune pTemplate="item">
                      <div class="flex align-items-center gap-2">
                          <div>{{ comune.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="residenzaComune">Comune</label>
                </span>
              </div>



              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete appendTo="body" formControlName="residenzaProvincia" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.residenzaProvincia', iscrittoForm)">

                    <ng-template let-provincia pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="residenzaProvincia">Provincia</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete appendTo="body" formControlName="residenzaStato" [suggestions]="filteredNazione" (completeMethod)="filterAutoComplete($event, 'nazioni')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.residenzaStato', iscrittoForm)">

                    <ng-template let-nazione pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ nazione.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="residenzaStato">Stato</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="residenzaLuogo" formControlName="residenzaLuogo"/>
                  <label class="pl-1" for="residenzaLuogo">Residenza Luogo</label>
                </span>
              </div>

            </div>
          </p-fieldset>

          <!-- seconda riga -->
          <p-fieldset legend="DOMICILIO" class="mb-6">
            <div class="flex flex-wrap pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="domicilioIndirizzo" formControlName="domicilioIndirizzo"/>
                  <label class="pl-1" for="domicilioIndirizzo">Indirizzo</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="domicilioCap" pKeyFilter="int" maxlength="5" formControlName="domicilioCap"/>
                  <label class="pl-1" for="domicilioCap">CAP</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete appendTo="body" formControlName="domicilioComune" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.domicilioComune', iscrittoForm)">

                    <ng-template let-comune pTemplate="item">
                      <div class="flex align-items-center gap-2">
                          <div>{{ comune.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="domicilioComune">Comune</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete appendTo="body" formControlName="domicilioProvincia" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.domicilioProvincia', iscrittoForm)">

                    <ng-template let-provincia pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ provincia.codice }} - {{ capitalizeFirstLetters(provincia.nome) }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="domicilioProvincia">Provincia</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="domicilioLuogo" formControlName="domicilioLuogo"/>
                  <label class="pl-1" for="domicilioLuogo">Domicilio Luogo</label>
                </span>
              </div>

            </div>

          </p-fieldset>

          <!-- terza riga -->
          <p-fieldset legend="CONTATTI" class="mb-6 contattiField">
            <div class="flex flex-wrap gap-8 py-5">

              <div class="md:mr-5">
                <span class="p-float-label px-3 mb-3">
                  <input class="px-0 inputCustom" pInputText id="email" formControlName="email"/>
                  <label class="pl-1" for="email" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.email')?.invalid}">E-mail*</label>
                </span>
              </div>

              <div class="md:mr-5">
                <span class="p-float-label px-3 mb-3">
                  <input class="px-0 inputCustom" pInputText id="pec" formControlName="pec"/>
                  <label class="pl-1" for="pec">PEC</label>
                </span>
              </div>

              <div class="flex">

                <div>
                  <span class="p-float-label px-3 mb-3 prefissi">
                    <p-autoComplete appendTo="body" formControlName="prefissoTelefonoPrincipale" [suggestions]="filteredPrefisso" (completeMethod)="filterAutoComplete($event, 'prefisso')" field="codice" placeholder="Es: 'italia'" [minLength]="2" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('anagrafica.prefissoTelefonoPrincipale', iscrittoForm)">

                      <ng-template let-prefisso pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ prefisso.bandiera }} {{ prefisso.nazione }} ({{ prefisso.codice }})</div>
                        </div>
                      </ng-template>

                    </p-autoComplete>
                    <label class="pl-1" for="prefissoTelefonoPrincipale" [ngClass]="{'custom-tab-panel': iscrittoForm.get('anagrafica.prefissoTelefonoPrincipale')?.invalid}">Prefisso</label>
                  </span>
                </div>

                <div>
                  <span class="p-float-label px-3 mb-3">
                    <input class="px-0 inputCustom" pInputText id="telefonoPrincipale" pKeyFilter="int" formControlName="telefonoPrincipale"/>
                    <label class="pl-1" for="telefonoPrincipale">Numero Principale</label>
                  </span>
                </div>

              </div>

            </div>

            @if (!nuovoIscritto) {

              <div class="flex flex-column mb-5">

                @if (listaNumeriSecondari.length > 0) {

                  <!-- tebella pagamenti -->
                  <p-table
                    [value]="listaNumeriSecondari"
                    class="mb-3"
                    [tableStyle]="{ 'max-width': '40rem' }"
                  >
                    <ng-template pTemplate="header">
                        <tr>
                          <th width="25%">Numero secondario</th>
                          <th width="15%">Tipo</th>
                          <th width="30%">Descrizione</th>
                          <th width="5%">
                            <p-button class="buttonTelefoni" icon="pi pi-plus" [rounded]="true" [text]="true" size="small" (click)="aggiungiNumero()"></p-button>
                          </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-telefono>
                        <tr>
                            <td>({{ telefono.prefisso.codice }}) {{ telefono.numero }}</td>
                            <td>{{ telefono.tipo }}</td>
                            <td>{{ telefono.descrizione }}</td>
                            <td>
                              <p-button class="buttonTelefoni" icon="pi pi-minus" severity="danger" [rounded]="true" [text]="true" size="small" (click)="eliminaNumero(telefono)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                  </p-table>
                }@else{
                  <div class="flex ml-3">
                    <p class="font-bold">Aggiungi Numero Secondario</p>
                    <p-button class="buttonTelefoni" icon="pi pi-plus" [rounded]="true" [text]="true" size="small" (click)="aggiungiNumero()"></p-button>
                  </div>
                }

              </div>

            }


          </p-fieldset>

          @if (!nuovoIscritto) {
            <div class="flex flex-column align-items-center px-3 mb-6">

              <p-image
                [src]="immagineIscritto"
                alt="Image"
                width="180"
                height="180"
                [imageStyle]="{'object-fit': 'cover'}"
                class="immagineIscritto"
                [preview]="true"
              />
              <p-button (click)="cambiaFoto()" severity="primary" label="Cambia" class="bottoneFoto" />

              <p-dialog header="Cambia Foto Iscritto" [modal]="true" [(visible)]="cambioFotoDialog">

                <div>
                  <p-fileUpload
                    name="file"
                    method="post"
                    accept=".jpg"
                    maxFileSize="1000000"
                    customUpload="true"
                    (uploadHandler)="uploadImageFile()"
                    (onSelect)="onBasicSelect($event)"
                    chooseLabel="Scegli"
                    uploadLabel="Invia"
                    showCancelButton="false"
                  />
                </div>

              </p-dialog>

            </div>
          }

        </div>
      </p-tabPanel>

      <!-- titoli -->
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span [ngClass]="{'custom-tab-panel': iscrittoForm.get('titoli')?.invalid}">Titoli</span>
        </ng-template>
        <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2" formGroupName="titoli">

          <!-- LAUREA FIELDSET -->
          <p-fieldset legend="TITOLI" class="mb-6">

            <!-- prima riga -->
            <div class="pt-3">
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="titolo" [suggestions]="filteredTitolo" (completeMethod)="filterAutoComplete($event, 'titolo')" field="nome" [minLength]="3" [showClear]="true" [style]="{'width': '100%'}"  (onBlur)="shared_service.checkValueAutocomplete('titoli.titolo', iscrittoForm)">

                  <ng-template let-titolo pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ titolo.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="titolo" [ngClass]="{'custom-tab-panel': iscrittoForm.get('titoli.titolo')?.invalid}">Titolo</label>
              </span>
            </div>

            <!-- seconda riga -->
            <div class="flex flex-wrap md:flex-nowrap">

              <div class="w-full">
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="univLaurea" [suggestions]="filteredUniversita" (completeMethod)="filterAutoComplete($event, 'universita')" field="nome" [minLength]="3" [showClear]="true" [style]="{'width': '100%'}"(onBlur)="shared_service.checkValueAutocomplete('titoli.univLaurea', iscrittoForm)">

                    <ng-template let-uni pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ uni.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="univLaurea" [ngClass]="{'custom-tab-panel': iscrittoForm.get('titoli.univLaurea')?.invalid}">Università Laurea</label>
                </span>
              </div>

              <div class="dataPickerCustom">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar
                    formControlName="laureaData"
                    inputId="laureaData"
                    [minDate]="iscrittoForm.get('anagrafica.dataNascita')?.value!"
                    [iconDisplay]="'input'"
                    [showIcon]="true"
                    dateFormat="dd/mm/yy"
                  >
                  </p-calendar>
                  <label class="pl-1" for="laureaData">Data di Laurea</label>
                </span>
              </div>

            </div>

            <div class="flex flex-wrap pl-3 mb-6">
              <div class="pr-3">
                <p-checkbox class="mr-1" formControlName="laureaAbilitante" [binary]="true" inputId="laureaAbilitante"></p-checkbox>
                <label class="checkboxLabelCustom" for="laureaAbilitante">Laurea Abilitante</label>
              </div>
            </div>

          </p-fieldset>

          <!-- ABILITAZIONI FIELDSET -->
          <p-fieldset legend="ABILITAZIONI" class="mb-6">

            <!-- terza riga -->
            <div class="flex flex-wrap md:flex-nowrap pt-3">

              <div class="uniCustom w-full">
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="universitaAbilitazione" [suggestions]="filteredUniversita" (completeMethod)="filterAutoComplete($event, 'universita')" field="nome" [minLength]="3" [showClear]="true"  [style]="{'width': '100%'}" (onBlur)="shared_service.checkValueAutocomplete('titoli.univLaurea', iscrittoForm)">

                    <ng-template let-uni pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ uni.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="universitaAbilitazione" [ngClass]="{'custom-tab-panel': iscrittoForm.get('titoli.universitaAbilitazione')?.invalid}">Università Abilitazione</label>
                </span>
              </div>

            </div>

            <div class="uniCustom flex flex-wrap md:flex-nowrap pt-3">
              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="abilitazioneData" formControlName="abilitazioneData"/>
                  <label class="pl-1" for="abilitazioneData">Data Abilit.</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-dropdown formControlName="abilitazione" [options]="abilitazioneData" [editable]="true" optionLabel="nome" [showClear]="true" ></p-dropdown>
                  <label class="pl-1" for="abilitazione">Abilitazione</label>
                </span>
              </div>
            </div>

            <div class="pl-3 mb-7">
              <p-checkbox class="mr-1" formControlName="riserva" [binary]="true" inputId="riserva"></p-checkbox>
              <label class="checkboxLabelCustom" for="riserva">Abilitato con Riserva</label>
            </div>

            <div class="flex flex-wrap">

              <div class="flex flex-wrap mb-5 mr-5">

                <!-- articolo 35 -->
                <div class="mr-5">

                  <div class="dataPickerCustom">
                    <span class="p-float-label px-3 mb-3">
                      <p-calendar formControlName="articolo35Data" dateFormat="dd/mm/yy" inputId="articolo35Data" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                      <label class="pl-1" for="articolo35Data">Art.35 Data</label>
                    </span>
                  </div>

                  <div class="flex align-items-center pl-3 mb-5">
                    <p-checkbox class="mr-1" formControlName="psicoterapeutaArticolo35" [binary]="true" inputId="psicoterapeutaArticolo35"></p-checkbox>
                    <label class="checkboxLabelCustom" for="psicoterapeutaArticolo35">Psicoterapeuta Art.35</label>
                  </div>

                </div>

                <!-- articolo 3 -->
                <div>

                  <div class="dataPickerCustom">
                    <span class="p-float-label px-3 mb-3">
                      <p-calendar formControlName="articolo3" dateFormat="dd/mm/yy" inputId="articolo3" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                      <label class="pl-1" for="articolo3">Art.3 Data</label>
                    </span>
                  </div>

                  <div class="flex align-items-center pl-3 mb-3">
                    <p-checkbox class="mr-1" formControlName="psicoterapeutaArticolo3" [binary]="true" inputId="psicoterapeutaArticolo3"></p-checkbox>
                    <label class="checkboxLabelCustom" for="psicoterapeutaArticolo3">Psicoterapeuta Art.3</label>
                  </div>

                </div>

              </div>

              <div class="flex flex-wrap mb-6">

                <div>
                  <span class="p-float-label px-3 mb-3">
                    <input class="px-0" pInputText id="protNpsNumero" formControlName="protNpsNumero"/>
                    <label class="pl-1" for="protNpsNumero">Prot. Nps</label>
                  </span>

                  <div class="flex align-items-center pl-3 mb-3">
                    <p-checkbox class="mr-1" formControlName="neuropsicologo" [binary]="true" inputId="neuropsicologo"></p-checkbox>
                    <label class="checkboxLabelCustom" for="neuropsicologo">Neuropsicologo</label>
                  </div>
                </div>

                <div class="dataPickerCustom">
                  <span class="p-float-label px-3 mb-3">
                    <p-calendar formControlName="protNpsData" dateFormat="dd/mm/yy" inputId="protNpsData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                    <label class="pl-1" for="protNpsData">Prot. Nps Data</label>
                  </span>
                </div>

              </div>



            </div>

          </p-fieldset>

          <!-- quinta riga -->
          <p-fieldset legend="CONTROLLI" class="customFieldset mb-4">
            <div class="flex flex-wrap pl-3 mb-5">
              <div class="pr-3">
                <p-checkbox class="mr-1" formControlName="controlloCasellario" [binary]="true" inputId="controlloCasellario"></p-checkbox>
                <label class="checkboxLabelCustom" for="controlloCasellario">Casellario</label>
              </div>

              <div class="px-3">
                <p-checkbox class="mr-1" formControlName="controlloAbilitazione" [binary]="true" inputId="controlloAbilitazione"></p-checkbox>
                <label class="checkboxLabelCustom" for="controlloAbilitazione">Abilitazione</label>
              </div>

              <div class="px-3">
                <p-checkbox class="mr-1" formControlName="controlloLaurea" [binary]="true" inputId="controlloLaurea"></p-checkbox>
                <label class="checkboxLabelCustom" for="controlloLaurea">Laurea</label>
              </div>
            </div>
          </p-fieldset>

        </div>
      </p-tabPanel>

      <!-- albo -->
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo')?.invalid}">Albo</span>
        </ng-template>
        <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2" formGroupName="albo">

          <!-- ISCRIZIONE FIELDSET -->
          <p-fieldset legend="ISCRIZIONE" class="mb-6">

            <div class="flex flex-wrap pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText pKeyFilter="int" id="numeroIscrizione" formControlName="numeroIscrizione" />
                  <label
                    class="pl-1"
                    for="numeroIscrizione"
                    [ngClass]="{'custom-tab-panel': (nuovoIscritto || infoOriginali?.statoIscritto?.codiceCnop !== 'P') && iscrittoForm.get('albo.numeroIscrizione')?.invalid}"
                  >N° Iscrizione</label>
                </span>
              </div>

              <div class="dataPickerCustom">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar
                    formControlName="dataIscrizione"
                    dateFormat="dd/mm/yy"
                    inputId="dataIscrizione"
                    [iconDisplay]="'input'"
                    [showIcon]="true"
                    [minDate]="iscrittoForm.get('albo.domandaData')?.value!"
                  >
                  </p-calendar>
                  <label
                    class="pl-1"
                    for="dataIscrizione"
                    [ngClass]="{'custom-tab-panel': (nuovoIscritto || infoOriginali?.statoIscritto?.codiceCnop !== 'P') && iscrittoForm.get('albo.dataIscrizione')?.invalid}"
                  >Data Iscrizione</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-dropdown [options]="sezioni" formControlName="sezioneAlbo" placeholder="scegli albo" optionLabel="nome" inputId="sezioneAlbo"></p-dropdown>
                  <label class="pl-1" for="sezioneAlbo" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.sezioneAlbo')?.invalid}">Sezione*</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="domandaProtocollo" formControlName="domandaProtocollo"/>
                  <label class="pl-1" for="domandaProtocollo">Protocollo Domanda</label>
                </span>
              </div>

              <div class="dataPickerCustom">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar
                    formControlName="domandaData"
                    dateFormat="dd/mm/yy"
                    inputId="domandaData"
                    [iconDisplay]="'input'"
                    [showIcon]="true"
                    [minDate]="iscrittoForm.get('laurea.laureaData')?.value!"
                  >
                  </p-calendar>
                  <label class="pl-1" for="domandaData">Data Domanda</label>
                </span>
              </div>

            </div>

          </p-fieldset>

          <!-- STATO ISCRITTO FIELDSET -->
          <p-fieldset legend="STATO ISCRITTO" class="mb-6">

            <div class="flex flex-wrap pt-3 mb-1">

              <div>
                <span class="p-float-label pl-3 mb-6">
                  <input
                    class="px-0 font-bold" type="text"
                    pInputText
                    readonly
                    id="readStato"
                    [value]="iscrittoForm.get('albo.statoIscritto')?.value?.nome"
                  />
                  <label class="pl-1" for="readStato" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.statoIscritto')?.invalid}">Stato Iscritto*</label>
                  @if (
                    (iscrittoForm.get('albo.statoIscritto')?.value?.nome !== 'CANCELLATO' &&
                    iscrittoForm.get('albo.statoIscritto')?.value?.nome !== 'RADIATO' &&
                    iscrittoForm.get('albo.statoIscritto')?.value?.nome !== 'PROVVISORIO') &&
                    statoIscrittoReadonly
                  ) {
                    <p-button
                      icon="pi pi-pencil"
                      (click)="modificaStato()"
                      [rounded]="true"
                      [text]="true"
                      class="p-0 buttonStato"
                      [disabled]="role_service.isConsultazioneRole()"
                    ></p-button>
                  }
                </span>
              </div>

            </div>

            <!-- seconda riga -->
            <div class="flex flex-wrap mb-1">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-dropdown formControlName="articolo26" [options]="articolo26Data" [editable]="true" optionLabel="descrizione"  [showClear]="true"></p-dropdown>
                  <label class="pl-1" for="articolo26">Motivo Sospensione</label>
                </span>
              </div>

              <div class="dataPickerCustom">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar formControlName="sospensioneDal" dateFormat="dd/mm/yy" inputId="sospensioneDal" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                  <label class="pl-1" for="sospensioneDal">Sospensione Dal</label>
                </span>
              </div>

              <div class="dataPickerCustom">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar formControlName="sospensioneAl" dateFormat="dd/mm/yy" inputId="sospensioneAl" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                  <label class="pl-1" for="sospensioneAl">Fino Al</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-dropdown formControlName="procedimentoDisciplinareInCorso" [options]="statoProcedimento" [editable]="true" optionLabel="nome"  [showClear]="true"></p-dropdown>
                  <label class="pl-1" for="procedimentoDisciplinareInCorso">Procedimento Disciplinare</label>
                </span>
              </div>

            </div>

            <!-- quarta riga -->
            @if (infoOriginali?.statoIscritto?.codiceCnop === 'C' || infoOriginali?.statoIscritto?.codiceCnop === 'R') {
              <div class="flex flex-wrap">

                <div class="dataPickerCustom">
                  <span class="p-float-label px-3 mb-6">
                    <p-calendar formControlName="cancellatoData" dateFormat="dd/mm/yy" inputId="cancellatoData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                    <label class="pl-1" for="cancellatoData">Cancellato il</label>
                  </span>
                </div>

                <div class="dataPickerCustom">
                  <span class="p-float-label px-3 mb-6">
                    <p-calendar formControlName="radiatoData" dateFormat="dd/mm/yy" inputId="radiatoData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                    <label class="pl-1" for="radiatoData">Radiato il</label>
                  </span>
                </div>

              </div>
            }

            <!-- terza riga -->
            <div class="flex flex-wrap mb-1">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="ctu" [suggestions]="filteredCTU" (completeMethod)="filterAutoComplete($event, 'ctu')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('albo.ctu', iscrittoForm)">

                    <ng-template let-ctu pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ ctu.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="ctu" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.ctu')?.invalid}">C.T.U.</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="perito" [suggestions]="filteredCTU" (completeMethod)="filterAutoComplete($event, 'ctu')" field="nome" [minLength]="3" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('albo.perito', iscrittoForm)">

                    <ng-template let-perito pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ perito.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="perito" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.perito')?.invalid}">Perito</label>
                </span>
              </div>

            </div>

            <!-- quinta riga -->
            <div class="flex flex-wrap mb-7">

              <div class="px-3">
                <p-checkbox class="mr-1" formControlName="articolo82" [binary]="true" inputId="articolo82"></p-checkbox>
                <label class="checkboxLabelCustom" for="articolo82">Articolo 8.2</label>
              </div>

              <div class="px-3">
                <p-checkbox class="mr-1" formControlName="dipendentePubblico" [binary]="true" inputId="dipendentePubblico"></p-checkbox>
                <label class="checkboxLabelCustom" for="dipendentePubblico">Dipendente Pubblico</label>
              </div>

            </div>

            <div class="flex flex-column flex-wrap mb-3">

              <div class="uniCustom w-full lg:w-6">
                <span class="p-float-label px-3 mb-3">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="professorePressoUniversita" [suggestions]="filteredUniversita" (completeMethod)="filterAutoComplete($event, 'universita')" field="nome" [minLength]="3" [showClear]="true" [style]="{'width': '100%'}" (onBlur)="shared_service.checkValueAutocomplete('albo.professorePressoUniversita', iscrittoForm)">

                    <ng-template let-uni pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ uni.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="professorePressoUniversita" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.professorePressoUniversita')?.invalid}">Professore presso Università</label>
                </span>
              </div>

              <div class="flex align-items-center pl-3 mb-5">
                <p-checkbox class="mr-1" formControlName="professoreUniversitario" [binary]="true" inputId="professoreUniversitario"></p-checkbox>
                <label class="checkboxLabelCustom" for="professoreUniversitario">Professore Universitario</label>
              </div>

            </div>

          </p-fieldset>

          <!-- TRASFERIMENTO FIELDSET -->
          <p-fieldset legend="TRASFERIMENTO" class="mb-6">

            <div class="flex pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="trasferimentoDaOrdine" [suggestions]="filteredOrdini" (completeMethod)="filterAutoComplete($event, 'ordini')" field="nome" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('albo.trasferimentoDaOrdine', iscrittoForm)">

                    <ng-template let-ordine pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ ordine.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="trasferimentoDaOrdine" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.trasferimentoDaOrdine')?.invalid}">Trasferimento Da</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6" (click)="erroreTrasferimento()">
                  <p-autoComplete [dropdown]="true" dropdownMode="current" appendTo="body" formControlName="trasferimentoAOrdine" [suggestions]="filteredOrdini" (completeMethod)="filterAutoComplete($event, 'ordini')" field="nome" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('albo.trasferimentoAOrdine', iscrittoForm)">

                    <ng-template let-ordine pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ ordine.nome }}</div>
                      </div>
                    </ng-template>

                  </p-autoComplete>
                  <label class="pl-1" for="trasferimentoAOrdine" [ngClass]="{'custom-tab-panel': iscrittoForm.get('albo.trasferimentoAOrdine')?.invalid}">Trasferimento A</label>
                </span>
              </div>

              <div (click)="erroreTrasferimento()">
                <span class="p-float-label px-3 mb-6">
                  <p-calendar formControlName="trasferimentoData" dateFormat="dd/mm/yy" inputId="trasferimentoData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                  <label class="pl-1" for="trasferimentoData">Data Trasferimento</label>
                </span>
              </div>

            </div>

          </p-fieldset>

        </div>
      </p-tabPanel>

      <!-- annotazioni -->
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span>Annotazioni</span>
        </ng-template>
        <div class="backPanel border-round-3xl p-5">
          <div class="pl-5">
            <p>{{ annotazioni }}</p>
          </div>
        </div>
      </p-tabPanel>

      <!-- pagamenti -->
      @if(!nuovoIscritto){

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>Pagamenti</span>
          </ng-template>
          <div class="backPanel border-round-3xl py-5 px-6">

            <div>

              <app-tabella-pagamenti [data]="listaData"></app-tabella-pagamenti>

            </div>

          </div>
        </p-tabPanel>

        <!-- modifiche -->
        <p-tabPanel [id]="'modifiche'" #modifiche>
          <ng-template pTemplate="header" >
            <span>Modifiche</span>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="backPanel border-round-3xl py-5 px-6">

              <p-table [value]="audiltLog" dataKey="eventId" [tableStyle]="{ 'min-width': '60rem' }" [expandedRowKeys]="expandedRows">
                <ng-template pTemplate="caption">
                    <div class="flex flex-wrap justify-content-end gap-2">
                        <p-button class="buttonToggle" label="Espandi tutto" icon="pi pi-plus" text (onClick)="expandAllLog()" />
                        <p-button class="buttonToggle" label="Chiudi tutto" icon="pi pi-minus" text (onClick)="collapseAllLog()" />
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5rem"></th>
                        <th pSortableColumn="timestamp">Timestamp <p-sortIcon field="actionDateTime" /></th>
                        <th pSortableColumn="username">Utente <p-sortIcon field="applicationUserName" /></th>
                        <th pSortableColumn="application">Applicazione <p-sortIcon field="applicationName" /></th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-log let-expanded="expanded">
                    <tr>
                        <td>
                            <p-button type="button" pRipple [pRowToggler]="log" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                        </td>
                        <td>{{ log.actionDateTime }}</td>
                        <td>{{ log.applicationUserName}}</td>
                        <td>{{ log.applicationName }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-log>
                    <tr>
                        <td colspan="7">
                            <div class="p-3">
                                <p-table [value]="log.changes" dataKey="attribute">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="attribute">Attributo <p-sortIcon field="attribute" /></th>
                                            <th pSortableColumn="previouse">Vecchio valore <p-sortIcon field="previousValue" /></th>
                                            <th pSortableColumn="new">Nuovo valore <p-sortIcon field="newValue" /></th>

                                            <th style="width: 4rem"></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-change>
                                        <tr>
                                            <td>{{ change.attribute }}</td>
                                            <td>{{ change.previousValue }}</td>
                                            <td>{{ change.newValue }}</td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="6">Nessun attributo di interesse variato.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                    </tr>
                </ng-template>
        </p-table>

            </div>
          </ng-template>
        </p-tabPanel>

      }

    </p-tabView>

  </form>

  @if (regione === 'opl') {
    <div class="flex align-items-center absolute top-0 right-0 mr-6 mt-8 pt-5">

      @if (!nuovoIscritto && infoOriginali?.statoIscritto?.codiceCnop !== 'P') {
        @if(syncSpinner){
          <div class="pr-4 pt-1">
            <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
          </div>
        }@else {
          <div>
            <p-button
              icon="pi pi-upload"
              (click)="startSynch(infoOriginali?.id!)"
              class="mr-3"
              [disabled]="disableBottoneSync() || !role_service.isAlboRole()"
              [severity]="severityButtons" pTooltip="Aggiorna iscritto albo nazionale" tooltipPosition="bottom"
            ></p-button>
          </div>

        }
      }


      @if(isLoadingSpinner){
        <div class="pl-2 pr-4 pt-1">
          <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
        </div>
      }@else {
        <div>
          <p-button
            icon="pi pi-save"
            [disabled]="disableBottoneSalva() || !role_service.isAlboRole()"
            (click)="inviaDatiIscritto()"
            class="mr-3"
            [severity]="severityButtons"
            pTooltip="Salvataggio" tooltipPosition="bottom"
          ></p-button>
        </div>
      }

      @if (!nuovoIscritto) {
        <div>
          <p-menu #menu [model]="vociStampa" [popup]="true" appendTo="body"></p-menu>
          <!-- label="Stampa" -->
          <button
            pButton
            type="button"
            (click)="menu.toggle($event)"
            icon="pi pi-print"
            [severity]="severityButtons"
            [disabled]="!role_service.isAlboRole()"
            pTooltip="Stampe" tooltipPosition="bottom"
          ></button>
        </div>
      }

    </div>
  }


  <p-toast position="bottom-center"></p-toast>

</div>

