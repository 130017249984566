<div class="flex flex-column justify-content-center align-items-center">

  <div [formGroup]="optionForm">
    <div>
      <span class="p-float-label px-3 mb-6">
        <input class="px-0" pInputText id="valore" formControlName="valore"/>
        <label class="pl-1" for="valore">Valore Lista</label>
      </span>
    </div>
  </div>

  <div>
    <p-button
      label="Salva"
      icon="pi pi-save"
      (click)="salvaOption()"
      [disabled]="optionForm.pristine || isEqualFromStart"
    >
    </p-button>
  </div>

</div>
