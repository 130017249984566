import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { Delibera } from '../../model/delibere-data';
import { ProvvisorioDettaglioListaDto, GruppoValore, ListaProvvisoriDto, FiltersListProvvisoriDto } from '../../model/iscritti-data';
import { DeliberaService } from '../../services/delibera.service';
import { IscrittiService } from '../../services/iscritti.service';
import { PrintService } from '../../services/print.service';
import { DialogDeliberaComponent } from '../dialog-delibera/dialog-delibera.component';
import { RoleService } from '../../services/role.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SearchFilters, TableSettings } from '../../model/filters-data';

@Component({
  selector: 'app-tabella-delibere-psi-a',
  templateUrl: './tabella-delibere-psi-a.component.html',
  styleUrl: './tabella-delibere-psi-a.component.scss'
})
export class TabellaDeliberePsiAComponent implements OnInit{

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //dialog dati
  ref: DynamicDialogRef | undefined;

  //services
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private iscritti_service = inject(IscrittiService);
  private delibera_service = inject(DeliberaService);
  private dialog_service = inject(DialogService);
  private message_service = inject(MessageService);
  private print_service = inject(PrintService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';
  sezione: string = '';
  deliberaCorrente: Delibera | undefined;

  keyDataCheckbox = "codiceFiscale";
  provissoriSelezionati: ProvvisorioDettaglioListaDto[] = [];

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>('')
  })

  sezioniOptions: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A' },
    { nome: 'Sezione B', valore: 'B' }
  ]

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'N° Iscrizione', 'Bozza Riepilogo', 'Pec']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'numeroIscrizione', 'numeroDelibera', 'pec']
  listaProvissori: ProvvisorioDettaglioListaDto[] = [];

  dettaglioIscrittoRoute = '/gestione_albo/gestione_iscritti/dettaglio_iscritto/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler(); //gestisce i dati iniziali

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const componente: string = this.route.snapshot.data['componente'];
    const iscritti: ListaProvvisoriDto = this.route.snapshot.data[componente];
    this.deliberaCorrente = this.route.snapshot.data['delibera'];
    this.sezione = 'A';

    this.componente = componente;

    this.listaProvissori = iscritti.lista;
    this.totaleDati = iscritti.conteggio;
    this.tipoLista = componente;

    this.provissoriSelezionati = this.listaProvissori.filter((provvisorio: ProvvisorioDettaglioListaDto) => provvisorio.numeroDelibera !== null && provvisorio.numeroDelibera === this.deliberaCorrente?.numeroDelibera)

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.iscritti_service.getProvvisori(data).subscribe({
      next: (result: ListaProvvisoriDto) => {
        this.listaProvissori = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: ''
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const str: string = '';

    this.filtri.patchValue({
      inserimento: str
    })

    this.currentPage = 0;
    this.page = 0;
    this.pageSize = 25;

  }

  //gestisce la possibilità di aggiungere iscritti in altre delibere
  disableSingleCheckbox(item: ProvvisorioDettaglioListaDto){

    if(this.deliberaCorrente?.stampa){
      return true;
    }

    if(!this.deliberaCorrente?.stampa && item.numeroDelibera){
      if(item.numeroDelibera !== this.deliberaCorrente!.numeroDelibera) return true;
    }

    return false;

  }

  //gestione selezione provvisorio
  selezioneIscritto(event: Event, iscritto: ProvvisorioDettaglioListaDto){
    this.impedisciPropagazione(event);
    (iscritto.numeroDelibera === null) ? this.inserisci(iscritto.id) : this.rimuovi(iscritto.id);
  }

  //invio provvisorio in delibera
  inserisci(idIscritto: number){
    this.delibera_service.inserisciIscrittoInDelibera(idIscritto, this.deliberaCorrente?.numeroDelibera!).subscribe({
      complete: () => {
        this.loadData();
      },
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Provvisorio non aggiunto a riepilogo.' });
      }
    });
  }

  // //rimuovi provvisorio da delibera
  rimuovi(idIscritto: number){
    this.delibera_service.rimuoviIscrittoDaDelibera(idIscritto, this.deliberaCorrente?.numeroDelibera!).subscribe({
      complete: () => {
        this.loadData();
      },
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Provvisorio non rimosso dal riepilogo.' });
      }
    });
  }

  //selezione/deselezione massiva
  selezioneMassiva(){
    (this.provissoriSelezionati.length === 0) ? this.svuotaDelibera() : this.inserisciTuttiInDelibera();
  }

  //inserimento massivo in delibera
  inserisciTuttiInDelibera(){
    const idIscritti: number[] = [];
    this.provissoriSelezionati.forEach(provvisorio => idIscritti.push(provvisorio.id));

    this.delibera_service.inserimentoMassivoInDelibera(idIscritti, this.deliberaCorrente?.numeroDelibera!).subscribe({
      complete: () => {
        this.loadData();
      },
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Inserimento in riepilogo interroto!' });
      }
    })

  }

  //svuota delibera
  svuotaDelibera(){
    this.delibera_service.svuotaDelibera(this.deliberaCorrente?.numeroDelibera!).subscribe({
      complete: () => {
        this.loadData();
      },
      error: () => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Impossibile svuotare il riepilogo' });
      }
    })
  }

  //gestisce il disable dei bottoni per i provvisori
  pulsanteDinamicoDisable(){

    if(this.provissoriSelezionati.length === 0){
      if(!this.deliberaCorrente?.stampa){
        return true
      }
    }

    if(this.provissoriSelezionati.length !== 0 && this.deliberaCorrente?.stampa){
      return true;
    }

    return false;
  }

  //gestisce il dialog delle delibere
  gestisciDeliberaDialog(operazione: string){

    this.ref = this.dialog_service.open(DialogDeliberaComponent, {
      data: {
        numeroDelibera: this.deliberaCorrente!.numeroDelibera,
        operazione: operazione
      },
      header: `${operazione} Riepilogo`
    });

    this.ref.onClose.subscribe({
      next: (result) => {

        if(result){

          if(result.esito){

            let tipoEsito: string = '';

            if(operazione === 'Compila') tipoEsito = 'compilato';
            if(operazione === 'Modifica') tipoEsito = 'modificato';
            if(operazione === 'Approva') tipoEsito = 'approvato';
            if(operazione === 'Cancella') tipoEsito = 'cancellato';

            this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Riepilogo ${tipoEsito} con successo.` });

            setTimeout(() => {

              if(operazione === 'Compila'){
                this.deliberaCorrente = result.delibera;
              }

              if(operazione === 'Cancella'){
                //this.deliberaCorrente = result.delibera;
                this.router.navigate(['/delibere/gestione']);
              }

              if(operazione === 'Modifica'){
                this.deliberaCorrente = result.delibera;
                // this.router.navigate(['/delibere/finalizzazione']);
              }

              if(operazione === 'Approva'){
                this.router.navigate(['/delibere/finalizzazione']);
              }

            }, 1000)

          }

          if(!result.esito){
            this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
          }

        }

      },
      complete: () => {
        if(operazione === 'Nuova' || operazione === 'Compila' || operazione === 'Modifica') this.loadData();
      },
      error: (errore) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //checkbox stop propagazione
  impedisciPropagazione(event: Event){
    event.stopPropagation();
  }

  iscrittoNonSelezionabile(iscritto: ProvvisorioDettaglioListaDto): boolean{

    if((!this.deliberaCorrente?.stampa && iscritto.numeroDelibera) || this.deliberaCorrente?.stampa){
      if(iscritto.numeroDelibera !== this.deliberaCorrente!.numeroDelibera) return false;
    }

    return true
  }

  mandaInStampa(){

    const idDelibera = this.deliberaCorrente?.id;
    const sezioneDelibera = this.deliberaCorrente?.tipo;

    this.print_service.stampaIscrizione(idDelibera!, sezioneDelibera?.codice.toLowerCase()!, true).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'delibera_iscrizione_sezione_a.pdf';
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
        // document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

}
