<div>

  @if (this.listaIscrittiStp.lista.length <= 0) {

    <div [formGroup]="filtri">

      <!-- filtri iscritti -->
      <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

        <!-- filtri -->
        <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

          <!-- ricerca iscritti -->
          <div class="sm:mr-3">
            <span class="p-input-icon-left p-input-icon-right p-float-label">
              <i class="pi pi-search"></i>
              <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="inserimento" />
              <label for="cercaIscritto">Cerca Iscritto</label>
              <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
            </span>
          </div>

        </div>

        <div class="flex bottoniStyle">

          @if(xlsSpinner){
            <div class="pr-4 pt-2">
              <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
            </div>
          }@else {
            <p-button
              type="button"
              icon="pi pi-table"
              iconPos="right"
              [severity]="checkRegione()"
              class="mr-3"
              (click)="printIscrittiXLS()"
              pTooltip="Genera XLS"
              tooltipPosition="bottom"
              [disabled]="!role_service.isAlboRole()"
            ></p-button>
          }

          <p-button
            type="button"
            routerLink="/gestione_albo/nuovo_iscritto"
            icon="pi pi-plus"
            iconPos="right"
            [severity]="checkRegione()"
            pTooltip="Nuovo Iscritto"
            tooltipPosition="bottom"
            [disabled]="!role_service.isAlboRole()"
          ></p-button>

        </div>

      </div>

      <!-- avanzati -->
      <div class="flex align-items-center justify-content-center sm:justify-content-start mb-5 flex-wrap gap-3">
        <div class="mb-3 sm:mb-0">
          <p-dropdown formControlName="stato" [showClear]="true" [options]="statiDropdown" optionLabel="nome" placeholder="Stato"></p-dropdown>
        </div>

        <div class="mb-3 sm:mb-0">
          <p-dropdown formControlName="sezione" [showClear]="true" [options]="sezioniDropdown" optionLabel="nome" placeholder="Sezione"></p-dropdown>
        </div>

        <div>
          <p-button label="Filtri Avanzati" icon="pi pi-filter" iconPos="right" class="filtriAvanzatiBtn mb-3 sm:mb-0" [badge]="numeroFiltriAvanzati.toString()" (click)="showFilters()"/>
        </div>

        <p-button
          class="clearFiltri"
          size="small"
          [outlined]="true"
          icon="pi pi-filter-slash"
          (click)="clearFilters()"
          pTooltip="Pulisci Filtri"
          tooltipPosition="right"
        />
      </div>

      @if(showAdvancedFilters){
        <!-- FILTRI AVANZATI -->
        <div class="flex gap-3 border-round shadow-4 mb-5 p-3">

          <div class="flex flex-column">
            <p class="filtroAvanzatoLabelPrimo">Provincia Residenza</p>
            <p class="filtroAvanzatoLabel">Comune Residenza</p>
            <p class="filtroAvanzatoLabel">Provincia Domicilio</p>
            <p class="filtroAvanzatoLabel">Comune Domicilio</p>
            <p class="filtroAvanzatoLabel">Anno Nascita</p>
            <p class="filtroAvanzatoLabel">Anno Iscrizione</p>
            <p class="filtroAvanzatoLabel">Titolo</p>
          </div>

          <div class="flex flex-column gap-3">

            <div>
              <p-dropdown
                formControlName="residenzaProvincia"
                [showClear]="true"
                [options]="provinceDropdown"
                optionLabel="nome"
                placeholder="--"
              ></p-dropdown>
            </div>

            <div>
              <span>
                <p-autoComplete appendTo="body" formControlName="residenzaComune" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3" [showClear]="true" placeholder="--">

                  <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ comune.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
              </span>
            </div>

            <div>
              <p-dropdown
                formControlName="domicilioProvincia"
                [showClear]="true"
                [options]="provinceDropdown"
                optionLabel="nome"
                placeholder="--"
              ></p-dropdown>
            </div>

            <div>
              <span>
                <p-autoComplete appendTo="body" formControlName="domicilioComune" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3" [showClear]="true" placeholder="--">

                  <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ comune.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
              </span>
            </div>

            <div class="flex align-items-center">

              <div class="mr-3">
                <p-inputNumber inputId="annoNascita" [useGrouping]="false" formControlName="annoNascita" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
              </div>

              <p class="m-0 mr-3">al</p>

              <div class="mr-3">
                <p-inputNumber inputId="annoNascitaAl" [useGrouping]="false" formControlName="annoNascitaAl" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
                <!-- <p-dropdown formControlName="annoNascitaAl" [showClear]="true" [options]="anniDropdown" optionLabel="nome" placeholder="--"></p-dropdown> -->
              </div>

            </div>

            <div class="flex align-items-center">

              <div class="mr-3">
                <p-inputNumber inputId="annoIscrizione" [useGrouping]="false" formControlName="annoIscrizione" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
              </div>

              <p class="m-0 mr-3">al</p>

              <div class="mr-3">
                <p-inputNumber inputId="annoIscrizioneAl" [useGrouping]="false" formControlName="annoIscrizioneAl" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
                <!-- <p-dropdown formControlName="annoNascitaAl" [showClear]="true" [options]="anniDropdown" optionLabel="nome" placeholder="--"></p-dropdown> -->
              </div>

            </div>

            <div>
              <p-dropdown
                formControlName="titoli"
                [showClear]="true"
                [options]="titoliDropdown"
                optionLabel="nome"
                placeholder="--"
              ></p-dropdown>
            </div>

          </div>

        </div>
      }

      <div class="mb-5">

        <!-- PAGINATOR della tabella -->
        <div class="flex justify-content-start">
          <p-paginator
            [rows]="pageSize"
            [first]="currentPage"
            [rowsPerPageOptions]="[25, 100, 250, 500]"
            (onPageChange)="pageChange($event)"
            [totalRecords]="totaleDati"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} di {totalRecords}"
            [showPageLinks]="false"
          >
          </p-paginator>
        </div>

      </div>

    </div>

  }


  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaIscritti"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="headerName === 'Stato' ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                @if(headerName !== 'Stato'){
                  <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
                }
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscritto>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(iscritto)">

            @for (rowName of rowsTabella; track $index) {

              @if (rowName === 'statoIscritto') {
                <td class="font-bold" [style.color]="coloreStato(iscritto.statoIscritto)">
                  {{ iscritto.statoIscritto.nome }}
                </td>
              }@else{
                <td>{{ iscritto[rowName] }}</td>
              }

            }
          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
