<div class=" h-full sm:pt-5 pt-8">

  @if (regione !== 'opl') {
    <div class="pl-3">
      <!--  label="Stampa Estratto Conto" -->
      <p-button icon="pi pi-print" (click)="stampaResoconto()" [disabled]="role_service.isConsultazioneRole()"></p-button>
    </div>
  }

  <p-tabView>

    <!-- pagamenti -->
    <p-tabPanel header="Pagamenti">
      <div class="backPanel border-round-3xl">
        <div class="px-5 pt-3 pb-5">

          <!-- tebella pagamenti -->
          <app-tabella-pagamenti [data]="listaData"></app-tabella-pagamenti>

        </div>

      </div>
    </p-tabPanel>

    <!-- anagrafica -->
    <p-tabPanel header="Anagrafica">
      <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2">

        <div class="flex flex-wrap">

          <!-- informazioni -->
          <p-fieldset legend="INFORMAZIONI" class="customFieldset mb-6">

            <div class="flex flex-wrap pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="codiceFiscale" [value]="iscrittoReadonlyData?.codiceFiscale" readonly/>
                  <label class="pl-1" for="codiceFiscale">Codice Fiscale</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="alias" [value]="iscrittoReadonlyData?.alias" readonly/>
                  <label class="pl-1" for="alias">Alias</label>
                </span>
              </div>

            </div>

            <div class="flex flex-wrap pt-3">

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="nome" [value]="iscrittoReadonlyData?.nome" readonly/>
                  <label class="pl-1" for="nome">Nome</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="cognome" [value]="iscrittoReadonlyData?.cognome" readonly/>
                  <label class="pl-1" for="cognome">Cognome</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="sesso" [value]="iscrittoReadonlyData?.sesso === 'F' ? 'Femmina' : 'Maschio'" readonly/>
                  <label class="pl-1" for="sesso">Sesso</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="dataNascita" [value]="iscrittoReadonlyData?.dataNascita" readonly/>
                  <label class="pl-1" for="dataNascita">Data Nascita</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="luogoNascita" [value]="iscrittoReadonlyData?.luogoNascita" readonly/>
                  <label class="pl-1" for="luogoNascita">Luogo di Nascita</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="provinciaNascita" [value]="iscrittoReadonlyData?.provinciaNascita" readonly/>
                  <label class="pl-1" for="provinciaNascita">Provincia di Nascita</label>
                </span>
              </div>

              <div>
                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="cittadinanza" [value]="iscrittoReadonlyData?.cittadinanza" readonly/>
                  <label class="pl-1" for="cittadinanza">Cittadinanza</label>
                </span>
              </div>

            </div>

            <div class="flex flex-wrap mb-5 gap-4">

              <div>

                <div>
                  <span class="p-float-label px-3 mb-3">
                    <input class="px-0" pInputText id="scadenzaPermessoSoggiorno" [value]="iscrittoReadonlyData?.scadenzaPermessoSoggiorno" readonly/>
                    <label class="pl-1" for="scadenzaPermessoSoggiorno">Scadenza P.S.</label>
                  </span>
                </div>

                <div class="px-3">
                  <p-checkbox class="mr-1" [binary]="true" inputId="permessoSoggiorno" [(ngModel)]="flagPermessoDiSoggiorno" readonly></p-checkbox>
                  <label class="checkboxLabelCustom" for="permessoSoggiorno">Permesso di Soggiorno</label>
                </div>

              </div>

              <div class="flex align-items-center ">
                <p-checkbox class="mr-1" [binary]="true" inputId="permessoSoggiornoIllimitato" [(ngModel)]="flagPermessoDiSoggiornoIllimitato" readonly></p-checkbox>
                <label class="checkboxLabelCustom" for="permessoSoggiornoIllimitato">Permesso di Soggiorno Illimitato</label>
              </div>

            </div>

          </p-fieldset>

        </div>

        <!-- residenza -->
        <p-fieldset legend="RESIDENZA" class="customFieldset mb-6">

          <div class="flex flex-wrap pt-3">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaIndirizzo" [value]="iscrittoReadonlyData?.residenzaIndirizzo" readonly/>
                <label class="pl-1" for="residenzaIndirizzo">Indirizzo</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaCap" [value]="iscrittoReadonlyData?.residenzaCap" readonly/>
                <label class="pl-1" for="residenzaCap">CAP</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaComune" [value]="iscrittoReadonlyData?.residenzaComune" readonly/>
                <label class="pl-1" for="residenzaComune">Comune</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaProvincia" [value]="iscrittoReadonlyData?.residenzaProvincia" readonly/>
                <label class="pl-1" for="residenzaProvincia">Provincia</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaStato" [value]="iscrittoReadonlyData?.residenzaStato" readonly/>
                <label class="pl-1" for="residenzaStato">Stato</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="residenzaLuogo" [value]="iscrittoReadonlyData?.residenzaLuogo" readonly/>
                <label class="pl-1" for="residenzaLuogo">Residenza Luogo</label>
              </span>
            </div>

          </div>

        </p-fieldset>

        <!-- domicilio -->
        <p-fieldset legend="DOMICILIO" class="customFieldset mb-6">

          <div class="flex flex-wrap pt-3">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domicilioIndirizzo" [value]="iscrittoReadonlyData?.domicilioIndirizzo" readonly/>
                <label class="pl-1" for="domicilioIndirizzo">Indirizzo</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domicilioCap" [value]="iscrittoReadonlyData?.domicilioCap" readonly/>
                <label class="pl-1" for="domicilioCap">CAP</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domicilioComune" [value]="iscrittoReadonlyData?.domicilioComune" readonly/>
                <label class="pl-1" for="domicilioComune">Comune</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domicilioProvincia" [value]="iscrittoReadonlyData?.domicilioProvincia" readonly/>
                <label class="pl-1" for="domicilioProvincia">Provincia</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domicilioLuogo" [value]="iscrittoReadonlyData?.domicilioLuogo" readonly/>
                <label class="pl-1" for="domicilioLuogo">Domicilio Luogo</label>
              </span>
            </div>

          </div>

        </p-fieldset>

        <!-- contatti -->
        <p-fieldset legend="CONTATTI" class="customFieldset mb-6">
          <div class="flex flex-wrap pt-3">

            <div class="md:mr-5">
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="email" [value]="iscrittoReadonlyData?.email" readonly/>
                <label class="pl-1" for="email">E-mail</label>
              </span>
            </div>

            <div class="md:mr-5">
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="pec" [value]="iscrittoReadonlyData?.pec" readonly/>
                <label class="pl-1" for="pec">PEC</label>
              </span>
            </div>

            <div class="md:mr-5">
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="telefonoPrincipale" [value]="iscrittoReadonlyData?.numeroPrincipale" readonly/>
                <label class="pl-1" for="telefonoPrincipale">Numero Principale</label>
              </span>
            </div>

          </div>
          <div class="mb-3">
            @if (numeriSecondari.length > 0) {

              <!-- tebella pagamenti -->
              <p-table
                [value]="numeriSecondari"
                class="mb-3"
                [tableStyle]="{ 'max-width': '40rem' }"
              >
                <ng-template pTemplate="header">
                    <tr>
                      <th width="25%">Numero secondario</th>
                      <th width="15%">Tipo</th>
                      <th width="30%">Descrizione</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-telefono>
                    <tr>
                        <td>({{ telefono.prefisso.codice }}) {{ telefono.numero }}</td>
                        <td>{{ telefono.tipo }}</td>
                        <td>{{ telefono.descrizione }}</td>
                    </tr>
                </ng-template>
              </p-table>
            }
          </div>


          <!-- @if (!nuovoIscritto) {

            <div class="flex flex-column mb-5">

              @if (listaNumeriSecondari.length > 0) { -->

                <!-- tebella pagamenti -->
                <!-- <p-table
                  [value]="listaNumeriSecondari"
                  class="mb-3"
                  [tableStyle]="{ 'max-width': '40rem' }"
                >
                  <ng-template pTemplate="header">
                      <tr>
                        <th width="25%">Numero secondario</th>
                        <th width="15%">Tipo</th>
                        <th width="30%">Descrizione</th>
                        <th width="5%">
                          <p-button class="buttonTelefoni" icon="pi pi-plus" [rounded]="true" [text]="true" size="small" (click)="aggiungiNumero()"></p-button>
                        </th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-telefono>
                      <tr>
                          <td>({{ telefono.prefisso.codice }}) {{ telefono.numero }}</td>
                          <td>{{ telefono.tipo }}</td>
                          <td>{{ telefono.descrizione }}</td>
                          <td>
                            <p-button class="buttonTelefoni" icon="pi pi-minus" severity="danger" [rounded]="true" [text]="true" size="small" (click)="eliminaNumero(telefono)"></p-button>
                          </td>
                      </tr>
                  </ng-template>
                </p-table>
              }@else{
                <div class="flex ml-3">
                  <p class="font-bold">Aggiungi Numero Secondario</p>
                  <p-button class="buttonTelefoni" icon="pi pi-plus" [rounded]="true" [text]="true" size="small" (click)="aggiungiNumero()"></p-button>
                </div>
              }

            </div>

          }-->


        </p-fieldset>

        <!-- @if (!nuovoIscritto) {
          <div class="flex flex-column align-items-center px-3 mb-6">

            <p-image
              [src]="immagineIscritto"
              alt="Image"
              width="180"
              height="180"
              [imageStyle]="{'object-fit': 'cover'}"
              class="immagineIscritto"
              [preview]="true"
            />
            <p-button (click)="cambiaFoto()" severity="primary" label="Cambia" class="bottoneFoto" />

            <p-dialog header="Cambia Foto Iscritto" [modal]="true" [(visible)]="cambioFotoDialog">

              <div>
                <p-fileUpload
                  name="file"
                  [url]="urlFototesseraUpload"
                  method="post"
                  accept="image/*" maxFileSize="1000000"
                  (onUpload)="onBasicUploadAuto($event)"
                  chooseLabel="Scegli"
                  uploadLabel="Invia"
                  showCancelButton="false"
                />
              </div>

            </p-dialog>

          </div>
        } -->

      </div>
    </p-tabPanel>

    <!-- albo -->
    <p-tabPanel header="Albo">

      <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2">

        <!-- ISCRIZIONE FIELDSET -->
        <p-fieldset legend="ISCRIZIONE" class="customFieldset mb-6">

          <div class="flex flex-wrap pt-3">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="numeroIscrizione" [value]="iscrittoReadonlyData?.numeroIscrizione" readonly/>
                <label class="pl-1" for="numeroIscrizione">N° Iscrizione</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="dataIscrizione" [value]="iscrittoReadonlyData?.dataIscrizione" readonly/>
                <label class="pl-1" for="dataIscrizione">Data Iscrizione</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="sezioneAlbo" [value]="iscrittoReadonlyData?.sezioneAlbo" readonly/>
                <label class="pl-1" for="sezioneAlbo">Sezione</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domandaProtocollo" [value]="iscrittoReadonlyData?.domandaProtocollo" readonly/>
                <label class="pl-1" for="domandaProtocollo">Protocollo Domanda</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domandaData" [value]="iscrittoReadonlyData?.domandaData" readonly/>
                <label class="pl-1" for="domandaData">Data Domanda</label>
              </span>
            </div>

          </div>

        </p-fieldset>

        <!-- STATO ISCRITTO FIELDSET -->
        <p-fieldset legend="STATO ISCRITTO" class="customFieldset mb-6">

          <div class="flex flex-wrap pt-3 mb-1">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="statoIscritto" [value]="iscrittoReadonlyData?.statoIscritto" readonly/>
                <label class="pl-1" for="statoIscritto">Stato Iscritto</label>
              </span>
            </div>

          </div>

          <!-- seconda riga -->
          <div class="flex flex-wrap mb-1">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="articolo26" [value]="iscrittoReadonlyData?.articolo26" readonly/>
                <label class="pl-1" for="articolo26">Motivo Sospensione</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="sospensioneDal" [value]="iscrittoReadonlyData?.sospensioneDal" readonly/>
                <label class="pl-1" for="sospensioneDal">Sospensione Dal</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="sospensioneAl" [value]="iscrittoReadonlyData?.sospensioneAl" readonly/>
                <label class="pl-1" for="sospensioneAl">Fino Al</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="procedimentoDisciplinareInCorso" [value]="iscrittoReadonlyData?.procedimentoDisciplinareInCorso" readonly/>
                <label class="pl-1" for="procedimentoDisciplinareInCorso">Procedimento Disciplinare / Sospensione</label>
              </span>
            </div>

          </div>

          <!-- terza riga -->
          <div class="flex flex-wrap mb-1">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="ctu" [value]="iscrittoReadonlyData?.ctu" readonly/>
                <label class="pl-1" for="ctu">C.T.U.</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="perito" [value]="iscrittoReadonlyData?.perito" readonly/>
                <label class="pl-1" for="perito">Perito</label>
              </span>
            </div>

          </div>

          <!-- quinta riga -->
          <div class="flex flex-wrap mb-7">

            <div class="px-3">
              <p-checkbox class="mr-1" [binary]="true" inputId="articolo82" [(ngModel)]="flag82" readonly></p-checkbox>
              <label class="checkboxLabelCustom" for="articolo82">Articolo 8.2</label>
            </div>

            <div class="px-3">
              <p-checkbox class="mr-1" [binary]="true" inputId="dipendentePubblico" [(ngModel)]="flagDP" readonly></p-checkbox>
              <label class="checkboxLabelCustom" for="dipendentePubblico">Dipendente Pubblico</label>
            </div>

          </div>

          <div class="flex flex-column flex-wrap mb-3">

            <div class="uniCustom w-full lg:w-6">

                <span class="p-float-label px-3 mb-6">
                  <input class="px-0" pInputText id="professorePressoUniversita" [value]="iscrittoReadonlyData?.professorePressoUniversita" readonly/>
                  <label class="pl-1" for="professorePressoUniversita">Professore presso Uni.</label>
                </span>

            </div>

            <div class="flex align-items-center pl-3 mb-5">
              <p-checkbox class="mr-1" [binary]="true" inputId="professoreUniversitario" [(ngModel)]="flagPU" readonly></p-checkbox>
              <label class="checkboxLabelCustom" for="professoreUniversitario">Professore Universitario</label>
            </div>

          </div>

        </p-fieldset>

        <!-- TRASFERIMENTO FIELDSET -->
        <p-fieldset legend="TRASFERIMENTO" class="customFieldset mb-6">

          <div class="flex pt-3">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="trasferimentoDaOrdine" [value]="iscrittoReadonlyData?.trasferimentoDaOrdine" readonly/>
                <label class="pl-1" for="trasferimentoDaOrdine">Trasferimento Da</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="trasferimentoAOrdine" [value]="iscrittoReadonlyData?.trasferimentoAOrdine" readonly/>
                <label class="pl-1" for="trasferimentoAOrdine">Trasferimento A</label>
              </span>
            </div>

            <div class="">
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="trasferimentoData" [value]="iscrittoReadonlyData?.trasferimentoData" readonly/>
                <label class="pl-1" for="trasferimentoData">Trasf. Data</label>
              </span>
            </div>

          </div>

        </p-fieldset>

      </div>

    </p-tabPanel>

  </p-tabView>

  @if (regione === 'opl') {
    <div class="absolute top-0 right-0 mr-6 mt-8 pt-5">
      <!--  label="Stampa Estratto Conto" -->
      <p-button icon="pi pi-print" (click)="stampaResoconto()" [disabled]="!role_service.isContabilitaRole()"></p-button>
    </div>
  }


  <p-toast position="bottom-center"></p-toast>
</div>
