import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private _http = inject(HttpClient);

  regione = environment.regione;

  private print_api: string = `${environment.printUrl}/jr`;

  stampaIscrizione(delibera: number, sezione: string, psi: boolean): Observable<any>{ // a / b / p
    return this._http.get(this.print_api + `/delibera-${psi ? "psi-" : ""}iscrizione-${sezione}/${delibera}`, { responseType: 'blob' });
  }

  stampaSchedaPersonale(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/scheda-personale/${idIscritto}`, { responseType: 'blob' });
  }

  stampaCertificatoIscrizione(idIscritto: number): Observable<any>{
    const endpoint = `/certificato-iscrizione${(this.regione) === 'opp' ? '-oprp' : ''}/${idIscritto}`;
    return this._http.get(this.print_api + endpoint , { responseType: 'blob' });
  }

  stampaCertificatoIscrizioneEstero(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/certificato-iscrizione-estero/${idIscritto}`, { responseType: 'blob' });
  }

  stampaResocontoContabile(idIscritto: number): Observable<any>{
    return this._http.get(this.print_api + `/resoconto-contabile/${idIscritto}`, { responseType: 'blob' });
  }

  stampaCertificatoIscrizioneStp(idStp: number): Observable<any>{
    return this._http.get(this.print_api + `/certificato-iscrizione_stp/${idStp}`, { responseType: 'blob' });
  }

}
