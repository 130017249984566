<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

        <!-- ricerca iscritti o altri elementi -->
        <div class="sm:mr-3">
          <span class="p-input-icon-left p-input-icon-right p-float-label">
            <i class="pi pi-search"></i>
            <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="stringaFiltro" />
            <label for="cercaIscritto">Cerca Stp</label>
            <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
          </span>
        </div>

      </div>

      <div class="flex">

        <div class="flex align-items-center bottoniStyle">
          <p-button
            type="button"
            routerLink="/gestione-albo/stp/nuova-stp"
            icon="pi pi-plus"
            iconPos="right"
            [severity]="checkRegione()"
            pTooltip="Inserisci STP"
            tooltipPosition="bottom"
            [disabled]="role_service.isConsultazioneRole()"
          ></p-button>
        </div>

        <p-button
          class="ml-3"
          size="small"
          [outlined]="true"
          icon="pi pi-filter-slash"
          (click)="clearFilters()"
          pTooltip="Pulisci Filtri"
          tooltipPosition="right"
        />

      </div>

    </div>

    <!-- avanzati -->
    <!-- <div class="flex align-items-center justify-content-center sm:justify-content-start mb-5 flex-wrap gap-3"></div> -->

  </div>

  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaStp"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="headerName === 'Stato' ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-stp>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(stp)">

            @for (rowName of rowsTabella; track $index) {
              @if(rowName === 'costituzioneSocieta'){
                <td>{{ transformData(stp[rowName]) }}</td>
              }@else {
                <td>{{ stp[rowName] }}</td>
              }

            }
          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>

