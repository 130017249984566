import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AggiornamentoAlboComponent } from './components/aggiornamento-albo/aggiornamento-albo.component';
import { ImportIscrittiComponent } from './components/import-iscritti/import-iscritti.component';
import { CancellatiResolver, CaricaAllegatiResolver, CaricaBozzaResolver, DashboardCardsResolver, DashboardChartsResolver, DashboardResolver, DashboardYearsResolver, DeliberaResolver, DelibereElenecoResolver, DropdownFiltriAvanzati, DropdwonDataResolver, InviateListResolver, IscrittiContabCancellatiResolver, IscrittiContabResolver, IscrittiDeliberaChiusa, IscrittiResolver, IscrittoDropdwonDataResolver, IscrittoQuoteListResolver, IscrittoReadonlyDataResolver, IscrittoReadonlyDataTelefoniResolver, IscrittoResolver, LavorateListResolver, ProvvisoriDeliberaResolver, ProvvisoriResolver, ProvvisoriSelezionabiliResolver, QuoteResolver, SpeseAmministrativeCorrentiResolver, StatiIscrittoAttiviResolver,  StatiIscrittoNonAttiviResolver, StpDropdownDataResolver, StpFiltersResolver, StpIscrittiResolver, StpListResolver, StpResolver, TipiDeliberaResolver, UltimoNumeroIscrizioneResolver } from './services/resolver.service';
import { DettaglioIscrittoComponent } from './components/gestione-albo/dettaglio-iscritto/dettaglio-iscritto.component';
import { DettaglioStpComponent } from './components/gestione-albo/dettaglio-stp/dettaglio-stp.component';
import { DettaglioContabileComponent } from './components/gestione-albo/dettaglio-contabile/dettaglio-contabile.component';
import { ConfigurazioneComponent } from './components/configurazione/configurazione.component';
import { AuthGuard } from './guards/auth-guard.guard';
import { TabellaIscrittiComponent } from './components/tabella-iscritti/tabella-iscritti.component';
import { TabellaProvvisoriComponent } from './components/tabella-provvisori/tabella-provvisori.component';
import { TabellaCancellatiComponent } from './components/tabella-cancellati/tabella-cancellati.component';
import { TabellaStpComponent } from './components/tabella-stp/tabella-stp.component';
import { TabellaDelibereComponent } from './components/tabella-delibere/tabella-delibere.component';
import { TabellaProvvisoriSelezionabiliComponent } from './components/tabella-provvisori-selezionabili/tabella-provvisori-selezionabili.component';
import { TabellaDelibereChiuseComponent } from './components/tabella-delibere-chiuse/tabella-delibere-chiuse.component';
import { TabellaContabilitaComponent } from './components/tabella-contabilita/tabella-contabilita.component';
import { ImportPagamentiComponent } from './components/import-pagamenti/import-pagamenti.component';
import { GeneraIuvComponent } from './components/genera-iuv/genera-iuv.component';
import { TabellaDelibereFinalizzaComponent } from './components/tabella-delibere-finalizza/tabella-delibere-finalizza.component';
import { TabellaDelibereChiuseListaComponent } from './components/tabella-delibere-chiuse-lista/tabella-delibere-chiuse-lista.component';
import { TabellaProvvisoriFinalizzaComponent } from './components/tabella-provvisori-finalizza/tabella-provvisori-finalizza.component';
import { TabellaContabCancellatiComponent } from './components/tabella-contab-cancellati/tabella-contab-cancellati.component';
import { TabellaDeliberePsiAComponent } from './components/tabella-delibere-psi-a/tabella-delibere-psi-a.component';
import { TabellaDelibereTrasfComponent } from './components/tabella-delibere-trasf/tabella-delibere-trasf.component';
import { SpeseAmministrativeComponent } from './components/spese-amministrative/spese-amministrative.component';
import { StampeEDelibereComponent } from './components/stampe-e-delibere/stampe-e-delibere.component';
import { TabellaGestioneDomandeComponent } from './components/tabella-gestione-domande/tabella-gestione-domande.component';
import { TabellaStoricoDomandeComponent } from './components/tabella-storico-domande/tabella-storico-domande.component';
import { DettaglioDomandaIscrizioneComponent } from './components/dettaglio-domanda-iscrizione/dettaglio-domanda-iscrizione.component';
import { environment } from './environment/environment';

const regione = environment.regione;

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()}`,
    data: {
      roles: ['albo', 'consultazione', 'iscrizioni', 'contabilita', 'deontologico'],
      title: 'Dashboard',
    }
  },
  {
    path: 'gestione_albo/gestione_iscritti/:routeFilters',
    component: TabellaIscrittiComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Gestione Iscritti`,
    data: { roles: ['albo','consultazione'], title: 'Gestione Iscritti', componente: 'iscritti' },
    resolve: {
      'iscritti': IscrittiResolver,
      'statiIscritto': StatiIscrittoAttiviResolver,
      'dropdown': DropdownFiltriAvanzati
    }
  },
  {
    path: 'gestione_albo/provvisori',
    component: TabellaProvvisoriComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Provvisori`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Provvisori', componente: 'provvisori' },
    resolve: { 'provvisori': ProvvisoriResolver }
  },
  {
    path: 'gestione_albo/cancellati',
    component: TabellaCancellatiComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Iscritti Cancellati`,
    data: { roles: ['albo','consultazione'], title: 'Iscritti Cancellati', componente: 'cancellati' },
    resolve: { 'cancellati': CancellatiResolver, 'statiIscritto': StatiIscrittoNonAttiviResolver }
  },
  {
    path: 'gestione_albo/stp',
    component: TabellaStpComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Stp`,
    data: { roles: ['albo','consultazione'], title: 'Stp', componente: 'stpList' },
    resolve: { 'listaStp': StpListResolver }
  },
  {
    path: 'delibere/gestione',
    component: TabellaDelibereComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Gestione Provvisori', componente: 'delibere' },
    resolve: {
      'delibere': DelibereElenecoResolver,
      'tipiDelibera': TipiDeliberaResolver }
  },
  {
    path: 'delibere/finalizzazione',
    component: TabellaDelibereFinalizzaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Finalizzazione Riepiloghi', componente: 'delibereFinalizzate' },
    resolve: {
      'delibere': DelibereElenecoResolver,
      'tipiDelibera': TipiDeliberaResolver
    }
  },
  {
    path: 'delibere/chiuse',
    component: TabellaDelibereChiuseListaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepiloghi Chiusi', componente: 'delibereChiuse' },
    resolve: {
      'delibere': DelibereElenecoResolver,
      'tipiDelibera': TipiDeliberaResolver
    }
  },
  {
    path: 'gestione-albo/gestione-iscritti/dettaglio-iscritto/:id',
    component: DettaglioIscrittoComponent,
    canActivate: [AuthGuard],
    canDeactivate: [(component: DettaglioIscrittoComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
      return component.unsavedChecker().then(() => true).catch(() => false);
    }],
    title: `Albo ${regione.toUpperCase()} | Dettaglio Iscritto`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], nuovoIscritto: false, title: 'Dettaglio Iscritto' },
    resolve: {
      'iscritto': IscrittoResolver,
      'quote': QuoteResolver,
      'iscrittoDropdown': IscrittoDropdwonDataResolver
    }
  },
  {
    path: 'gestione-albo/nuovo-iscritto',
    component: DettaglioIscrittoComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Nuovo Iscritto`,
    canDeactivate: [(component: DettaglioIscrittoComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
      return component.unsavedChecker().then(() => true).catch(() => false);
    }],
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], nuovoIscritto: true, title: 'Nuovo Iscritto', provvisorio: false },
    resolve: {
      'iscrittoDropdown': IscrittoDropdwonDataResolver,
      'ultimoNumeroIscrizione': UltimoNumeroIscrizioneResolver
    }
  },
  {
    path: 'gestione-albo/nuovo-provvisorio',
    component: DettaglioIscrittoComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Nuovo Iscritto`,
    canDeactivate: [(component: DettaglioIscrittoComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
      return component.unsavedChecker().then(() => true).catch(() => false);
    }],
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], nuovoIscritto: true, title: 'Nuovo Provvisorio', provvisorio: true },
    resolve: {
      'iscrittoDropdown': IscrittoDropdwonDataResolver,
      'ultimoNumeroIscrizione': UltimoNumeroIscrizioneResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/A/:id',
    component: TabellaProvvisoriSelezionabiliComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Sezione A', componente: 'provvisori_a' },
    resolve: {
      'delibera': DeliberaResolver,
      'provvisori_a': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/B/:id',
    component: TabellaProvvisoriSelezionabiliComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Sezione B', componente: 'provvisori_b' },
    resolve: {
      'delibera': DeliberaResolver,
      'provvisori_b': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/P/:id',
    component: TabellaDeliberePsiAComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Psicoterapeuti Sezione A', componente: 'psi_a' },
    resolve: {
      'delibera': DeliberaResolver,
      'psi_a': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/T/:id',
    component: TabellaDelibereTrasfComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Trasferiementi', componente: 'trasferimento' },
    resolve: {
      'delibera': DeliberaResolver,
      'trasferimento': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/finalizza/A/:id',
    component: TabellaProvvisoriFinalizzaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Sezione A', componente: 'provvisori_a_finalizza' },
    resolve: {
      'delibera': DeliberaResolver,
      'provvisori_a_finalizza': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/finalizza/B/:id',
    component: TabellaProvvisoriFinalizzaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Sezione B', componente: 'provvisori_b_finalizza' },
    resolve: {
      'delibera': DeliberaResolver,
      'provvisori_b_finalizza': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/finalizza/P/:id',
    component: TabellaProvvisoriFinalizzaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Psicoterapeuti Sezione A', componente: 'psi_a_finalizza' },
    resolve: {
      'delibera': DeliberaResolver,
      'psi_a_finalizza': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/finalizza/T/:id',
    component: TabellaProvvisoriFinalizzaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Trasferiementi', componente: 'trasferimento_finalizza' },
    resolve: {
      'delibera': DeliberaResolver,
      'trasferimento_finalizza': ProvvisoriDeliberaResolver
    }
  },
  {
    path: 'delibere/dettaglio_delibera/iscrizione_chiusa/:id',
    component: TabellaDelibereChiuseComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Delibere`,
    data: { roles: ['albo', 'consultazione', 'iscrizioni'], title: 'Riepilogo Chiuso', componente: 'delibera_chiusa' },
    resolve: {
      'delibera': DeliberaResolver,
      'delibera_chiusa': IscrittiDeliberaChiusa
    }
  },
  {
    path: 'gestione-albo/stp/dettaglio-stp/:id',
    component: DettaglioStpComponent,
    canActivate: [AuthGuard],
    canDeactivate: [(component: DettaglioStpComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
      return component.unsavedChecker().then(() => true).catch(() => false);
    }],
    title: `Albo ${regione.toUpperCase()} | Stp Dettaglio`,
    data: { roles: ['albo','consultazione'], nuovoStp: false, title: 'Stp Dettaglio' },
    resolve: {
      'stp': StpResolver,
      'stp_iscritti': StpIscrittiResolver,
      'stpFilters': StpFiltersResolver,
      'dropdown': StpDropdownDataResolver }
  },
  {
    path: 'gestione-albo/stp/nuova-stp',
    component: DettaglioStpComponent,
    canActivate: [AuthGuard],
    canDeactivate: [(component: DettaglioStpComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
      return component.unsavedChecker().then(() => true).catch(() => false);
    }],
    title: `Albo ${regione.toUpperCase()} | Nuova Stp`,
    data: {roles: ['albo'], nuovoStp: true, title: 'Nuova Stp' },
    resolve: { 'dropdown': StpDropdownDataResolver }
  },
  {
    path: 'contabilita-iscritti/:routeFilters',
    component: TabellaContabilitaComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Contabilità Iscritti`,
    data: { roles: ['contabilita', 'consultazione'], title: 'Contabilità Iscritti', componente: 'contabile' },
    resolve: { 'contabile': IscrittiContabResolver }
  },
  {
    path: 'contabilita-iscritti-cancellati/:routeFilters',
    component: TabellaContabCancellatiComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Contabilità Iscritti Cancellati`,
    data: { roles: [ 'contabilita', 'consultazione'], title: 'Contabilità Iscritti Cancellati', componente: 'contabile-cancellati' },
    resolve: { 'contabile-cancellati': IscrittiContabCancellatiResolver }
  },
  {
    path: 'contabilita-iscritti/dettaglio-iscritto/:id',
    component: DettaglioContabileComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Contabilità Iscritti`,
    data: { roles: [ 'contabilita', 'consultazione'], title: 'Dettaglio Contabilità' },
    resolve: {
      'iscritto': IscrittoQuoteListResolver,
      'iscrittoReadonlyData': IscrittoReadonlyDataResolver,
      'numeriSecondari': IscrittoReadonlyDataTelefoniResolver
     }
  },
  {
    path: 'contabilita/spese-amministrative',
    component: SpeseAmministrativeComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['contabilita', 'consultazione'], title: 'Applica Spese Amministrative', componente: 'spese-amministrative' },
    resolve: {
      'speseAmministrativeCorrenti': SpeseAmministrativeCorrentiResolver
    }
  },
  {
    path: 'contabilita/genera-iuv',
    component: GeneraIuvComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: [ 'contabilita', 'consultazione'], title: 'Genera IUV' }
  },
  {
    path: 'contabilita/import-pagamenti',
    component: ImportPagamentiComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['contabilita', 'consultazione'], title: 'Import Pagamenti' }
  },
  {
    path: 'stampe_e_delibere',
    component: StampeEDelibereComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['albo', 'iscrizioni', 'consultazione'], title: 'Stampe e Delibere' }
  },
  {
    path: 'domande_iscrizione/gestione',
    component: TabellaGestioneDomandeComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['albo', 'iscrizioni', 'consultazione'], title: 'Gestione Domande Iscrizione', componente: 'gestione_domande_iscrizione' },
    resolve: {
      'inviateList': InviateListResolver
    }
  },
  {
    path: 'domande_iscrizione/storico',
    component: TabellaStoricoDomandeComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['albo', 'iscrizioni', 'consultazione'], title: 'Storico Domande Iscrizione', componente: 'storico_domande_iscrizione' },
    resolve: {
      'finalizzateList': LavorateListResolver
    }
  },
  {
    path: 'domande_iscrizione/dettaglio-domanda/:id',
    component: DettaglioDomandaIscrizioneComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['albo', 'iscrizioni', 'consultazione'], title: 'Dettaglio Domanda', componente: 'domanda', allegati: 'allegati' },
    resolve: {
      'domanda': CaricaBozzaResolver,
      'allegati': CaricaAllegatiResolver
    }
  },
  {
    path: 'domande_iscrizione/storico/dettaglio-pratica/:id',
    component: DettaglioDomandaIscrizioneComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['albo', 'iscrizioni', 'consultazione'], title: 'Dettaglio Pratica', componente: 'storico_pratica', allegati: 'allegati' },
    resolve: {
      'storico_pratica': CaricaBozzaResolver,
      'allegati': CaricaAllegatiResolver
    }
  },
  {
    path: 'import-iscritti',
    component: ImportIscrittiComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | `,
    data: { roles: ['iscrizioni', 'consultazione'], title: 'Import Iscritti Provvisori' }
  },
  {
    path: 'aggiornamento-nazionale',
    component: AggiornamentoAlboComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Aggiornamento Albo Nazionale`,
    data: { roles: ['albo', 'consultazione'], title: 'Aggiornamento Albo Nazionale' }
  },
  {
    path: 'configurazione',
    component: ConfigurazioneComponent,
    canActivate: [AuthGuard],
    title: `Albo ${regione.toUpperCase()} | Configurazione`,
    data: {
      roles: ['configurazione'],
      title: 'Configurazione'
    },
    resolve: { 'dropdown': DropdwonDataResolver }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

