<div class="px-3 pt-7" [formGroup]="deliberaForm">
  <span class="p-float-label px-3 mb-6">
    <p-dropdown [options]="oggettoOptions" formControlName="oggetto" placeholder="scegli un oggetto" optionLabel="nome" inputId="oggetto" [showClear]="true"></p-dropdown>
    <label class="pl-1" for="oggetto">Oggetto</label>
  </span>
</div>

<div class="flex">
  <!-- PRESENZE DELIBERA CANCELLAZIONE PER TRASFERIMENTO -->
  @if(checkCT()){
    <div class="pl-5">
      <p-fieldset legend="{{'presenze'.toUpperCase()}}">

        @for (item of consiglio; track $index) {
          <div class="flex align-items-center justify-content-between text-lg py-2">
            <p class="m-0 mr-2"><span class="font-bold">{{item.nome}}</span>{{" (" + item.ruolo + ")"}}</p>
            <p-inputSwitch [(ngModel)]="item.presente" />
          </div>
        }

      </p-fieldset>
    </div>
  }

  @if(deliberaForm.get('oggetto')?.value! !== null){
    <div class="flex flex-column pl-5">

      <div [ngClass]="{'p-3': checkCT()}" class="mr-5" [formGroup]="deliberaForm">

        @if (!deliberaForm.get('oggetto')?.invalid) {

          <div class="flex flex-column justify-content-center w-full" [ngClass]="{'mt-5': checkCT()}">
            <span class="p-float-label px-3 mb-6">
              <p-autoComplete
                  appendTo="body"
                  formControlName="iscritto"
                  [suggestions]="iscrittiFiltered"
                  [minLength]="3"
                  (completeMethod)="iscrittiStampeSearch($event)"
                  (input)="inputValueAutocomplete($event)"
                  optionLabel="infoEstese"
              />
              <label class="pl-1" for="iscritto">Iscritto</label>
            </span>
          </div>

          @if (checkNumeroDelibera()) {
            <div class="">
              <span  class="p-float-label px-3 mb-6">
                <input pInputText pKeyFilter="num" formControlName="numeroDelibera" id="numeroDelibera" />
                <label class="pl-1" for="oggetto">Numero Delibera</label>
              </span>
            </div>
          }

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <p-calendar formControlName="dataDelibera" dateFormat="dd/mm/yy" inputId="dataDelibera" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
              <label class="pl-1" for="dataDelibera">Data Delibera</label>
            </span>
          </div>
        }

      </div>

      <div>
        @if(checkRNT() && !deliberaForm.get('oggetto')?.invalid){
          <div>
            <span class="p-float-label px-3 mb-6">
              <p-dropdown [options]="ordini" [(ngModel)]="ordineSelezionato" placeholder="scegli un ordine" optionLabel="nome" inputId="orine"></p-dropdown>
              <label class="pl-1" for="ordine">Ordine</label>
            </span>
          </div>
        }

        @if(!deliberaForm.get('oggetto')?.invalid){
          <p-button
            label="Genera Delibera"
            class="ml-3"
            (click)="generaDelibera()"
            [disabled]="deliberaForm.invalid"
          ></p-button>
        }
      </div>



    </div>
  }


</div>




