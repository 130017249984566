<div class="flex flex-column mb-3">

  <div class="flex justify-content-center">
    <p class="font-bold">I SEGUENTI DATI SONO STATI MODIFICATI MA NON SALVATI</p>
  </div>

  <ul class="text-left w-100">
    @for (item of unsavedData; track $index) {
      <li>
        <p class="text-lg font-semibold">{{ item }}</p>
      </li>
    }
  </ul>

  <div class="flex justify-content-center">
    <p class="font-semibold">Continuare senza inviare le modifiche?</p>
  </div>

</div>

<div class="flex justify-content-center">
  <p-button
    class="mr-5 denyButton"
    label="Abbandona"
    (click)="close(true)">
  </p-button>
  <p-button
    label="Torna ai dettagli"
    (click)="close(false)"></p-button>
</div>

