import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MegaMenuItem } from 'primeng/api';
import { environment } from '../../environment/environment';
import { MegaMenu } from 'primeng/megamenu';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss'
})
export class MenuBarComponent implements OnInit{

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  items: MegaMenuItem[] | undefined;

  accessDomande: string[] = ['opp', 'demo'];

  @ViewChild(MegaMenu) megaMenu: MegaMenu | undefined;


  private role_service = inject(RoleService);

  ngOnInit(): void {
    this.items = [
      {
          label: '<span class="line-height-3">Dashboard</span>',
          escape: false,
          icon: 'pi pi-th-large',
          routerLink: [''],
          routerLinkActiveOptions: { exact: true }

      },
      ...(this.role_service.isAlboOrConsultazione() ? [{
        label: '<span class="line-height-3">Gestione Albo</span>',
        escape: false,
        root: true,
        icon: 'pi pi-database',
        items: [
          [
            {
              items: [
                {
                  label: '<span class="line-height-3">Gestione Iscritti</span>',
                  escape: false,
                  routerLink: ['gestione_albo/gestione_iscritti/iscritti'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Provvisori</span>',
                  escape: false,
                  routerLink: ['gestione_albo/provvisori'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Società ( STP )</span>',
                  escape: false,
                  routerLink: ['gestione_albo/stp'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Cancellati</span>',
                  escape: false,
                  routerLink: ['gestione_albo/cancellati'],
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            }
          ]
        ]
      }] : []),
      ...(this.role_service.isAlboOrisIscrizioniOrisConsultazione() && this.accessDomande.includes(this.regione) ? [{
        label: '<span class="line-height-3">Stampe e Delibere</span>',
        escape: false,
        icon: 'pi pi-print',
        routerLink: ['stampe_e_delibere'],
        routerLinkActiveOptions: { exact: true }
      }] : []),
      ...(this.role_service.isAlboOrisIscrizioniOrisConsultazione() ? [{
        label: '<span class="line-height-3">Domande Iscrizione</span>',
        escape: false,
        icon: 'pi pi-file-import',
        items: [
          [
            {
              items: [
                {
                  label: '<span class="line-height-3">Gestione</span>',
                  escape: false,
                  routerLink: ['domande_iscrizione/gestione'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Storico</span>',
                  escape: false,
                  routerLink: ['domande_iscrizione/storico'],
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            }
          ]
        ]
      }] : []),
      ...(this.role_service.isAlboOrisIscrizioniOrisConsultazione() ? [{
        label: '<span class="line-height-3">Delibere</span>',
        escape: false,
        icon: 'pi pi-file-edit',
        items: [
          [
            {
              items: [
                {
                  label: '<span class="line-height-3">Gestione</span>',
                  escape: false,
                  routerLink: ['delibere/gestione'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Finalizzazione</span>',
                  escape: false,
                  routerLink: ['delibere/finalizzazione'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Chiuse</span>',
                  escape: false,
                  routerLink: ['delibere/chiuse'],
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            }
          ]
        ]
      }] : []),
      ...(this.role_service.isIscrizioniOrisConsultazioneOrisDeontologico() ? [{
        label: '<span class="line-height-3">Gestione Procedimenti</span>',
        escape: false,
        icon: 'pi pi-inbox',
        iconClass: 'text-lg mr-4 ml-3'
      }] : []),
      ...(this.role_service.isContabilitaOrisConsultazione() ? [{
        label: '<span class="line-height-3">Contabilità</span>',
        escape: false,
        icon: 'pi pi-dollar',
        items: [
          [
            {
              items: [
                {
                  label: '<span class="line-height-3">Contab. Iscritti</span>',
                  escape: false,
                  routerLink: ['contabilita-iscritti/iscritti'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Cancellati & Radiati</span>',
                  escape: false,
                  routerLink: ['contabilita-iscritti-cancellati/iscritti'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Applica Spese Amministrative</span>',
                  escape: false,
                  routerLink: ['contabilita/spese-amministrative'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Genera IUV</span>',
                  escape: false,
                  routerLink: ['contabilita/genera-iuv'],
                  routerLinkActiveOptions: { exact: true }
                },
                {
                  label: '<span class="line-height-3">Importa Pagamenti</span>',
                  escape: false,
                  routerLink: ['contabilita/import-pagamenti'],
                  routerLinkActiveOptions: { exact: true }
                }
              ]
            }

          ]
        ]
      }] : []),
      ...(this.role_service.isAlboOrConsultazione() ? [{
        label: '<span class="line-height-3">Aggiornamento albo nazionale</span>',
        escape: false,
        icon: 'pi pi-sync',
        iconClass: 'text-lg mr-4 ml-3',
        routerLink: ['aggiornamento-nazionale'],
        routerLinkActiveOptions: { exact: true }
      }] : []),
      ...(this.role_service.isIscrizioniOrisConsultazione() ? [{
        label: '<span class="line-height-3">Import iscritti provvisori</span>',
        escape: false,
        icon: 'pi pi-upload',
        iconClass: 'text-lg mr-4 ml-3',
        routerLink: 'import-iscritti',
        routerLinkActiveOptions: { exact: true }
      }] : []),
      ...(this.role_service.isConfigurazioneRole() ? [{
        label: '<span class="line-height-3">Configurazione</span>',
        escape: false,
        icon: 'pi pi-cog',
        iconClass: 'text-lg mr-4 ml-3',
        routerLink: 'configurazione',
        routerLinkActiveOptions: { exact: true }
      }] : [])
    ]

  }

  collapseMenu(): void {
    if (this.megaMenu?.activeItem) {
      this.megaMenu.activeItem.set(null);
    }
  }

  checkRegione(){

    let classeRegione = '';

    if(this.regione === 'opp') classeRegione = 'opp-style';
    if(this.regione === 'demo') classeRegione = 'demo-style';

    return classeRegione;
  }

}
