<div class="h-full pt-5">
  <div class="flex p-5">

  </div>

  <div class="boxCustom backPanel border-round-3xl">

    @if(nuovoSocio){
      <div [formGroup]="professionistiSearch" class="flex flex-column border-2 border-round-3xl justify-content-center py-4 px-6">
        <p class="grey mb-1"> Ricerca Iscritto (Nome, Cognome, Codice Fiscale)</p>

        <p-autoComplete
          appendTo="body"
          formControlName="professionistaSelezionato"
          field="anagrafica"
          [suggestions]="professionistiFiltered"
          (completeMethod)="filterProfessionisti($event)"
          [minLength]="3"
          (input)="inputValueAutocomplete($event)"
          (onSelect)="popolaForm($event)"
          (onClear)="clearForm()"
          showClear="true"
        >
          <ng-template let-professionista pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{ professionista.nome }}, {{ professionista.cognome }}, {{ professionista.codiceFiscale }}</div>
            </div>
          </ng-template>

        </p-autoComplete>

      </div>
    }

    <form [formGroup]="socioForm">
      <div class="flex flex-column px-5 pt-5 pb-2">

        <div class="pt-3">
          <span class="p-float-label px-3 mb-6">
            <p-dropdown [options]="tipoSocioList" formControlName="tipoSocio" placeholder="scegli un tipo socio" optionLabel="nome" inputId="tipoSocio" showClear="true"></p-dropdown>
            <label class="pl-1" for="tipoSocio" [ngClass]="{'custom-tab-panel': socioForm.get('tipoSocio')?.invalid}">Tipo Socio*</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <p-dropdown [options]="ruoloSocioList" formControlName="ruoloSocio" placeholder="scegli un ruolo socio" optionLabel="nome" inputId="ruoloSocio" showClear="true"></p-dropdown>
            <label class="pl-1" for="ruoloSocio" [ngClass]="{'custom-tab-panel': socioForm.get('ruoloSocio')?.invalid}">Ruolo Socio*</label>
          </span>
        </div>

        <!-- prima riga -->
        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <p-dropdown [options]="ordini" formControlName="ordine" placeholder="scegli un ordine" optionLabel="nome" inputId="ordine" showClear="true"></p-dropdown>
              <label class="pl-1" for="ordine">Ordine</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="numeroOrdine" formControlName="numeroOrdine"/>
              <label class="pl-1" for="numeroOrdine">Numero Ordine</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <p-dropdown [options]="sezioni" formControlName="sezioneAlbo" placeholder="scegli una sezione" optionLabel="nome" inputId="sezioneAlbo" showClear="true"></p-dropdown>
              <label class="pl-1" for="sezioneAlbo">Sezione</label>
            </span>
          </div>

        </div>

        <!-- seconda riga -->
        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="nome" formControlName="nome"/>
              <label class="pl-1" for="nome" [ngClass]="{'custom-tab-panel': socioForm.get('nome')?.invalid}">Nome*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="cognome" formControlName="cognome"/>
              <label class="pl-1" for="cognome" [ngClass]="{'custom-tab-panel': socioForm.get('cognome')?.invalid}">Cognome*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="codiceFiscale" formControlName="codiceFiscale" maxlength="16" pKeyFilter="alphanum"/>
              <label class="pl-1" for="codiceFiscale" [ngClass]="{'custom-tab-panel': socioForm.get('codiceFiscale')?.invalid}">Codice Fiscale*</label>
            </span>
          </div>

        </div>

        <!-- seconda riga -->
        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="luogoNascita" formControlName="luogoNascita"/>
              <label class="pl-1" for="luogoNascita" [ngClass]="{'custom-tab-panel': socioForm.get('luogoNascita')?.invalid}">Luogo Nascita*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <p-autoComplete appendTo="body" formControlName="provinciaNascita" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true" (onBlur)="shared_service.checkValueAutocomplete('provinciaNascita', socioForm)">

                <ng-template let-provincia pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                  </div>
                </ng-template>

              </p-autoComplete>
              <label class="pl-1" for="provinciaNascita" [ngClass]="{'custom-tab-panel': socioForm.get('provinciaNascita')?.invalid}">Provincia Nascita*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <p-autoComplete appendTo="body" formControlName="nazionalita" [suggestions]="filteredNazione" (completeMethod)="filterAutoComplete($event, 'nazioni')" field="nazionalita" [minLength]="3" (onBlur)="shared_service.checkValueAutocomplete('nazionalita', socioForm)">

                <ng-template let-nazione pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ nazione.nazionalita }}</div>
                  </div>
                </ng-template>

              </p-autoComplete>
              <label class="pl-1" for="nazionalita" [ngClass]="{'custom-tab-panel': socioForm.get('nazionalita')?.invalid}">Cittadinanza*</label>
            </span>
          </div>

          <div class="dataPickerCustom">
            <span class="p-float-label px-3 mb-6">
              <p-calendar formControlName="dataNascita" dateFormat="dd/mm/yy" inputId="dataNascita" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
              <label class="pl-1" for="dataNascita" [ngClass]="{'custom-tab-panel': socioForm.get('dataNascita')?.invalid}">Data Nascita*</label>
            </span>
          </div>

        </div>

        <!-- terza riga -->
        <div class="flex flex-wrap">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="indirizzo" formControlName="indirizzo"/>
              <label class="pl-1" for="indirizzo" [ngClass]="{'custom-tab-panel': socioForm.get('indirizzo')?.invalid}">Indirizzo*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="citta" formControlName="citta"/>
              <label class="pl-1" for="citta" [ngClass]="{'custom-tab-panel': socioForm.get('citta')?.invalid}">Città*</label>
            </span>
          </div>


          <div>
            <span class="p-float-label px-3 mb-6">
              <p-autoComplete appendTo="body" formControlName="provincia" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" showClear="true" (onBlur)="shared_service.checkValueAutocomplete('provincia', socioForm)">

                <ng-template let-provincia pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                  </div>
                </ng-template>

              </p-autoComplete>
              <label class="pl-1" for="provincia" [ngClass]="{'custom-tab-panel': socioForm.get('provincia')?.invalid}">Provincia*</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="cap" formControlName="cap"  pKeyFilter="int" maxlength="5"/>
              <label class="pl-1" for="cap" [ngClass]="{'custom-tab-panel': socioForm.get('cap')?.invalid}">CAP*</label>
            </span>
          </div>

        </div>

      </div>
    </form>


  </div>


  <div class="flex absolute top-0 right-0 mr-6 mt-8 pt-5">
    @if(isLoadingSpinner){
      <div class="pr-2 pt-2">
        <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
      </div>
    }@else {
      <p-button
        icon="pi pi-save"
        [disabled]="disableBottoneSalva() || !role_service.isAlboRole()"
        (click)="inviaDatiSocio()"
      ></p-button>
    }

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>


