<div class="backPanel border-round-3xl px-5 pt-3 mt-5 pb-5">

  <div>
    <p-fieldset legend="Ultimo Aggiornamento" class="flex justify-content-center sm:justify-content-start">

      <div class="flex flex-column sm:flex-row flex-wrap justify-content-between align-items-center sm:pr-2">
        <div class="sm:mr-8 text-center">
          <p class="text-xl font-bold">Fine Processo</p>
          <p class="text-xl">{{status?.lastRunComplete}}</p>
        </div>
        <div class="sm:mr-8 text-center">
          <p class="text-xl font-bold">Utente Esecuzione</p>
          <p class="text-xl">{{status?.lastRunUser}}</p>
        </div>
        <div class="sm:mr-8 text-center">
          <p class="text-xl font-bold">Esito</p>
          <p class="text-xl">{{status?.lastRunResult}}</p>
        </div>

        <div>
          @if(status?.running){
            <div class="pr-2 pt-2">
              <i class="pi pi-spin pi-cog" style="font-size: 2rem; color: #3a8dde"></i>
            </div>
          }@else {
            <p-button
              icon="pi pi-upload"
              (click)="startSynch()"
              [disabled]="role_service.isConsultazioneRole()"
              pTooltip="Esegui Aggiornamento Albo Nazionale"
              tooltipPosition="bottom"
            ></p-button>
          }
        </div>

      </div>

    </p-fieldset>
  </div>

</div>

<p-toast position="bottom-center"></p-toast>
