import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  userPerms: string[] = [];

  constructor(
    protected readonly r: Router,
    protected readonly k: KeycloakService
  ) {
    super(r, k);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: document.baseURI.slice(0, -1) + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // // Allow the user to proceed if all the required roles are present.
    // return requiredRoles.every((role) => this.roles.includes(role));
    // Allow the user to proceed if some the required roles are present.
    return requiredRoles.some((role) => this.roles.includes(role));

  }

}
