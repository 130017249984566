import { Component, inject, OnInit } from '@angular/core';
import { StpService } from '../../services/stp.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IscrittoSelezionabile } from '../../model/stp-data';
import { FormControl, FormGroup } from '@angular/forms';
import { IscrittiService } from '../../services/iscritti.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { IscrittoItem, RicercaIscritto } from '../../model/cnop-data';

@Component({
  selector: 'app-dialog-nuovo-professionista',
  templateUrl: './dialog-nuovo-professionista.component.html',
  styleUrl: './dialog-nuovo-professionista.component.scss'
})
export class DialogNuovoProfessionistaComponent implements OnInit{

  //services
  private iscritti_service = inject(IscrittiService);
  private stp_service = inject(StpService);
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);

  stpId = 0;

  //spinner dei bottoni
  isLoadingSpinner = false;

  //dati inserimento professionista
  lista: IscrittoItem[] = [];

  //form filtro professinista
  professionistiFiltered: IscrittoItem[] = [];

  professionistiSearch = new FormGroup({
    professionistaSelezionato: new FormControl<IscrittoItem|null>(null)
  })

  ngOnInit(): void {
    this.stpId = this.dialog_config.data.stpId;
    this.gestioneListaSelezionabili('');
  }

  inputValueAutocomplete(event: any){
    const val = event.target.value;
    this.gestioneListaSelezionabili(val);
  }

  gestioneListaSelezionabili(par: string){

    const parametro: RicercaIscritto = {
      criterio: par
    };

    this.iscritti_service.getIscrittiCnop(parametro).subscribe((result: IscrittoItem[]) => {
      this.lista = result;
      this.lista = this.lista.map(pro => ({
        ...pro,
        anagrafica: `${pro.nome}, ${pro.cognome}, ${pro.codiceFiscale}`
      }));
    });

  }

  //gestione del filtraggio dei professionisti
  filterProfessionisti(event: AutoCompleteCompleteEvent){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    this.professionistiFiltered = this.lista.filter(professionista =>
      queryParts.every(part =>
        `${professionista.nome} ${professionista.cognome} ${professionista.codiceFiscale}`.toLowerCase().includes(part)
      )
    )
  }

  //invio del nuovo professionista
  iscrizioneProfessionista(){
    const pro = this.professionistiSearch.get('professionistaSelezionato')?.value;
    this.isLoadingSpinner = true;

    setTimeout(() => {
      this.isLoadingSpinner = false;
      this.dialog_ref.close({
        pro
      })
    }, 1500);
  }

}
