import { Component, OnInit, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-unsaved-dialog',
  templateUrl: './unsaved-dialog.component.html',
  styleUrl: './unsaved-dialog.component.scss'
})
export class UnsavedDialogComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);

  unsavedData: string[] = [];

  ngOnInit(): void {
    this.unsavedData = this.dialog_config.data.unsavedMod;
  }

  close(result: boolean){
    this.dialog_ref.close(result)
  }

}

