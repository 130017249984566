@if (operazione !== 'Nuova') {

  <div class="text-center mb-5 mx-5">
    <p class="text-lg">{{ corpoDialog }}</p>
    <h2>{{ numeroDeliberaMostrato }}</h2>
  </div>

}

@if(operazione === 'Nuova'){
  <div [formGroup]="tipiDeliberaForm" class="flex flex-column">

    <div class="text-center mb-5 mx-5">
      <p class="text-lg">{{ corpoDialog }}</p>
      <div>
        <span class="p-float-label px-3 mb-6">
          <input class="px-0" pInputText pKeyFilter="int" id="numeroDelibera" formControlName="numeroDelibera"/>
        </span>
      </div>
    </div>

    <div  class="text-center mb-8">
      <p class="mr-3">Seleziona <b>tipo</b> e <b>data</b> della delibera</p>
    </div>
    <div class="flex justify-content-center">
      <div>
        <span class="p-float-label px-3 mb-6">
          <p-dropdown [options]="tipiDelibera" formControlName="tipo" optionLabel="nome" inputId="tipo" appendTo="body"></p-dropdown>
          <label class="pl-1" for="tipo">Tipo*</label>
        </span>
      </div>

      <div class="dataPickerCustom">
        <span class="p-float-label px-3">
          <p-calendar appendTo="body" formControlName="dataDelibera" dateFormat="dd/mm/yy" inputId="dataDelibera" [iconDisplay]="'input'" [showIcon]="true" class="overflow-visible"></p-calendar>
          <label class="pl-1" for="dataDelibera">Data*</label>
        </span>
      </div>
    </div>
  </div>

}

<div class="flex justify-content-center">
  @if (isLoadingSpinner) {
    <i class="pi pi-spin pi-cog" style="font-size: 3rem; color: #3a8dde"></i>
  }@else {
    <p-button [label]="labelBottone" (click)="bottoneDelibera()" [disabled]="disableSubmitHandler()"></p-button>
  }
</div>
