import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CnopService } from '../../services/cnop.service';
import { SynchProcessStatus } from '../../model/cnop-data';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, interval } from 'rxjs';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-aggiornamento-albo',
  templateUrl: './aggiornamento-albo.component.html',
  styleUrl: './aggiornamento-albo.component.scss'
})
export class AggiornamentoAlboComponent implements OnInit, OnDestroy {
  private message_service = inject(MessageService);
  public role_service = inject(RoleService);
  status?: SynchProcessStatus;
  secondsCounter: Observable<number>;
  constructor (private service: CnopService) {
    this.secondsCounter = interval(10000);
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }

  private subscribe?: Subscription ;
  ngOnInit(): void {
    this.loadStatus();

    this.subscribe = this.secondsCounter.subscribe({
      next: (value:number) => {
        this.loadStatus();
      }
    });
  }

  loadStatus() {
    this.service.getSyncStatus().subscribe({
      next: (status: SynchProcessStatus)=> {
        this.status=status;
      }
    });
  }

  startSynch() {
    this.service.startSyncFull().subscribe({
      next: (status: SynchProcessStatus)=> {
        if (status.started) {
          this.message_service.add({ severity: 'success', summary: 'Operazione completata', detail: 'Sincronizzazione avviata' })
        }
      },
      complete: ()=> {
        this.loadStatus();
      }
    });
  }

}
