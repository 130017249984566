<div class="px-3 h-full pt-5">

  @if (regione !== 'opl') {
    <div class="flex align-items-center">
      @if(isLoadingSpinner){
        <div class="pr-2 pt-2">
          <i class="pi pi-spin pi-cog" style="font-size: 2rem; color: #3a8dde"></i>
        </div>
      }@else {
        <p-button
          icon="pi pi-save"
          [disabled]="disableBottoneSalva() || role_service.isConsultazioneRole()"
          (click)="inviaDatiStp()"
          [severity]="severityButtons"
        ></p-button>
      }

      @if(!nuovoStp){
        <div class="ml-3">
          <p-menu #menu [model]="vociStampa" [popup]="true" appendTo="body"></p-menu>
          <!-- label="Stampa" -->
          <button
            pButton
            type="button"
            (click)="menu.toggle($event)"
            icon="pi pi-print"
            [severity]="severityButtons"
            [disabled]="role_service.isConsultazioneRole()"
            [severity]="severityButtons"
          ></button>
        </div>

        <div class="ml-3">
          <p-button
            icon="pi pi-user-plus"
            (click)="nuovoProfessionistaDialog()"
            [disabled]="role_service.isConsultazioneRole()"
            [severity]="severityButtons"
          ></p-button>
        </div>
      }


    </div>
  }

  <form [formGroup]="stpForm">
    <p-tabView>

      <!-- anagrafica -->
      <p-tabPanel class="boxCustom">
        <ng-template pTemplate="header">
          <span [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica')?.invalid}">Anagrafica Società</span>
        </ng-template>
        <div class="flex flex-column backPanel border-round-3xl px-5 pt-7 pb-2" formGroupName="anagrafica">

          <!-- prima riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="denominazione" formControlName="denominazione"/>
                <label class="pl-1" for="denominazione" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.denominazione')?.invalid}">Denominazione*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="codiceStp" formControlName="codiceStp"/>
                <label class="pl-1" for="codiceStp" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.codiceStp')?.invalid}">Codice STP*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="sezioneSocieta" formControlName="sezioneSocieta"/>
                <label class="pl-1" for="sezioneSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.sezioneSocieta')?.invalid}">Sezione*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="costituzioneSocieta" dateFormat="dd/mm/yy" inputId="costituzioneSocieta" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="costituzioneSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.costituzioneSocieta')?.invalid}">Costituzione*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="iscrizioneData" dateFormat="dd/mm/yy" inputId="iscrizioneData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="iscrizioneData" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.iscrizioneData')?.invalid}">Data Iscrizione*</label>
              </span>
            </div>

          </div>

          <!-- seconda riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="indirizzoSocieta" formControlName="indirizzoSocieta"/>
                <label class="pl-1" for="indirizzoSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.indirizzoSocieta')?.invalid}">Indirizzo Società*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="comuneSocieta" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3">

                  <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ comune.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="comuneSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.comuneSocieta')?.invalid}">Comune*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="provinciaSocieta" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true">

                  <ng-template let-provincia pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="provinciaSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.provinciaSocieta')?.invalid}">Provincia*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="capSocieta" formControlName="capSocieta" pKeyFilter="int" maxlength="5"/>
                <label class="pl-1" for="capSocieta" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.capSocieta')?.invalid}">CAP*</label>
              </span>
            </div>

          </div>

          <!-- terza riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="domandaProtocollo" formControlName="domandaProtocollo"/>
                <label class="pl-1" for="domandaProtocollo" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.domandaProtocollo')?.invalid}">Protocollo Domanda*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="domandaData" dateFormat="dd/mm/yy" inputId="domandaData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="domandaData" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.domandaData')?.invalid}">Data Domanda*</label>
              </span>
            </div>

          </div>

          <!-- quarta riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="notificaProtocollo" formControlName="notificaProtocollo"/>
                <label class="pl-1" for="notificaProtocollo" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.notificaProtocollo')?.invalid}">Notifica Protocollo*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="notificaProtocolloData" dateFormat="dd/mm/yy" inputId="notificaProtocolloData" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="notificaProtocolloData" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.notificaProtocolloData')?.invalid}">Not. Prot. Data*</label>
              </span>
            </div>

          </div>

          <!-- quinta riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rea" formControlName="rea"/>
                <label class="pl-1" for="rea" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.rea')?.invalid}">Rea*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="reaData" inputId="reaData" dateFormat="dd/mm/yy" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="reaData" [ngClass]="{'custom-tab-panel': stpForm.get('anagrafica.reaData')?.invalid}">Data Rea*</label>
              </span>
            </div>

          </div>

        </div>
      </p-tabPanel>

      <!-- rappresentante legale -->
      <p-tabPanel class="boxCustom">
        <ng-template pTemplate="header">
          <span [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale')?.invalid}">Rappresentante Legale</span>
        </ng-template>
        <div class="flex flex-column backPanel border-round-3xl px-5 pt-7 pb-2" formGroupName="rappresentanteLegale">

          <!-- prima riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteOrdine" formControlName="rappresentanteOrdine"/>
                <label class="pl-1" for="rappresentanteOrdine" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteOrdine')?.invalid}">Ordine*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteNumeroOrdine" formControlName="rappresentanteNumeroOrdine"/>
                <label class="pl-1" for="rappresentanteNumeroOrdine" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteNumeroOrdine')?.invalid}">Numero Ordine*</label>
              </span>
            </div>

          </div>

          <!-- seconda riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteNome" formControlName="rappresentanteNome"/>
                <label class="pl-1" for="rappresentanteNome" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteNome')?.invalid}">Nome*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteCognome" formControlName="rappresentanteCognome"/>
                <label class="pl-1" for="rappresentanteCognome" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteCognome')?.invalid}">Cognome*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteCodiceFiscale" formControlName="rappresentanteCodiceFiscale" maxlength="16" pKeyFilter="alphanum"/>
                <label class="pl-1" for="rappresentanteCodiceFiscale" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteCodiceFiscale')?.invalid}">Codice Fiscale*</label>
              </span>
            </div>

          </div>

          <!-- seconda riga -->
          <div class="flex flex-wrap">

            <!-- <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="rappresentanteLuogoNascita" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3">

                  <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ comune.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="rappresentanteLuogoNascita" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteLuogoNascita')?.invalid}">Luogo Nascita*</label>
              </span>
            </div> -->

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteLuogoNascita" formControlName="rappresentanteLuogoNascita"/>
                <label class="pl-1" for="rappresentanteLuogoNascita" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteLuogoNascita')?.invalid}">Luogo Nascita*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="rappresentanteProvinciaNascita" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2" [showClear]="true">

                  <ng-template let-provincia pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="rappresentanteProvinciaNascita" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteProvinciaNascita')?.invalid}">Provincia Nascita*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="rappresentanteNazionalita" [suggestions]="filteredNazione" (completeMethod)="filterAutoComplete($event, 'nazioni')" field="nome" [minLength]="3">

                  <ng-template let-nazione pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ nazione.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="rappresentanteNazionalita" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteNazionalita')?.invalid}">Cittadinanza*</label>
              </span>
            </div>

            <div class="dataPickerCustom">
              <span class="p-float-label px-3 mb-6">
                <p-calendar formControlName="rappresentanteDataNascita" dateFormat="dd/mm/yy" inputId="rappresentanteDataNascita" [iconDisplay]="'input'" [showIcon]="true"></p-calendar>
                <label class="pl-1" for="rappresentanteDataNascita" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteDataNascita')?.invalid}">Data Nascita*</label>
              </span>
            </div>

          </div>

          <!-- terza riga -->
          <div class="flex flex-wrap">

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteIndirizzo" formControlName="rappresentanteIndirizzo"/>
                <label class="pl-1" for="rappresentanteIndirizzo" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteIndirizzo')?.invalid}">Indirizzo*</label>
              </span>
            </div>

            <!--<div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="rappresentanteCitta" [suggestions]="filteredComuni" (completeMethod)="filterAutoComplete($event, 'comuni')" field="nome" [minLength]="3">

                  <ng-template let-comune pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ comune.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="rappresentanteCitta" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteCitta')?.invalid}">Comune*</label>
              </span>

            </div>-->

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteCitta" formControlName="rappresentanteCitta"/>
                <label class="pl-1" for="rappresentanteCitta" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteCitta')?.invalid}">Città*</label>
              </span>
            </div>


            <div>
              <span class="p-float-label px-3 mb-6">
                <p-autoComplete appendTo="body" formControlName="rappresentanteProvincia" [suggestions]="filteredProvince" (completeMethod)="filterAutoComplete($event, 'province')" field="nome" [minLength]="2">

                  <ng-template let-provincia pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ provincia.codice }} - {{ provincia.nome }}</div>
                    </div>
                  </ng-template>

                </p-autoComplete>
                <label class="pl-1" for="rappresentanteProvincia" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteProvincia')?.invalid}">Provincia*</label>
              </span>
            </div>

            <div>
              <span class="p-float-label px-3 mb-6">
                <input class="px-0" pInputText id="rappresentanteCap" formControlName="rappresentanteCap"  pKeyFilter="int" maxlength="5"/>
                <label class="pl-1" for="rappresentanteCap" [ngClass]="{'custom-tab-panel': stpForm.get('rappresentanteLegale.rappresentanteCap')?.invalid}">CAP*</label>
              </span>
            </div>

          </div>

        </div>
      </p-tabPanel>

      @if (!nuovoStp) {
        <!-- professionista -->
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span>Professionisti</span>
          </ng-template>
          <div class="flex flex-column border-round-3xl pt-1 pb-2">

            <!-- controlla se mantenere o meno -->
            <!-- <app-tabella-iscritti class="tab" [data]="listaIscrittiStp"></app-tabella-iscritti> -->

            <p-table [value]="listaMembri" [tableStyle]="{ 'min-width': '50rem' }">
              <ng-template pTemplate="header">
                  <tr>
                      @for (header of headersMembri; track $index) {
                        <th>{{ header }}</th>
                      }
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-membro>
                  <tr>
                      @for (row of rowsMembri; track $index) {
                        <td>{{ membro[row] }}</td>
                      }
                  </tr>
              </ng-template>
          </p-table>

          </div>
        </p-tabPanel>
      }

    </p-tabView>
  </form>

  @if (regione === 'opl') {
    <div class="flex absolute top-0 right-0 mr-6 mt-8 pt-5">
      @if(isLoadingSpinner){
        <div class="pr-2 pt-2">
          <i class="pi pi-spin pi-cog" style="font-size: 2rem; color: #3a8dde"></i>
        </div>
      }@else {
        <p-button
          icon="pi pi-save"
          [disabled]="disableBottoneSalva() || role_service.isConsultazioneRole()"
          (click)="inviaDatiStp()"
        ></p-button>
      }

      @if(!nuovoStp){
        <div class="ml-3">
          <p-menu #menu [model]="vociStampa" [popup]="true" appendTo="body"></p-menu>
          <!-- label="Stampa" -->
          <button
            pButton
            type="button"
            (click)="menu.toggle($event)"
            icon="pi pi-print"
            [severity]="severityButtons"
            [disabled]="role_service.isConsultazioneRole()"
          ></button>
        </div>

        <div class="ml-3">
          <p-button
            icon="pi pi-user-plus"
            (click)="nuovoProfessionistaDialog()"
            [disabled]="role_service.isConsultazioneRole()"
          ></p-button>
        </div>
      }


    </div>
  }


  <p-toast position="bottom-center"></p-toast>

</div>
