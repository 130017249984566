import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Telefono, Iscritto, StatoIscritto, PagamentoMezzo, Prefisso } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { StpService } from '../../services/stp.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { QuoteService } from '../../services/quote.service';
import { IscrittoSelezionabile } from '../../model/stp-data';

@Component({
  selector: 'app-dialog-dinamico',
  templateUrl: './dialog-dinamico.component.html',
  styleUrl: './dialog-dinamico.component.scss'
})
export class DialogDinamicoComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private stp_service = inject(StpService);
  private iscritti_service = inject(IscrittiService);
  private quote_service = inject(QuoteService);

  private isChangingFromCode = false;
  prefissi: Prefisso[] = [];
  filteredPrefisso: Prefisso[] = [];

  //spinner dei bottoni
  isLoadingSpinner = false;

  //tipo di dialog
  tipo: string = '';

  //dati eliminazione professionista
  nomeProfessionista: string = '';
  nomeStp: string = '';

  //dati del bottone submit
  labelBottone: string = '';
  classeBottone: string = '';

  //numero da eliminare
  telefonoEliminazione: Telefono | undefined;

  //professionista da eliminare
  professionista: Iscritto | undefined;

  //form del numero
  telefonoForm = new FormGroup({
    prefisso: new FormControl<Prefisso|null>(null, Validators.required),
    numero: new FormControl<string|null>(null, Validators.required),
    tipo: new FormControl<string|null>(null),
    descrizione: new FormControl<string|null>(null)
  })

  //form pagamento
  pagamentoForm = new FormGroup({
    data: new FormControl<Date|null>(null, Validators.required),
    pagamentoMezzo: new FormControl<PagamentoMezzo|null>(null, Validators.required),
    importo: new FormControl<number|null>(null, Validators.required)
  })

  pagamentiOptions: PagamentoMezzo[] = [];

  //gestione stato iscritto
  stati: StatoIscritto[] = [
    { id: 1, nome: 'ATTIVO', codiceCnop: 'A' },
    { id: 2, nome: 'SOSPESO', codiceCnop: 'S' },
    { id: 3, nome: 'TRASFERIMENTO', codiceCnop: 'T' },
    { id: 4, nome: 'CANCELLATO', codiceCnop: 'C' },
    { id: 5, nome: 'RADIATO', codiceCnop: 'R' }
  ]

  statoForm = new FormGroup({
    statoIscritto: new FormControl<StatoIscritto|null>(null, Validators.required),
    dataForm: new FormControl<Date|null>(null, Validators.required)
  })

  statoCorrente: string = '';
  dataFormCorrente: Date | undefined;

  //rimuove professionista da stp
  nomeIscritto: string = '';
  idIscritto: number = 0;

  ngOnInit(): void {
    this.tipo = this.dialog_config.data.tipo;
    this.gestisciDati(this.tipo ,this.dialog_config.data);

    this.statoForm.get('statoIscritto')!.valueChanges.subscribe(() => this.gestisciStatoIscritto());
  }

  //gestisce l'esito del dialog
  gestisciTipoDialog(){

    this.isLoadingSpinner = true;
    let elemento: any = undefined;

    if(this.tipo === 'aggiungiTelefono'){
      const t = this.telefonoForm.value;
      elemento = {
        prefisso: t.prefisso,
        numero: t.numero,
        tipo: t.tipo,
        descrizione: t.descrizione
      }
    }

    if(this.tipo === 'eliminazioneTelefono') elemento = this.telefonoEliminazione?.id;
    if(this.tipo === 'rimuoviProfessionista') elemento = this.professionista?.id;
    if(this.tipo === 'ripristinaIscritto') elemento = this.idIscritto;

    if(this.tipo === 'inserisciPagamento'){
      const p = this.pagamentoForm.value;
      elemento = {
        data: p.data,
        pagamentoMezzo: p.pagamentoMezzo,
        importo: Math.abs(p.importo!)
      }
    }

    if(this.tipo === 'modificaStato'){
      const si = this.statoForm.value;
      elemento = {
        statoIscritto: si.statoIscritto,
        dataForm: si.dataForm
      }
    }

    setTimeout(() => {
      this.dialog_ref.close({
        elemento
      })
    }, 1500);

  }

  //gestisce i dati del dialog
  private gestisciDati(tipo: string, data: any){

    if(tipo === 'aggiungiTelefono'){
      this.labelBottone = 'Salva';
      this.iscritti_service.getPrefissi().subscribe(result => {
        this.prefissi = result;
      });
      console.log(this.prefissi)
    }

    if(tipo === 'eliminazioneTelefono'){
      this.labelBottone = 'Elimina';
      this.telefonoEliminazione = data.elemento;
      this.classeBottone = 'denyButton';
    }

    if(tipo === 'rimuoviProfessionista'){
      this.stp_service.getStp(data.stpId).subscribe(result => {
        this.labelBottone = 'Rimuovi';
        this.classeBottone = 'denyButton';
        this.professionista = data.elemento;
        this.nomeProfessionista = `${data.elemento.nome} ${data.elemento.cognome}`;
        this.nomeStp = result.denominazione;
      })
    }

    if(tipo === 'inserisciPagamento'){
      this.labelBottone = 'Inserisci';
      this.quote_service.getPagamentiOptions().subscribe(result => this.pagamentiOptions = result);
    }


    if(this.tipo === 'modificaStato'){
      this.labelBottone = 'Modifica';
      this.statoCorrente = (data.elemento) ? data.elemento.nome : '';
      this.dataFormCorrente = (data.elemento) ? data.elemento.dataForm : null;

      this.statoForm.patchValue({
        statoIscritto: data.elemento,
        dataForm: data.dataForm
      })

    }

    if(tipo === 'ripristinaIscritto'){
      this.labelBottone = 'Ripristina';
      this.nomeIscritto = `${data.elemento.nome} ${data.elemento.cognome}`
      this.idIscritto = data.elemento.id;
    }

  }

  //gestisce stato corrente
  gestoreStato(): boolean{
    const selectedValue = this.statoForm.get('statoIscritto')?.value?.nome;

    if(
      this.statoCorrente === 'SOSPESO' ||
      this.statoCorrente === 'CANCELLATO' ||
      this.statoCorrente === 'RADIATO' ||
      (this.statoCorrente === 'SOSPESO' && selectedValue === 'ATTIVO') ||
      (this.statoCorrente === 'ATTIVO' && selectedValue === 'SOSPESO') ||
      (this.statoCorrente === 'ATTIVO' && selectedValue === 'CANCELLATO') ||
      (this.statoCorrente === 'ATTIVO' && selectedValue === 'RADIATO') ||
      (this.statoCorrente === '' && selectedValue === 'SOSPESO') ||
      (this.statoCorrente === '' && selectedValue === 'CANCELLATO') ||
      (this.statoCorrente === '' && selectedValue === 'RADIATO') ||
      (this.statoCorrente === 'TRASFERIMENTO' && selectedValue === 'SOSPESO') ||
      (this.statoCorrente === 'TRASFERIMENTO' && selectedValue === 'CANCELLATO') ||
      (this.statoCorrente === 'TRASFERIMENTO' && selectedValue === 'RADIATO')
    )return true;

    return false;
  }

  //gestisce stringa data in base allo stato corrente
  gestoreStatoStringa(): string{
    let str = '';

    const selectedValue = this.statoForm.get('statoIscritto')?.value?.nome;

    if(selectedValue === 'ATTIVO') str = 'Sospeso al';
    if(selectedValue === 'SOSPESO') str = 'Sospeso dal';
    if(selectedValue === 'CANCELLATO') str = 'In data';
    if(selectedValue === 'RADIATO') str = 'In data';

    return str;
  }

  //gestisce le casistiche dello stato iscritto al cambio del dropdown
  private gestisciStatoIscritto(){

    if(this.statoForm.get('statoIscritto')?.value?.nome === 'TRASFERIMENTO'){
      this.statoForm.get('dataForm')?.clearValidators();
      this.statoForm.get('dataForm')?.updateValueAndValidity();
      this.isChangingFromCode = false;
    }

    if(
      this.statoCorrente === 'TRASFERIMENTO' &&
      this.statoForm.get('statoIscritto')?.value?.nome === 'ATTIVO'
    ){
      this.statoForm.get('dataForm')?.clearValidators();
      this.statoForm.get('dataForm')?.updateValueAndValidity();
      this.isChangingFromCode = false;
    }

    if(
      !this.isChangingFromCode &&
      this.statoCorrente === 'SOSPESO' &&
      this.statoForm.get('statoIscritto')?.value?.nome === 'ATTIVO' &&
      this.statoForm.get('dataForm')?.value !== null
    ){
      this.isChangingFromCode = true;
      this.statoForm.patchValue({
        dataForm: null
      });
      this.isChangingFromCode = false;
    }

    if(
      !this.isChangingFromCode &&
      this.statoCorrente === 'SOSPESO' &&
      this.statoForm.get('statoIscritto')?.value?.nome === 'SOSPESO'
    ){
      this.isChangingFromCode = true;
      this.statoForm.patchValue({
        dataForm: this.dataFormCorrente
      });
      this.isChangingFromCode = false;
    }

    if(
      !this.isChangingFromCode &&
      this.statoCorrente === ''
    ){
      this.isChangingFromCode = true;
      if(this.statoForm.get('statoIscritto')?.value?.nome === 'ATTIVO' || this.statoForm.get('statoIscritto')?.value?.nome === 'TRASFERIMENTO'){
        this.statoForm.get('dataForm')?.clearValidators();
        this.statoForm.get('dataForm')?.updateValueAndValidity();
      }
      this.isChangingFromCode = false;
    }

  }

  //autocompletamento per i p-autocomplete
  filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'prefisso'){
      this.filteredPrefisso = this.prefissi.filter(prefisso =>
        queryParts.every(part =>
            prefisso.nazione.toLowerCase().includes(part)
        )
      );
    }
  }

}
