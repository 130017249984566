<div>

  <!-- CARDS -->
  <div class="grid m-0 mb-3 mt-5">
    @for (card of cards; track $index) {
      <div class="col px-1" [routerLink]="card.routeString" style="cursor: pointer;">
        <div class="flex align-items-center p-4 border-round-lg shadow-6 customBorder">
          @if (cardsLoading) {
            <div class="ml-1 mr-5">
              <p-skeleton shape="circle" width="4.26rem" height="4.26rem" />
            </div>
            <div class="flex flex-column">
              <div class="mb-1">
                <p-skeleton width="4.26rem" height="2rem" />
              </div>
              <div>
                <p-skeleton width="4.26rem" height="1.2rem" />
              </div>
            </div>
          }@else {
            <div class="ml-1 mr-5">
              <i class="{{ card.icon }} p-4 border-circle" [style]="card.stile"></i>
            </div>
            <div>
              <p class="font-bold text-2xl mb-2">{{ card.value }}</p>
              <p class="text-lg white-space-nowrap">{{ card.nome }}</p>
            </div>
          }
        </div>
      </div>
    }
  </div>

  <!-- DATA & CHARTS -->
  <div class="grid m-0">

    <div class="col-12 xl:col-6 flex flex-column justify-content-between">
      <div class="border-round-lg shadow-6 customBorder p-4">
        <p class="text-center text-2xl font-semibold mb-3">Albo Riepilogo</p>
        <div class="p-3">
          <p-table *ngIf="!yearsLoading" [value]="riepiloghi" [tableStyle]="{ 'min-height': '25.3rem' }">
            <ng-template pTemplate="header">
                <tr class="text-sm">
                    <th width="5%" class="text-center text-white {{ checkRegione() }} border-round-top-xl px-3 pt-3"><i class="pi pi-calendar text-xl"></i></th>
                    <th class="text-center">Tot. Iscritti</th>
                    <th class="text-center">Sezione A</th>
                    <th class="text-center">Sezione B</th>
                    <th class="text-center">Sospesi</th>
                    <th class="text-center">STP</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-riepilogo>
                <tr class="text-lg">
                    <td class="px-3 text-center text-white {{ checkRegione() }} {{ riepilogo.anno === annoPiccolo ? 'border-round-bottom-xl' : '' }}">
                      {{ riepilogo.anno }}
                    </td>
                    <td class="text-center">{{ riepilogo.totaleIscritti }}</td>
                    <td class="text-center">{{ riepilogo.iscrittiSezioneA }}</td>
                    <td class="text-center">{{ riepilogo.iscrittiSezioneB }}</td>
                    <td class="text-center">{{ riepilogo.sospesi }}</td>
                    <td class="text-center">{{ riepilogo.stp }}</td>
                </tr>
            </ng-template>
          </p-table>

          <div *ngIf="yearsLoading" class="pt-2">
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.7rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="100%" height="1.6rem" styleClass="mb-3"></p-skeleton>
          </div>

        </div>
      </div>
    </div>

    <div class="col-6 hidden xl:block">
      <div class="backroundBlue border-round-lg shadow-6 customBorder py-4">
        <div class="pt-2 mb-5">
          <p class="text-center text-2xl font-semibold">Analitica Iscritti Albo Psicologi {{ checkRegioneLabel() }}</p>
        </div>
        <div class="flex justify-content-center py-2">

          <!-- grafico sesso -->
          <div class="flex justify-content-center mr-4">
            <p-chart type="doughnut" [data]="dataSesso" [options]="optionsSesso" width="14vw" height="397px"></p-chart>
          </div>

          <!-- grafico età -->
          <div class="flex justify-content-center ml-4">
            <p-chart type="doughnut" [data]="dataEta" [options]="optionsEta" width="14.3vw" height="397px"></p-chart>
          </div>

        </div>

      </div>
    </div>

    <div class="col-12 xl:hidden">
      <div class="backroundBlue border-round-lg shadow-6 customBorder py-4">
        <div class="pt-2 mb-5">
          <p class="text-center text-2xl font-semibold">Analitica Iscritti Albo Psicologi {{ checkRegioneLabel() }}</p>
        </div>
        <div class="flex justify-content-center py-2">

          <!-- grafico sesso -->
          <div class="flex justify-content-center mr-4">
            <p-chart type="doughnut" [data]="dataSesso" [options]="optionsSesso" width="30vw" height="398px"></p-chart>
          </div>

          <!-- grafico età -->
          <div class="flex justify-content-center ml-4">
            <p-chart type="doughnut" [data]="dataEta" [options]="optionsEta" width="30vw" height="398px"></p-chart>
          </div>

        </div>

      </div>
    </div>

  </div>

  @if(regione === 'opl'){
    <div class="grid m-0">
      <div class="col-12">
        <div class="backroundBlue border-round-lg shadow-6 customBorder py-4">
          <div class="pt-2 mb-5">
            <p class="text-center text-2xl font-semibold">Analitica Informazioni Iscritti</p>
          </div>
          <div class="flex justify-content-center py-2">

            <!-- grafico titoli -->
            <div class="flex justify-content-center mr-4">
              <p-chart type="doughnut" [data]="dataTitoli" [options]="optionsTitoli" width="30vw" height="398px"></p-chart>
            </div>

            <!-- grafico età -->
            <div class="flex justify-content-center ml-4">
              <p-chart type="doughnut" [data]="dataRegioni" [options]="optionsRegioni" width="30vw" height="398px"></p-chart>
            </div>

          </div>

        </div>
      </div>
    </div>
  }


  <p-toast position="bottom-center"></p-toast>

</div>
