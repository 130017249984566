import { Component, inject, Input, OnInit } from '@angular/core';
import { cardData, DashboardCardsDto, ElementoCard } from '../../model/dashboard-data';
import { RoleService } from '../../services/role.service';


@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrl: './dashboard-card.component.scss'
})
export class DashboardCardComponent implements OnInit{

  // services
  private role_service = inject(RoleService);

  // data in input
  @Input('data')
  cardData: cardData = {
    id: undefined,
    type: undefined,
    value: undefined
  };

  // dati della card
  card: ElementoCard = {
    id: 0,
    icon: '',
    nome: '',
    stile: '',
    value: 0,
    routeString: ''
  }

  // loadre dello skeleton
  cardsLoading: boolean = true;


  ngOnInit(): void {
    this.handleCardType();
  }

  private handleCardType(){

    this.card.id = this.cardData.id!;
    this.card.stile = 'color: white; font-size: 1.3em;';
    this.card.value = this.cardData.value!;

    switch(this.cardData.type){

      case 'provvisori':
        this.card.icon = 'pi pi-users';
        this.card.nome = 'Provvisori';
        this.card.stile += ' background-color: #FEA702';
        this.card.routeString = this.role_service.isAlboOrConsultazione() ? '/gestione_albo/provvisori' : '';
        break;

      case 'morosità':
        this.card.icon = 'pi pi-money-bill';
        this.card.nome = 'Morosità';
        this.card.stile += ' background-color: #CD2028';
        this.card.routeString = this.role_service.isContabilitaOrConsultazione() ? 'contabilita_iscritti/moroso' : '';
        break;

      case 'trasferimento':
        this.card.icon = 'pi pi-arrow-right-arrow-left';
        this.card.nome = 'Trasferimento';
        this.card.stile += ' background-color: #53C7A8';
        this.card.routeString = this.role_service.isAlboOrConsultazione() ? 'gestione_albo/gestione_iscritti/trasferimento' : '';
        break;

      case 'sospesi':
        this.card.icon = 'pi pi-clock';
        this.card.nome = 'Sospesi';
        this.card.stile += ' background-color: #EBA79A';
        this.card.routeString = this.role_service.isAlboOrConsultazione() ? 'gestione_albo/gestione_iscritti/sospeso' : '';
        break;

    }

  }

}
