import { Injectable } from '@angular/core';
import { SearchFilters, TableSettings } from '../model/filters-data';

@Injectable({
  providedIn: 'root'
})
export class CustomFiltersService {

  baseFilters: SearchFilters = {
    orderColumn: '',
    order: '',
    page: 0,
    pageSize: 25,
    currentPage: 0,
    inserimento: '',
    stato: '',
    sezione: '',
    tipoLista: '',
    residenzaProvincia: '',
    residenzaComune: '',
    domicilioProvincia: '',
    domicilioComune: '',
    annoNascita: null,
    annoNascitaAl: null,
    annoIscrizione: null,
    annoIscrizioneAl: null,
    titoli: '',
    situazione: '',
    annoMorositaDal: null,
    annoMorositaAl: null,
    numeroAnniMorositaDa: null,
    numeroAnniMorositaAl: null,
    numeroDelibera: undefined,
    tipoDelibera: '',
    chiusa: false,
    finalizzata: false,
    statoIscrizione: ''
  }

  //gestisce i filtri del componente
  handleFilterByComponent(componenete: string): SearchFilters{

    const data = this.getDataFromSessionStorage();

    if(data && data[componenete]){
      return data[componenete];
    }

    if(data && !data[componenete]){
      const newFilter = this.createFilter(componenete);
      data[componenete] = newFilter;
      sessionStorage.setItem('data', JSON.stringify(data));
      return newFilter;
    }

    if(!data){
      let newData: {[key: string]: {}} = {};
      const newFilter = this.createFilter(componenete);
      newData[componenete] = newFilter;
      sessionStorage.setItem('data', JSON.stringify(newData));
      return newFilter;
    }

    throw new Error('Errore nella gestione dei filtri.');

  }

  //salvataggio in session storage dai componenti
  sessionStorageUpdate(values: any, componente: string, tabSett: TableSettings): SearchFilters{

    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[componente].inserimento = values.inserimento;
    sessionData[componente].stato = values.stato?.codiceCnop ? values.stato?.codiceCnop : '';

    if(['provvisori_a', 'psi_a', 'provvisori_a_finalizza', 'psi_a_finalizza'].includes(componente))
      sessionData[componente].sezione = 'A';
    else if(['provvisori_b', 'provvisori_b_finalizza'].includes(componente))
      sessionData[componente].sezione = 'B';
    else
      sessionData[componente].sezione = values.sezione?.valore ? values.sezione?.valore : '';

    sessionData[componente].orderColumn = tabSett.orderColumnName;
    sessionData[componente].order = tabSett.order;
    sessionData[componente].page = tabSett.page;
    sessionData[componente].pageSize = tabSett.pageSize;
    sessionData[componente].currentPage = tabSett.currentPage;

    const rp = values.residenzaProvincia;
    sessionData[componente].residenzaProvincia = rp ? rp?.codice : '';

    const rc = values.residenzaComune;
    sessionData[componente].residenzaComune = rc?.codice_istat ? rc?.codice_istat : '';

    const dp = values.domicilioProvincia;
    sessionData[componente].domicilioProvincia = dp ? dp.codice : '';

    const dc = values.domicilioComune;
    sessionData[componente].domicilioComune = dc?.codice_istat ? dc?.codice_istat : '';

    sessionData[componente].annoNascita = values.annoNascita ? values.annoNascita : null;
    sessionData[componente].annoNascitaAl = values.annoNascitaAl ? values.annoNascitaAl : null;

    sessionData[componente].annoIscrizione = values.annoIscrizione ? values.annoIscrizione : null;
    sessionData[componente].annoIscrizioneAl = values.annoIscrizioneAl ? values.annoIscrizioneAl : null;

    sessionData[componente].titoli = values.titoli ? values.titoli.id.toString() : '';

    sessionData[componente].situazione = values.situazione ? values.situazione.valore : '';
    sessionData[componente].annoMorositaDal = values.annoMorositaDal ? values.annoMorositaDal : null;
    sessionData[componente].annoMorositaAl = values.annoMorositaAl ? values.annoMorositaAl : null;
    sessionData[componente].numeroAnniMorositaDa = values.numeroAnniMorositaDa ? values.numeroAnniMorositaDa : null;
    sessionData[componente].numeroAnniMorositaAl = values.numeroAnniMorositaAl ? values.numeroAnniMorositaAl : null;

    sessionData[componente].numeroDelibera = values.numeroDelibera;
    sessionData[componente].tipoDelibera = values.tipoDelibera?.codice ? values.tipoDelibera?.codice : '';

    if(['delibereChiuse'].includes(componente))
      sessionData[componente].chiusa = true;
    else
      sessionData[componente].chiusa = values.chiusa ? true : false;

    if(['delibereFinalizzate', 'delibereChiuse'].includes(componente))
      sessionData[componente].finalizzata = true;
    else
      sessionData[componente].finalizzata = values.finalizzata ? true : false;

    sessionData[componente].statoIscrizione = '';

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));

    return sessionData[componente];

  }

  //verifica genericamente se data esiste e lo rende stringa
  private getDataFromSessionStorage(){
    const data = sessionStorage.getItem('data');
    return data ? JSON.parse(data) : null;
  }

  // creazione di un nuovo filtro in base al componente
  private createFilter(componente: string): SearchFilters{

    const newFilter = this.baseFilters;
    newFilter.tipoLista = componente;

    switch(componente){

      case 'iscritti':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'provvisori':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'provvisori_a': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'A';
        break;
      }

      case 'psi_a': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'A';
        break;
      }

      case 'provvisori_b': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'B';
        break;
      }

      case 'provvisori_a_finalizza': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'A';
        break;
      }

      case 'provvisori_b_finalizza': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'B';
        break;
      }

      case 'psi_a_finalizza': {
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        newFilter.sezione = 'A';
        break;
      }

      case 'cancellati':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'stpList':{
        newFilter.orderColumn = 'codiceStp';
        newFilter.order = 'ASC';
        break;
      }

      case 'stpFilters':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'delibere':{
        newFilter.orderColumn = 'dataDelibera';
        newFilter.order = 'DESC';
        break;
      }

      case 'delibereFinalizzate':{
        newFilter.orderColumn = 'dataDelibera';
        newFilter.order = 'DESC';
        newFilter.finalizzata = true;
        break;
      }

      case 'delibereChiuse':{
        newFilter.orderColumn = 'dataDelibera';
        newFilter.order = 'DESC';
        newFilter.chiusa = true;
        newFilter.finalizzata = true;
        break;
      }

      case 'contabile':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'contabile-cancellati':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'contabile-stp':{
        newFilter.orderColumn = 'codiceFiscale';
        newFilter.order = 'ASC';
        break;
      }

      case 'gestione_domande_iscrizione':{
        newFilter.orderColumn = 'domandaProtocollo';
        newFilter.order = 'ASC';
        break;
      }

      case 'storico_domande_iscrizione':{
        newFilter.orderColumn = 'domandaProtocollo';
        newFilter.order = 'ASC';
        break;
      }

      default:{
        break;
      }

    }

    return newFilter;

  }



}
