import { Component, inject, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit{

  sidebarMenu: MenuItem[] | undefined;

  private role_service = inject(RoleService);

  ngOnInit(): void {

    this.sidebarMenu = [
      {
        label: '<span class="line-height-3">Dashboard</span>',
        escape: false,
        icon: 'pi pi-th-large',
        routerLink: [''],
        routerLinkActiveOptions: { exact: true }
      },
      ...(this.role_service.isAlboOrConsultazione() ? [
        {
          label: '<span class="line-height-3">Gestione Albo</span>',
          escape: false,
          icon: 'pi pi-users',
          items: [
            {
              label: '<span class="line-height-3">Gestione Iscritti</span>',
              escape: false,
              routerLink: ['gestione_albo/gestione_iscritti/iscritti'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Provvisori</span>',
              escape: false,
              routerLink: ['gestione_albo/provvisori'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Società ( STP )</span>',
              escape: false,
              routerLink: ['gestione_albo/stp'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Cancellati</span>',
              escape: false,
              routerLink: ['gestione_albo/cancellati'],
              routerLinkActiveOptions: { exact: true }
            }
          ]
        }
      ] : []),
      ...(this.role_service.isAlboOrisIscrizioniOrisConsultazione() ? [
        {
          label: '<span class="line-height-3">Gestione Provvisori</span>',
          escape: false,
          icon: 'pi pi-file-edit',
          items: [
            {
              label: '<span class="line-height-3">Gestione</span>',
              escape: false,
              routerLink: ['delibere/gestione'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Finalizzazione</span>',
              escape: false,
              routerLink: ['delibere/finalizzazione'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Chiusi</span>',
              escape: false,
              routerLink: ['delibere/chiuse'],
              routerLinkActiveOptions: { exact: true }
            }
          ]
        }
      ] : []),
      ...(this.role_service.isIscrizioniOrisConsultazioneOrisDeontologico() ? [{
        label: '<span class="line-height-3">Gestione Procedimenti</span>',
        escape: false,
        icon: 'pi pi-inbox',
        iconClass: 'text-lg mr-4 ml-3'
      }] : []),
      ...(this.role_service.isContabilitaOrisConsultazione() ? [
        {
          label: '<span class="line-height-3">Contabilità</span>',
          escape: false,
          icon: 'pi pi-dollar',
          items: [
            {
              label: '<span class="line-height-3">Contab. Iscritti</span>',
              escape: false,
              routerLink: ['contabilita-iscritti/iscritti'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Cancellati & Radiati</span>',
              escape: false,
              routerLink: ['contabilita-iscritti-cancellati/iscritti'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Applica Spese Amministrative</span>',
              escape: false,
              routerLink: ['contabilita/spese-amministrative'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Genera IUV</span>',
              escape: false,
              routerLink: ['contabilita/genera-iuv'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: '<span class="line-height-3">Importa Pagamenti</span>',
              escape: false,
              routerLink: ['contabilita/import-pagamenti'],
              routerLinkActiveOptions: { exact: true }
            }
          ]
        }
      ] : []),
      ...(this.role_service.isAlboOrConsultazione() ? [
        {
          label: '<span class="line-height-3">Aggiornamento albo nazionale</span>',
          escape: false,
          icon: 'pi pi-sync',
          iconClass: 'text-lg mr-4 ml-3',
          routerLink: ['aggiornamento-nazionale'],
          routerLinkActiveOptions: { exact: true }
        }
      ] : []),
      ...(this.role_service.isIscrizioniOrisConsultazione() ? [
        {
          label: '<span class="line-height-3">Import iscritti provvisori</span>',
          escape: false,
          icon: 'pi pi-download',
          iconClass: 'text-lg mr-4 ml-3',
          routerLink: 'import-iscritti',
          routerLinkActiveOptions: { exact: true }
        }
      ] : []),
      ...(this.role_service.isConfigurazioneRole() ? [
        {
          label: '<span class="line-height-3">Configurazione</span>',
          escape: false,
          icon: 'pi pi-cog',
          iconClass: 'text-lg mr-4 ml-3',
          routerLink: 'configurazione',
          routerLinkActiveOptions: { exact: true }
        }
      ] : [])
    ]
  }

}
