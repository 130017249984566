import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Articolo26, Comune, GruppoValore, Iscritto, IscrittoDropdownData, ListaQuote, Nazione, Prefisso, Provincia, Quota, Regione, Abilitazione, StatoIscritto, Telefono, Titolo, Universita, CTU, Ordine, AuditLog } from '../../../model/iscritti-data';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IscrittiService } from '../../../services/iscritti.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogDinamicoComponent } from '../../dialog-dinamico/dialog-dinamico.component';
import { UnsavedService } from '../../../services/unsaved.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { environment } from '../../../environment/environment';
import { FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import { SharedService } from '../../../services/shared.service';
import { CnopService } from '../../../services/cnop.service';
import { DialogCambioStatoComponent } from '../../dialog-cambio-stato/dialog-cambio-stato.component';
import { PrintService } from '../../../services/print.service';
import { TabView, TabViewChangeEvent } from 'primeng/tabview';
import { TableRowCollapseEvent } from 'primeng/table';
import { RoleService } from '../../../services/role.service';
import { DomandeService } from '../../../services/domande.service';

@Component({
  selector: 'app-dettaglio-iscritto',
  templateUrl: './dettaglio-iscritto.component.html',
  styleUrl: './dettaglio-iscritto.component.scss'
})
export class DettaglioIscrittoComponent implements OnInit{

  annotazioni = '';

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  severityButtons: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined = null;

  immagineIscritto: any;

  fileToUpload: File | undefined;
  cambioFotoDialog: boolean = false;

  statoIscrittoReadonly = true;

  codiceFiscaleFlag = false;

  modificaInCorsoFlag = false;

  ultimoNumeroIscrizione: number | undefined;

  flagSoggiorno: boolean = false;
  flagSoggiornoIllimitato: boolean = false;
  flagScadenzaSoggiorno: boolean = false;

  //services
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private dialog_service = inject(DialogService);
  private cnop_service = inject(CnopService);
  private unsaved_service = inject(UnsavedService);
  public shared_service = inject(SharedService);
  private print_service = inject(PrintService);
  public role_service = inject(RoleService);
  public domande_service = inject(DomandeService);

  ref: DynamicDialogRef | undefined;
  checkUnsavedChanges = false;

  text: string | undefined;

  //nuovo iscritto route flag
  nuovoIscritto: boolean | null = null;
  nuovoProvvisorio: boolean | null = null;

  //spinner dei bottoni
  isLoadingSpinner = false;
  syncSpinner = false;

  //id iscritto
  currentId = 0;

  //informazioni iniziali iscritto (se non nuovo)
  infoOriginali: Iscritto | null = null;
  quoteOriginali: Quota[] = [];
  dataConfrontoOriginale: any;

  //dati dei dropdown
  regioniData: Regione[] = [];
  nazioni: Nazione[] = [];
  province: Provincia[] = [];
  comuni: Comune[] = [];
  articolo26Data: Articolo26[] = [];
  CTUData: CTU[] = [];
  abilitazioneData: Abilitazione[] = [];
  titoloData: Titolo[] = [];
  universitaData: Universita[] = [];
  prefissoData: Prefisso[] = [];
  oridiniData: Ordine[] = [];

  stati: StatoIscritto[] = [
    { id: 1, nome: 'ATTIVO', codiceCnop: 'A' },
    { id: 2, nome: 'SOSPESO', codiceCnop: 'S' },
    { id: 3, nome: 'TRASFERIMENTO', codiceCnop: 'T' },
    { id: 4, nome: 'CANCELLATO', codiceCnop: 'C' },
    { id: 5, nome: 'RADIATO', codiceCnop: 'R' }
  ]

  stato_provvisorio: StatoIscritto = { id: 6, nome: 'PROVVISORIO', codiceCnop: 'P' };
  ordine_lombardia: Ordine = { id: 11, nome: 'Lombardia'};

  sessi: GruppoValore[] = [
    { nome: 'Maschio', valore: 'M'},
    { nome: 'Femmina', valore: 'F'}
  ];

  sezioni: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A'},
    { nome: 'Sezione B', valore: 'B'}
  ];

  statoProcedimento: GruppoValore[] = [
    { nome: 'Aperto', valore: 'A'},
    { nome: 'Chiuso', valore: 'C'}
  ];

  selectedPrefisso: Prefisso | undefined;
  filteredComuni: Comune[] = [];
  filteredProvince: Provincia[] = [];
  filteredNazione: Nazione[] = [];
  filteredPrefisso: Prefisso[] = [];
  filteredTitolo: Titolo[] = [];
  filteredUniversita: Universita[] = [];
  filteredCTU: CTU[] = [];
  filteredOrdini: Ordine[] = [];

  //numeri telefonici
  listaNumeriSecondari: Telefono[] = [];

  //dati dei pagamenti
  listaData: ListaQuote = {
    lista: [],
    id: null,
    componente: 'readonly'
  }

  //elementi modifica stato dialog
  visible: boolean = false;
  selectedStato: StatoIscritto | undefined;

  //elementi del bottone stampa
  vociStampa = [
    {
      label: 'Scheda Personale',
      command: () => { this.stampaSchedaIscritto() }
    },
    {
      label: 'Certificato Iscrizione',
      command: () => { this.stampaCertidficatoIscrizione() }
    },
    {
      label: 'Certificato Iscrizione Estero',
      command: () => { this.stampaCertidficatoIscrizioneEstero() }
    },
    {
      label: 'Tesserino',
      command: () => { this.stampaTesserino() }
    }
  ];

  //iscritto form control
  iscrittoForm = new FormGroup({
    anagrafica: new FormGroup({
      codiceFiscale: new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(16)]),
      alias: new FormControl<string|null>(null),
      nome: new FormControl<string|null>(null, Validators.required),
      cognome: new FormControl<string|null>(null, Validators.required),
      sesso: new FormControl<GruppoValore|null>(null, Validators.required),
      luogoNascita: new FormControl<string|null>(null, Validators.required),
      provinciaNascita: new FormControl<Provincia|null>(null, Validators.required),
      dataNascita: new FormControl<Date|null>(null, Validators.required),
      cittadinanza: new FormControl<Nazione|null>(null, Validators.required),
      permessoSoggiornoIllimitato: new FormControl<boolean|null>(false),
      permessoSoggiorno: new FormControl<boolean|null>(false),
      scadenzaPermessoSoggiorno: new FormControl<Date|null>(null),

      email: new FormControl<string|null>(null, [Validators.required, this.customMailValidator]),
      pec: new FormControl<string|null>(null),
      telefonoPrincipale: new FormControl<string|null>(null),
      prefissoTelefonoPrincipale: new FormControl<Prefisso|null>(null),
      idTelefonoPrincipale: new FormControl<number|null>(null),

      residenzaIndirizzo: new FormControl<string|null>(null),
      residenzaCap: new FormControl<string|null>(null),
      residenzaComune: new FormControl<Comune|null>(null),
      residenzaProvincia: new FormControl<Provincia|null>(null),
      residenzaStato: new FormControl<Nazione|null>(null),
      residenzaLuogo: new FormControl<string|null>(null),

      domicilioIndirizzo: new FormControl<string|null>(null),
      domicilioCap: new FormControl<string|null>(null),
      domicilioComune: new FormControl<Comune|null>(null),
      domicilioProvincia: new FormControl<Provincia|null>(null),
      domicilioLuogo: new FormControl<string|null>(null),
    }),
    albo: new FormGroup({
      statoIscritto: new FormControl<StatoIscritto|null>(null, Validators.required),
      dataIscrizione: new FormControl<Date|null>(null),
      domandaProtocollo: new FormControl<string|null>(null),
      domandaData: new FormControl<Date|null>(null),
      dipendentePubblico: new FormControl<boolean|null>(null),
      articolo82: new FormControl<boolean|null>(null),
      ctu: new FormControl<CTU|null>(null),
      perito: new FormControl<CTU|null>(null),
      sezioneAlbo: new FormControl<GruppoValore|null>(null, Validators.required),
      articolo26: new FormControl<Articolo26|null>(null),
      sospensioneDal: new FormControl<Date|null>(null),
      sospensioneAl: new FormControl<Date|null>(null),
      procedimentoDisciplinareInCorso: new FormControl<GruppoValore|null>(null),
      numeroIscrizione: new FormControl<number|null>(null),
      trasferimentoDaOrdine: new FormControl<Ordine|null>(null),
      trasferimentoAOrdine: new FormControl<Ordine|null>(null),
      trasferimentoData: new FormControl<Date|null>(null),
      radiatoData: new FormControl<Date|null>(null),
      cancellatoData: new FormControl<Date|null>(null),
      professorePressoUniversita: new FormControl<Universita|null>(null),
      professoreUniversitario: new FormControl<boolean|null>(null)
    }),
    titoli: new FormGroup({
      titolo: new FormControl<Titolo|null>(null),
      abilitazione: new FormControl<Abilitazione|null>(null),
      univLaurea: new FormControl<Universita|null>(null),
      laureaData: new FormControl<Date|null>(null),
      laureaAbilitante: new FormControl<boolean|null>(null),
      universitaAbilitazione: new FormControl<Universita|null>(null),
      abilitazioneData: new FormControl<string|null>(null),
      controlloCasellario: new FormControl<boolean|null>(null),
      controlloAbilitazione: new FormControl<boolean|null>(null),
      controlloLaurea: new FormControl<boolean|null>(null),
      articolo3: new FormControl<Date|null>(null),
      psicoterapeutaArticolo35: new FormControl<boolean|null>(null),
      articolo35Data: new FormControl<Date|null>(null),
      riserva: new FormControl<boolean|null>(null),
      psicoterapeutaArticolo3: new FormControl<boolean|null>(null),
      neuropsicologo: new FormControl<boolean|null>(null),
      protNpsNumero: new FormControl<string|null>(null),
      protNpsData: new FormControl<Date|null>(null)
    })
  });

  @ViewChild('tv') tabview?: TabView;

  updating: boolean = false;

  ngOnInit(): void {

    this.severityButtons = this.checkAlbo();

    //caricamento iscritto on routing
    const dataIscritto = this.route.snapshot.data;

    if(dataIscritto['nuovoIscritto']){
      this.ultimoNumeroIscrizione = dataIscritto['ultimoNumeroIscrizione'];
    }

    this.popolaDropdown(dataIscritto['iscrittoDropdown'], dataIscritto['iscritto']);
    this.loadIscritto(dataIscritto['iscritto'], dataIscritto['nuovoIscritto'], dataIscritto['quote']);

    this.iscrittoForm.valueChanges.subscribe(() => {
      if(this.iscrittoForm.dirty) this.checkUnsavedChanges = true
      if(this.nuovoProvvisorio || this.infoOriginali?.statoIscritto.codiceCnop === 'P'){
        const trasferimentoA = this.iscrittoForm.get('albo.trasferimentoAOrdine');
        const prov = this.iscrittoForm.get('albo.trasferimentoDaOrdine')?.value !== null;

        if(!this.updating){
          this.updating = true;
          trasferimentoA?.patchValue(prov ? this.ordine_lombardia : null)
          const dataTrasf = this.iscrittoForm.get('albo.trasferimentoData');

          if(prov){
            dataTrasf?.enable();
          }else{
            dataTrasf?.patchValue(null);
            dataTrasf?.disable();
          }

          this.updating = false;
        }

      }

      // if(this.infoOriginali?.statoIscritto.codiceCnop === 'P'){
      //   if(!this.updating){
      //     const prov = this.iscrittoForm.get('albo.trasferimentoDaOrdine')?.value === null;
      //     if(prov){
      //       const trasferimentoA = this.iscrittoForm.get('albo.trasferimentoAOrdine');
      //       trasferimentoA?.patchValue(null)
      //     }
      //     this.updating = false;
      //   }
      // }
    })

    this.iscrittoForm.get('anagrafica.codiceFiscale')?.valueChanges.subscribe((cf) => {
      this.checkCodiceFiscaleEsistente(cf!);
    })

  }

  unsavedChecker(): Promise<void>{

    if(this.confrontaDatiOriginali()){
      return Promise.resolve();
    }

    return this.unsaved_service.unsavedChangesChecker(this.iscrittoForm);
  }

  //aggiunge le validazioni per i dati di iscrizione
  aggiungiIscrizioneValidators(){

    const numeroIscrizione = this.iscrittoForm.get('albo.numeroIscrizione');
    numeroIscrizione?.setValidators(Validators.required);

    const dataIscrizione = this.iscrittoForm.get('albo.dataIscrizione');
    dataIscrizione?.setValidators(Validators.required);

    if(this.nuovoIscritto){
      numeroIscrizione?.disable();
    }

    if(this.nuovoProvvisorio){
      dataIscrizione?.disable();
    }

  }

  //assegna valori al form
  loadIscritto(iscritto: Iscritto, routeIscritto: boolean, quote: Quota[]){

    this.nuovoIscritto = routeIscritto;
    this.nuovoProvvisorio = this.route.snapshot.data['provvisorio'];

    //se nuovo iscritto
    if(routeIscritto){

      this.iscrittoForm.patchValue({
        albo: {
          statoIscritto: this.nuovoProvvisorio ? this.stato_provvisorio : this.stati[0]
        }
      });

      this.iscrittoForm.get('albo.trasferimentoAOrdine')?.disable();
      this.iscrittoForm.get('albo.trasferimentoData')?.disable();

      this.statoIscrittoReadonly = false;

      this.aggiungiIscrizioneValidators();

    }

    //se iscritto dettaglio
    if(iscritto && !routeIscritto){

      //su nuova iscrizione
      if(this.route.snapshot.queryParams['afterNuovoIscrittoSave']){
        setTimeout(() => {
          let msg = '';

          if(iscritto.statoIscritto.codiceCnop === 'P') msg = 'Nuovo provvisorio generato correttamente';
          if(iscritto.statoIscritto.codiceCnop === 'A') msg = `Nuovo utente generato con numero iscrizione: ${iscritto.numeroIscrizione}`;

          this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: msg })
        }, 1100)
      }

      if(iscritto.statoIscritto.codiceCnop === 'P'){
        this.iscrittoForm.get('albo.numeroIscrizione')?.disable();
        this.iscrittoForm.get('albo.dataIscrizione')?.disable();
      }

      if(iscritto.statoIscritto.codiceCnop !== 'T'){
        this.iscrittoForm.get('albo.trasferimentoAOrdine')?.disable();
        if(iscritto.statoIscritto.codiceCnop === 'P'){
          if(iscritto.trasferimentoDaOrdine === null){
            this.iscrittoForm.get('albo.trasferimentoData')?.disable();
          }
        }else{
          this.iscrittoForm.get('albo.trasferimentoData')?.disable();
        }
      }

      this.annotazioni = iscritto.annotazioni;

      //this.urlFototesseraUpload = `${base_url}/images/upload/fototessera/${iscritto.id.toString()}`;
      this.caricaImmagineIscritto(iscritto)

      this.currentId = iscritto.id;
      this.infoOriginali = iscritto;
      this.quoteOriginali = quote;

      this.listaData.lista = quote;
      this.listaData.id = iscritto.id;

      this.selectedStato = iscritto.statoIscritto;

      if(iscritto.statoIscritto.codiceCnop !== 'P') this.aggiungiIscrizioneValidators();

      if(iscritto.statoIscritto === null){
        this.iscrittoForm.get('anagrafica.statoIscritto')?.clearValidators();
        this.iscrittoForm.get('anagrafica.statoIscritto')?.updateValueAndValidity();
      }

      const numeroPrincipaleEstratto = this.estraiNumeroPrincipale(iscritto.telefoni);
      this.listaNumeriSecondari = this.estraiNumeriSecondari(iscritto.telefoni);

      if(iscritto.sospensioneDal !== null) this.iscrittoForm.get('albo.sospensioneAl')?.enable();

      if(numeroPrincipaleEstratto !== null) this.selectedPrefisso = numeroPrincipaleEstratto.prefisso;

      this.iscrittoForm.patchValue({
        anagrafica: {
          codiceFiscale: (iscritto.codiceFiscale) ? iscritto.codiceFiscale.toUpperCase() : '',
          alias: iscritto.alias,
          nome: this.gestisciMaiuscole(iscritto.nome),
          cognome: this.gestisciMaiuscole(iscritto.cognome),
          sesso: this.generaGruppoValore(iscritto.sesso, 'sesso'),
          luogoNascita: iscritto.luogoNascita,
          provinciaNascita: iscritto.provinciaNascita,
          dataNascita: iscritto.dataNascita ? new Date(iscritto.dataNascita) : null,
          cittadinanza: iscritto.cittadinanza,
          permessoSoggiornoIllimitato: iscritto.permessoSoggiornoIllimitato,
          permessoSoggiorno: iscritto.permessoSoggiorno,
          scadenzaPermessoSoggiorno: iscritto.scadenzaPermessoSoggiorno ? new Date(iscritto.scadenzaPermessoSoggiorno) : null,
          email: (iscritto.email) ? iscritto.email.toLowerCase() : '',
          pec: (iscritto.pec) ? iscritto.pec.toLowerCase() : '',

          telefonoPrincipale: numeroPrincipaleEstratto?.numero,
          prefissoTelefonoPrincipale: numeroPrincipaleEstratto?.prefisso,
          idTelefonoPrincipale: numeroPrincipaleEstratto?.id,
          residenzaIndirizzo: iscritto.residenzaIndirizzo,
          residenzaCap: iscritto.residenzaCap,
          residenzaComune: iscritto.residenzaComune,
          residenzaProvincia: iscritto.residenzaProvincia,
          residenzaStato: iscritto.residenzaStato,
          residenzaLuogo: iscritto.residenzaLuogo,
          domicilioIndirizzo: iscritto.domicilioIndirizzo,
          domicilioCap: iscritto.domicilioCap,
          domicilioComune: iscritto.domicilioComune,
          domicilioProvincia: iscritto.domicilioProvincia,
          domicilioLuogo: iscritto.domicilioLuogo
        },
        albo: {
          statoIscritto: iscritto.statoIscritto,
          dataIscrizione: iscritto.dataIscrizione ? new Date(iscritto.dataIscrizione) : null,
          domandaProtocollo: iscritto.domandaProtocollo,
          domandaData: iscritto.domandaData ? new Date(iscritto.domandaData) : null,
          dipendentePubblico: iscritto.dipendentePubblico,
          articolo82: iscritto.articolo82,
          ctu: iscritto.ctu,
          perito: iscritto.perito,
          sezioneAlbo: this.generaGruppoValore(iscritto.sezioneAlbo, 'sezione'),
          articolo26: iscritto.articolo26,
          sospensioneDal: iscritto.sospensioneDal ? new Date(iscritto.sospensioneDal) : null,
          sospensioneAl: iscritto.sospensioneAl ? new Date(iscritto.sospensioneAl) : null,
          procedimentoDisciplinareInCorso: this.statoProcedimento.find(stato => stato.valore === iscritto.procedimentoDisciplinareInCorso),
          numeroIscrizione: iscritto.numeroIscrizione,
          trasferimentoDaOrdine: iscritto.trasferimentoDaOrdine,
          trasferimentoAOrdine: iscritto.trasferimentoAOrdine,
          trasferimentoData: iscritto.trasferimentoData ? new Date(iscritto.trasferimentoData) : null,
          radiatoData: iscritto.radiatoData ? new Date(iscritto.radiatoData) : null,
          cancellatoData: iscritto.cancellatoData ? new Date(iscritto.cancellatoData) : null,
          professorePressoUniversita: iscritto.professorePressoUniversita,
          professoreUniversitario: iscritto.professoreUniversitario
        },
        titoli: {
          titolo: iscritto.titolo,
          abilitazione: iscritto.abilitazione,
          univLaurea: iscritto.univLaurea,
          laureaData: iscritto.laureaData ? new Date(iscritto.laureaData) : null,
          laureaAbilitante: iscritto.laureaAbilitante,
          universitaAbilitazione: iscritto.universitaAbilitazione,
          abilitazioneData: iscritto.abilitazioneData,
          controlloCasellario: iscritto.controlloCasellario,
          controlloAbilitazione: iscritto.controlloAbilitazione,
          controlloLaurea: iscritto.controlloLaurea,
          articolo3: iscritto.articolo3 ? new Date(iscritto.articolo3) : null,
          psicoterapeutaArticolo35: iscritto.psicoterapeutaArticolo35,
          articolo35Data: iscritto.articolo35Data ? new Date(iscritto.articolo35Data) : null,
          riserva: iscritto.riserva,
          psicoterapeutaArticolo3: iscritto.psicoterapeutaArticolo3,
          neuropsicologo: iscritto.neuropsicologo,
          protNpsNumero: iscritto.protNpsNumero,
          protNpsData: iscritto.protNpsData ? new Date(iscritto.protNpsData) : null
        }
      });

      this.dataConfrontoOriginale = this.creaOggetto(this.iscrittoForm);

    }

    if(!this.role_service.isAlboRole()){
      this.iscrittoForm.disable();
    }

  }

  //invio dati nuovo iscritto - modifica dati
  inviaDatiIscritto(){

    if(this.nuovoProvvisorio || this.infoOriginali?.statoIscritto.codiceCnop === 'P'){
      this.iscrittoForm.get('albo.trasferimentoAOrdine')?.enable();
      this.iscrittoForm.get('albo.trasferimentoData')?.enable();
    }

      this.iscrittoForm.get('albo.trasferimentoAOrdine')?.enable();
      this.iscrittoForm.get('albo.trasferimentoData')?.enable();

    const formValues = this.iscrittoForm.value;
    const numeriSecondari = this.listaNumeriSecondari;

    const data = {
      iscritto: {

        id: (this.nuovoIscritto) ? null : this.currentId,

        //anagrafica
        codiceFiscale: formValues.anagrafica?.codiceFiscale?.toUpperCase(),
        alias: formValues.anagrafica?.alias,
        nome: this.gestisciMaiuscole(formValues.anagrafica?.nome),
        cognome: this.gestisciMaiuscole(formValues.anagrafica?.cognome),
        sesso: formValues.anagrafica?.sesso?.valore,
        luogoNascita: formValues.anagrafica?.luogoNascita,
        provinciaNascita: formValues.anagrafica?.provinciaNascita,
        dataNascita: formValues.anagrafica?.dataNascita ? this.buildDate(formValues.anagrafica?.dataNascita) : null,
        cittadinanza: (formValues.anagrafica?.cittadinanza) ? formValues.anagrafica?.cittadinanza : null,
        permessoSoggiornoIllimitato: formValues.anagrafica?.permessoSoggiornoIllimitato ? true : false,
        permessoSoggiorno: formValues.anagrafica?.permessoSoggiorno ? true : false,
        scadenzaPermessoSoggiorno: formValues.anagrafica?.scadenzaPermessoSoggiorno ? this.buildDate(formValues.anagrafica?.scadenzaPermessoSoggiorno) : null,

        //contatti
        email: formValues.anagrafica?.email?.toLowerCase(),
        pec: formValues.anagrafica?.pec?.toLowerCase(),

        //residenza
        residenzaIndirizzo: formValues.anagrafica?.residenzaIndirizzo,
        residenzaCap: formValues.anagrafica?.residenzaCap,
        residenzaComune: formValues.anagrafica?.residenzaComune,
        residenzaProvincia: formValues.anagrafica?.residenzaProvincia,
        residenzaStato: formValues.anagrafica?.residenzaStato,
        residenzaLuogo: formValues.anagrafica?.residenzaLuogo,

        //domicilio
        domicilioIndirizzo: formValues.anagrafica?.domicilioIndirizzo,
        domicilioCap: formValues.anagrafica?.domicilioCap,
        domicilioComune: formValues.anagrafica?.domicilioComune,
        domicilioProvincia: formValues.anagrafica?.domicilioProvincia,
        domicilioLuogo: formValues.anagrafica?.domicilioLuogo,

        //albo
        statoIscritto: formValues.albo?.statoIscritto,
        dataIscrizione: formValues.albo?.dataIscrizione ? this.buildDate(formValues.albo?.dataIscrizione) : null,
        domandaProtocollo: formValues.albo?.domandaProtocollo,
        domandaData: formValues.albo?.domandaData ? this.buildDate(formValues.albo?.domandaData) : null,
        dipendentePubblico: formValues.albo?.dipendentePubblico,
        articolo82: formValues.albo?.articolo82,
        ctu: formValues.albo?.ctu,
        perito: formValues.albo?.perito,
        sezioneAlbo: formValues.albo?.sezioneAlbo?.valore,
        articolo26: formValues.albo?.articolo26 ? formValues.albo?.articolo26 : null,
        sospensioneDal: formValues.albo?.sospensioneDal ? this.buildDate(formValues.albo?.sospensioneDal) : null,
        sospensioneAl: formValues.albo?.sospensioneAl ? this.buildDate(formValues.albo?.sospensioneAl) : null,
        procedimentoDisciplinareInCorso: formValues.albo?.procedimentoDisciplinareInCorso?.valore,
        numeroIscrizione: this.iscrittoForm.get('albo.numeroIscrizione')?.value,
        trasferimentoDaOrdine: formValues.albo?.trasferimentoDaOrdine ? formValues.albo?.trasferimentoDaOrdine : null,
        trasferimentoAOrdine: formValues.albo?.trasferimentoAOrdine ? formValues.albo?.trasferimentoAOrdine : null,
        trasferimentoData: formValues.albo?.trasferimentoData ? this.buildDate(formValues.albo?.trasferimentoData) : null,
        radiatoData: formValues.albo?.radiatoData ? this.buildDate(formValues.albo?.radiatoData) : null,
        cancellatoData: formValues.albo?.cancellatoData ? this.buildDate(formValues.albo?.cancellatoData) : null,
        professorePressoUniversita: formValues.albo?.professorePressoUniversita,
        professoreUniversitario: formValues.albo?.professoreUniversitario,

        //laurea
        titolo: formValues.titoli?.titolo,
        abilitazione: formValues.titoli?.abilitazione,
        univLaurea: formValues.titoli?.univLaurea,
        laureaData: formValues.titoli?.laureaData ? this.buildDate(formValues.titoli?.laureaData) : null,
        universitaAbilitazione: formValues.titoli?.universitaAbilitazione,
        abilitazioneData: formValues.titoli?.abilitazioneData,
        laureaAbilitante: formValues.titoli?.laureaAbilitante,
        controlloCasellario: formValues.titoli?.controlloCasellario,
        controlloAbilitazione: formValues.titoli?.controlloAbilitazione,
        controlloLaurea: formValues.titoli?.controlloLaurea,
        articolo3: formValues.titoli?.articolo3 ? this.buildDate(formValues.titoli?.articolo3) : null,
        psicoterapeutaArticolo35: formValues.titoli?.psicoterapeutaArticolo35,
        articolo35Data: formValues.titoli?.articolo35Data ? this.buildDate(formValues.titoli?.articolo35Data) : null,
        riserva: formValues.titoli?.riserva,
        psicoterapeutaArticolo3: formValues.titoli?.psicoterapeutaArticolo3,
        neuropsicologo: formValues.titoli?.neuropsicologo,
        protNpsNumero: formValues.titoli?.protNpsNumero,
        protNpsData: formValues.titoli?.protNpsData ? this.buildDate(formValues.titoli?.protNpsData) : null
      },
      telefoni: caricaTelefoni()
    };console.log(data)

    if(this.nuovoIscritto){
      this.inserisciNuovoIscritto(data)
    }else{
      this.modificaIscritto(data);
    }

    function caricaTelefoni(): Telefono[]{
      const telefoni: Telefono[] = [];
      const telefonoPrincipale: Telefono = {
        id: formValues.anagrafica?.idTelefonoPrincipale,
        prefisso: formValues.anagrafica?.prefissoTelefonoPrincipale!,
        numero: formValues.anagrafica?.telefonoPrincipale!,
        principale: true,
        descrizione: '',
        tipo: ''
      }
      telefoni.push(telefonoPrincipale);
      if(numeriSecondari.length > 0) {
        numeriSecondari.forEach(numeroSecondario => {
          if(numeroSecondario.numero) telefoni.push(numeroSecondario);
        });
      }
      return telefoni;
    }

  }

  //aggiunge numero alla lista
  aggiungiNumero(){
    this.ref = this.dialog_service.open(DialogDinamicoComponent, {
      data: {
        tipo: 'aggiungiTelefono'
      },
      header: 'Aggiungi Numero'
    });

    this.ref.onClose.subscribe({
      next: result => {
        if(result != undefined){
          if(result.elemento){

            const t = result.elemento;

            const data = {
              telefono: {
                prefisso: t.prefisso,
                numero: t.numero,
                tipo: t.tipo,
                descrizione: t.descrizione
              },
              id_iscritto: this.currentId
            }

            this.iscritti_service.creaTelefonoSecondario(data).subscribe({
              complete: () => {

                this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Il numero ${result.result} è stato inserito` })
                setTimeout(() => {
                  this.iscritti_service.getIscritto(this.currentId.toLocaleString()).subscribe(result => {
                    this.loadIscritto(result, false, result.quoteList);
                  })
                }, 1000)
              },
              error: (error) => {
                if(error.status === 409){
                  this.message_service.add({ severity: 'error', summary: 'Numero già registrato', detail: 'Operazione interrotta.' })
                }

              }
            })

          }
        }

      }
    })

  }

  //eliminazione di un numero secondario
  eliminaNumero(telefono: Telefono){
    this.ref = this.dialog_service.open(DialogDinamicoComponent, {
      data: {
        tipo: 'eliminazioneTelefono',
        elemento: telefono
      },
      header: 'Eliminazione Numero'
    });

    this.ref.onClose.subscribe({
      next: result => {
        if(result != undefined){
          if(result.elemento){

            this.iscritti_service.cancellaTelefonoSecondario(result.elemento).subscribe({
              next: result => {},
              complete: () => {
                this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Il numero è stato cancellato` })
                setTimeout(() => {
                  this.iscritti_service.getIscritto(this.currentId.toLocaleString()).subscribe(result => {
                    this.loadIscritto(result, false, result.quoteList);
                  })
                }, 1000)
              },
              error: (error) => {
                this.message_service.add({ severity: 'error', summary: 'Operazione Annullata', detail: 'Qualcosa è andato storto.' })
              }
            })

          }
        }

      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Operazione Annullata', detail: 'Qualcosa è andato storto.' })
      }
    })

  }

  //gestione dialog cambio stato dell'iscritto
  modificaStato(){

    let dataStato: Date | undefined | null;
    const formValues = this.iscrittoForm.value;
    const statoIscrittoAttuale = formValues.albo?.statoIscritto;
    const trasferimentoA_attuale = formValues.albo?.trasferimentoAOrdine;

    if(statoIscrittoAttuale?.nome === 'TRASFERIMENTO') dataStato = formValues.albo?.trasferimentoData;
    if(statoIscrittoAttuale?.nome === 'SOSPESO') dataStato = formValues.albo?.sospensioneDal;
    if(statoIscrittoAttuale?.nome === 'RADIATO') dataStato = formValues.albo?.radiatoData;
    if(statoIscrittoAttuale?.nome === 'CANCELLATO') dataStato = formValues.albo?.cancellatoData;

    this.ref = this.dialog_service.open(DialogCambioStatoComponent, {
      data: {
        statoIscrittoAttuale: statoIscrittoAttuale,
        dataStato: dataStato,
        trasferimentoA_attuale: trasferimentoA_attuale,
        idIscritto: this.infoOriginali?.id
      },
      header: 'Seleziona stato iscritto'
    });

    this.ref.onClose.subscribe({
      next: result => {

        if(result != undefined){

          this.message_service.add({ severity: 'success', summary: `Cambio Stato Completato`, detail: `Stato iscritto cambiato in: ${result.data.stato.nome}` })

          const si = result.data.stato;
          const day = result.data.data;
          const trasfA = result.data.trasferimentoA;

          this.iscrittoForm.get('albo.statoIscritto')?.patchValue(si);

          if(day){
            let str = '';
            if(si.nome === 'ATTIVO') str = 'albo.sospensioneAl';
            if(si.nome === 'SOSPESO'){
              str = 'albo.sospensioneDal';
              this.iscrittoForm.get('albo.sospensioneAl')?.patchValue(null);
            }
            if(si.nome === 'TRASFERIMENTO') str = 'albo.trasferimentoData';
            if(si.nome === 'CANCELLATO') str = 'albo.cancellatoData';
            if(si.nome === 'RADIATO') str = 'albo.radiatoData';

            this.iscrittoForm.get(str)?.patchValue(new Date(day));
          }

          if(trasfA){
            this.iscrittoForm.get('albo.trasferimentoAOrdine')?.patchValue(trasfA);
          }

          if(si.nome === 'ATTIVO'){
            this.iscrittoForm.get('albo.trasferimentoData')?.disable();
            this.iscrittoForm.get('albo.trasferimentoAOrdine')?.disable();
          }

        }

      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Operazione Annullata', detail: 'Qualcosa è andato storto.' })
      }
    })
  }

  //sincronizza iscritto con albo nazionale
  startSynch(id: number){

    this.syncSpinner = true;

    this.cnop_service.startSyncSingle(id.toString()).subscribe({
      complete: () => {
        this.syncSpinner = false;
        this.message_service.add({ severity: 'success', summary: `Sincronizzazione Completata`, detail: `Sincronizzazione con albo nazionale effettuata` })
      },
      error: () => {
        this.syncSpinner = false;
        this.message_service.add({ severity: 'error', summary: 'Sincronizzazione Interrotta', detail: 'Qualcosa è andato storto.' })
      }
    })

  }

  //analizza lo stato dell'iscritto per il dropdown
  private generaGruppoValore(gruppo: string , elemento: string): GruppoValore{

    const s: GruppoValore = { nome: '', valore: '' }

    if(elemento === 'sesso'){
      if(gruppo === 'M') s.nome = 'Maschio';
      if(gruppo === 'F') s.nome = 'Femmina';
    }

    if(elemento === 'sezione'){
      if(gruppo === 'A') s.nome = 'Sezione A';
      if(gruppo === 'B') s.nome = 'Sezione B';
    }

    s.valore = gruppo;

    return s;
  }

  //estrae il numero principale
  private estraiNumeroPrincipale(telefoni: Telefono[]): Telefono | null{
    let telefonoPrincipale: Telefono | null = null;
    telefoni.forEach(telefono => {
      if(telefono.principale) telefonoPrincipale = telefono;
    })
    return telefonoPrincipale;
  }

  //ignora il numero principale
  private estraiNumeriSecondari(telefoni: Telefono[]): Telefono[]{
    let telefoniSecondari: Telefono[] = [];
    telefoni.forEach(telefono => {
      if(!telefono.principale) telefoniSecondari.push(telefono);
    })
    return telefoniSecondari;
  }

  //inserisci nuovo iscritto nel database
  private inserisciNuovoIscritto(data: any){
    let id: number = 0;

    this.iscritti_service.inserisciIscritto(data).subscribe({
      next: result => {
        this.isLoadingSpinner = true;
        id = result;
      },
      complete: () => {
        this.gestioneSpinner('Crea', '', id);
      },
      error: err => {
        this.gestioneSpinner('errore', 'Qualcosa è andato storto', 0);
      }
    });
  }

  //modifica iscritto
  private modificaIscritto(data: any){
    this.modificaInCorsoFlag = true;
    this.iscritti_service.modificaIscritto(this.currentId, data).subscribe({
      next: result => {
        this.isLoadingSpinner = true;
      },
      complete: () => {
        this.gestioneSpinner('Modifica', '' , 0);
        this.shared_service.changeMessage(`${data.iscritto.nome} ${data.iscritto.cognome} n.${data.iscritto.numeroIscrizione} ${data.iscritto.titolo.nome}`);
      },
      error: err => {
        this.gestioneSpinner('errore', err.error, 0);
      }
    });
  }

  //gestione spinner bottone
  private gestioneSpinner(evento: string, error: string, id: number){

    if(evento === 'errore'){
      this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: error });

      const values = this.iscrittoForm.value;

      if(values.anagrafica?.permessoSoggiorno && values.anagrafica.permessoSoggiornoIllimitato){
        this.flagSoggiorno = true;
        this.flagSoggiornoIllimitato = true;
        this.flagScadenzaSoggiorno = false;
      }

      if(values.anagrafica?.permessoSoggiorno && values.anagrafica.scadenzaPermessoSoggiorno === null && !values.anagrafica.permessoSoggiornoIllimitato){
        this.flagSoggiorno = false;
        this.flagSoggiornoIllimitato = false;
        this.flagScadenzaSoggiorno = true;
      }

      if(!values.anagrafica?.permessoSoggiorno && values.anagrafica?.scadenzaPermessoSoggiorno && !values.anagrafica.permessoSoggiornoIllimitato){
        this.flagSoggiorno = true;
        this.flagSoggiornoIllimitato = false;
        this.flagScadenzaSoggiorno = false;
      }

    }else{
      this.message_service.add({ severity: 'success', summary: `${evento} completata`, detail: `Iscritto ${evento.toLowerCase()}to correttamente` });
    }

    this.isLoadingSpinner = false;

    if(evento === 'Modifica'){
      this.iscritti_service.getIscritto(this.currentId).subscribe(result => {
        this.iscrittoForm.get('albo.sospensioneAl')?.disable();
        this.loadIscritto(result, false, result.quoteList);
      });
    }

    if(evento === 'Crea'){
      this.router.navigate(
        [`/gestione-albo/gestione-iscritti/dettaglio-iscritto/${id}`],
        { queryParams: { afterNuovoIscrittoSave: true } }
      );
    }

    if(evento !== 'errore'){
      this.flagSoggiorno = false;
      this.flagSoggiornoIllimitato = false;
      this.flagScadenzaSoggiorno = false;
      this.iscrittoForm.markAsUntouched();
      this.iscrittoForm.markAsPristine();
    }
  }

  //validator personalizzato per lunghezza
  private customLenghtValidator(length: number){
    return (control: any) => {
      if (control.value && control.value.length !== length) {
        return { 'invalidLength': true };
      }
      return null;
    };
  }

  private customMailValidator(control: AbstractControl): ValidationErrors | null{
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const valid = regex.test(control.value);
    return valid ? null : { 'invalidMail': true };
  }

  //prima lettera maiuscola
  private gestisciMaiuscole(stringa: string | null | undefined){

    if(stringa === null || stringa === undefined) return null;

    return stringa.split(" ").map(parola => primaMaiuscola(parola)).join(" ");

    function primaMaiuscola(parola: string): string{
      return parola.charAt(0).toUpperCase() + parola.slice(1);
    }
  }

  //popola i dropdown
  private popolaDropdown(dropdownData: IscrittoDropdownData, iscritto: Iscritto){

    this.regioniData = dropdownData.regioniList;
    this.nazioni = dropdownData.nazioneList;
    this.province = dropdownData.provinciaList;
    this.comuni = dropdownData.comuneList;
    this.articolo26Data = dropdownData.articolo26List;
    this.CTUData = dropdownData.CTUList;
    this.abilitazioneData = dropdownData.abilitazioneList;
    this.titoloData = dropdownData.titoloList;
    this.universitaData = dropdownData.universitaList;
    this.prefissoData = dropdownData.prefissoList;
    this.oridiniData = dropdownData.ordiniList;

    //articolo
    if(iscritto && iscritto.articolo26){
      let flag = false
      for(const articolo of this.articolo26Data){
        if(iscritto.articolo26.id === articolo.id){
          flag = true;
           break;
        }
      }

      if(!flag) this.articolo26Data.push(iscritto.articolo26);
    }

    //ctu
    if(iscritto && iscritto.ctu){
      let flag = false
      for(const ctu of this.CTUData){
        if(iscritto.ctu){
          if(iscritto.ctu.id === ctu.id){
            flag = true;
            break;
          }
        }

      }

      if(!flag) this.CTUData.push(iscritto.ctu);
    }

    //specializzazione
    if(iscritto && iscritto.abilitazione){
      let flag = false
      for(const abilitazione of this.abilitazioneData){
        if(iscritto.abilitazione.id === abilitazione.id){
          flag = true;
          break;
        }
      }

      if(!flag) this.abilitazioneData.push(iscritto.abilitazione);
    }

    //titolo
    if(iscritto && iscritto.titolo){
      let flag = false
      for(const titolo of this.titoloData){
        if(iscritto.titolo.id === titolo.id){
          flag = true;
          break;
        }
      }

      if(!flag) this.titoloData.push(iscritto.titolo);
    }

    //universita
    if(iscritto && iscritto.universitaAbilitazione){
      let flag = false;
      for(const universita of this.universitaData){
        if(iscritto.universitaAbilitazione.id === universita.id){
          flag = true;
          break;
        }
      }

      if(!flag) this.universitaData.push(iscritto.universitaAbilitazione);
    }

  }

  //autocompletamento per i p-autocomplete
  filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filteredComuni = this.comuni.filter(comune =>
        queryParts.every(part =>
            comune.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'province'){
      this.filteredProvince = this.province.filter(provincia =>
        queryParts.every(part =>
            provincia.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'nazioni'){
      this.filteredNazione = this.nazioni.filter(nazione =>
        queryParts.every(part =>
            nazione.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'prefisso'){
      this.filteredPrefisso = this.prefissoData.filter(prefisso =>
        queryParts.every(part =>
            prefisso.nazione.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'titolo'){
      this.filteredTitolo = this.titoloData.filter(titolo =>
        queryParts.every(part =>
            titolo.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'universita'){
      this.filteredUniversita = this.universitaData.filter(univ =>
        queryParts.every(part =>
            univ.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'ctu'){
      this.filteredCTU = this.CTUData.filter(ctu =>
        queryParts.every(part =>
            ctu.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'ordini'){
      this.filteredOrdini = this.oridiniData.filter(ordine =>
        queryParts.every(part =>
            ordine.nome.toLowerCase().includes(part)
        )
      );
    }

  }

  checkAlbo(){

    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;

    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'

    return colore;
  }

  caricaImmagineIscritto(iscritto: Iscritto) {
    const sezioneAlbo = (iscritto.sezioneAlbo === 'A') ? 'sezione_a' : 'sezione_b';
    const imageName = iscritto.id.toString();

    this.iscritti_service.getImmagineIscritto(imageName).subscribe({
      next: imageBlob => {
        this.immagineIscritto = URL.createObjectURL(imageBlob);
      },
      error: e => {
        this.immagineIscritto = 'assets/images/default_user.jpg';
      }
    });
  }

  onBasicSelect(event: FileSelectEvent){
    this.fileToUpload = event.files[0];
  }

  uploadImageFile(){
    this.iscritti_service.caricaFototessera(this.currentId, this.fileToUpload!).subscribe({
      next: (result: string) => {
        this.cambioFotoDialog = false;
        this.message_service.add({ severity: 'success', summary: 'Processo Completato', detail: result });
      },
      complete: () => {
        this.iscritti_service.getImmagineIscritto(this.currentId.toString()).subscribe({
          next: imageBlob => {
            this.immagineIscritto = URL.createObjectURL(imageBlob);
          },
          error: e => {
            this.immagineIscritto = 'assets/images/default_user.jpg';
          }
        });
      },
      error: e => {
        this.message_service.add({ severity: 'error', summary: 'Caricamento Interrotto', detail: e.error });
      }
    });
  }

  cambiaFoto(){
    this.cambioFotoDialog = true;
  }

  disableBottoneSalva(): boolean{
    if(this.iscrittoForm.pristine || this.iscrittoForm.invalid || this.confrontaDatiOriginali() || this.codiceFiscaleFlag){
      return true;
    }

    return false;
  }

  disableBottoneSync(): boolean{
    if(this.isLoadingSpinner || !this.confrontaDatiOriginali()){
      return true;
    }

    return false;
  }

  creaOggetto(formGroup: FormGroup): any {
    const result: any = {};
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        result[key] = this.creaOggetto(control);
      } else if (control instanceof FormControl) {
        result[key] = control.value;
      }
    });
    return result;
  }

  confrontaDatiOriginali(){

    const oggettoForm = this.creaOggetto(this.iscrittoForm);

    return areObjectsEqual(oggettoForm, this.dataConfrontoOriginale);

    function areObjectsEqual(obj1: any, obj2: any): boolean {
      // Check if both are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }

      // Check if both have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Compare each key's value
      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }

        const val1 = obj1[key];
        const val2 = obj2[key];

        // Check if both are Date objects
        if (val1 instanceof Date && val2 instanceof Date) {
          if (val1.getTime() !== val2.getTime()) {
            return false;
          }
        // Recursively compare nested objects
        } else if (typeof val1 === 'object' && typeof val2 === 'object') {
          if (!areObjectsEqual(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }

      return true; //i dati sono uguali all'originale
    }

  }

  checkCodiceFiscaleEsistente(codiceFiscale: string){

    this.codiceFiscaleFlag = false;

    if(codiceFiscale?.length === 16){
      if(this.nuovoIscritto || (!this.nuovoIscritto && this.infoOriginali?.codiceFiscale !== codiceFiscale && !this.modificaInCorsoFlag)){

        this.iscritti_service.checkCodiceFiscale(codiceFiscale.toUpperCase()).subscribe((result: boolean) => {
          if(!result){
            this.codiceFiscaleFlag = true;
            this.iscrittoForm.get('anagrafica.codiceFiscale')?.setErrors({ invalid: true });
            this.message_service.add({ severity: 'error', summary: 'Codice Fiscale Esistente', detail: 'Il codice fiscale inserito risulta già registrato.' });
          }
        });

      }
    }

    this.modificaInCorsoFlag = false;

  }

  parseLocalDateStringToDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date
  }

  stampaSchedaIscritto(){

    const idIscritto = this.infoOriginali?.id;

    this.print_service.stampaSchedaPersonale(idIscritto!).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.infoOriginali?.cognome.toLowerCase()}_${this.infoOriginali?.nome.toLowerCase()}_scheda_personale.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }

  stampaCertidficatoIscrizione(){

    const idIscritto = this.infoOriginali?.id;

    this.print_service.stampaCertificatoIscrizione(idIscritto!).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.infoOriginali?.cognome.toLowerCase()}_${this.infoOriginali?.nome.toLowerCase()}_certificato_iscrizione.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }

  stampaCertidficatoIscrizioneEstero(){

    const idIscritto = this.infoOriginali?.id;

    this.print_service.stampaCertificatoIscrizioneEstero(idIscritto!).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.infoOriginali?.cognome.toLowerCase()}_${this.infoOriginali?.nome.toLowerCase()}_certificato_estero.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }

  buildDate(data_in: Date){
    const day = String(data_in.getDate()).padStart(2, '0');
    const month = String(data_in.getMonth() + 1).padStart(2, '0');
    const year = String(data_in.getFullYear());

    const data = `${year}-${month}-${day}`;

    return data;
  }

  capitalizeFirstLetters(input: string): string {
    return input.split(' ').map(word => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return word;
    }).join(' ');
  }

  erroreTrasferimento(){
    if(!['T', 'P'].includes(this.iscrittoForm.get('albo.statoIscritto')?.value?.codiceCnop!) && !this.nuovoProvvisorio){
      this.message_service.add({ severity: 'error', summary: 'Iscritto non in trasferimento', detail: 'Passare l\'iscritto "in trasferimento" per popolare questo campo.' });
    }
  }

  audiltLog: AuditLog[] = [];
  expandedRows = {};

  onChange($event: TabViewChangeEvent) {
    //console.debug("tab change", $event);
    //if (this.tabview?.tabPanels?.get($event.index)?.id === 'modifiche') {
    //if (this.tabview?.tabs[$event.index].header === 'Modifiche') {
/* non funziona nessuno di questi... */
      if ($event.index === 5) {
      console.debug("caricamento registro modifiche");
      const idIscritto = this.infoOriginali?.id;
      if (idIscritto)
        this.iscritti_service.getModificheIscritto(idIscritto).subscribe({
          next: (log)=>{
            this.audiltLog = log;
          }});

    }
  }

  expandAllLog() {
    this.expandedRows = this.audiltLog.reduce((acc:any, p) => (acc[p.eventId] = true) && acc, {});
  }

  collapseAllLog() {
      this.expandedRows = {};
  }


  stampaTesserino(){

    const idIscritto = this.infoOriginali?.id;

    this.print_service.stampaTesserino(idIscritto!).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.infoOriginali?.cognome.toLowerCase()}_${this.infoOriginali?.nome.toLowerCase()}_tesserino.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }
}
