<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

        <!-- filtri -->
        <div class="flex align-items-center mb-5 xl:mb-0">

          <!-- ricerca iscritti o altri elementi -->
          <div class="mr-3">
            <span class="p-input-icon-left p-input-icon-right p-float-label">
              <i class="pi pi-search"></i>
              <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="inserimento" />
              <label for="cercaIscritto">Cerca Iscritto</label>
              <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
            </span>
          </div>

        </div>

        <div class="flex bottoniStyle">

          <div class="mr-3">
            <p-button
              (click)="gestisciDeliberaDialog('Cancella')"
              icon="pi pi-trash"
              severity="danger"
              [disabled]="!role_service.isIscrizioniRole()"
              pTooltip="Elimina Bozza"
              tooltipPosition="bottom"
            ></p-button>
          </div>

          <div class="mr-3">
            <p-button
              (click)="gestisciDeliberaDialog('Modifica')"
              icon="pi pi-pencil"
              iconPos="right"
              severity="warning"
              [disabled]="!deliberaCorrente?.stampa || !role_service.isIscrizioniRole()"
              pTooltip="Modifica Selezione"
              tooltipPosition="bottom"
            ></p-button>
          </div>

          <!-- <div class="mr-3">
            <p-button
              (click)="mandaInStampa()"
              icon="pi pi-print"
              iconPos="right"
              severity="warning"
              [disabled]="!deliberaCorrente?.stampa"
            ></p-button>
          </div> -->

          <!-- <div>
            <p-button
              type="button"
              (click)="this.gestisciDeliberaDialog('Approva')"
              icon="pi pi-check-circle"
              iconPos="right"
            ></p-button>
          </div> -->

          <div>
            <p-button
              type="button"
              (click)="this.gestisciDeliberaDialog('Compila')"
              icon="pi pi-file"
              iconPos="right"
              [disabled]="pulsanteDinamicoDisable() || !role_service.isIscrizioniRole()"
              pTooltip="Compila Bozza"
              tooltipPosition="bottom"
            ></p-button>
          </div>

        </div>

        <p-button
          class="ml-3 clearFiltri"
          size="small"
          [outlined]="true"
          icon="pi pi-filter-slash"
          (click)="clearFilters()"
          pTooltip="Pulisci Filtri"
          tooltipPosition="bottom"
        />

      </div>

    </div>

  </div>

  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaProvissori"
      [rowHover]="true"
      [dataKey]="keyDataCheckbox"
      [(selection)]="provissoriSelezionati"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>

            <th style="width: 4rem">
              <p-tableHeaderCheckbox
                [disabled]="deliberaCorrente?.stampa || !role_service.isIscrizioniRole()"
                (click)="selezioneMassiva()"
              />
            </th>

            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="headerName === 'Stato' ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscritto>

        @if(iscrittoNonSelezionabile(iscritto)){

          <tr>

            <td>
              <p-tableCheckbox
                [value]="iscritto"
                (click)="disableSingleCheckbox(iscritto) ? impedisciPropagazione($event) : selezioneIscritto($event, iscritto)"
                [disabled]="disableSingleCheckbox(iscritto) || !role_service.isIscrizioniRole()"
              />
            </td>
            <!-- [routerLink]="generaRoutingLink(iscritto)" -->
            @for (rowName of rowsTabella; track $index) {
              <td>{{ iscritto[rowName] }}</td>
            }

          </tr>

        }

      </ng-template>

    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
