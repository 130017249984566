import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { Stp, StpDropdownData, FiltersListStpDto, ListaStpDto, IscrittoStp } from '../model/stp-data';

@Injectable({
  providedIn: 'root'
})
export class StpService {

  private _http = inject(HttpClient);
  private stp_api: string = `${environment.baseUrl}/stp`;

  getAllStp(data: FiltersListStpDto): Observable<ListaStpDto>{
    return this._http.post<ListaStpDto>(this.stp_api, data);
  }

  getStp(id: string | number): Observable<Stp>{
    return this._http.get<Stp>(`${this.stp_api}/${id}`);
  }

  inserisciStp(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(this.stp_api + '/nuova-stp', data);
  }

  modificaStp(id: number, data: any): Observable<Boolean>{
    return this._http.put<Boolean>(this.stp_api + `/modifica-stp/${id}`, data);
  }

  inserisciProfessionista(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.stp_api}/inserisci-professionista`, data);
  }

  rimuoviProfessionista(data: any): Observable<Boolean>{
    return this._http.post<Boolean>(`${this.stp_api}/rimuovi-professionista`, data);
  }

  getDropdwonData(): Observable<StpDropdownData>{
    return this._http.get<StpDropdownData>(this.stp_api + '/dropdown-stp-data');
  }

  getIscrittiStp(id: string | number): Observable<IscrittoStp[]>{
    return this._http.get<IscrittoStp[]>(`${this.stp_api}/stp-iscritti/${id}`);
  }

}
