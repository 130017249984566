import { Component, ViewChild, inject } from '@angular/core';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProvvisorioDettaglioListaDto, ListaProvvisoriDto, FiltersListProvvisoriDto, GruppoValore } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { RoleService } from '../../services/role.service';
import { SearchFilters, TableSettings } from '../../model/filters-data';
import { CustomFiltersService } from '../../services/custom-filters.service';

@Component({
  selector: 'app-tabella-provvisori',
  templateUrl: './tabella-provvisori.component.html',
  styleUrl: './tabella-provvisori.component.scss'
})
export class TabellaProvvisoriComponent {

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>(''),
    sezione: new FormControl<GruppoValore|null>(null)
  })

  sezioniOptions: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A' },
    { nome: 'Sezione B', valore: 'B' }
  ]

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Data Domanda', 'Nome', 'Cognome', 'Sezione', 'N° Iscrizione', 'Riepilogo Iscrizione', 'Pec']
  rowsTabella: string[] = ['codiceFiscale', 'domandaData', 'nome', 'cognome', 'sezioneAlbo', 'numeroIscrizione', 'numeroDelibera', 'pec']
  listaProvissori: ProvvisorioDettaglioListaDto[] = [];

  dettaglioIscrittoRoute = '/gestione_albo/gestione_iscritti/dettaglio_iscritto/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler();

    this.filtri.valueChanges.subscribe(() => {
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const iscritti: ListaProvvisoriDto = this.route.snapshot.data['provvisori'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaProvissori = iscritti.lista;
    this.totaleDati = iscritti.conteggio;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.iscritti_service.getProvvisori(data).subscribe({
      next: (result: ListaProvvisoriDto) => {
        this.listaProvissori = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: '',
      sezione: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const str: string = sessionData.inserimento;
    const szn: GruppoValore = this.sezioniOptions.find(sezione => sezione.valore === sessionData.sezione)!;

    this.filtri.patchValue({
      inserimento: str,
      sezione: szn
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;
    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    this.custom_filters_service.sessionStorageUpdate(this.filtri.value, this.componente, tabData);

  }


}
