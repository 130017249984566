import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StpContabileDto, StpContabileFilters, StpContabileListaDto } from '../../model/stp-data';
import { StpService } from '../../services/stp.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { GruppoValore } from '../../model/iscritti-data';
import { FormControl, FormGroup } from '@angular/forms';
import { RoleService } from '../../services/role.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';
import { CustomFiltersService } from '../../services/custom-filters.service';

@Component({
  selector: 'app-tabella-contab-stp',
  templateUrl: './tabella-contab-stp.component.html',
  styleUrl: './tabella-contab-stp.component.scss'
})
export class TabellaContabStpComponent implements OnInit{

  @ViewChild('dt') dt!: Table;

  private route = inject(ActivatedRoute);
  private stp_service = inject(StpService);
  private message_service = inject(MessageService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Ragione Sociale', 'Situazione Contabile', 'Numero Iscrizione'];
  rowsTabella: string[] = ['codiceFiscale', 'denominazione', 'situazione', 'codiceStp'];

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  listaStpContab: StpContabileDto[] = [];
  conteggio: number = 0;

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  componente: string = '';

  // filtro per gli stati dell'stp
  statiDropdown: GruppoValore[] = [
    { nome: 'REGOLARE', valore:'regolare' },
    { nome: 'MOROSA', valore:'morosa' }
  ]

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>(''),
    situazione: new FormControl<GruppoValore | null>(null)
  })

  ngOnInit(): void {
    this.initialHandler();

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })
  }

  private initialHandler(){

    const data = this.route.snapshot.data;

    const dataLista: StpContabileListaDto = data['lista'];
    this.listaStpContab = dataLista.lista;
    this.conteggio = dataLista.conteggio;console.log(dataLista);


    this.componente = data['componente'];

    this.inizializzaFiltri();
  }

  private loadData(){

    const values = this.filtri.value;

     const tabData: TableSettings = {
          orderColumnName: this.orderColumnName,
          order: this.order,
          page: this.page,
          pageSize: this.pageSize,
          currentPage: this.currentPage
        }

        const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.stp_service.getStpListaContabilita(data).subscribe({
      next: (result: StpContabileListaDto) => {
        this.listaStpContab = result.lista;
        this.conteggio = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceStp';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  generaRoutingLink(id: number){
    return `/contabilita_stp/dettaglio_contabile/${id}`;
  }

  //assegna colore stato
  coloreStato(stato: string): string{
    let colore = '';

    if(stato === 'REGOLARE') colore = '#2EAB5B';
    if(stato === 'MOROSA') colore = '#FF0000';
    if(stato === 'inattesa') colore = '#E5C130';

    return colore;
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceStp';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: '',
      situazione: null
    })

    this.dt.reset();

    this.loadData();

  }

  //aggiorna il session storage
  sessionStorageUpdate(){

    const values = this.filtri.value;
    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[this.componente].inserimento = values.inserimento;
    sessionData[this.componente].stato = values.situazione ? values.situazione.valore : '';
    sessionData[this.componente].orderColumn = this.orderColumnName;
    sessionData[this.componente].order = this.order;
    sessionData[this.componente].page = this.page;
    sessionData[this.componente].pageSize = this.pageSize;
    sessionData[this.componente].currentPage = this.currentPage;

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const stati: GruppoValore[] = this.statiDropdown;
    const str: string = sessionData.inserimento;

    const statoUpdate: GruppoValore = cercaStato();

    this.filtri.patchValue({
      inserimento: str,
      situazione: statoUpdate,
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.page = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);

    this.sessionStorageUpdate();

    //-------------------------------------------------------

    function cercaStato(): GruppoValore{
      return stati.find(si => si.valore === sessionData.situazione)!
    }
  }


}
