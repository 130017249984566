import { Component, OnInit, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IscrittiService } from '../../../services/iscritti.service';
import { GruppoValore, Iscritto } from '../../../model/iscritti-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-iscrizione-dialog',
  templateUrl: './iscrizione-dialog.component.html',
  styleUrl: './iscrizione-dialog.component.scss'
})
export class IscrizioneDialogComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private iscritti_service = inject(IscrittiService);

  //iscritto importato
  iscritto: Iscritto | undefined;

  dataProtocollo: Date | undefined;

  //sezioni
  sezioni: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A'},
    { nome: 'Sezione B', valore: 'B'}
  ];

  //spinner dei bottoni
  isLoadingSpinner = false;

  //form dati
  iscrizioneDati = new FormGroup({
    numeroIscrizione: new FormControl<number | null>(null, Validators.required),
    dataIscrizione: new FormControl<Date | null>(null, Validators.required),
    sezioneAlbo: new FormControl<GruppoValore | null>(null, Validators.required)
  })

  ngOnInit(): void {

    this.iscritti_service.getIscritto(this.dialog_config.data.id).subscribe({
      next: result => {
        this.iscritto = result;
        this.dataProtocollo = new Date(result.domandaData);
        this.iscrizioneDati.patchValue({
          numeroIscrizione: result.numeroIscrizione,
          dataIscrizione: result.dataIscrizione ? new Date(result.dataIscrizione) : null,
          sezioneAlbo: this.generaGruppoValore(result.sezioneAlbo, 'sezione')
        });
      }
    });

  }

  //chiusura dialog
  inviaDati(){
    this.isLoadingSpinner = true;
    const values = this.iscrizioneDati.value;

    setTimeout(() => {
      this.dialog_ref.close({
        id: this.iscritto?.id,
        numeroIscrizione: values.numeroIscrizione,
        dataIscrizione: new Date(values.dataIscrizione!),
        sezioneAlbo: values.sezioneAlbo?.valore
      })
    },2000)

  }

  //analizza lo stato dell'iscritto per il dropdown
  private generaGruppoValore(gruppo: string, elemento: string): GruppoValore{

    const s: GruppoValore = { nome: '', valore: '' }

    if(elemento === 'sezione'){
      if(gruppo === 'A') s.nome = 'Sezione A';
      if(gruppo === 'B') s.nome = 'Sezione B';
    }

    s.valore = gruppo;

    return s;
  }

}
