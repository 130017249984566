<div class="backPanel border-round-3xl flex flex-column align-items-center xl:align-items-start px-5 pt-3 mt-5 pb-5">
  <p class="text-lg text-center sm:text-start">Valore Spese Amministrative Attuali</p>
  <p class="box-spese text-5xl border-round-lg p-3"><b>€ {{ speseAmministrativeAttuali }}</b></p>

  @if (speseSpinner) {
    <div class="pl-2 pr-2 pt-1">
      <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
    </div>
  }@else {
    <p-button
      label="Applica Spese"
      (click)="applicaSpese()"
      [disabled]="!role_service.isContabilitaRole()"
    ></p-button>
  }

  <p-toast position="bottom-center"></p-toast>

</div>
