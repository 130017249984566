import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from './environment/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ChartModule } from 'primeng/chart';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AggiornamentoAlboComponent } from './components/aggiornamento-albo/aggiornamento-albo.component';
import { ImportIscrittiComponent } from './components/import-iscritti/import-iscritti.component';
import { HttpClientModule } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { DettaglioIscrittoComponent } from './components/gestione-albo/dettaglio-iscritto/dettaglio-iscritto.component';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api'
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { IscrizioneDialogComponent } from './components/gestione-albo/iscrizione-dialog/iscrizione-dialog.component';
import { InputMaskModule } from 'primeng/inputmask';
import { DettaglioStpComponent } from './components/gestione-albo/dettaglio-stp/dettaglio-stp.component';
import { DialogDinamicoComponent } from './components/dialog-dinamico/dialog-dinamico.component';
import { SidebarModule } from 'primeng/sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DettaglioContabileComponent } from './components/gestione-albo/dettaglio-contabile/dettaglio-contabile.component';
import { PaginatorModule } from 'primeng/paginator';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabellaPagamentiComponent } from './components/tabella-pagamenti/tabella-pagamenti.component';
import { UnsavedDialogComponent } from './components/unsaved-dialog/unsaved-dialog.component';
import { ConfigurazioneComponent } from './components/configurazione/configurazione.component';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfigurazioneDialogComponent } from './components/configurazione-dialog/configurazione-dialog.component';
import { MegaMenuModule } from 'primeng/megamenu';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogDeliberaComponent } from './components/dialog-delibera/dialog-delibera.component';
import { BadgeModule } from 'primeng/badge';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SharedService } from './services/shared.service';
import { TabellaIscrittiComponent } from './components/tabella-iscritti/tabella-iscritti.component';
import { TabellaProvvisoriComponent } from './components/tabella-provvisori/tabella-provvisori.component';
import { TabellaCancellatiComponent } from './components/tabella-cancellati/tabella-cancellati.component';
import { TooltipModule } from 'primeng/tooltip';
import { TabellaStpComponent } from './components/tabella-stp/tabella-stp.component';
import { TabellaDelibereComponent } from './components/tabella-delibere/tabella-delibere.component';
import { DialogNuovaDeliberaComponent } from './components/dialog-nuova-delibera/dialog-nuova-delibera.component';
import { TabellaProvvisoriSelezionabiliComponent } from './components/tabella-provvisori-selezionabili/tabella-provvisori-selezionabili.component';
import { TabellaDelibereChiuseComponent } from './components/tabella-delibere-chiuse/tabella-delibere-chiuse.component';
import { TabellaContabilitaComponent } from './components/tabella-contabilita/tabella-contabilita.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogCambioStatoComponent } from './components/dialog-cambio-stato/dialog-cambio-stato.component';
import { GeneraIuvComponent } from './components/genera-iuv/genera-iuv.component';
import { ImportPagamentiComponent } from './components/import-pagamenti/import-pagamenti.component';
import { TabellaDelibereFinalizzaComponent } from './components/tabella-delibere-finalizza/tabella-delibere-finalizza.component';
import { TabellaDelibereChiuseListaComponent } from './components/tabella-delibere-chiuse-lista/tabella-delibere-chiuse-lista.component';
import { TabellaProvvisoriFinalizzaComponent } from './components/tabella-provvisori-finalizza/tabella-provvisori-finalizza.component';
import { TabellaContabCancellatiComponent } from './components/tabella-contab-cancellati/tabella-contab-cancellati.component';
import { TabellaDelibereTrasfComponent } from './components/tabella-delibere-trasf/tabella-delibere-trasf.component';
import { TabellaDeliberePsiAComponent } from './components/tabella-delibere-psi-a/tabella-delibere-psi-a.component';
import { SpeseAmministrativeComponent } from './components/spese-amministrative/spese-amministrative.component';
import { DialogNuovoProfessionistaComponent } from './components/dialog-nuovo-professionista/dialog-nuovo-professionista.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RoleService } from './services/role.service';
import { StampeEDelibereComponent } from './components/stampe-e-delibere/stampe-e-delibere.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TabellaGestioneDomandeComponent } from './components/tabella-gestione-domande/tabella-gestione-domande.component';
import { TabellaStoricoDomandeComponent } from './components/tabella-storico-domande/tabella-storico-domande.component';
import { DettaglioDomandaIscrizioneComponent } from './components/dettaglio-domanda-iscrizione/dettaglio-domanda-iscrizione.component';
import { DialogDeliberaIscrittoComponent } from './components/dialog-delibera-iscritto/dialog-delibera-iscritto.component';
import { DettaglioStpSocioComponent } from './components/gestione-albo/dettaglio-stp-socio/dettaglio-stp-socio.component';
import { DialogInviaEmailComponent } from './components/dialog-invia-email/dialog-invia-email.component';
import { TabellaContabStpComponent } from './components/tabella-contab-stp/tabella-contab-stp.component';
import { DettaglioContabileStpComponent } from './components/dettaglio-contabile-stp/dettaglio-contabile-stp.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DashboardComponent,
    AggiornamentoAlboComponent,
    ImportIscrittiComponent,
    DettaglioIscrittoComponent,
    IscrizioneDialogComponent,
    DettaglioStpComponent,
    DialogDinamicoComponent,
    DettaglioContabileComponent,
    TabellaPagamentiComponent,
    UnsavedDialogComponent,
    ConfigurazioneComponent,
    ConfigurazioneDialogComponent,
    DialogDeliberaComponent,
    TabellaIscrittiComponent,
    TabellaProvvisoriComponent,
    TabellaCancellatiComponent,
    TabellaStpComponent,
    TabellaDelibereComponent,
    DialogNuovaDeliberaComponent,
    TabellaProvvisoriSelezionabiliComponent,
    TabellaDelibereChiuseComponent,
    TabellaContabilitaComponent,
    DialogCambioStatoComponent,
    GeneraIuvComponent,
    ImportPagamentiComponent,
    TabellaDelibereFinalizzaComponent,
    TabellaDelibereChiuseListaComponent,
    TabellaProvvisoriFinalizzaComponent,
    TabellaContabCancellatiComponent,
    TabellaDelibereTrasfComponent,
    TabellaDeliberePsiAComponent,
    SpeseAmministrativeComponent,
    DialogNuovoProfessionistaComponent,
    StampeEDelibereComponent,
    TabellaGestioneDomandeComponent,
    TabellaStoricoDomandeComponent,
    DettaglioDomandaIscrizioneComponent,
    DialogDeliberaIscrittoComponent,
    DettaglioStpSocioComponent,
    DialogInviaEmailComponent,
    TabellaContabStpComponent,
    DettaglioContabileStpComponent,
    DashboardCardComponent
  ],
  imports: [
    BrowserModule,
    KeycloakAngularModule,
    HttpClientModule,
    AppRoutingModule,
    ButtonModule,
    ImageModule,
    PanelMenuModule,
    ChartModule,
    InputTextModule,
    ReactiveFormsModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    KeyFilterModule,
    ToastModule,
    DialogModule,
    DynamicDialogModule,
    InputMaskModule,
    SidebarModule,
    AutoCompleteModule,
    OverlayPanelModule,
    PaginatorModule,
    SplitButtonModule,
    MenuModule,
    ConfirmDialogModule,
    CardModule,
    InputSwitchModule,
    MegaMenuModule,
    FileUploadModule,
    BadgeModule,
    SelectButtonModule,
    TooltipModule,
    SkeletonModule,
    ProgressSpinnerModule,
    InputNumberModule,
    FloatLabelModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    provideAnimationsAsync(),
    MessageService,
    DialogService,
    SharedService,
    RoleService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: `${environment.keycloakUrl}`,
        realm: `${environment.realm}`,
        clientId: `${environment.clientId}`
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}
