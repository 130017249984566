import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { GruppoValore, IscrittoContabileDto, PagedResultDto, FiltersListContabDto } from '../../model/iscritti-data';
import { IscrittiService } from '../../services/iscritti.service';
import { XlsService } from '../../services/xls.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-tabella-contab-cancellati',
  templateUrl: './tabella-contab-cancellati.component.html',
  styleUrl: './tabella-contab-cancellati.component.scss'
})
export class TabellaContabCancellatiComponent implements OnInit{

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;
  showAdvancedFilters: boolean = false;
  numeroFiltriAvanzati: number = 0;
  xlsSpinner:boolean = false;


  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private xls_service = inject(XlsService);
  public role_service = inject(RoleService);

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    stringaFiltro: new FormControl<string|null>(''),
    stato: new FormControl<GruppoValore | null>(null),
    statoIscritto: new FormControl<GruppoValore | null>(null),
    annoDal: new FormControl<number|null>(null),
    annoAl: new FormControl<number|null>(null),
    numeroAnniDa: new FormControl<number|null>(null),
    numeroAnniAl: new FormControl<number|null>(null)
  })

  // filtro per gli stati dell'iscritto
  statiDropdown: GruppoValore[] = [
    { nome: 'REGOLARE', valore:'regolare' },
    { nome: 'IN ATTESA', valore:'inattesa' },
    { nome: 'MOROSO', valore:'moroso' }
  ]

  statiIscrittoDropdown: GruppoValore[] = [
    { nome: 'CANCELLATO', valore:'CANCELLATO' },
    { nome: 'RADIATO', valore:'RADIATO' }
  ]

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Stato Iscritto', 'Situazione Contabile']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'statoIscritto', 'stato']
  listaIscritti: IscrittoContabileDto[] = [];

  dettaglioIscrittoRoute = '/contabilita-iscritti/dettaglio-iscritto/';

  orderColumnName: string = '';
  order: string = '';

  //dati del paginator
  pageSize: number = 25;
  pageSelected: number = 0;
  currentPage: number = 0;

  totaleDati: number = 0;

  vociStampa = [
    {
      label: 'Completo',
      command: () => { this.printContabXLS("completa") }
    },
    {
      label: 'Solo Partite Aperte',
      command: () => { this.printContabXLS("solo_aperte") }
    }
  ];


  ngOnInit(): void {

    this.initialDataHandler(); //gestisce i dati iniziali

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const iscritti: PagedResultDto = this.route.snapshot.data['contabile-cancellati'];
    const componente: string = this.route.snapshot.data['componente'];

    this.componente = componente;

    this.listaIscritti = iscritti.items;
    this.totaleDati = iscritti.count;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    this.sessionStorageUpdate();

    const data: FiltersListContabDto = {
      stringaFiltro: values.stringaFiltro!,
      stato: values.stato ? values.stato.valore : '',
      statoIscritto: values.statoIscritto ? values.statoIscritto.valore : '',
      annoDal: values.annoDal ? values.annoDal : null,
      annoAl: values.annoAl ? values.annoAl : null,
      numeroAnniDa: values.numeroAnniDa ? values.numeroAnniDa : null,
      numeroAnniAl: values.numeroAnniAl ? values.numeroAnniAl : null,

      tipoLista: this.tipoLista,
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.pageSelected,
      pageSize: this.pageSize,
      stpId: ''
    }

    this.iscritti_service.getIscrittiContabCancellati(data).subscribe({
      next: (result: PagedResultDto) => {
        this.listaIscritti = result.items;
        this.totaleDati = result.count;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })

    this.numeroFiltriAvanzati = this.conteggioAvanzati();

  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //assegna colore stato
  coloreStato(stato: string): string{
    let colore = '';

    if(stato === 'regolare') colore = '#2EAB5B';
    if(stato === 'moroso') colore = '#FF0000';
    if(stato === 'inattesa') colore = '#E5C130';

    return colore;
  }

  //assegna colore stato
  coloreStatoIscritto(statoIscritto: string): string{
    let colore = '';
    const id = statoIscritto;

    if(id === 'CANCELLATO') colore = '#FBA465';
    if(id === 'RADIATO') colore = '#EF5233';

    return colore;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.pageSelected = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.pageSelected = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('stringaFiltro')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      stringaFiltro: '',
      stato: null,
      statoIscritto: null,
      annoDal: null,
      annoAl: null,
      numeroAnniDa: null,
      numeroAnniAl: null
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //aggiorna il session storage
  sessionStorageUpdate(){

    const values = this.filtri.value;
    const sessionData = JSON.parse(sessionStorage.getItem('data')!);

    sessionData[this.componente].stringaFiltro = values.stringaFiltro;
    sessionData[this.componente].stato = values.stato ? values.stato.valore : '';
    sessionData[this.componente].statoIscritto = values.statoIscritto ? values.statoIscritto.valore : '';
    sessionData[this.componente].annoDal = values.annoDal ? values.annoDal : null;
    sessionData[this.componente].annoAl = values.annoAl ? values.annoAl : null;
    sessionData[this.componente].numeroAnniDa = values.numeroAnniDa ? values.numeroAnniDa : null;
    sessionData[this.componente].numeroAnniAl = values.numeroAnniAl ? values.numeroAnniAl : null;
    sessionData[this.componente].orderColumn = this.orderColumnName;
    sessionData[this.componente].order = this.order;
    sessionData[this.componente].page = this.pageSelected;
    sessionData[this.componente].pageSize = this.pageSize;
    sessionData[this.componente].currentPage = this.currentPage;

    sessionStorage.setItem(`data`, JSON.stringify(sessionData));
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const sessionData = JSON.parse(sessionStorage.getItem('data')!)[this.componente];

    const stati: GruppoValore[] = this.statiDropdown;
    const statiIscritto: GruppoValore[] = this.statiIscrittoDropdown;
    const str: string = sessionData.stringaFiltro;

    const routeFilter = this.route.snapshot.params['routeFilters'];

    const statoUpdate: GruppoValore = cercaStato();
    const statoIscrittoUpdate: GruppoValore = cercaStatoIscritto();

    this.filtri.patchValue({
      stringaFiltro: str,
      stato: statoUpdate,
      statoIscritto: statoIscrittoUpdate,
      annoDal: sessionData.annoDal,
      annoAl: sessionData.annoAl,
      numeroAnniDa: sessionData.numeroAnniDa,
      numeroAnniAl: sessionData.numeroAnniAl
    })

    this.orderColumnName = sessionData.orderColumn;
    this.order = sessionData.order;

    this.currentPage = Number(sessionData.currentPage);
    this.pageSelected = Number(sessionData.page);
    this.pageSize = Number(sessionData.pageSize);


    function estraiStato(routeFilter: string) {
      let stringaStato = '';
      if(routeFilter === 'moroso') stringaStato = 'moroso';
      return stringaStato;
    }

    this.sessionStorageUpdate();

    this.numeroFiltriAvanzati = this.conteggioAvanzati();


    //-------------------------------------------------------

    function cercaStato(): GruppoValore{
      if(routeFilter === 'iscritti'){
        return stati.find(s => s.valore === sessionData.stato)!
      }else{
        return stati.find(s => s.valore === routeFilter)!
      }
    }

    function cercaStatoIscritto(): GruppoValore{
      return statiIscritto.find(si => si.valore === sessionData.statoIscritto)!
    }
  }

  //mostra/nascondi filtri avanzati
  showFilters(){
    this.showAdvancedFilters = !this.showAdvancedFilters;
  }

  //conta i filtri avanzati presenti
  conteggioAvanzati(): number{

    const values = this.filtri.value;
    let conteggio = 0;

    if(values.annoDal) conteggio++;
    if(values.annoAl) conteggio++;
    if(values.numeroAnniDa) conteggio++;
    if(values.numeroAnniAl) conteggio++;

    return conteggio;
  }

  printContabXLS(vista: string){

    this.xlsSpinner = true;

    const values = this.filtri.value;

    const data: FiltersListContabDto = {
      stringaFiltro: values.stringaFiltro!,
      stato: values.stato?.valore ? values.stato?.valore : '',
      statoIscritto: values.statoIscritto?.valore ? values.statoIscritto?.valore : '',
      tipoLista: this.tipoLista,
      orderColumn: this.orderColumnName,
      order: this.order,
      page: this.pageSelected,
      pageSize: this.pageSize,

      annoDal: values.annoDal ? values.annoDal : null,
      annoAl: values.annoAl ? values.annoAl : null,

      numeroAnniDa: values.numeroAnniDa ? values.numeroAnniDa : null,
      numeroAnniAl: values.numeroAnniAl ? values.numeroAnniAl : null,
      stpId: ''
    }

    if(vista == "completa"){
      this.xls_service.stampaContabCancellatiXls(data).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contabilità_cancellati.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        this.xlsSpinner = false;
      }, error => {
        this.xlsSpinner = false;
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
        console.error('Error downloading the file', error);
      });
    }

    if(vista == "solo_aperte"){
      this.xls_service.stampaContabCancellatiAperteXls(data).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contabilità_solo_aperte_cancellati.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        this.xlsSpinner = false;
      }, error => {
        this.xlsSpinner = false;
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
        console.error('Error downloading the file', error);
      });
    }



  }

}
