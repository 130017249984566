<div [routerLink]="card.routeString" >
  <div class="flex align-items-center p-4 border-round-lg shadow-6 customBorder">
    <div class="ml-1 mr-5">
      <i class="{{ card.icon }} p-4 border-circle" [style]="card.stile"></i>
    </div>
    <div>
      <p class="font-bold text-2xl mb-2">{{ card.value }}</p>
      <p class="text-lg white-space-nowrap">{{ card.nome }}</p>
    </div>
  </div>
</div>

