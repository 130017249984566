import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Iscritto, IscrittoQuoteDto, IscrittoReadonlyData, ListaQuote, Pagamento, Quota, Telefono } from '../../../model/iscritti-data';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogDinamicoComponent } from '../../dialog-dinamico/dialog-dinamico.component';
import { QuoteService } from '../../../services/quote.service';
import { IscrittiService } from '../../../services/iscritti.service';
import { PrintService } from '../../../services/print.service';
import { environment } from '../../../environment/environment';
import { RoleService } from '../../../services/role.service';


@Component({
  selector: 'app-dettaglio-contabile',
  templateUrl: './dettaglio-contabile.component.html',
  styleUrl: './dettaglio-contabile.component.scss'
})
export class DettaglioContabileComponent implements OnInit{

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private print_service = inject(PrintService);
  public role_service = inject(RoleService);

  //dialog ref
  ref: DynamicDialogRef | undefined;

  //dati iscritto
  iscrittoReadonlyData: IscrittoReadonlyData | undefined;
  iscrittoCorrente: IscrittoQuoteDto | undefined;
  listaPagamenti: Pagamento[] = [];
  numeriSecondari: Telefono[] = [];

  flag82 = false;
  flagDP = false;
  flagPU = false;
  flagPermessoDiSoggiorno = false;
  flagPermessoDiSoggiornoIllimitato = false;

  //dati dei pagamenti
  listaData: ListaQuote = {
    lista: [],
    id: null,
    componente: 'contabile'
  }

  ngOnInit(): void {
    this.iscrittoCorrente = this.route.snapshot.data['iscritto'];
    this.iscrittoReadonlyData = this.route.snapshot.data['iscrittoReadonlyData'];
    this.numeriSecondari = this.route.snapshot.data['numeriSecondari'];

    this.flag82 = this.iscrittoReadonlyData?.articolo82!;
    this.flagDP = this.iscrittoReadonlyData?.dipendentePubblico!;
    this.flagPU = this.iscrittoReadonlyData?.professoreUniversitario!;
    this.flagPermessoDiSoggiorno = this.iscrittoReadonlyData?.permessoSoggiorno!;
    this.flagPermessoDiSoggiornoIllimitato = this.iscrittoReadonlyData?.permessoSoggiornoIllimitato!;

    this.listaData.id = this.iscrittoCorrente!.id;
    this.listaData.lista = this.iscrittoCorrente?.quoteList!;
  }

  stampaResoconto(){

    this.print_service.stampaResocontoContabile(this.iscrittoCorrente?.id!).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.iscrittoCorrente?.cognome}_${this.iscrittoCorrente?.nome}_resoconto_contabile.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }

}
