import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuoteService } from '../../services/quote.service';
import { MessageService } from 'primeng/api';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-spese-amministrative',
  templateUrl: './spese-amministrative.component.html',
  styleUrl: './spese-amministrative.component.scss'
})
export class SpeseAmministrativeComponent implements OnInit{

  //services
  private route = inject(ActivatedRoute);
  private quote_service = inject(QuoteService);
  private message_service = inject(MessageService);
  public role_service = inject(RoleService);


  // spinner per le spese
  speseSpinner: boolean = false;

  // valore spese amministrative
  speseAmministrativeAttuali: number = 0;

  ngOnInit(): void {
    this.loadData();
  }

  // carica i dati del componente
  loadData(){
    this.speseAmministrativeAttuali = this.route.snapshot.data['speseAmministrativeCorrenti'];
  }

  // funzione di applicazione spese amministrative
  applicaSpese(){
    this.speseSpinner = true;
    this.quote_service.applicaSpeseAmministrativeCorrenti().subscribe({
      next: (result) => {
        if(result){
          this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Spese Amministrative assegnate con successo.` });
        }
      },
      complete: () => {
        this.speseSpinner = false;
      },
      error: (err) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
        this.speseSpinner = false;
      }
    });
  }

}
