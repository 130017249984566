import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { FiltersListContabDto, FiltersListIscrittiDto } from '../model/iscritti-data';

@Injectable({
  providedIn: 'root'
})
export class XlsService {

  private _http = inject(HttpClient);
  private iscritti_api: string = `${environment.baseUrl}/iscritti`;

  stampaIscrittiXls(data: FiltersListIscrittiDto): Observable<any>{
    return this._http.post(this.iscritti_api + '/stampa-iscritti-xls', data, { responseType: 'blob' });
  }

  stampaContabXls(data: FiltersListContabDto): Observable<any>{
    return this._http.post(this.iscritti_api + '/stampa-contab-xls', data, { responseType: 'blob' });
  }

  stampaContabAperteXls(data: FiltersListContabDto): Observable<any>{
    return this._http.post(this.iscritti_api + '/stampa-contab-xls_solo-aperte', data, { responseType: 'blob' });
  }

  stampaContabCancellatiXls(data: FiltersListContabDto): Observable<any>{
    return this._http.post(this.iscritti_api + '/stampa-contab-xls_cancellati', data, { responseType: 'blob' });
  }

  stampaContabCancellatiAperteXls(data: FiltersListContabDto): Observable<any>{
    return this._http.post(this.iscritti_api + '/stampa-contab-xls_solo-aperte_cancellati', data, { responseType: 'blob' });
  }

}
