import { inject, Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
export class RoleService{

  private keycloack_service = inject(KeycloakService);

  private isAlbo = this.keycloack_service.isUserInRole('albo');
  private isConsultazione = this.keycloack_service.isUserInRole('consultazione');
  private isContabilita = this.keycloack_service.isUserInRole('contabilita');
  private isDeontologico = this.keycloack_service.isUserInRole('deontologico');
  private isIscrizioni = this.keycloack_service.isUserInRole('iscrizioni');
  private isConfigurazione = this.keycloack_service.isUserInRole('configurazione');

  isAlboRole(): boolean{
    return this.isAlbo;
  }

  isConsultazioneRole(): boolean{
    return this.isConsultazione;
  }

  isContabilitaRole(): boolean{
    return this.isContabilita;
  }

  isDeontologicoRole(): boolean{
    return this.isDeontologico;
  }

  isIscrizioniRole(): boolean{
    return this.isIscrizioni;
  }

  isConfigurazioneRole(): boolean{
    return this.isConfigurazione;
  }

  // ------ combination

  isAlboOrConsultazione(): boolean{
    return this.isAlbo || this.isConsultazione;
  }

  isIscrizioniOrConsultazione(): boolean{
    return this.isIscrizioni || this.isConsultazione;
  }

  isConsultazioneAndisAlboAndNotContabilita(): boolean{
    return this.isConsultazione || (this.isAlbo && !this.isContabilita);
  }

  isContabilitaOrConsultazione(): boolean{
    return this.isContabilita || this.isConsultazione;
  }

  isAlboOrisIscrizioniOrisConsultazione(): boolean{
    return this.isAlbo || this.isIscrizioni || this.isConsultazione;
  }

  isIscrizioniOrisConsultazioneOrisDeontologico(): boolean{
    return this.isAlbo || this.isConsultazione || this.isDeontologico;
  }

}
