<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

        <!-- ricerca iscritti o altri elementi -->
        <div class="sm:mr-3">
          <span class="p-input-icon-left p-input-icon-right p-float-label">
            <i class="pi pi-search"></i>
            <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="inserimento" />
            <label for="cercaIscritto">Cerca Iscritto</label>
            <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
          </span>
        </div>

      </div>

      <div>
        @if(xlsSpinner){
          <div class="pl-2 pr-2 pt-1">
            <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
          </div>
        }@else {
          <div class="bottoniStyle">
            <p-menu #menu [model]="vociStampa" [popup]="true" appendTo="body"></p-menu>
            <!-- label="Stampa" -->
            <button
              pButton
              type="button"
              (click)="menu.toggle($event)"
              icon="pi pi-table"
              pTooltip="Stampe XLS"
              tooltipPosition="right"
              [disabled]="!role_service.isContabilitaRole()"
            >
            </button>
          </div>
        }
      </div>

    </div>

    <!-- filtri -->
    <div class="flex align-items-center justify-content-center sm:justify-content-start mb-5 flex-wrap gap-3">

      <div class="sm:mb-0">
        <p-dropdown formControlName="situazione" [showClear]="true" [options]="situazioniDropdown" optionLabel="nome" placeholder="Situazione"></p-dropdown>
      </div>

      <div class="sm:mb-0">
        <p-dropdown formControlName="stato" [showClear]="true" [options]="statiIscrittoDropdown" optionLabel="nome" placeholder="Stato"></p-dropdown>
      </div>

      <div>
        <p-button label="Filtri Avanzati" icon="pi pi-filter" iconPos="right" class="filtriAvanzatiBtn" [badge]="numeroFiltriAvanzati.toString()" (click)="showFilters()"/>
      </div>

      <p-button
        class="clearFiltri"
        size="small"
        [outlined]="true"
        icon="pi pi-filter-slash"
        (click)="clearFilters()"
        pTooltip="Pulisci Filtri"
        tooltipPosition="right"
      />

    </div>

    @if(showAdvancedFilters){
      <!-- FILTRI AVANZATI -->
      <div class="flex gap-3 border-round shadow-4 mb-5 p-3">

        <div class="flex flex-column">
          <p class="filtroAvanzatoLabelPrimo">Anno Morosità</p>
          <p class="filtroAvanzatoLabel">N° Anni Morosità</p>
        </div>

        <div class="flex flex-column gap-3">

          <div class="flex align-items-center">

            <div class="mr-3">
              <p-inputNumber inputId="annoDal" [useGrouping]="false" formControlName="annoDal" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
            </div>

            <p class="m-0 mr-3">al</p>

            <div class="mr-3">
              <p-inputNumber inputId="annoAl" [useGrouping]="false" formControlName="annoAl" [minlength]="4" [maxlength]="4" placeholder="----" [showClear]="true" />
            </div>

          </div>

          <div class="flex align-items-center">

            <div class="mr-3">
              <p-inputNumber inputId="numeroAnniDa" [useGrouping]="false" formControlName="numeroAnniDa" placeholder="--" [showClear]="true" />
            </div>

            <p class="m-0 mr-3">a</p>

            <div class="mr-3">
              <p-inputNumber inputId="numeroAnniAl" [useGrouping]="false" formControlName="numeroAnniAl" placeholder="--" [showClear]="true" />
            </div>

          </div>

        </div>

      </div>
    }

  </div>

  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaIscritti"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="headerName === 'Stato' ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                @if(headerName !== 'Stato'){
                  <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
                }
              </th>
            }
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscritto>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(iscritto)">

            @for (rowName of rowsTabella; track $index) {

              @if (rowName === 'situazione') {
                <td class="font-bold" [style.color]="coloreStato(iscritto.situazione)">
                  {{ iscritto.situazione.toUpperCase() }}
                </td>
              }@else if(rowName === 'stato'){
                <td class="font-bold" [style.color]="coloreStatoIscritto(iscritto.stato)">
                  {{ iscritto.stato.toUpperCase() }}
                </td>
              }@else{
                <td>{{ iscritto[rowName] }}</td>
              }

            }
          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
