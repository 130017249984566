import { Component, inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModificaStatoIscrittoDto, Ordine, Regione, StatoIscritto } from '../../model/iscritti-data';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { IscrittiService } from '../../services/iscritti.service';

@Component({
  selector: 'app-dialog-cambio-stato',
  templateUrl: './dialog-cambio-stato.component.html',
  styleUrl: './dialog-cambio-stato.component.scss'
})
export class DialogCambioStatoComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private iscritti_service = inject(IscrittiService);

  isLoadingSpinner: boolean = false;

  //gestione stato iscritto
  stati: StatoIscritto[] = [
    { id: 1, nome: 'ATTIVO', codiceCnop: 'A' },
    { id: 2, nome: 'SOSPESO', codiceCnop: 'S' },
    { id: 3, nome: 'TRASFERIMENTO', codiceCnop: 'T' },
    { id: 4, nome: 'CANCELLATO', codiceCnop: 'C' },
    { id: 5, nome: 'RADIATO', codiceCnop: 'R' }
  ]

  //regioni
  ordiniData: Ordine[] = [
    { id: 17, nome: 'Abruzzo' },
    { id: 15, nome: 'Bolzano' },
    { id: 16, nome: 'Basilicata' },
    { id: 14, nome: 'Calabria' },
    { id: 3, nome: 'Campania' },
    { id: 9, nome: 'Emilia Romagna' },
    { id: 1, nome: 'Friuli Venezia Giulia' },
    { id: 10, nome: 'Lazio' },
    { id: 4, nome: 'Liguria' },
    { id: 12, nome: 'Marche' },
    { id: 19, nome: 'Molise' },
    { id: 5, nome: 'Puglia' },
    { id: 2, nome: 'Piemonte' },
    { id: 6, nome: 'Sardegna' },
    { id: 7, nome: 'Sicilia' },
    { id: 13, nome: 'Toscana' },
    { id: 18, nome: 'Trento' },
    { id: 8, nome: 'Veneto' },
    { id: 20, nome: 'Umbria' },
    { id: 21, nome: "Valle D'Aosta" }
  ];

  filteredOrdini: Ordine[] = [];

  idIscritto: number | undefined;

  statoIscrittoAttuale: StatoIscritto | undefined;
  dataStatoAttuale: Date | null | undefined;
  trasferimetoA_attuale: Ordine | null | undefined;

  statoIscrittoForm = new FormGroup({
    statoIscritto: new FormControl<StatoIscritto | null>(null, Validators.required),
    dataStato: new FormControl<Date | null>(null, Validators.required),
    trasferimentoA: new FormControl<Ordine | null>(null, Validators.required)
  })

  ngOnInit(): void {

    this.idIscritto = this.dialog_config.data.idIscritto;

    this.statoIscrittoAttuale = this.dialog_config.data.statoIscrittoAttuale;
    this.dataStatoAttuale = this.dialog_config.data.dataStato;
    this.trasferimetoA_attuale = this.dialog_config.data.trasferimentoA_attuale;

    this.initialDataHandler();

    const selectedValue = this.statoIscrittoForm.get('statoIscritto')?.value?.codiceCnop;
    const codiceAttuale = this.statoIscrittoAttuale?.codiceCnop;
    this.disableBoxData(selectedValue, codiceAttuale);

    this.statoIscrittoForm.get('statoIscritto')?.valueChanges.subscribe(() => this.changeSelectedStato())

  }



  //gestisce i dati in entrata
  initialDataHandler(){

    this.statoIscrittoForm.patchValue({
      statoIscritto: this.statoIscrittoAttuale,
      dataStato: this.dataStatoAttuale,
      trasferimentoA: this.trasferimetoA_attuale
    })

  }

  //gestisce stringa data in base allo stato selezionato
  placeholderData(): string{
    let str = '';

    const selectedValue = this.statoIscrittoForm.get('statoIscritto')?.value?.nome;

    if(selectedValue === 'ATTIVO') str = 'Sospeso al';
    if(selectedValue === 'SOSPESO') str = 'Sospeso dal';
    if(['TRASFERIMENTO', 'CANCELLATO', 'RADIATO'].includes(selectedValue!)) str = 'In Data';

    return str;
  }

  //gestisce stato corrente
  gestoreStato(): boolean{

    const selectedValue = this.statoIscrittoForm.get('statoIscritto')?.value?.codiceCnop;
    const codiceAttuale = this.statoIscrittoAttuale?.codiceCnop;

    if(
      (codiceAttuale === 'T' && selectedValue !== 'A') ||
      (codiceAttuale === 'S') ||
      (codiceAttuale === 'A' && ['S', 'C', 'R', 'T'].includes(selectedValue!)) ||
      (codiceAttuale === 'T' && ['S', 'C', 'R'].includes(selectedValue!))
    ) return true;

    return false;

  }

  //gestisce le date sul cambio di stato
  changeSelectedStato(){

    const selectedValue = this.statoIscrittoForm.get('statoIscritto')?.value?.codiceCnop;
    const codiceAttuale = this.statoIscrittoAttuale?.codiceCnop;

    // if(codiceAttuale === 'S'){
    //   this.statoIscrittoForm.patchValue({
    //     dataStato: selectedValue === 'S' ? this.dataStatoAttuale : null
    //   })
    // }

    this.valuesOnChange(selectedValue, codiceAttuale);
    this.disableBoxData(selectedValue, codiceAttuale);
    this.validatorsHandler(selectedValue, codiceAttuale);

  }

  //gestisce il disable del box data
  disableBoxData(selectedValue: string | undefined, codiceAttuale: string | undefined): void{

    if(codiceAttuale === 'S'){
      if(selectedValue === 'S'){
        this.statoIscrittoForm.controls['dataStato'].disable();
      }else{
        this.statoIscrittoForm.controls['dataStato'].enable();
      }
    }

    if(codiceAttuale === 'T'){
      if(selectedValue === 'T'){
        this.statoIscrittoForm.controls['dataStato'].disable();
        this.statoIscrittoForm.controls['trasferimentoA'].disable();
      }else{
        this.statoIscrittoForm.controls['dataStato'].enable();
        this.statoIscrittoForm.controls['trasferimentoA'].enable();
      }
    }


  }

  validatorsHandler(selectedValue: string | undefined, codiceAttuale: string | undefined){

    if(['A', 'S', 'C', 'R'].includes(selectedValue!)){
      this.statoIscrittoForm.get('statoIscritto')?.setValidators([Validators.required]);
      this.statoIscrittoForm.get('dataStato')?.setValidators([Validators.required]);
      this.statoIscrittoForm.get('trasferimentoA')?.setValidators([]);
    }

    if(selectedValue === 'T'){
      this.statoIscrittoForm.get('statoIscritto')?.setValidators([Validators.required]);
      this.statoIscrittoForm.get('dataStato')?.setValidators([Validators.required]);
      this.statoIscrittoForm.get('trasferimentoA')?.setValidators([Validators.required]);
    }

    if(codiceAttuale === 'T' && selectedValue === 'A'){
      this.statoIscrittoForm.get('statoIscritto')?.setValidators([Validators.required]);
      this.statoIscrittoForm.get('dataStato')?.setValidators([]);
      this.statoIscrittoForm.get('trasferimentoA')?.setValidators([]);
    }

    this.updateFormValidity();
  }

  //udpate dei validators del form
  updateFormValidity(){
    this.statoIscrittoForm.get('statoIscritto')?.updateValueAndValidity({emitEvent: false});
    this.statoIscrittoForm.get('dataStato')?.updateValueAndValidity({emitEvent: false});
    this.statoIscrittoForm.get('trasferimentoA')?.updateValueAndValidity({emitEvent: false});
  }

  //resetta i valori al cambio stato
  valuesOnChange(selectedValue: string | undefined, codiceAttuale: string | undefined){

    if(codiceAttuale === 'A'){
      if(['T', 'S', 'C', 'R'].includes(selectedValue!)){
        this.statoIscrittoForm.patchValue({
          dataStato: null,
          trasferimentoA: null
        });
      }
    }

    if(codiceAttuale === 'S'){
      this.statoIscrittoForm.patchValue({
        dataStato: selectedValue === 'S' ? this.dataStatoAttuale : null,
        trasferimentoA: null
      })
    }

    if(codiceAttuale === 'T'){
      this.statoIscrittoForm.patchValue({
        dataStato: selectedValue === 'T' ? this.dataStatoAttuale : null,
        trasferimentoA: selectedValue === 'T' ? this.trasferimetoA_attuale : null
      })
    }

  }

  //invio dei dati in back
  inviaDati(){

    this.isLoadingSpinner = true;

    const valori = this.statoIscrittoForm.value;

    const dateForm = (valori.dataStato !== null) ? this.formatDateToLocalDateString(valori.dataStato!) : null;

    const data: ModificaStatoIscrittoDto = {
      stato: valori.statoIscritto!,
      data: dateForm,
      trasferimentoA: valori.trasferimentoA!
    }

    this.iscritti_service.modificaStatoIscritto(this.idIscritto!, data).subscribe({
      complete: () => {

        setTimeout(() => {
          this.dialog_ref.close({
            data
          })
        }, 1500);

      },
      error: (error) => {
        console.log(error)
      }
    });

  }

  formatDateToLocalDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  parseLocalDateStringToDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date
  }

}
