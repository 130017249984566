import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Allegati, DatiIscrizione, RifiutoPraticaDto } from '../../model/domande-data';
import { DomandeService } from '../../services/domande.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dettaglio-domanda-iscrizione',
  templateUrl: './dettaglio-domanda-iscrizione.component.html',
  styleUrl: './dettaglio-domanda-iscrizione.component.scss'
})
export class DettaglioDomandaIscrizioneComponent implements OnInit{

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private domanda_service = inject(DomandeService);
  private messageService = inject(MessageService);

  componente: string | undefined;
  domandaIscrizione: DatiIscrizione | undefined;
  visibleR: boolean = false;
  visibleA: boolean = false;
  visibleMotivo: boolean = false;
  rifiuto: string = "";
  username: any;
  tipoPratica: string = "";
  selectedOption: any;
  fototessera: any;
  allegati: Allegati[] = [];
  showAllegati: boolean = false;
  icon: string = "pi-angle-down";
  statoColori = new Map<string, string>([
    ['In Lavorazione', '#E67E22'],
    ['Rifiutata', '#C0392B'],
    ['Approvata', '#3498DB']
  ]);

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.componente = this.route.snapshot.data['componente'];
    this.domandaIscrizione = this.route.snapshot.data[this.componente!];
    this.allegati = this.route.snapshot.data['allegati'];
    console.log(this.allegati);
    
  }

  showAllegatiButton(){
    this.showAllegati = !this.showAllegati;
    this.icon = this.showAllegati ? "pi-angle-up" : "pi-angle-down";
  }

  formatName(name: string){
    if(name === "Tesserino"){
      return "Tassa Tesserino"
    }
    return name.replace(/_/g, ' ');
  }

  revertDateFormat(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  vediMotivo(){
    this.visibleMotivo = true;
  }

  visualizzaFile(tipo: string){
    const id: number = this.domandaIscrizione?.id!;
    this.domanda_service.getFileAllegato(id, tipo).subscribe({
      next: (fileBlob: Blob) => {
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, '_blank');
      },
      error: e => {
        this.messageService.add({ severity: 'error', summary: 'Errore', detail: e.error });
      }
    });
  }

  accettaDomandaDialog(){
    this.visibleA = true;
  }
  accettaDomanda(){
    const id = this.domandaIscrizione?.id;
    this.domanda_service.registraProvvisorioDaDomanda(Number(id!)).subscribe({
      next: (result) => {
        this.domanda_service.LavoraDomanda(Number(id!)).subscribe({
          next: (lavoraResult) => {
            this.messageService.add({ severity: 'success', summary: 'Domanda accettata', detail: lavoraResult });
            setTimeout(() => {
              this.router.navigate(['/domande_iscrizione/gestione']);
            }, 2000);
          },
          error: (lavoraError) => {
            this.messageService.add({ severity: 'error', summary: 'Lavoro interrotto', detail: lavoraError.error });
          }
        });
        this.copiaFototessera(result.id.toString());
      },
      error: (provvisorioError) => {
        this.messageService.add({ severity: 'error', summary: 'Registrazione Interrotta', detail: provvisorioError.error });
      }
    })
  }

  rifiutaPraticaDialog(){
    this.visibleR = true;
  }
  rifiutaPratica(){
    const motivoRifiuto: RifiutoPraticaDto = {
      motivoRifiuto: this.rifiuto
    }
    const id = this.domandaIscrizione!.id;
    this.domanda_service.rifiutaPratica(id!, motivoRifiuto).subscribe(data => {
      this.messageService.add({ severity: 'success', summary: 'Rifiuto effettuato', detail: data });
    })
    this.visibleR = false;
    setTimeout(() => {
      this.router.navigate(['/domande_iscrizione/gestione']);
    }, 1500);
  }

  copiaFototessera(idIscritto: string){
    const id: number = this.domandaIscrizione?.id!;
    this.domanda_service.caricaFototessera(id, idIscritto).subscribe({
      next: result => {
      },
      error: e => {
        this.messageService.add({ severity: 'error', summary: 'Errore', detail: e.error });
      }
    })
  }
}
