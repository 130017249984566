import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { Delibera, DeliberaFiltri, DeliberaListDto, IscrittiDeliberaChiusaDto, TipoDelibera } from '../model/delibere-data';
import { FiltersListIscrittiDto, Iscritto } from '../model/iscritti-data';

@Injectable({
  providedIn: 'root'
})
export class DeliberaService {

  private _http = inject(HttpClient);
  private delibera_api: string = `${environment.baseUrl}/delibere`;

  getTipiDelibera(): Observable<TipoDelibera[]>{
    return this._http.get<TipoDelibera[]>(this.delibera_api + `/tipi_delibera`);
  }

  getDeliberaById(id: number): Observable<Delibera>{
    return this._http.get<Delibera>(this.delibera_api + `/${id}`);
  }

  getIscrittiByDeliberaId(id: number, deliberaFiltriDto: FiltersListIscrittiDto): Observable<IscrittiDeliberaChiusaDto>{
    return this._http.post<IscrittiDeliberaChiusaDto>(this.delibera_api + `/iscritti_delibera_chiusa/${id}`, deliberaFiltriDto);
  }

  getConteggioElementiDelibera(id: number): Observable<number>{
    return this._http.get<number>(this.delibera_api + `/conteggio_elementi_delibera/${id}`);
  }

  listaDelibere(deliberaFiltriDto: DeliberaFiltri): Observable<DeliberaListDto>{
    return this._http.post<DeliberaListDto>(this.delibera_api, deliberaFiltriDto);
  }

  ultimoNumeroDelibera(): Observable<number>{
    return this._http.get<number>(this.delibera_api + `/ultimo_numero_delibera`);
  }

  deliberaCorrente(tipoCodice: string): Observable<Delibera | null>{
    return this._http.get<Delibera | null>(this.delibera_api + `/delibera_corrente/${tipoCodice}`);
  }

  ultimaDelibera(tipoCodice: string): Observable<Delibera | null>{
    return this._http.get<Delibera | null>(this.delibera_api + `/ultima_delibera/${tipoCodice}`);
  }

  approvaDeliberaCorrente(numeroDelibera: number): Observable<boolean>{
    return this._http.put<boolean>(this.delibera_api + `/approva_delibera/${numeroDelibera}`, {});
  }

  stampaDeliberaCorrente(numeroDelibera: number): Observable<Delibera>{
    return this._http.put<Delibera>(this.delibera_api + `/stampa_delibera/${numeroDelibera}`, {});
  }

  modificaDeliberaCorrente(numeroDelibera: number): Observable<Delibera>{
    return this._http.put<Delibera>(this.delibera_api + `/abilita_modifica_delibera_corrente/${numeroDelibera}`, {});
  }

  inserisciIscrittoInDelibera(idIscritto: number, numeroDelibera: number): Observable<boolean>{
    return this._http.put<boolean>(this.delibera_api + `/inserisci_iscritto/${idIscritto}`, numeroDelibera);
  }

  rimuoviIscrittoDaDelibera(idIscritto: number, numeroDelibera: number): Observable<boolean>{
    return this._http.put<boolean>(this.delibera_api + `/rimuovi_iscritto/${idIscritto}`, numeroDelibera);
  }

  inserimentoMassivoInDelibera(idIscritti: number[], numeroDelibera: number): Observable<boolean>{
    return this._http.put<boolean>(this.delibera_api + `/inserimento_massivo/${numeroDelibera}`, idIscritti);
  }

  svuotaDelibera(numeroDelibera: number): Observable<boolean>{
    return this._http.put<boolean>(this.delibera_api + `/svuota_delibera/${numeroDelibera}`, {});
  }

  generaDelibera(numeroDelibera: number, tipoCodice: string, dataDelibera: string): Observable<Delibera>{
    const data = {
      tipoCodice: tipoCodice,
      dataDelibera: dataDelibera,
      numeroDelibera: numeroDelibera
    }
    return this._http.post<Delibera>(this.delibera_api + `/genera_nuova_delibera`, data);
  }

  cancellaDelibera(numeroDelibera: number): Observable<boolean>{
    return this._http.delete<boolean>(this.delibera_api + `/elimina_delibera/${numeroDelibera}`);
  }

}
