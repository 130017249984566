import { Component, inject, OnInit } from '@angular/core';
import { IscrittoStp, RuoloSocio, StpDropdownData, TipoSocio } from '../../../model/stp-data';
import { ActivatedRoute } from '@angular/router';
import { StpService } from '../../../services/stp.service';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Regione, Nazione, Provincia, Comune, Ordine, GruppoValore } from '../../../model/iscritti-data';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { RoleService } from '../../../services/role.service';
import { IscrittiService } from '../../../services/iscritti.service';
import { IscrittoItem, RicercaIscritto } from '../../../model/cnop-data';
import { MessageService } from 'primeng/api';
import { UnsavedService } from '../../../services/unsaved.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-dettaglio-stp-socio',
  templateUrl: './dettaglio-stp-socio.component.html',
  styleUrl: './dettaglio-stp-socio.component.scss'
})
export class DettaglioStpSocioComponent implements OnInit{

  //services
  private route = inject(ActivatedRoute);
  private stp_service = inject(StpService);
  public role_service = inject(RoleService);
  public shared_service = inject(SharedService);
  private iscritti_service = inject(IscrittiService);
  private message_service = inject(MessageService);
  private unsaved_service = inject(UnsavedService);

  isLoadingSpinner: boolean = false;

  idStp: number | undefined;
  infoOriginali: IscrittoStp | undefined;
  dataConfrontoOriginale: any;
  nuovoSocio: boolean = false;

  //dati dei dropdown
  regioniData: Regione[] = [];
  nazioni: Nazione[] = [];
  province: Provincia[] = [];
  comuni: Comune[] = [];
  ordini: Ordine[] = [];
  tipoSocioList: TipoSocio[] = [];
  ruoloSocioList: RuoloSocio[] = [];

  filteredProvince: Provincia[] = [];
  filteredNazione: Nazione[] = [];

  //dati inserimento professionista
  lista: IscrittoItem[] = [];

  //form filtro professinista
  professionistiFiltered: IscrittoItem[] = [];

  sezioni: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A'},
    { nome: 'Sezione B', valore: 'B'}
  ];

  professionistiSearch = new FormGroup({
    professionistaSelezionato: new FormControl<IscrittoItem|null>(null)
  })

  socioForm = new FormGroup({
    tipoSocio: new FormControl<TipoSocio|null>(null, Validators.required),
    ruoloSocio: new FormControl<RuoloSocio|null>(null, Validators.required),

    ordine: new FormControl<Ordine|null>(null),
    numeroOrdine: new FormControl<string|null>(null),
    sezioneAlbo: new FormControl<GruppoValore|null>(null),

    nome: new FormControl<string|null>(null, Validators.required),
    cognome:  new FormControl<string|null>(null, Validators.required),
    codiceFiscale:  new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(16)]),

    dataNascita: new FormControl<Date|null>(null, Validators.required),
    luogoNascita: new FormControl<string|null>(null, Validators.required),
    provinciaNascita: new FormControl<Provincia|null>(null, Validators.required),
    nazionalita: new FormControl<Nazione|null>(null, Validators.required),

    indirizzo: new FormControl<string|null>(null, Validators.required),
    cap: new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(5)]),
    citta: new FormControl<string|null>(null, Validators.required),
    provincia: new FormControl<Provincia|null>(null, Validators.required)
  });

  ngOnInit(): void {
    this.initialHandler();
    this.gestioneListaSelezionabili('');
  }

  private initialHandler(){

    const data = this.route.snapshot.data;
    const socio = data['socio'];

    this.popolaDropdown(data['dropdown']);

    this.idStp = Number(this.route.snapshot.paramMap.get('idStp'));
    this.tipoSocioList = data['tipoSocioList'];
    this.ruoloSocioList = data['ruoloSocioList'];
    this.infoOriginali = socio;
    this.nuovoSocio = data['nuovoSocio'];

    if(!this.nuovoSocio)
      this.loadForm(socio)

  }

  private popolaDropdown(dropdownData: StpDropdownData){
    this.regioniData = dropdownData.regioniList;
    this.nazioni = dropdownData.nazioneList;
    this.province = dropdownData.provinciaList;
    this.comuni = dropdownData.comuneList;
    this.ordini = dropdownData.ordiniList;
  }

  private loadForm(data: IscrittoStp){
    this.socioForm.patchValue({
      tipoSocio: data.tipoSocio,
      ruoloSocio: data.ruoloSocio,
      ordine: data.ordine,
      numeroOrdine: data.numeroIscrizione,

      nome: data.nome,
      cognome: data.cognome,
      codiceFiscale: data.codiceFiscale,

      sezioneAlbo: this.sezioni.find(s => s.valore === data.sezioneAlbo),

      dataNascita: data.dataNascita ? new Date(data.dataNascita) : null,
      luogoNascita: data.dataNascita,
      provinciaNascita: data.provinciaNascita,
      nazionalita: data.nazionalita,

      indirizzo: data.indirizzo,
      cap: data.cap,
      citta: data.citta,
      provincia: data.provincia
    });

    this.dataConfrontoOriginale = this.creaOggetto(this.socioForm);

  }

  inviaDatiSocio(){

    const formValues = this.socioForm.value;

    const data: IscrittoItem = {
      idPersona: '',

      tipo_socio_id: formValues.tipoSocio?.id!,
      ruolo_socio_id: formValues.ruoloSocio?.id!,

      idOrdine: formValues.ordine?.id.toString()!,
      ordine: '',
      numIscrizione: formValues.numeroOrdine!,
      dataIscrizione: '',
      sezione: formValues.sezioneAlbo?.valore!,

      nome: this.gestisciMaiuscole(formValues.nome)!,
      cognome:  this.gestisciMaiuscole(formValues.cognome)!,
      codiceFiscale:  formValues.codiceFiscale?.toUpperCase()!,

      pec: '',
      email: '',

      psicoterapeuta: false, //valore provvisorio - attualmente non viene salvato

      dataNascita: this.buildDate(formValues.dataNascita!),
      luogoNascita: this.gestisciMaiuscole(formValues.luogoNascita)!,
      provinciaNascita: formValues.provinciaNascita?.codice!,
      nazionalita: formValues.nazionalita?.codice!,

      indirizzo: this.gestisciMaiuscole(formValues.indirizzo)!,
      cap: formValues.cap!,
      citta: this.gestisciMaiuscole(formValues.citta)!,
      provincia: formValues.provincia?.codice!

    }

    if(this.nuovoSocio){
      this.inserisciSocio(data)
    }else{
      this.updateSocio(data);
    }
  }

  private inserisciSocio(data: IscrittoItem){

    const sentData = {
      iscrittoItem: data,
      idStp: this.idStp
    }

    this.stp_service.inserisciProfessionista(sentData).subscribe({
      next: result => {
        if(result){
          this.professionistiSearch.reset();
          this.socioForm.reset();
          this.message_service.add({ severity: 'success', summary: `Inserimento completato`, detail: `Dati del socio inseriti in STP` });
        }
      },
      error: err => {
        this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: err.error });
      }
    });
  }

  private updateSocio(data: IscrittoItem){
    this.stp_service.updateIscrittoStp(this.infoOriginali?.id!, data).subscribe({
      next: (result: IscrittoStp) => {
        if(result){
          this.loadForm(result);
          this.message_service.add({ severity: 'success', summary: `Salvataggio completato`, detail: `Dati del socio aggiornati` });
        }
      },
      error: err => {
        this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: err.error });
      }
    });
  }

  disableBottoneSalva(): boolean{
    if(this.socioForm.pristine || this.socioForm.invalid || this.confrontaDatiOriginali()){
      return true;
    }
    return false;
  }

  popolaForm(event: any){
    if(this.nuovoSocio){
      const selectedProfessionista = event.value;


      this.socioForm.patchValue({
        tipoSocio: null,
        ruoloSocio: null,
        ordine: this.ordini.find(o => o.id === Number(selectedProfessionista.idOrdine)),
        numeroOrdine: selectedProfessionista.numIscrizione,

        nome: selectedProfessionista.nome,
        cognome: selectedProfessionista.cognome,
        codiceFiscale: selectedProfessionista.codiceFiscale,

        sezioneAlbo: this.sezioni.find(s => s.valore === selectedProfessionista.sezione),

        dataNascita: selectedProfessionista.dataNascita ? new Date(selectedProfessionista.dataNascita) : null,
        luogoNascita: selectedProfessionista.luogoNascita,
        provinciaNascita: this.province.find(p => p.codice === selectedProfessionista.provinciaNascita),
        nazionalita: this.nazioni.find(n => n.nazionalita === selectedProfessionista.nazionalita),

        indirizzo: selectedProfessionista.indirizzo,
        cap: selectedProfessionista.cap,
        citta: selectedProfessionista.citta,
        provincia: this.province.find(p => p.codice === selectedProfessionista.provincia)
      });
    }
  }

  clearForm(){
    this.socioForm.reset();
  }

  //autocompletamento per i p-autocomplete
  filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'province'){
      this.filteredProvince = this.province.filter(provincia =>
        queryParts.every(part =>
            provincia.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'nazioni'){
      this.filteredNazione = this.nazioni.filter(nazione =>
        queryParts.every(part =>
            nazione.nome.toLowerCase().includes(part)
        )
      );
    }

  }

  inputValueAutocomplete(event: any){
    const val = event.target.value;
    this.gestioneListaSelezionabili(val);
  }

  gestioneListaSelezionabili(par: string){

    const parametro: RicercaIscritto = {
      criterio: par
    };

    this.iscritti_service.getIscrittiCnop(parametro).subscribe((result: IscrittoItem[]) => {
      this.lista = result;
      this.lista = this.lista.map(pro => ({
        ...pro,
        anagrafica: `${pro.nome}, ${pro.cognome}, ${pro.codiceFiscale}`
      }));
    });

  }

  //gestione del filtraggio dei professionisti
  filterProfessionisti(event: AutoCompleteCompleteEvent){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    this.professionistiFiltered = this.lista.filter(professionista =>
      queryParts.every(part =>
        `${professionista.nome} ${professionista.cognome} ${professionista.codiceFiscale}`.toLowerCase().includes(part)
      )
    )
  }

  private creaOggetto(formGroup: FormGroup): any {
    const result: any = {};
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        result[key] = this.creaOggetto(control);
      } else if (control instanceof FormControl) {
        result[key] = control.value;
      }
    });
    return result;
  }

  private confrontaDatiOriginali(){

    const oggettoForm = this.creaOggetto(this.socioForm);

    return areObjectsEqual(oggettoForm, this.dataConfrontoOriginale);

    function areObjectsEqual(obj1: any, obj2: any): boolean {
      // Check if both are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }

      // Check if both have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Compare each key's value
      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }

        const val1 = obj1[key];
        const val2 = obj2[key];

        // Recursively compare nested objects
        if (typeof val1 === 'object' && typeof val2 === 'object') {
          if (!areObjectsEqual(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }

      return true; //i dati sono uguali all'originale
    }

  }

  //prima lettera maiuscola
  private gestisciMaiuscole(stringa: string | null | undefined){

    if(stringa === null || stringa === undefined) return null;

    return stringa.split(" ").map(parola => primaMaiuscola(parola)).join(" ");

    function primaMaiuscola(parola: string): string{
      return parola.charAt(0).toUpperCase() + parola.slice(1);
    }
  }

  //validator personalizzato per lunghezza
  private customLenghtValidator(length: number){
    return (control: any) => {
      if (control.value && control.value.length !== length) {
        return { 'invalidLength': true };
      }
      return null;
    };
  }

  private buildDate(data_in: Date){
    const day = String(data_in.getDate()).padStart(2, '0');
    const month = String(data_in.getMonth() + 1).padStart(2, '0');
    const year = String(data_in.getFullYear());

    const data = `${year}-${month}-${day}`;

    return data;
  }

  unsavedChecker(): Promise<void>{

    if(this.confrontaDatiOriginali()){
      return Promise.resolve();
    }

    return this.unsaved_service.unsavedChangesChecker(this.socioForm);
  }

}
