import { Component, OnInit, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DeliberaService } from '../../services/delibera.service';
import { Delibera, TipoDelibera } from '../../model/delibere-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environment/environment';
import { DomandeService } from '../../services/domande.service';

@Component({
  selector: 'app-dialog-delibera',
  templateUrl: './dialog-delibera.component.html',
  styleUrl: './dialog-delibera.component.scss'
})
export class DialogDeliberaComponent implements OnInit{

  //services
  private dialog_config = inject(DynamicDialogConfig);
  private dialog_ref = inject(DynamicDialogRef);
  private delibera_service = inject(DeliberaService);
  private domande_service = inject(DomandeService);

  // regione corrente
  regione = environment.regione;

  //spinner del bottone di invio
  isLoadingSpinner = false;

  //dati liste
  tipiDelibera: TipoDelibera[] = [];

  tipiDeliberaForm = new FormGroup({
    numeroDelibera: new FormControl<number|null>(null, Validators.required),
    tipo: new FormControl<TipoDelibera|null>(null, Validators.required),
    dataDelibera: new FormControl<Date|null>(null, Validators.required)
  });

  // dati delibera corrente
  numeroDeliberaMostrato: number | undefined;

  operazione: string | undefined;
  tipo: string | undefined;
  codiceDelibera: string | undefined;

  corpoDialog: string | undefined;

  labelBottone: string | undefined;


  ngOnInit(): void {
    this.operazione = this.dialog_config.data.operazione;
    this.tipo = this.dialog_config.data.tipo;
    this.codiceDelibera = this.generaCodiceDelibera();
    this.handleTipo();
  }

  //chiude il dialog e invia approvazione delibera corrente
  bottoneDelibera(){

    this.isLoadingSpinner = true;

    if(this.operazione === 'Approva')
      this.approvazioneDeliberaClose();

    if(this.operazione === 'Nuova')
      this.generazioneDelibera();

    if(this.operazione === 'Compila')
      this.compilaDeliberaClose();

    if(this.operazione === 'Modifica')
      this.modificaDeliberaCorrenteClose();

    if(this.operazione === 'Cancella')
      this.cancellazioneDeliberaClose();

  }

  //gestisce il tipo di delibera o dialog
  handleTipo(){

    if(this.operazione === 'Approva'){
      this.corpoDialog = 'Confermi di voler approvare il riepilogo corrente?';
      this.numeroDeliberaMostrato = this.dialog_config.data.numeroDelibera;
      this.labelBottone = 'Approva Riepilogo';
    }

    if(this.operazione === 'Compila'){
      this.corpoDialog = 'Confermi di voler compilare il riepilogo corrente?';
      this.numeroDeliberaMostrato = this.dialog_config.data.numeroDelibera;
      this.labelBottone = 'Compila Riepilogo';
    }

    if(this.operazione === 'Modifica'){
      this.corpoDialog = 'Modificando il seguente riepilogo, sarà necessario effettuata una nuova stampa.';
      this.numeroDeliberaMostrato = this.dialog_config.data.numeroDelibera;
      this.labelBottone = 'Modifica Riepilogo';
    }

    if(this.operazione === 'Cancella'){
      this.corpoDialog = 'Confermi di voler cancellare il riepilogo corrente?';
      this.numeroDeliberaMostrato = this.dialog_config.data.numeroDelibera;
      this.labelBottone = 'Cancella Riepilogo';
    }

    if(this.operazione === 'Nuova'){
      this.labelBottone = 'Genera Riepilogo';

      this.delibera_service.ultimoNumeroDelibera().subscribe({
        next: ultimoNumero => {
          this.corpoDialog = 'Il nuovo riepilogo avrà il seguente numero';
          this.numeroDeliberaMostrato = ultimoNumero + 1;
        },
        error: (e) => {
          this.corpoDialog = 'Errore del server. Impossibile Procedere!'
        }
      })

      this.delibera_service.getTipiDelibera().subscribe(result => this.tipiDelibera = result);
    }

  }

  //chiusura dialog con stampa delibera
  compilaDeliberaClose(){

    this.delibera_service.stampaDeliberaCorrente(this.numeroDeliberaMostrato!).subscribe({
      next: (result) => {
        this.dialog_ref.close(
          {
            delibera: result,
            esito: true
          }
        );
      },
      error: (e) => {
        this.dialog_ref.close({esito: false});
      }
    })

  }

  //chiusura dialog con approvazione delibera
  approvazioneDeliberaClose(){

    const nd = this.numeroDeliberaMostrato;

    this.delibera_service.approvaDeliberaCorrente(nd!).subscribe({
      next: () => {
        if(['demo'].includes(this.regione)){
          this.domande_service.approvaDomandeIscrizione(nd!).subscribe(result => {
            console.log(result);
          })
        }
      },
      complete: () => {
        this.dialog_ref.close({esito: true});
      },
      error: (e) => {
        this.dialog_ref.close({esito: false});
      }
    })

  }

  //chiusura dialog con generazione nuova delibera
  generazioneDelibera(){

    const tdf = this.tipiDeliberaForm.value;

    const day = String(tdf.dataDelibera?.getDate()).padStart(2, '0');
    const month = String(tdf.dataDelibera?.getMonth()).padStart(2, '0');
    const year = String(tdf.dataDelibera?.getFullYear());

    const dataDelibera = `${day}-${month}-${year}`;

    this.delibera_service.generaDelibera(tdf.numeroDelibera!, tdf.tipo?.codice!, dataDelibera).subscribe({
      next: (result) => {
        this.dialog_ref.close(
          {
            delibera: result,
            esito: true
          }
        );
      },
      error: (e) => {
        this.dialog_ref.close({errore: e, esito: false});
      }
    })

  }

  //chiusura dialog con abilitazione a modifica delibera
  modificaDeliberaCorrenteClose(){

    this.delibera_service.modificaDeliberaCorrente(this.numeroDeliberaMostrato!).subscribe({
      next: (result) => {
        this.dialog_ref.close(
          {
            delibera: result,
            esito: true
          }
        );
      },
      error: (e) => {
        this.dialog_ref.close({esito: false});
      }
    })

  }

  //chiusura dialog con cancellazione delibera
  cancellazioneDeliberaClose(){

    this.delibera_service.cancellaDelibera(this.numeroDeliberaMostrato!).subscribe({
      complete: () => {
        this.dialog_ref.close({esito: true});
      },
      error: (e) => {
        this.dialog_ref.close({esito: false});
      }
    })

  }

  //genera codice delibera
  generaCodiceDelibera(){

    let codice = '';

    if(this.tipo === 'provvisori_a') codice = 'A';
    if(this.tipo === 'provvisori_b') codice = 'B';


    return codice;

  }

  //gestione disable del bottono di immissione
  disableSubmitHandler(){

    if(this.operazione === 'Nuova' && (this.tipiDeliberaForm.pristine || this.tipiDeliberaForm.invalid)){
      return true
    }

    return false;
  }

}
