<div>

  <div [formGroup]="filtri">

    <!-- filtri iscritti -->
    <div class="flex flex-column align-items-center sm:flex-row mb-5 pt-5">

      <!-- filtri -->
      <div class="flex align-items-center justify-content-center mb-5 sm:mb-0">

        <!-- ricerca iscritti o altri elementi -->
        <div class="sm:mr-3">
          <span class="p-input-icon-left p-input-icon-right p-float-label">
            <i class="pi pi-search"></i>
            <input type="text" id="cercaIscritto" class="shadow-4" pInputText formControlName="stringaFiltro" />
            <label for="cercaIscritto">Cerca Iscritto</label>
            <i class="pi pi-times pr-1" id="clearIcon" (click)="resetBoxRicerca()"></i>
          </span>
        </div>

      </div>

    </div>

    <div class="flex align-items-center gap-3 mb-5">
      <p-dropdown formControlName="stato" [showClear]="true" [options]="statiDropdown" optionLabel="nome" placeholder="filtro stato"></p-dropdown>

      <p-button
        class=""
        size="small"
        [outlined]="true"
        icon="pi pi-filter-slash"
        (click)="clearFilters()"
        pTooltip="Pulisci Filtri"
        tooltipPosition="right"
      />
    </div>

  </div>

  <div class="mb-5">

    <!-- PAGINATOR della tabella -->
    <div class="flex justify-content-start">
      <p-paginator
        [rows]="pageSize"
        [first]="currentPage"
        [rowsPerPageOptions]="[25, 100, 250, 500]"
        (onPageChange)="pageChange($event)"
        [totalRecords]="totaleDati"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} di {totalRecords}"
        [showPageLinks]="false"
      >
      </p-paginator>
    </div>

  </div>

  <!-- tabella -->
  <div>
    <p-table
      #dt
      [value]="listaCancellati"
      [rowHover]="true"
      (onLazyLoad)="ordinamento($event)"
      [customSort]="true"
      [lazy]="true"
    >
      <!-- headers -->
      <ng-template pTemplate="header">
          <tr>
            @for(headerName of headersTabella; track $index){
              <th
                [pSortableColumn]="(headerName === 'Stato') ? '' : rowsTabella[$index]"
              >
                {{ headerName }}
                @if(headerName !== 'Stato'){
                  <p-sortIcon [field]="rowsTabella[$index]"></p-sortIcon>
                }
              </th>
            }
            <th></th>
          </tr>
      </ng-template>

      <!-- rows -->
      <ng-template pTemplate="body" let-iscritto>

          <tr style="cursor: pointer;" [routerLink]="generaRoutingLink(iscritto)">

            @for (rowName of rowsTabella; track $index) {

              @if (rowName === 'statoIscritto') {
                <td class="font-bold" [style.color]="coloreStato(iscritto.statoIscritto)">
                  {{ iscritto.statoIscritto.nome }}
                </td>
              }@else{
                <td>{{ iscritto[rowName] }}</td>
              }

            }
            <td>
              @if(iscritto.statoIscritto.codiceCnop === 'C'){
                <p-button
                  class="customButton"
                  [raised]="true"
                  severity="primary"
                  (click)="ripristinaIscritto(iscritto, $event)"
                  icon="pi pi-replay"
                  [disabled]="role_service.isConsultazioneRole()"
                  pTooltip="Ripristina Iscritto"
                  tooltipPosition="left"
                >
                </p-button>
              }
            </td>

          </tr>
      </ng-template>
    </p-table>

  </div>

  <p-toast position="bottom-center"></p-toast>

</div>
