import { Component, OnInit, inject } from '@angular/core';
import { StpService } from '../../../services/stp.service';
import { Comune, FiltersListIscrittiDto, IscrittoDropdownData, ListaData, ListaQuote, Nazione, Ordine, Provincia, Regione } from '../../../model/iscritti-data';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { IscrittoStp, ProfessionistaDto, QuotaStp, Stp, StpDropdownData, TipoSocio } from '../../../model/stp-data';
import { UnsavedService } from '../../../services/unsaved.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { SharedService } from '../../../services/shared.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogDinamicoComponent } from '../../dialog-dinamico/dialog-dinamico.component';
import { IscrittiService } from '../../../services/iscritti.service';
import { PrintService } from '../../../services/print.service';
import { DialogNuovoProfessionistaComponent } from '../../dialog-nuovo-professionista/dialog-nuovo-professionista.component';
import { RoleService } from '../../../services/role.service';
import { environment } from '../../../environment/environment';

interface SezioneList {
  id: number;
  nome: string;
}
@Component({
  selector: 'app-dettaglio-stp',
  templateUrl: './dettaglio-stp.component.html',
  styleUrl: './dettaglio-stp.component.scss'
})
export class DettaglioStpComponent implements OnInit{

  regione = environment.regione;

  //services
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private stp_service = inject(StpService);
  private message_service = inject(MessageService);
  private unsaved_service = inject(UnsavedService);
  public shared_service = inject(SharedService);
  private dialog_service = inject(DialogService);
  private iscritti_service = inject(IscrittiService);
  private print_service = inject(PrintService);
  public role_service = inject(RoleService);

  severityButtons: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined = null;


  // headersMembri: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'Numero Iscrizione', 'Ordine', ''];
  // rowsMembri: string[] = ['codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'numeroIscrizione', 'ordine', 'rimuovi'];

  headersMembri: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Ruolo Socio', ''];
  rowsMembri: string[] = ['codiceFiscale', 'nome', 'cognome', 'ruoloSocio', 'rimuovi'];


  nuovoSocioLink: string | undefined;

  listaMembri: IscrittoStp[] = [];

  quoteStp: QuotaStp[] = [];
  listaQuote: ListaQuote = {
    lista: [],
    id: null,
    componente: 'readonly'
  }

  // tipoSocioList: TipoSocio[] = [];

  stpFilters: FiltersListIscrittiDto | undefined;

  sezioneList: SezioneList[] = [{
    id: 1,
    nome: 'STP'
  }];

  //dialog ref
  ref: DynamicDialogRef | undefined;

  //spinner dei bottoni
  isLoadingSpinner = false;

  //dati dei dropdown
  regioniData: Regione[] = [];
  nazioni: Nazione[] = [];
  province: Provincia[] = [];
  comuni: Comune[] = [];
  ordini: Ordine[] = [];

  filteredComuni: Comune[] = [];
  filteredProvince: Provincia[] = [];
  filteredNazione: Nazione[] = [];

  //nuovo stp route flag
  nuovoStp: boolean | null = null;

  //informazioni iniziali iscritto (se non nuovo)
  infoOriginali: Stp | null = null;
  dataConfrontoOriginale: any;

  //stp
  currentId = 0;

  //stp form control
  stpForm = new FormGroup({
    anagrafica: new FormGroup({
      codiceFiscale: new FormControl<string|null>(null, Validators.required),
      denominazione: new FormControl<string|null>(null, Validators.required),
      codiceStp: new FormControl<string|null>(null, Validators.required),
      costituzioneSocieta:  new FormControl<Date|null>(null, Validators.required),
      indirizzoSocieta:  new FormControl<string|null>(null, Validators.required),
      comuneSocieta:  new FormControl<Comune|null>(null, Validators.required),
      capSocieta:  new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(5)]),
      provinciaSocieta:  new FormControl<Provincia|null>(null, Validators.required),
      sezioneSocieta:  new FormControl<SezioneList|null>(null, Validators.required),
      iscrizioneData: new FormControl<Date|null>(null, Validators.required),
      domandaProtocollo: new FormControl<string|null>(null, Validators.required),
      domandaData: new FormControl<Date|null>(null, Validators.required),
      //notificaProtocollo: new FormControl<string|null>(null, Validators.required),
      //notificaProtocolloData: new FormControl<Date|null>(null, Validators.required),
      rea: new FormControl<string|null>(null, Validators.required),
      reaData: new FormControl<Date|null>(null, Validators.required),
      emailPeo: new FormControl<string|null>(null, Validators.required),
      emailPec: new FormControl<string|null>(null, Validators.required),
      numeroTelefono: new FormControl<string|null>(null, Validators.required),
      stpMultidisciplinare: new FormControl<boolean|null>(null),
      stpUnipersonale: new FormControl<boolean|null>(null)
    })
    // rappresentanteLegale: new FormGroup({
    //   rappresentanteOrdine: new FormControl<Ordine|null>(null),
    //   rappresentanteNumeroOrdine: new FormControl<string|null>(null),
    //   rappresentanteCodiceFiscale: new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(16)]),
    //   rappresentanteNome: new FormControl<string|null>(null, Validators.required),
    //   rappresentanteCognome: new FormControl<string|null>(null, Validators.required),
    //   rappresentanteDataNascita: new FormControl<Date|null>(null, Validators.required),
    //   rappresentanteLuogoNascita: new FormControl<string|null>(null, Validators.required),
    //   rappresentanteProvinciaNascita: new FormControl<Provincia|null>(null, Validators.required),
    //   rappresentanteNazionalita: new FormControl<Nazione|null>(null, Validators.required),
    //   rappresentanteIndirizzo: new FormControl<string|null>(null, Validators.required),
    //   rappresentanteCap: new FormControl<string|null>(null, [Validators.required, this.customLenghtValidator(5)]),
    //   rappresentanteCitta: new FormControl<string|null>(null, Validators.required),
    //   rappresentanteProvincia: new FormControl<Provincia|null>(null, Validators.required),
    //   tipoSocio: new FormControl<TipoSocio|null>(null, Validators.required)
    // })
  })

  //elementi del bottone stampa
  vociStampa = [
    {
      label: 'Certificato Iscrizione',
      command: () => { this.stampaCertidficatoIscrizione() }
    }
  ];

  ngOnInit(): void {

    this.initialHandler();

    this.popolaDropdown(this.route.snapshot.data['dropdown']);

    //caricamento stp on routing
    this.loadStp(this.route.snapshot.data['stp'], this.route.snapshot.data['nuovoStp']);
    this.loadQuote(this.route.snapshot.data['quote-stp']);
    console.log(this.quoteStp);


    this.stpFiltersTab();
  }

  initialHandler(){
    // this.stp_service.getTipoSocioList().subscribe({
    //   next: result => {
    //     this.tipoSocioList = result;
    //   },
    //   error: err => {console.log(err);}
    // });
  }

  unsavedChecker(): Promise<void>{

    if(this.confrontaDatiOriginali()){
      return Promise.resolve();
    }

    return this.unsaved_service.unsavedChangesChecker(this.stpForm);
  }

  stpFiltersTab(){
    this.stpFilters = this.route.snapshot.data['stpFilters'];
  }

  //assegna valori al form
  private loadStp(stp: Stp, routeStp: boolean){

    this.nuovoStp = routeStp;

    if(stp && !routeStp){

      this.currentId = stp.id;
      this.infoOriginali = stp;

      this.nuovoSocioLink = `/gestione_albo/stp/dettaglio_stp/${this.currentId}/nuovo_socio`;

      this.listaMembri = this.route.snapshot.data['stp_iscritti'];

      this.stpForm.clearValidators();

      this.stpForm.patchValue({
        anagrafica: {
          codiceFiscale: stp.codiceFiscale,
          denominazione: this.gestisciMaiuscole(stp.denominazione),
          codiceStp: stp.codiceStp,
          costituzioneSocieta: stp.costituzioneSocieta ? new Date(stp.costituzioneSocieta) : null,
          indirizzoSocieta:  this.gestisciMaiuscole(stp.indirizzoSocieta),
          comuneSocieta:  stp.comuneSocieta,
          capSocieta:  stp.capSocieta,
          provinciaSocieta:  stp.provinciaSocieta,
          sezioneSocieta:  this.sezioneList.find(s => s.nome === stp.sezioneSocieta),
          iscrizioneData: stp.iscrizioneData ? new Date(stp.iscrizioneData) : null,
          domandaProtocollo: stp.domandaProtocollo,
          domandaData: stp.domandaData ? new Date(stp.domandaData) : null,
          //notificaProtocollo: stp.notificaProtocollo,
          //notificaProtocolloData: stp.notificaProtocolloData ? new Date(stp.notificaProtocolloData) : null,
          rea: stp.rea,
          reaData: stp.reaData ? new Date(stp.reaData) : null,
          emailPeo: stp.emailPeo,
          emailPec: stp.emailPec,
          numeroTelefono: stp.numeroTelefono,
          stpMultidisciplinare: stp.stpMultidisciplinare,
          stpUnipersonale: stp.stpUnipersonale
        }
        // rappresentanteLegale: {
        //   rappresentanteOrdine: stp.rappresentanteOrdine,
        //   rappresentanteNumeroOrdine: stp.rappresentanteNumeroOrdine,
        //   rappresentanteCodiceFiscale: stp.rappresentanteCodiceFiscale.toUpperCase(),
        //   rappresentanteNome: this.gestisciMaiuscole(stp.rappresentanteNome),
        //   rappresentanteCognome: this.gestisciMaiuscole(stp.rappresentanteCognome),
        //   rappresentanteDataNascita: stp.domandaData ? new Date(stp.domandaData) : null,
        //   rappresentanteLuogoNascita: stp.rappresentanteLuogoNascita,
        //   rappresentanteProvinciaNascita: stp.rappresentanteProvinciaNascita,
        //   rappresentanteNazionalita: stp.rappresentanteNazionalita,
        //   rappresentanteIndirizzo: this.gestisciMaiuscole(stp.rappresentanteIndirizzo),
        //   rappresentanteCap: stp.rappresentanteCap,
        //   rappresentanteCitta: stp.rappresentanteCitta,
        //   rappresentanteProvincia: stp.rappresentanteProvincia,
        //   tipoSocio: stp.tipoSocio
        // }
      })

      this.dataConfrontoOriginale = this.creaOggetto(this.stpForm);

      if(!this.role_service.isAlboRole()){
        this.stpForm.disable();
      }
    }
  }

  private loadQuote(lista: QuotaStp[]){
    this.quoteStp = lista;
    this.listaQuote.id = this.currentId;
    this.listaQuote.lista = lista;
  }

  //invio dati nuova stp - modifica dati
  inviaDatiStp(){

    const formValues = this.stpForm.value;

    const data = {

      id: (this.nuovoStp) ? null : this.currentId,

      codiceFiscale: formValues.anagrafica?.codiceFiscale?.toUpperCase(),
      denominazione: this.gestisciMaiuscole(formValues.anagrafica?.denominazione),
      codiceStp: formValues.anagrafica?.codiceStp,
      costituzioneSocieta:  formValues.anagrafica?.costituzioneSocieta,
      indirizzoSocieta:  this.gestisciMaiuscole(formValues.anagrafica?.indirizzoSocieta),
      comuneSocieta:  formValues.anagrafica?.comuneSocieta,
      capSocieta:  formValues.anagrafica?.capSocieta,
      provinciaSocieta:  formValues.anagrafica?.provinciaSocieta,
      sezioneSocieta:  formValues.anagrafica?.sezioneSocieta?.nome,
      iscrizioneData: formValues.anagrafica?.iscrizioneData,
      domandaProtocollo: formValues.anagrafica?.domandaProtocollo,
      domandaData: formValues.anagrafica?.domandaData,
      //notificaProtocollo: formValues.anagrafica?.notificaProtocollo,
      //notificaProtocolloData: formValues.anagrafica?.notificaProtocolloData,
      rea: formValues.anagrafica?.rea,
      reaData: formValues.anagrafica?.reaData,

      emailPeo: formValues.anagrafica?.emailPeo,
      emailPec: formValues.anagrafica?.emailPec,
      numeroTelefono: formValues.anagrafica?.numeroTelefono,
      stpMultidisciplinare: formValues.anagrafica?.stpMultidisciplinare,
      stpUnipersonale: formValues.anagrafica?.stpUnipersonale,

      // rappresentanteOrdine: formValues.rappresentanteLegale?.rappresentanteOrdine,
      // rappresentanteNumeroOrdine: formValues.rappresentanteLegale?.rappresentanteNumeroOrdine,
      // rappresentanteCodiceFiscale: formValues.rappresentanteLegale?.rappresentanteCodiceFiscale?.toUpperCase(),
      // rappresentanteNome: this.gestisciMaiuscole(formValues.rappresentanteLegale?.rappresentanteNome),
      // rappresentanteCognome: this.gestisciMaiuscole(formValues.rappresentanteLegale?.rappresentanteCognome),
      // rappresentanteDataNascita: formValues.rappresentanteLegale?.rappresentanteDataNascita,
      // rappresentanteLuogoNascita: formValues.rappresentanteLegale?.rappresentanteLuogoNascita,
      // rappresentanteProvinciaNascita: formValues.rappresentanteLegale?.rappresentanteProvinciaNascita,
      // rappresentanteNazionalita: formValues.rappresentanteLegale?.rappresentanteNazionalita,
      // rappresentanteIndirizzo: this.gestisciMaiuscole(formValues.rappresentanteLegale?.rappresentanteIndirizzo),
      // rappresentanteCap: formValues.rappresentanteLegale?.rappresentanteCap,
      // rappresentanteCitta: formValues.rappresentanteLegale?.rappresentanteCitta,
      // rappresentanteProvincia: formValues.rappresentanteLegale?.rappresentanteProvincia,

      // tipoSocio: formValues.rappresentanteLegale?.tipoSocio
    }

    if(this.nuovoStp){
      this.inserisciNuovaStp(data)
    }else{
      this.modificaStp(data);
    }

  }

  //inserisci nuova stp nel database
  private inserisciNuovaStp(data: any){
    this.stp_service.inserisciStp(data).subscribe({
      next: result => {
        this.gestioneSpinner('Crea', result);
      },
      error: err => {
        this.gestioneSpinner('errore', '');
        this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: 'Qualcosa è andato storto.' })
      }
    });
  }

  //modifica stp
  private modificaStp(data: any){
    this.stp_service.modificaStp(this.currentId, data).subscribe({
      next: result => {
        this.gestioneSpinner('Modifica', '');
      },
      complete: () => {
        this.shared_service.changeMessage('Stp Dettaglio - ' + data.denominazione);
      },
      error: err => {
        this.gestioneSpinner('errore', '');
      }
    });
  }

  //gestione spinner bottone
  private gestioneSpinner(evento: string, id: string | number){
    this.isLoadingSpinner = true;
    setTimeout(() => {

      (evento === 'errore') ?
      this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: 'Qualcosa è andato storto.' })
        :
      this.message_service.add({ severity: 'success', summary: `${evento} completata`, detail: `STP ${evento.toLowerCase()}ta correttamente` })

      this.isLoadingSpinner = false;

      if(evento === 'Modifica'){
        this.stp_service.getStp(this.currentId).subscribe(result => {
          this.loadStp(result, false);
        });
      }

      if(evento === 'Crea'){
        this.router.navigate(
          [`/gestione-albo/stp/dettaglio-stp/${id}`]
        );
      }

      this.stpForm.markAsUntouched();
      this.stpForm.markAsPristine();

    }, 1300);
  }

  //validator personalizzato per lunghezza
  private customLenghtValidator(length: number){
    return (control: any) => {
      if (control.value && control.value.length !== length) {
        return { 'invalidLength': true };
      }
      return null;
    };
  }

  //prima lettera maiuscola
  private gestisciMaiuscole(stringa: string | null | undefined){

    if(stringa === null || stringa === undefined) return null;

    return stringa.split(" ").map(parola => primaMaiuscola(parola)).join(" ");

    function primaMaiuscola(parola: string): string{
      return parola.charAt(0).toUpperCase() + parola.slice(1);
    }
  }

  //popola i dropdown
  private popolaDropdown(dropdownData: StpDropdownData){

    this.regioniData = dropdownData.regioniList;
    this.nazioni = dropdownData.nazioneList;
    this.province = dropdownData.provinciaList;
    this.comuni = dropdownData.comuneList;
    this.ordini = dropdownData.ordiniList;

  }

  //autocompletamento per i p-autocomplete
  filterAutoComplete(event: AutoCompleteCompleteEvent, tipo: string){
    const query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filteredComuni = this.comuni.filter(comune =>
        queryParts.every(part =>
            comune.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'province'){
      this.filteredProvince = this.province.filter(provincia =>
        queryParts.every(part =>
            provincia.nome.toLowerCase().includes(part)
        )
      );
    }

    if(tipo === 'nazioni'){
      this.filteredNazione = this.nazioni.filter(nazione =>
        queryParts.every(part =>
            nazione.nome.toLowerCase().includes(part)
        )
      );
    }

  }

  nuovoProfessionistaDialog(){

    this.ref = this.dialog_service.open(DialogNuovoProfessionistaComponent, {
      data: {
        stpId: this.currentId
      },
      header: 'Cerca professionista'
    });

    this.ref.onClose.subscribe({
      next: result => {

        if(result){

          const data: ProfessionistaDto = {
            iscrittoItem: result.pro,
            idStp: this.currentId
          };

          this.stp_service.inserisciProfessionista(data).subscribe({
            complete: () =>{
              this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Socio associato.` });
              setTimeout(() => {
                this.stp_service.getIscrittiStp(this.currentId).subscribe(result => {
                  this.listaMembri = result;
                })
              }, 1000)
            },
            error: (error) => {
              const msg_not_found = error.status == 404 ? error.error : 'Qualcosa è andato storto.';
              this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: msg_not_found });
            }
          })

        }

      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Operazione interrotta', detail: 'Qualcosa è andato storto.' });
      }
    })

  }

  disableBottoneSalva(): boolean{
    if(this.stpForm.pristine || this.stpForm.invalid || this.confrontaDatiOriginali()){
      return true;
    }

    return false;
  }

  creaOggetto(formGroup: FormGroup): any {
    const result: any = {};
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        result[key] = this.creaOggetto(control);
      } else if (control instanceof FormControl) {
        result[key] = control.value;
      }
    });
    return result;
  }

  confrontaDatiOriginali(){

    const oggettoForm = this.creaOggetto(this.stpForm);

    return areObjectsEqual(oggettoForm, this.dataConfrontoOriginale);

    function areObjectsEqual(obj1: any, obj2: any): boolean {
      // Check if both are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }

      // Check if both have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Compare each key's value
      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }

        const val1 = obj1[key];
        const val2 = obj2[key];

        // Recursively compare nested objects
        if (typeof val1 === 'object' && typeof val2 === 'object') {
          if (!areObjectsEqual(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }

      return true; //i dati sono uguali all'originale
    }

  }

  stampaCertidficatoIscrizione(){

    this.print_service.stampaCertificatoIscrizioneStp(this.currentId).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        const a = document.createElement('a');
        a.href = url;
        a.download = `certificato_iscrizione_${this.infoOriginali?.denominazione.toLowerCase()}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })

  }

  // //rimuove professionista da stp
  rimuoviSocio(membro: any | number, event: any){

    event.stopPropagation();

    this.ref = this.dialog_service.open(DialogDinamicoComponent, {
          data: {
            tipo: 'rimuoviProfessionista',
            stpId: this.currentId,
            elemento: membro
          },
          header: 'Rimuovere Professionista'
    });


    this.ref.onClose.subscribe({
      next: result => {
        if(result.elemento){

          this.stp_service.rimuoviSocio(membro.id).subscribe({
            complete: () =>{
              this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Socio rimosso.` });
              setTimeout(() => {
                this.stp_service.getIscrittiStp(this.currentId).subscribe(result => {
                  this.listaMembri = result;
                })
              }, 1000)
            },
            error: (error) => {
              this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
            }
          })

        }

      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })

  }

}
