<div class="px-3 pt-3 pb-8">
  @if(componente === 'domanda'){
    <div class="flex p-3 mb-6 gap-3">
      <p-button
        pButton
        class="denyButton"
        (click)="rifiutaPraticaDialog()"
        label="Rifiuta">
      </p-button>
      <p-dialog header="Rifiuta Pratica" [modal]="true" [(visible)]="visibleR" [style]="{ width: '25rem' }">
        <div class="flex flex-column mb-3 justify-content-end">
            <label for="username" class="font-semibold">Motivo del rifiuto:</label>
            <input pInputText id="username" [(ngModel)]="rifiuto" autocomplete="off" />
        </div>
        <div class="flex justify-content-end">
            <p-button
              class="denyButton"
              [disabled]="rifiuto.length < 1"
              label="Invia rifiuto"
              (onClick)="rifiutaPratica()" />
        </div>
      </p-dialog>
    
      <p-button
        pButton
        class="acceptButton"
        (click)="accettaDomandaDialog()"
        label="Accetta" >
      </p-button>
      <p-dialog header="Accetta Pratica" [modal]="true" [(visible)]="visibleA" [style]="{ width: '25rem' }">
        <div class="flex flex-column mb-3 justify-content-end">
            <p>Accettare la pratica e passare l'utente ai Provvisori?</p>
        </div>
        <div class="flex justify-content-end">
          <p-button
            class="acceptButton"
            label="Accetta"
            (onClick)="accettaDomanda()" />
        </div>
      </p-dialog>
    </div>
  }
  @else{
    <h3 class="px-3 mb-6">Stato Pratica: 
      <span [ngStyle]="{ 'color': statoColori.get(domandaIscrizione?.statoIscrizione?.descrizione!) }">
        {{domandaIscrizione?.statoIscrizione?.descrizione}}
      </span>
    </h3>
    @if(domandaIscrizione?.statoIscrizione?.codice === 'R'){
      <div class="px-3 mb-6">
        <p-button pButton (click)="vediMotivo()" label="Motivo Rifiuto" severity="primary"></p-button>
        <p-dialog header="Motivo Rifiuto" [modal]="true" [(visible)]="visibleMotivo" [style]="{ width: '25rem' }">
            <p>{{ domandaIscrizione?.motivoRifiuto }}</p>
        </p-dialog>
      </div>
    }
  }
  <p-toast position="bottom-center" />

  <div [ngClass]="showAllegati ? 'mb-3' : ' mb-6'" class="px-3">
    <p-button
      label="ALLEGATI"
      icon="pi {{icon}}"
      iconPos="right"
      class="allegatiBtn" 
      (click)="showAllegatiButton()" />
  </div>
  @if(showAllegati){
    <div class="md:w-10 flex flex-column ml-3 px-3 mb-6 border-round shadow-4">
        @for(allegato of allegati; track allegato.id; let last = $last){
          <div [ngClass]="last ? '' : 'border-bottom-1'" class="w-full border-400 flex flex-wrap align-items-center p-1">
            <p class="w-7 sm:w-6 md:w-5">{{formatName(allegato.tipoFile)}}</p>
            <i (click)="visualizzaFile(allegato.tipoFile)" class="w-3 flex justify-content-center w-3 text-2xl pi pi-eye cursor-pointer"></i>
          </div>
        }
    </div>
  }

  <div class="flex flex-column backPanel border-round-3xl px-5 pt-5 pb-2">

    <p-fieldset legend="ESTREMI DOMANDA E DOCUMENTO" class="mb-4">

      <div class="flex flex-wrap pt-4">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="domandaProtocollo" [value]="domandaIscrizione?.domandaProtocollo" readonly/>
            <label class="pl-1" for="domandaProtocollo">Protocollo Domanda</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="domandaData" [value]="domandaIscrizione?.domandaData" readonly/>
            <label class="pl-1" for="domandaData">Data Domanda</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="sezioneAlbo" [value]="domandaIscrizione?.sezioneAlbo!.codice === 'B' ? 'B' : 'A'" readonly/>
            <label class="pl-1" for="sezioneAlbo">Sezione Albo</label>
          </span>
        </div>

      </div>

      <div class="flex flex-wrap">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="tipoDocumento" [value]="domandaIscrizione?.tipoDocumento?.descrizione" readonly/>
            <label class="pl-1" for="tipoDocumento">Tipo Documento</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="numeroDocumento" [value]="domandaIscrizione?.numeroDocumento" readonly/>
            <label class="pl-1" for="numeroDocumento">Numero Documento</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="dataRilascio" [value]="revertDateFormat(domandaIscrizione?.dataRilascioDocumento!)" readonly/>
            <label class="pl-1" for="dataRilascio">Data di Rilascio</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="rilasciatoDa" [value]="domandaIscrizione?.rilasciatoDa" readonly/>
            <label class="pl-1" for="rilasciatoDa">Rilasciato da</label>
          </span>
        </div>

      </div>

    </p-fieldset>

    <p-fieldset legend="INFORMAZIONI" class="mb-4">

      <div class="flex flex-wrap pt-4">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="codiceFiscale" [value]="domandaIscrizione?.codiceFiscale" readonly/>
            <label class="pl-1" for="codiceFiscale">Codice Fiscale</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="nome" [value]="domandaIscrizione?.nome" readonly/>
            <label class="pl-1" for="nome">Nome</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="cognome" [value]="domandaIscrizione?.cognome" readonly/>
            <label class="pl-1" for="cognome">Cognome</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="sesso" [value]="domandaIscrizione?.sesso === 'F' ? 'Femmina' : 'Maschio'" readonly/>
            <label class="pl-1" for="sesso">Sesso</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="dataNascita" [value]="revertDateFormat(domandaIscrizione?.dataNascita!)" readonly/>
            <label class="pl-1" for="dataNascita">Data Nascita</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="comuneNascita" [value]="domandaIscrizione?.comuneNascita?.nome" readonly/>
            <label class="pl-1" for="comuneNascita">Comune Nascita</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="proinciaNascita" [value]="domandaIscrizione?.provinciaNascita?.nome" readonly/>
            <label class="pl-1" for="proinciaNascita">Provincia Nascita</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="cittadinanza" [value]="domandaIscrizione?.cittadinanza?.nazionalita" readonly/>
            <label class="pl-1" for="cittadinanza">Cittadinanza</label>
          </span>
        </div>

      </div>

    </p-fieldset>

    <p-fieldset legend="RESIDENZA" class="mb-4">

      <div class="flex flex-wrap pt-4">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaIndirizzo" [value]="domandaIscrizione?.residenzaIndirizzo" readonly/>
            <label class="pl-1" for="residenzaIndirizzo">Indirizzo</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaCap" [value]="domandaIscrizione?.residenzaCap" readonly/>
            <label class="pl-1" for="residenzaCap">Cap</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaComune" [value]="domandaIscrizione?.residenzaComune?.nome" readonly/>
            <label class="pl-1" for="residenzaComune">Comune</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaProvincia" [value]="domandaIscrizione?.residenzaProvincia?.nome" readonly/>
            <label class="pl-1" for="residenzaProvincia">Provincia</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaStato" [value]="domandaIscrizione?.residenzaStato?.nome" readonly/>
            <label class="pl-1" for="residenzaStato">Stato</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="residenzaFrazione" [value]="domandaIscrizione?.residenzaFrazione" readonly/>
            <label class="pl-1" for="residenzaFrazione">Frazione</label>
          </span>
        </div>

      </div>


    </p-fieldset>

    @if(domandaIscrizione?.domicilioFlag){

      <p-fieldset legend="DOMICILIO" class="mb-4">

        <div class="flex flex-wrap pt-4">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioIndirizzo" [value]="domandaIscrizione?.domicilioIndirizzo" readonly/>
              <label class="pl-1" for="domicilioIndirizzo">Indirizzo</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioCap" [value]="domandaIscrizione?.domicilioCap" readonly/>
              <label class="pl-1" for="domicilioCap">Cap</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioComune" [value]="domandaIscrizione?.domicilioComune?.nome" readonly/>
              <label class="pl-1" for="domicilioComune">Comune</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioProvincia" [value]="domandaIscrizione?.domicilioProvincia?.nome" readonly/>
              <label class="pl-1" for="domicilioProvincia">Provincia</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioStato" [value]="domandaIscrizione?.domicilioStato?.nome" readonly/>
              <label class="pl-1" for="domicilioStato">Stato</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="domicilioFrazione" [value]="domandaIscrizione?.domicilioFrazione" readonly/>
              <label class="pl-1" for="domicilioFrazione">Frazione</label>
            </span>
          </div>

        </div>

      </p-fieldset>

    }
    
    <p-fieldset legend="CONTATTI" class="mb-4">

      <div class="flex flex-wrap pt-4">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="email" [value]="domandaIscrizione?.email" readonly/>
            <label class="pl-1" for="email">Email</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="pec" [value]="domandaIscrizione?.pec" readonly/>
            <label class="pl-1" for="pec">Pec</label>
          </span>
        </div>

      </div>

      <div class="flex flex-wrap">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="cellulare" [value]="domandaIscrizione?.cellulare" readonly/>
            <label class="pl-1" for="cellulare">Cellulare</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="telefono" [value]="domandaIscrizione?.telefono" readonly/>
            <label class="pl-1" for="telefono">Telefono</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="fax" [value]="domandaIscrizione?.fax" readonly/>
            <label class="pl-1" for="fax">Fax</label>
          </span>
        </div>

      </div>

    </p-fieldset>
    
    <p-fieldset legend="TITOLI E ABILITAZIONI" class="mb-4">

      <div class="flex flex-wrap pt-4">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="titolo" [value]="domandaIscrizione?.titolo?.nome" readonly/>
            <label class="pl-1" for="titolo">Titolo</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="univLaurea" [value]="domandaIscrizione?.univLaurea?.nome" readonly/>
            <label class="pl-1" for="univLaurea">Università Laurea</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="laureaData" [value]="revertDateFormat(domandaIscrizione?.laureaData!)" readonly/>
            <label class="pl-1" for="laureaData">Data Laurea</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="corsoLaurea" [value]="domandaIscrizione?.corsoLaurea" readonly/>
            <label class="pl-1" for="corsoLaurea">Corso Laurea</label>
          </span>
        </div>

      </div>

      <div class="flex flex-wrap">

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="universitaAbilitazione" [value]="domandaIscrizione?.universitaAbilitazione?.nome" readonly/>
            <label class="pl-1" for="universitaAbilitazione">Università Abilitazione</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="abilitazioneData" [value]="revertDateFormat(domandaIscrizione?.abilitazioneData!)" readonly/>
            <label class="pl-1" for="abilitazioneData">Data Abilitazione</label>
          </span>
        </div>

        <div>
          <span class="p-float-label px-3 mb-6">
            <input class="px-0" pInputText id="abilitazioneSessione" [value]="domandaIscrizione?.abilitazioneSessione" readonly/>
            <label class="pl-1" for="abilitazioneSessione">Sessione Abilitazione</label>
          </span>
        </div>

      </div>

      @if(domandaIscrizione?.dipendentePubblico){
          
        <p class="p-3">Dipendente Pubblico:</p>

        <div class="flex flex-wrap pt-4">

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="dipendentePubblicoPresso" [value]="domandaIscrizione?.dipendentePubblicoPresso" readonly/>
              <label class="pl-1" for="dipendentePubblicoPresso">Presso</label>
            </span>
          </div>

          <div>
            <span class="p-float-label px-3 mb-6">
              <input class="px-0" pInputText id="dipendentePubblicoRuolo" [value]="domandaIscrizione?.dipendentePubblicoRuolo" readonly/>
              <label class="pl-1" for="dipendentePubblicoRuolo">In Qualità di</label>
            </span>
          </div>

        </div>

        <div class="flex flex-wrap">

          <div class="px-3 mb-6">
            <p-checkbox class="mr-1" [binary]="true" inputId="articolo82" [ngModel]="domandaIscrizione?.articolo82" readonly></p-checkbox>
            <label class="checkboxLabelCustom" for="articolo82">Autorizzazione a Svolgere l'attività di libero professionista (Articolo 82)</label>
          </div>

        </div>
      }

      <div class="flex flex-wrap pt-4">

        <div class="px-3 mb-6">
          <p-checkbox class="mr-1" [binary]="true" inputId="delittiFlag" [ngModel]="domandaIscrizione?.delittiFlag" readonly></p-checkbox>
          <label class="checkboxLabelCustom" for="delittiFlag">Dichiaro di non aver riportato condanne penali passate (Articolo 35)</label>
        </div>

      </div>

      <div class="flex flex-wrap">

        <div class="px-3 mb-6">
          <p-checkbox class="mr-1" [binary]="true" inputId="altriConsigliFlag" [ngModel]="domandaIscrizione?.altriConsigliFlag" readonly></p-checkbox>
          <label class="checkboxLabelCustom" for="altriConsigliFlag">Dichiaro di non aver presentato domanda in altri Consigli Regionali o Provinciali</label>
        </div>

      </div>

      <div class="flex flex-wrap">

        <div class="px-3 mb-6">
          <p-checkbox class="mr-1" [binary]="true" inputId="tesserino" [ngModel]="domandaIscrizione?.tesserino" readonly></p-checkbox>
          <label class="checkboxLabelCustom" for="tesserino">Richiesta Tesserino</label>
        </div>

      </div>

    </p-fieldset>
  </div>

</div>
