import { Component, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { environment } from '../../environment/environment';
import { Delibera, IscrittiDeliberaChiusaDto, ProvvisorioDeliberaChiusa } from '../../model/delibere-data';
import { GruppoValore, ListaProvvisoriDto, FiltersListIscrittiDto } from '../../model/iscritti-data';
import { DeliberaService } from '../../services/delibera.service';
import { IscrittiService } from '../../services/iscritti.service';
import { PrintService } from '../../services/print.service';
import { RoleService } from '../../services/role.service';
import { DialogInviaEmailComponent } from '../dialog-invia-email/dialog-invia-email.component';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { TableSettings, SearchFilters } from '../../model/filters-data';

@Component({
  selector: 'app-tabella-delibere-chiuse',
  templateUrl: './tabella-delibere-chiuse.component.html',
  styleUrl: './tabella-delibere-chiuse.component.scss'
})
export class TabellaDelibereChiuseComponent {

  @ViewChild('dt') dt!: Table;

  resetFlag: boolean = false;
  initialFlagHandler: boolean = true;

  // prova per la gestione della regione per i componenti
  regione = environment.regione;

  //dialog dati
  ref: DynamicDialogRef | undefined;


  //services
  private route = inject(ActivatedRoute);
  private iscritti_service = inject(IscrittiService);
  private delibera_service = inject(DeliberaService);
  private message_service = inject(MessageService);
  private print_service = inject(PrintService);
  private custom_filters_service = inject(CustomFiltersService);
  public role_service = inject(RoleService);

  constructor(
    private dialogService: DialogService,
    //private readonly confirmationService : ConfirmationService,
      ) {}

  //gestione del componente
  tipoLista: string = '';
  componente: string = '';
  sezione: string = '';
  deliberaCorrente: Delibera | undefined;

  iconaDinamica: string = '';

  //filtro generale per tutti i tipi di tabella
  filtri = new FormGroup({
    inserimento: new FormControl<string|null>('')
  })

  sezioniOptions: GruppoValore[] = [
    { nome: 'Sezione A', valore: 'A' },
    { nome: 'Sezione B', valore: 'B' }
  ]

  //dati della tabella
  headersTabella: string[] = ['Codice Fiscale', 'Nome', 'Cognome', 'Sezione', 'N° Iscrizione', 'Pec']
  rowsTabella: string[] = ['codiceFiscale', 'nome', 'cognome', 'sezioneAlbo', 'numeroIscrizione', 'pec']
  listaIscritti: ProvvisorioDeliberaChiusa[] = [];

  dettaglioIscrittoRoute = '/gestione_albo/gestione_iscritti/dettaglio_iscritto/';

  orderColumnName: string = '';
  order: string = '';
  page: number = 0;
  pageSize: number = 25;
  currentPage: number = 0;

  totaleDati: number = 0;


  ngOnInit(): void {

    this.initialDataHandler(); //gestisce i dati iniziali

    this.filtri.valueChanges.subscribe(() => { //gestione sul cambio filtri
      this.resetPagination();
      this.loadData();
    })

  }


  //gestione dei dati iniziali
  private initialDataHandler(){

    const componente: string = this.route.snapshot.data['componente'];
    const iscritti: ListaProvvisoriDto = this.route.snapshot.data[componente];
    this.deliberaCorrente = this.route.snapshot.data['delibera'];

    this.componente = componente;

    this.listaIscritti = iscritti.lista;
    this.totaleDati = iscritti.conteggio;
    this.tipoLista = componente;

    this.inizializzaFiltri();

  }

  //gestisce il caricamento della tabella quando siamo sul componente
  private loadData(){

    const values = this.filtri.value;

    const tabData: TableSettings = {
      orderColumnName: this.orderColumnName,
      order: this.order,
      page: this.page,
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }

    const data: SearchFilters = this.custom_filters_service.sessionStorageUpdate(values, this.componente, tabData);

    this.delibera_service.getIscrittiByDeliberaId(this.deliberaCorrente?.id!,data).subscribe({
      next: (result: IscrittiDeliberaChiusaDto) => {
        this.listaIscritti = result.lista;
        this.totaleDati = result.conteggio;
      },
      error: (error) => {
        this.message_service.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Operazione interrotta.' });
      }
    })
  }

  //resertta paginazione
  resetPagination(){
    this.orderColumnName = 'codiceFiscale';
    this.order = 'ASC';
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;
  }

  //crea il RouterLink per le voci della tabella
  generaRoutingLink(iscritto: any): string{
    return this.dettaglioIscrittoRoute + iscritto.id;
  }

  //cambio pagina della tabella
  pageChange(event: any){
    this.currentPage = event.first;
    this.page = event.page;
    this.pageSize = event.rows;

    this.loadData();
  }

  ordinamento(event: any){

    if(this.initialFlagHandler){
      this.initialFlagHandler = false;
      return;
    }

    this.orderColumnName = event.sortField ? event.sortField : 'codiceFiscale';
    this.order = event.sortOrder === 1 ? 'ASC' : 'DESC'; //-1 discendente / 1 ascendente
    this.pageSize = 25;
    this.page = 0;
    this.currentPage = 0;

    if(this.resetFlag){
      this.resetFlag = false;
      this.dt.reset();
      return;
    }

    if(event.sortOrder === -1) this.resetFlag = true;

    this.loadData();
  }

  //resetta i lbox di ricerca
  resetBoxRicerca(){
    this.filtri.get('inserimento')?.setValue('')
  }

  //resetta i filtri di ricerca
  clearFilters(){

    this.resetPagination();

    this.filtri.patchValue({
      inserimento: ''
    })

    this.dt.reset();

    this.loadData();

  }

  //controlla regione di utilizzo
  checkRegione(){
    let colore: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;
    if(this.regione === 'opl') colore = 'primary'
    if(this.regione === 'opp') colore = 'warning'
    return colore;
  }

  //inizializza i filtri sul routing
  inizializzaFiltri(){

    const str: string = '';

    this.filtri.patchValue({
      inserimento: str
    })

    this.currentPage = 0;
    this.page = 0;
    this.pageSize = 25;

  }

  mandaInStampa(){

    const psi: boolean = this.deliberaCorrente?.tipo.codice === 'P';

    const idDelibera = this.deliberaCorrente?.id;
    const sezioneDelibera = this.deliberaCorrente?.tipo;

    this.print_service.stampaIscrizione(idDelibera!, sezioneDelibera?.codice.toLowerCase()!, psi).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'delibera_iscrizione_sezione_a.pdf';
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
        // document.body.removeChild(a);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }


  confermaInvio(event: Event) {
    if (this.deliberaCorrente) {
      this.ref = this.dialogService.open(DialogInviaEmailComponent, {
        header: 'Attenzione',
        width: '50vw',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
        },
        // templates: {
        //     footer: Footer
        // }
    });

    this.ref.onClose.subscribe((data: any) => {
        if (data) {
          this.print_service.notificaEmail(this.deliberaCorrente!!.id, data).subscribe({
            next: (inviate: boolean)=>{
              if (inviate)
              this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Email di notifica inviate.` });
            }
          });

        } else {

        }

    });



      // this.confirmationService.confirm({
      //     target: event.target as EventTarget,
      //     message: 'Confermi invio email a tutti gli iscritti presenti nella delibera?',
      //     header: 'Attenzione',
      //     icon: 'pi pi-exclamation-triangle',
      //     acceptIcon:"pi pi-send",
      //     rejectVisible: false,
      //     // rejectIcon:"pi pi-times",
      //     acceptLabel:"Invia",
      //     acceptButtonStyleClass: "p-button-danger",
      //     //rejectButtonStyleClass:"p-button-text",
      //     accept: () => {
      //         this.print_service.notificaEmail(this.deliberaCorrente!!.id).subscribe({
      //           next: (inviate: boolean)=>{
      //             if (inviate)
      //             this.message_service.add({ severity: 'success', summary: `Operazione completata`, detail: `Email di notifica inviate.` });
      //           }
      //         });
      //     },
      //     reject: () => {

      //     }
      // });
    }
}

}
